import React, { Component, createElement } from "react";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import {firestore} from '../config/firebase';
import "firebase/auth";
import ROUTES from "../ROUTES";
import ftlogo from "../logo.png";
import defaultAvatar from "../default-avatar.png";
import "jquery";
import authService from "../services/authService";
import authStore from "../models/AuthStore";
import Config from "../config/Config";
import Utils from "../Helpers/Utility/Utils";
import { Link as RSLink } from "react-scroll";
import ReactTooltip from 'react-tooltip';
import './nav.scss';
import {database} from '../config/firebase';

declare var $: any;
export interface NavProps extends RouteComponentProps { }

export interface NavState { }

class Nav extends Component<any, NavState> {
  showModal() {
    $("#exampleModal").modal("show");
  }
  state = {
    currentUser: null,
    collapsed: true,
    user:{firstname:""}
  };
  componentDidMount() {
    this.setState({ currentUser: authStore.user });
  }

  isActive = (path1: any, path2?: any, path3?: any) => {
    if (
      this.props.location.pathname == path1 ||
      this.props.location.pathname == path2 ||
      this.props.location.pathname == path3
    ) {
      return "active";
    } else {
      return "";
    }
  };
  isClassActive = (path1: any, path2?: any, path3?: any, path4?: any) => {
    if (
      this.props.location.pathname + this.props.location.search == path1 ||
      this.props.location.pathname == path2
    ) {
      return "active";
    } else {
      return "";
    }
  };
  async signOut() {
    sessionStorage.removeItem("Keep me signed in");

    // make me inactive
    database.ref('activeUsers').child(authStore.user.email.toString().replace(/\./g,",")).remove();

    authService.signOut();
    setTimeout(() => {
      this.props.history.push(ROUTES.HOME);
    },500);
    // this.showModal();
  }

  getLoggedInUsername = () => {
    firestore.collection(authStore.user.role.toLowerCase()).doc(authStore.user.user_id).get().then((snap:any) => {
        this.setState(st => ({...st, user:{firstname:snap.data().firstname}}));
    }).catch((e:any) => {
    })
  }

  componentWillUpdate(){
    if(authStore.user){
      if(this.state.user.firstname.length === 0){
        this.getLoggedInUsername();
      }
    }
  }

  render() {
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse "
      : "collapse navbar-collapse show ";
    const classTwo = collapsed ? "navbar-toggle collapsed" : "navbar-toggle";

    return (
      <header className="header header_style_01">
        <nav className="megamenu navbar navbar-default "></nav>
        <nav className="megamenu navbar navbar-default backgroundImage-h ">
          <div className="container-fluid">
            <div className="navbar-header top-screen-ft">
              <button
                type="button"
                className={`${classTwo}` + " align-button-right"}
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded={this.state.collapsed ? "false" : "true"}
                aria-controls="navbar"
                aria-label="Toggle navigation"
                onClick={() => {
                  this.setState({ collapsed: !this.state.collapsed });
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>

              <div className="top-screen-fit">
                {authStore.user &&
                  (authStore.user.role == "STUDENT" ||
                    authStore.user.role == "PROFESSOR" ||
                    authStore.user.role == "TEACHER" || 
                    authStore.user.role == "PLAYER") ? (
                    <Link to={ROUTES.HOME} className="navbar-brand">
                      <img
                        style={{marginTop:6, marginRight:0}}
                        src={ftlogo}
                        width="33px"
                        height="25px"
                        alt="image"
                      />
                    </Link>
                  ) : (
                    <a
                    onClick={this.props.minimizeAllTabs}
                      className="navbar-brand"
                      href={
                        authStore.user &&
                          (authStore.user.role == "STUDENT" ||
                            authStore.user.role == "PROFESSOR" ||
                            authStore.user.role == "TEACHER"||
                            authStore.user.role == "PLAYER")
                          ? "/"
                          : "/"
                      }
                    >
                      <img
                      onClick={() => {
                        this.props.minimizeAllTabs();
                        window.location.href="/"
                      }}
                        style={{marginTop:6, marginRight:0}}
                        src={ftlogo}
                        width="33px"
                        height="25px"
                        alt="image"
                      />
                    </a>
                  )}
              </div>
              
              <div className="top-screen-fit ft-logo-text" style={{position:'relative', left:-10}}>
                {authStore.user &&
                  (authStore.user.role == "STUDENT" ||
                    authStore.user.role == "PROFESSOR" ||
                    authStore.user.role == "TEACHER") ? (
                    <Link to={ROUTES.HOME} className="navbar-brand">
                      <span
                      onClick={this.props.minimizeAllTabs}
                        className="future-traders-top-align ft-right  "
                        style={{
                          color: "#fd6802",
                          fontSize: "28px",
                          marginLeft:0,
                          fontFamily:'Heebo',
                        }}
                      >
                        Future
                    </span>
                      <span
                      onClick={this.props.minimizeAllTabs}
                        className="future-traders-top-align ft-left"
                        style={{
                          color: "#1a73e8",
                          fontSize: "28px",
                          marginLeft: "0px",
                          fontFamily:'Heebo',
                        }}
                      >
                        Traders
                    </span>

                      <span
                        className="beta-top-align"
                        style={{
                          color: "#FF1717",
                          fontSize: "14px",
                          fontWeight: 700,
                          marginTop: 8,
                          marginLeft: "5px",
                        }}
                      >
                        <i>(beta)</i>
                      </span>
                      {/* </a> */}
                    </Link>
                  ) : (
                    <a
                      className="navbar-brand"
                      href={
                        authStore.user &&
                          (authStore.user.role == "STUDENT" ||
                            authStore.user.role == "PROFESSOR" ||
                            authStore.user.role == "TEACHER")
                          ? "/"
                          : "/"
                      }
                    >
                      <span
                      onClick={this.props.minimizeAllTabs}
                        className="future-traders-top-align ft-right  "
                        style={{
                          color: "#fd6802",
                          fontSize: "28px",
                          fontFamily:'Heebo',
                        }}
                      >
                        Future
                    </span>
                      <span
                      onClick={this.props.minimizeAllTabs}
                        className="future-traders-top-align ft-left"
                        style={{
                          color: "#1a73e8",
                          fontSize: "28px",
                          marginLeft: "0px",
                          fontFamily:'Heebo',
                        }}
                      >
                        Traders
                    </span>

                      <span
                        className="beta-top-align"
                        style={{
                          color: "#FF1717",
                          fontSize: "14px",
                          fontWeight: 700,
                          marginTop: 8,
                          marginLeft: "5px",
                        }}
                      >
                        <i>(beta)</i>
                      </span>
                    </a>
                  )}
              </div>
            </div>
            <div id="navbar" className={`${classOne}`}>
              <ul
                className={
                  !this.state.collapsed
                    ? "navbar-nav nav navbar-right"
                    : "navbar-nav nav navbar-right hides-onclick"
                }
              >

                {/* register button */}
                {!authStore.user ? (
                  <li>
                    <a
                      className={"btn " + this.isActive(ROUTES.PROFESSORREG)}
                      onClick={() => this.isActive(ROUTES.PROFESSORREG)}
                      style={{ textAlign: "left", fontSize:14.5 }}
                    >
                      Register
                    </a>
                    <ul className="dropdown align-register">
                      <li className="border-bottom">
                        <Link
                          to={ROUTES.PROFESSORREG}
                          onClick={() => {
                            this.setState({ collapsed: !this.state.collapsed });
                          }}
                          className={"contest-dropdown-item "}
                        >
                          <strong style={{fontSize:14.5}}> Professor</strong>
                        </Link>
                      </li>
                      <li className="border-bottom">
                        <Link
                          to={ROUTES.TEACHERREG}
                          onClick={() => {
                            this.setState({ collapsed: !this.state.collapsed });
                          }}
                          className={"contest-dropdown-item "}
                        >
                          <strong style={{fontSize:14.5}}> Teacher</strong>
                        </Link>
                      </li>
                      {/* <li className="border-bottom">
                        <Link
                          to={ROUTES.STUDENTREG}
                          onClick={() => {
                            this.setState({ collapsed: !this.state.collapsed });
                          }}
                        >
                          Student
                        </Link>
                      </li> */}
                      <li className="border-bottom">
                        <Link
                          to={ROUTES.PLAYERREG}
                          onClick={() => {
                            this.setState({ collapsed: !this.state.collapsed });
                          }}
                          className={"contest-dropdown-item "}
                        >
                          <strong style={{fontSize:14.5}}>Player</strong>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : null}

                {/* Experience FT! button */}
                {!authStore.user ? (
                  <li>
                    <a
                      className={"btn"}
                      style={{ textAlign: "left", fontSize:14.5 }}
                    >
                      Experience FT!
                    </a>
                    <ul className="dropdown align-register">
                      <li onClick={
                            () => window.location.href = "#what-is-ft"
                      } className="border-bottom contest-dropdown-item">
                          <strong style={{fontSize:14.5, marginLeft:8, cursor:'pointer'}}> FT Platform</strong>
                      </li>
                      <li className="border-bottom contest-dropdown-item" onClick={() => {
                            window.location.href = "#video-tutorials"
                            this.setState({ collapsed: !this.state.collapsed });
                          }}>
                        <strong style={{fontSize:14.5, marginLeft:8, cursor:'pointer'}}> FT Game</strong>
                      </li>
                    </ul>
                  </li>
                ) : null}

                {/* Contact us */}
                {
                  !authStore.user ? 
                  <li>
                  <a
                    href="#contact_us"
                    className={"btn"}
                    style={{ textAlign: "left", fontSize:14.5 }}
                  >
                    CONTACT US
                  </a>
                </li>: null
                }

                {/* how to play button */}
                {/* {!this.isActive(ROUTES.HOME) ? null : (
                  <li>
                    <RSLink
                      id="how-to"
                      offset={-80}
                      activeClass="active"
                      className="btn btn-primary btn-lg register-button-o"
                      to="video-tutorials"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      <div style={{fontSize:14.5}} >How to Play</div>
                    </RSLink>
                  </li>
                )} */}

                {authStore.user ?
                  <li>
                    <a href={"/guide?page=" + authStore.user.role.toLowerCase()} target="_blank" style={{ boxShadow: 'none', fontSize:14.5 }}>Interactive Guide</a>
                  </li> : null
                }
                {authStore.user &&
                  (authStore.user.role == "STUDENT" ||
                    authStore.user.role == "PROFESSOR" ||
                    authStore.user.role == "TEACHER") ? (
                    <li onClick={this.props.minimizeAllTabs}>
                      <Link
                        style={{ height: 34, boxShadow: 'none'}}
                        to={ROUTES.CLASSLIST}
                        className={this.isClassActive(
                          ROUTES.CLASSLIST,
                          ROUTES.DASHBOARD,
                          ROUTES.QUIZ,
                          ROUTES.TOPICITEM
                        )}
                      >
                        <div style={{fontSize:14.5}}>My Classes</div>
                    </Link>
                    </li>
                  ) : null}
                {/* {authStore.user && authStore.user.role == "SUPERADMIN" ? (
                  <li>
                    <Link
                      to={ROUTES.CARDLIST}
                      className={this.isClassActive(
                        ROUTES.CARDLIST,
                        ROUTES.DASHBOARD
                      )}
                    >
                      My Cards
                    </Link>
                  </li>
                ) : null} */}
                {authStore.user &&
                  (authStore.user.role == "PLAYER" ||
                    authStore.user.role == "PROFESSOR" ||
                    authStore.user.role == "STUDENT" ||
                    authStore.user.role == "TEACHER") ? (
                    <li onClick={this.props.minimizeAllTabs}>

                      {
                        authStore.user.role == "STUDENT" ?
                          <Link 
                        style={{ height: 34, boxShadow: 'none'}}
                          className={
                            "btn " +
                            (this.isClassActive(ROUTES.HOSTED_CONTESTLIST) ||
                              this.isClassActive(ROUTES.INVITED_CONTESTLIST))
                          } to={ROUTES.INVITED_CONTESTLIST}><div style={{fontSize:14.5, height: 34}}>My Contests</div></Link> :
                          <a
                            className={
                              "btn " +
                              (this.isClassActive(ROUTES.HOSTED_CONTESTLIST) ||
                                this.isClassActive(ROUTES.INVITED_CONTESTLIST))
                            }
                            style={{ textAlign: "center", height: 34 }}
                          >
                            <div style={{fontSize:14.5}}>My Contests</div>
                          </a>
                      }
                      {
                        authStore.user.role != "STUDENT" ?
                          <ul className="dropdown align-register">
                            {authStore.user &&
                              (authStore.user.role == "PLAYER" ||
                                authStore.user.role == "PROFESSOR" ||
                                authStore.user.role == "TEACHER") ? (
                                <li className="border-bottom">
                                  <Link
                                    to={ROUTES.HOSTED_CONTESTLIST}
                                    className={
                                      "contest-dropdown-item " +
                                      this.isClassActive(ROUTES.HOSTED_CONTESTLIST)
                                    }
                                  >
                                    <strong style={{fontSize:14.5}}>Hosted</strong>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )}
                            {authStore.user &&
                              (authStore.user.role == "PLAYER" ||
                                authStore.user.role == "PROFESSOR" ||
                                authStore.user.role == "TEACHER") ? (
                                <li className="border-bottom">
                                  <Link
                                    to={ROUTES.INVITED_CONTESTLIST}
                                    className={
                                      "contest-dropdown-item " +
                                      this.isClassActive(ROUTES.INVITED_CONTESTLIST)
                                    }
                                  >
                                    <strong style={{fontSize:14.5}}>Participating</strong>
                                  </Link>
                                </li>
                              ) : null}

                            {/* {authStore.user && authStore.user.role == "STUDENT" ? (
                          <li className="border-bottom">
                            <Link
                              to={ROUTES.INVITED_CONTESTLIST}
                              className={
                                "contest-dropdown-item " +
                                this.isClassActive(ROUTES.INVITED_CONTESTLIST)
                              }
                            >
                            </Link>
                          </li>
                        ) : null} */}
                          </ul> : null
                      }
                    </li>
                  ) : null}
                  {
                    authStore.user && authStore.user.role == "PLAYER" ?
                    <li onClick={this.props.minimizeAllTabs}>
                      <Link 
                        style={{ height: 34, boxShadow: 'none'}}
                          className={"btn "+this.isClassActive(ROUTES.MY_GAMES)} to={ROUTES.MY_GAMES}><div style={{fontSize:14.5, height: 34}}>My Games</div></Link>
                    </li>:null
                  }

                {/* {authStore.user &&
                (authStore.user.role == "PROFESSOR" ||
                  authStore.user.role == "TEACHER") ? (
                  <li>
                    <Link
                      to={ROUTES.INVITE}
                      className={this.isActive(ROUTES.INVITE)}
                    >
                      Invite Student
                    </Link>
                  </li>
                ) : null} */}
                {/* <li>
                  {authStore.user &&
                    (authStore.user.role == "PROFESSOR" ||
                      authStore.user.role == "TEACHER" || 
                      authStore.user.role == "STUDENT" || 
                      authStore.user.role == "PLAYER") ? (null
                      // <a
                      //   className="redirect-link"
                      //   href={
                      //     Config.base_url.includes("staging")
                      //       ? "https://playfuturetraderstaging.firebaseapp.com?token=" +
                      //         authStore.authToken
                      //       : "http://playftgameapp.firebaseapp.com?token=" +
                      //         authStore.authToken
                      //   }
                      //   target="_blank"
                      // >
                      //   Play Game
                      // </a>
                    ) : (
                      <a
                        href={"https://playfuturetradergame.firebaseapp.com/"}
                        onClick={() =>
                          this.setState({ collapsed: !this.state.collapsed })
                        }
                        target="_blank"
                      >
                        <div style={{fontSize:14.5}}>Play Demo</div>
                      </a>
                    )}
                </li> */}
                {/* {authStore.user ? null : (
                  <li>
                    <Link
                      to={ROUTES.CONTACTUS}
                      className={this.isActive(ROUTES.CONTACTUS)}
                      onClick={() => {
                        this.setState({ collapsed: !this.state.collapsed });
                      }}
                    >
                      Contact Us
                    </Link>
                  </li>
                )} */}
                {!authStore.user ? (
                  <li>
                    <a
                      className="btn"
                      onClick={() => {
                        this.showModal();
                        this.setState({ collapsed: !this.state.collapsed });
                      }}
                      style={{ textAlign: "left" }}
                    >
                      <div style={{fontSize:14.5}}>Login</div>
                    </a>
                  </li>
                ) : (
                    <ul style={{position:'relative', marginLeft:14, marginRight:18}} onClick={this.props.minimizeAllTabs} className="user-wrap profile-logout">
                      <div className="pro">
                        <a
                          onClick={this.props.minimizeAllTabs}
                          href=""
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">

                          {authStore.user.role == "PROFESSOR" || authStore.user.role == "TEACHER" ? (
                            <div onClick={this.props.minimizeAllTabs} data-tip data-for="tt_p_t_s_p">
                              <span className="desig-span"><i className="fas fa-user-tie"></i><label onClick={this.props.minimizeAllTabs} style={{ marginLeft: 10, marginRight: 20, fontSize:14.5 }}>{this.state.user.firstname}</label></span>
                            </div>
                          ) : authStore.user.role == "STUDENT" ? (
                            <div onClick={this.props.minimizeAllTabs} data-tip data-for="tt_p_t_s_p">
                              <span className="desig-span"><i className="fas fa-user-graduate"></i><label onClick={this.props.minimizeAllTabs} style={{ marginLeft: 10, marginRight: 20, fontSize:14.5 }}>{this.state.user.firstname}</label></span>
                            </div>
                          ) : authStore.user.role === "PLAYER" ? (
                            <div onClick={this.props.minimizeAllTabs} data-tip data-for="tt_p_t_s_p">
                              <span className="desig-span"><i className="fas fa-user"></i><label onClick={this.props.minimizeAllTabs} style={{ marginLeft: 10, marginRight: 20, fontSize:14.5 }}>{this.state.user.firstname}</label></span>
                            </div>
                          ) : authStore.user.role == "SUPERADMIN" ? (
                            <div className="">
                              {" "}
                              <span className="desig-span">
                                {" "}
                                {"SA - "}
                                {authStore.user.name.split(" ")[0]}
                              &nbsp;&nbsp;&nbsp;
                            </span>{" "}
                            </div>
                          ) : authStore.user.role == "TEACHER" ? (
                            <div className="">
                              {" "}
                              <span className="desig-span">
                                {" "}
                                {"T - "}
                                {authStore.user.name.split(" ")[0]}
                              &nbsp;&nbsp;&nbsp;
                            </span>{" "}
                            </div>
                          ) : null}
                          <span
                            className="glyphicon glyphicon-triangle-bottom glyphicon-triangle-bottom-position  down-arow arrow-down"
                            aria-hidden="true"
                          ></span>
                        </a>
                        <ReactTooltip multiline={true} id="tt_p_t_s_p" backgroundColor="black" place="bottom" effect="solid"><div style={{ maxWidth: 340 }}>Signed in as a {authStore.user.role.toLowerCase()}</div></ReactTooltip>

                        <div className="dropdown-menu log-out">
                          <ul className="dropdown-logout">
                            <li>
                              <Link
                                to={
                                  authStore.user.role == "PROFESSOR"
                                    ? {
                                      pathname: ROUTES.PROFESSORREG,
                                      state: { id: authStore.user.user_id }
                                    }
                                    : authStore.user.role == "TEACHER"
                                      ? {
                                        pathname: ROUTES.TEACHERREG,
                                        state: { id: authStore.user.user_id }
                                      }
                                      : authStore.user.role == "STUDENT"
                                        ? {
                                          pathname: ROUTES.STUDENTREG,
                                          state: { id: authStore.user.user_id }
                                        }
                                        : authStore.user.role == "SUPERADMIN"
                                          ? {
                                            pathname: ROUTES.SUPERADMIN_REG,
                                            state: { id: authStore.user.user_id }
                                          }
                                          : {
                                            pathname: ROUTES.PLAYER_PROFILE,
                                            state: { id: authStore.user.user_id }
                                          }
                                }
                                className={this.isActive(
                                  ROUTES.PROFESSORREG,
                                  ROUTES.STUDENTREG,
                                  ROUTES.TEACHERREG
                                )}
                              >
                                &nbsp;&nbsp; My Profile
                            </Link>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  this.signOut();
                                  this.setState({
                                    collapsed: !this.state.collapsed
                                  });
                                }}
                              >
                                &nbsp;&nbsp; Logout
                            </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ul>
                  )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Nav);
