import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ContestDetails } from "../../interfaces";
import SubscriptionItem from "./SubscriptionItem";
import contesttService from "../../services/contestService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ROUTES from "../../ROUTES";
import Toast from "../../components/Toast";
import authStore from "../../models/AuthStore";
import Validate from "../../Helpers/Utility/Validation";
import "jquery";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import LoadingWheel from "../../components/LoadingWheel";
import { any } from "prop-types";
import PrintComponents from "react-print-components";
import ComponentToPrint from "./ComponentToPrint";
import Select from "react-select";
import winnerSvg from "../../winnerSvg.svg";
import config from '../../config';
import Config from '../../config/Config';

//models
import ContestCreated from '../../models/contestCreated';
import ContestStudentInvite from '../../components/ContestStudentInvite';

var QRCode = require("qrcode.react");
declare var $: any;
const ct = require("countries-and-timezones");
const moment = require("moment");

function styleFn(provided?: any, state?: any) {
  return { ...provided, theme: "danger" };
}

export interface RegProps extends RouteComponentProps { }

export interface RegState { }
class ContestReg extends Component<any, RegState> {
  state = {
    Page: 1,
    contestDetails: { initialCapital: 1000, noofparticipants: 100, roundAteachLevel: 5, description: " " } as any,
    errors: {} as any,
    showLoader: false,
    contests: Array<ContestDetails>(),
    selectedContest: Array<ContestDetails>(),
    isDateValueNull: false,
    isSubmit: false,
    selectContestErrorMsg: "",
    checkbox_checked: false,
    //for modal
    showContestCreatedModel: false,
    contestHolder: null,
    showContestStudentInviteModel: false,

    //for game create
    type: "Contest"
  };

  msg = "";
  timeZone: any;
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);

  setDefaultValues() {
    var today = new Date();
    var week = 7 * 24 * 60 * 60 * 1000;
    var three_months = 12 * week;
    var dateAfterWeek = new Date(today.getTime() + week);
    var dateAfterThreeMonths = new Date(today.getTime() + three_months);

    this.state.contestDetails.regStartDate = today;
    this.state.contestDetails.regEndDate = today;
    this.state.contestDetails.tradeStartDate = today;
    this.state.contestDetails.tradeEndDate = today;
    this.setState(st => ({ ...st, contestDetails: { ...this.state.contestDetails, 'regStartDate': today, 'regEndDate': dateAfterWeek, 'tradeStartDate': dateAfterWeek, 'tradeEndDate': dateAfterThreeMonths } }))

    this.setState(
      Object.assign(this.state.contestDetails, this.state.contestDetails)
    );
  }

  SelectAllGameTypes() {
    this.setState({ checkbox_checked: true });
  }

  SelectNoGameTypes() {
    this.setState({ checkbox_checked: false });
  }

  updateUserState = (e: any, type?: any) => {
    if (type && e.target.value.length <= 100) {
      if (type == "text") {
        if (e.target.value.match(/^[A-Za-z\s]{0,}$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value.trimLeft();
          this.setState(Object.assign(this.state.contestDetails, stateObject));
        }
      } else if (type == "number") {
        if (
          e.target.value <= 10000 &&
          (e.target.value != 0 || e.target.value == "")
        ) {
          if (
            e.target.name == "noofparticipants" ||
            e.target.name == "passingScore"
          ) {
            if (e.target.value <= 100) {
              let stateObject = {} as any;
              stateObject[e.target.name] = e.target.value;
              this.setState(
                Object.assign(this.state.contestDetails, stateObject)
              );
            }
          } else if (
            e.target.name == "roundAteachLevel" ||
            e.target.name == "noofattempts"
          ) {
            if (e.target.value <= 5) {
              let stateObject = {} as any;
              stateObject[e.target.name] = e.target.value;
              this.setState(
                Object.assign(this.state.contestDetails, stateObject)
              );
            }
          } else {
            let stateObject = {} as any;
            stateObject[e.target.name] = e.target.value;
            this.setState(
              Object.assign(this.state.contestDetails, stateObject)
            );
          }
        }
      }
    } else if (e.target.value.length <= 100) {
      let stateObject = {} as any;
      stateObject[e.target.name] = e.target.value;
      this.setState(Object.assign(this.state.contestDetails, stateObject));
    }
  };

  updateRadioValue = (e: any) => {
    let stateObject = {} as any;
    stateObject[e.target.name] = e.target.title;
    this.setState(Object.assign(this.state.contestDetails, stateObject));
  };

  updateCheckbox = (e: any) => {
    this.state.contestDetails.tradeRules =
      this.state.contestDetails.tradeRules || [];
    if (e.target.name == "All") {
      if (e.target.checked) {
        this.state.contestDetails.tradeRules = [
          "Stocks",
          "Bonds",
          // "Options",
          // "Technical Analysis",
          // "Fixed Income",
          // "Commodities",
          // "Currencies",
          // "Crypto-Commodities",
        ];
      } else {
        this.state.contestDetails.tradeRules = [];
      }
    } else {
      if (e.target.checked) {
        if (this.state.contestDetails.tradeRules) {
          this.state.contestDetails.tradeRules.push(e.target.name);
        } else {
          this.state.contestDetails.tradeRules = e.target.name;
        }
      } else {
        this.state.contestDetails.tradeRules = this.state.contestDetails.tradeRules.filter(
          (item: any) => item !== e.target.name
        );
      }
    }
    this.setState(
      Object.assign(this.state.contestDetails, this.state.contestDetails)
    );
  };

  async componentDidMount() {
    this.setState(Object.assign(this.state.contestDetails, { "timeZone": "America/New_York" }));

    this.setTimeZone();

    if(this.props.history.location.game){
      this.setState(st => ({...st, type:"Game"}));
      const day = 24 * 60 * 60 * 1000;
      const today = new Date().getTime();
      const data = {
        contestname:authStore.user.name.split(" ")[0]+new Date().getTime(),
        subject:"Other",
        description:"Game created at "+new Date().toLocaleString(),
        regStartDate: new Date().toLocaleString(),
        regEndDate: new Date(today + (30 * day)).toLocaleString(),
        tradeStartDate: new Date().toLocaleString(),
        tradeEndDate: new Date(today + (90 * day)).toLocaleString(),
        timeZone:"America/New_York",
        noofparticipants:10,
        tradeRules:["Stocks","Bonds"],
        initialCapital:1000,
        roundAteachLevel:1,
      }
      this.createAndLaunchGame(data);
    }

    if (this.props.location.state) {
      this.setState({ showLoader: true });
      let contestDetails = await contesttService.getContestbyId(
        this.props.location.state.id
      );
      this.setState({ showLoader: false });
      this.setState({ contestDetails: contestDetails.data });
    } else {
      this.setDefaultValues();
      if (this.state.contestDetails.tradeRules) {
        this.state.contestDetails.tradeRules = [];
      }

      // contesttService.getAllContestsForProfessor().then(res => {
      //     this.setState({ contests: res.data })
      // })
      //     .catch((error) => {
      //         console.log(error)
      //     })
    }
  }

  async createAndLaunchGame(contestDetails:any) {
    this.setState({ showLoader: true });
    await contesttService.createGame(contestDetails).then(async (res) => {
      this.setState({ showLoader: false });
      if (res.status == 200) {
        this.msg = "Success";
         this.notifySuccess();
            window.open(Config.base_url.includes("staging")
            ? "https://playftcontestgamestg.firebaseapp.com?token=" +
            authStore.authToken +
            "&current_contest_name=" +
            res.contestData.id
            : "https://playftcontestgame.firebaseapp.com?token=" +
            authStore.authToken +
            "&current_contest_name=" +
            res.contestData.id
            ,"_blank")
        setTimeout(() => {
          this.props.history.push({
            pathname: ROUTES.MY_GAMES,
          });
        }, 2000);
      } else if (res.status == 401) {
        this.msg = "Unauthorized";
        this.notifyError();
        this.setState({ isSubmit: false });
      } else if(res.status === 400){
        alert("Game name is already taken")
        this.msg = "Game name is already taken";
        this.notifyError();
        this.setState({ isSubmit: false });
      } else {
        this.msg = "Something went wrong. Please try again later";
        this.notifyError();
        this.setState({ isSubmit: false });
      }
    }).catch((e) => {
      this.setState({ showLoader: false });
      this.msg = "Something went wrong. Please try again later";
      this.notifyError();
      this.setState({ isSubmit: false });
    });

  }

  async createContest() {
    this.setState({ showLoader: true });
    if(this.state.type === "Contest"){
      await contesttService.createContest({...this.state.contestDetails}).then(async (res) => {
        this.setState({ showLoader: false });
        if (res.status == 200) {
          this.msg = "Success";
          await this.notifySuccess();
          setTimeout(() => {
            this.setState(st => ({ ...st, contestHolder: res.contestData, showContestCreatedModel: true }))
          }, 2000);
        } else if (res.status == 401) {
          this.msg = "Unauthorized";
          this.notifyError();
          this.setState({ isSubmit: false });
        } else if(res.status === 400){
          alert("Game name is already taken")
          this.msg = "Game name is already taken";
          this.notifyError();
          this.setState({ isSubmit: false });
        } else {
          this.msg = "Something went wrong. Please try again later";
          this.notifyError();
          this.setState({ isSubmit: false });
        }
      }).catch((e) => {
        this.setState({ showLoader: false });
        this.msg = "Something went wrong. Please try again later";
        this.notifyError();
        this.setState({ isSubmit: false });
      });
    }else{
      await contesttService.createGame(this.state.contestDetails).then(async (res) => {
        this.setState({ showLoader: false });
        if (res.status == 200) {
          this.msg = "Success";
          await this.notifySuccess();
          setTimeout(() => {
            this.props.history.push({
              pathname: ROUTES.MY_GAMES,
            });
          }, 2000);
        } else if (res.status == 401) {
          this.msg = "Unauthorized";
          this.notifyError();
          this.setState({ isSubmit: false });
        } else if(res.status === 400){
          alert("Game name is already taken")
          this.msg = "Game name is already taken";
          this.notifyError();
          this.setState({ isSubmit: false });
        } else {
          this.msg = "Something went wrong. Please try again later";
          this.notifyError();
          this.setState({ isSubmit: false });
        }
      }).catch((e) => {
        this.setState({ showLoader: false });
        this.msg = "Something went wrong. Please try again later";
        this.notifyError();
        this.setState({ isSubmit: false });
      });
    }

  }

  async updateContest() {
    this.setState({ showLoader: true });
    let response = await contesttService.updateContest(
      this.state.contestDetails
    );
    this.setState({ showLoader: false });
    if (response.status) {
      if (response.status == 200) {
        this.msg = response.msg;
        await this.notifySuccess();
        setTimeout(() => {
          this.props.history.push(ROUTES.HOSTED_CONTESTLIST);
          this.setState({ Page: 1 });
        }, 2000);
      } else {
        this.msg = response.msg;
        await this.notifyError();
      }
    } else {
      this.msg = response.msg;
      this.notifyError();
    }
  }

  selectedContest(value: any) {
    let obj = this.state.contests.filter((item) => {
      if (item.contestname == value) {
        return item;
      }
    });

    this.setState({ selectedContest: obj });
  }

  changeDate = (value: any, field: any) => {
    if (value != null) {
      if (value != "Invalid Date") {
        this.state.errors[field] = "";
        this.setState(Object.assign(this.state.errors, this.state.errors));
        this.state.contestDetails[field] = value;
        this.setState(
          Object.assign(this.state.contestDetails, this.state.contestDetails)
        );
        this.setState({ isDateValueNull: false });
      } else {
        this.setState({ isDateValueNull: true });
      }
    } else {
      this.state.contestDetails[field] = "";
      this.setState(
        Object.assign(this.state.contestDetails, this.state.contestDetails)
      );
    }
  };

  async copyPreviousContest() {
    if (this.state.selectedContest && this.state.selectedContest[0]) {
      delete this.state.selectedContest[0].contestname;
      delete this.state.selectedContest[0].id;
      delete this.state.selectedContest[0].link;
      await this.setState(
        {
          selectClassErrorMsg: "",
          contestDetails:
            this.state.selectedContest[0] || this.state.selectedContest,
        },
        () => {
          $("#showClasses").modal("hide");
        }
      );
    } else {
      this.setState({ selectClassErrorMsg: "Select a class" });
    }
  }

  validateSelectedContest() {
    if (this.state.selectedContest && this.state.selectedContest[0]) {
      this.setState({ selectClassContestErrorMsg: "" });
    } else {
      this.setState({ selectContestErrorMsg: "Select a class" });
    }
  }

  showPreviousContest() {
    return (
      <div
        className="modal fade"
        id="showClasses"
        role="dialog"
        aria-labelledby="showClassesLabel"
        aria-hidden="true"
      >
        {/* <div className="modal-dialog sign-in-modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body signin-modal">
                            <h2>Select Contest</h2>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <select className="form-control bfh-timezones" onChange={(e) => this.selectedContest(e.target.value)}
                                        onBlur={() => this.validateSelectedContest()}
                                    >
                                        <option selected disabled>select</option>
                                        {
                                            this.state.contests.map(item => {
                                                return (
                                                    <option>{item.contestname}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className="validation-error">{this.state.selectContestErrorMsg}</div>
                                    <button style={{ marginTop: 20 }} className="btn btn-primary pull-right next-btn" onClick={() => this.copyPreviousContest()}>Done</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }

  isAnyErrorFound(error: any) {
    this.setState(Object.assign(this.state.errors, error.errors));
  }

  async validatePage1() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let formError;

    formError = await Validate.validateSelect(
      this.state.contestDetails.subject,
      "subject",
      "contest subject"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateDescription(
      this.state.contestDetails.description,
      "description"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateSelect(
      this.state.contestDetails.timeZone,
      "timeZone",
      "any timeZone"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateNoParticipants(
      this.state.contestDetails.noofparticipants,
      "noofparticipants"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    // formError = await Validate.validateContestPrice(
    //   this.state.contestDetails.contestPrice,
    //   "contestPrice"
    // );
    // if (!formError.isValid) {
    //   this.isAnyErrorFound(formError);
    //   formvalid = false;
    // }
    // formError = await Validate.validateContestPrize(
    //   this.state.contestDetails.contestPrize,
    //   "contestPrize"
    // );
    // if (!formError.isValid) {
    //   this.isAnyErrorFound(formError);
    //   formvalid = false;
    // }
    // formError = await Validate.validateSelect(this.state.contestDetails.academic,"academic","academic level")
    // if(!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }
    formError = await Validate.validateTradeRules(
      this.state.contestDetails.tradeRules,
      "tradeRules"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateDate(
      this.state.contestDetails.regStartDate,
      "regStartDate",
      "registration start",
      this.props.location.state,
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateDate(
      this.state.contestDetails.regEndDate,
      "regEndDate",
      "registration end",
      this.props.location.state,
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateRound(
      this.state.contestDetails.roundAteachLevel,
      "roundAteachLevel"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateRegContestName(
        this.state.contestDetails.contestname,
        "contestname"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }

    this.setState({ showLoader: false });

    if (formvalid == true) {
      if (this.props.location.state && this.props.location.state.id) {
        this.updateContest();
      } else {
        this.createContest();
      }
    } else {
      this.setState({ isSubmit: false });
    }
  }

  async validatePage4() {
    this.setState({ showLoader: true });
    let formError;
    let formvalid = true;
    formError = await Validate.validateSelect(
      this.state.contestDetails.subscription,
      "subscription",
      "subscription plan"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateSelect(
      this.state.contestDetails.paymethod,
      "paymethod",
      "payment method"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    this.setState({ showLoader: false });

    if (formvalid == true) {
      if (this.props.location.state && this.props.location.state.id) {
        this.updateContest();
      } else {
        this.createContest();
      }
    } else {
      this.setState({ isSubmit: false });
    }
  }

  async validatePage3() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let formError;
    formError = await Validate.validateRound(
      this.state.contestDetails.roundAteachLevel,
      "roundAteachLevel"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateMinBenchmark(
      this.state.contestDetails.minProfit,
      "minProfit"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    this.setState({ showLoader: false });

    // if (formvalid == true) {
    //     this.setState({ Page: 4 })

    // }
    if (formvalid == true) {
      if (this.props.location.state && this.props.location.state.id) {
        this.updateContest();
      } else {
        this.createContest();
      }
    } else {
      this.setState({ isSubmit: false });
    }
  }

  async validatePage2() {
    let formvalid = true;
    let formError;
    formError = await Validate.validatePassingScore(
      this.state.contestDetails.passingScore,
      "passingScore"
    );
    if (!formError.isValid) {
      formvalid = false;
      this.isAnyErrorFound(formError);
    }
    formError = await Validate.validateNoofAttempt(
      this.state.contestDetails.noofattempts,
      "noofattempts"
    );
    if (!formError.isValid) {
      formvalid = false;
      this.isAnyErrorFound(formError);
    }
    formError = await Validate.validateSelect(
      this.state.contestDetails.amountAwarded,
      "amountAwarded",
      "amount awarded"
    );
    if (!formError.isValid) {
      formvalid = false;
      this.isAnyErrorFound(formError);
    }

    if (formvalid == true) {
      this.setState({ Page: 3 });
    }
  }

  checkStatus(value: string) {
    if (this.state.contestDetails.tradeRules) {
      if (this.state.contestDetails.tradeRules.length == 6) {
        return true;
      } else {
        if (value === "All") {
          if(this.state.contestDetails.tradeRules.length == 2){
            return true;
          }else{
            return false;
          }
        }else{
          let array = this.state.contestDetails.tradeRules.filter(
            (item: any) => item == value
          );
          if (array.length != 0) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  }

  showModal() {
    $("#showClasses").modal("show");
  }

  setTimeZone() {
    let timezones = ct.getAllTimezones();
    this.timeZone = Object.keys(timezones).map((item: any) => {
      let obj = {} as any;
      obj.value = item;
      obj.label = item;
      return obj;
    });
  }

  renderPage1() {
    return (
      <div className="step">
        <div className="row">
          <div className="col-md-12">
            <ul className="stepper stepper-horizontal">
              {/* <li className="active">
                                <a href="#!">
                                    <span className="circle">1</span>
                                    <span className="label">Contest Information</span>
                                </a>
                            </li>

                            <li >
                                <a href="#!">
                                    <span className="circle">2</span>
                                    <span className="label">Curriculum Configuration</span>
                                </a>
                            </li>
                            <li >
                                <a href="#!">
                                    <span className="circle">3</span>
                                    <span className="label">Game Configuration</span>
                                </a>
                            </li> */}
              {/* <li >
                                <a href="#!">
                                    <span className="circle">4</span>
                                    <span className="label">Subscription </span>
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>

        {/* <h5 className="form-heading">Class Information</h5> */}
        {/* {this.state.contestDetails.link ?
                <div className="row mt-5 mb-5">
                        <div className="row mt-5 mb-5 info-head" style={{ paddingLeft: "15px" }}>
                            <h3 className="col-md-6 ">Contest Information</h3>
                        </div>
                <PrintComponents
                    trigger={<button className="print-button"><span className="print-icon"></span> <br />Print QR Code</button>}
                >
                            <ComponentToPrint contestDetails={this.state.contestDetails} />
                </PrintComponents>
                </div>
                    : null} */}
        {/* authStore.user &&
          (authStore.user.role == "STUDENT)" */}
        {this.state.contestDetails.link && (new Date() < new Date(this.state.contestDetails.regEndDate)) ? (
          //  &&
          // authStore.user &&
          // authStore.user.role == "PROFESSOR"
          <div className="row mt-5 mb-5">
            <div className="col-md-3">Invite URL</div>
            <div className="col-md-9">
              <div className="col-md-12 url-div ">
                {this.state.contestDetails.link}
              </div>
            </div>

            {/* <PrintComponents
              trigger={
                <button className="print-button">
                  <span className="print-icon"></span> <br />
                  Print QR Code
                </button>
              }
            >
              <ComponentToPrint contestDetails={this.state.contestDetails} />
            </PrintComponents> */}
          </div>
        ) : null}
        <div className="row mt-5 mb-5">
          {!this.state.contestDetails.link && (new Date() < new Date(this.state.contestDetails.regEndDate)) ? (
            <div
              className="row mt-5 mb-5 info-head"
              style={{ paddingLeft: "15px" }}
            >
              <h3 className="col-md-6 ">{this.state.type === "Contest" ? "Contest" : "Game"} Information</h3>
            </div>
          ) : null}
          <div className="col-md-3">
            {this.state.type === "Contest" ? "Contest" : "Game"} Name<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              name="contestname"
              id="copyPreviousclass"
              onChange={(e) => this.updateUserState(e)}
              value={
                this.state.contestDetails.contestname
                  ? this.state.contestDetails.contestname
                  : ""
              }
              onLoad={async () => {
                let error = await Validate.validateRegContestName(
                  this.state.contestDetails.contestname,
                  "contestname"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              aria-describedby="previousclass"
              placeholder={this.state.type == "Contest" ? "Contest Name" : "Game Name"}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.contestname}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            {this.state.type === "Contest" ? "Contest" : "Game"} Subject<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-6">
            <select
              className="form-control"
              name="subject"
              value={
                this.state.contestDetails.subject
                  ? this.state.contestDetails.subject
                  : ""
              }
              onBlur={async () => {
                let error = await Validate.validateSelect(
                  this.state.contestDetails.subject,
                  "subject",
                  "contest subject"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              onChange={(e) => this.updateUserState(e)}
            >
              <option selected disabled value="">
                Select
              </option>
              <option>Investments</option>
              <option>Portfolio Management</option>
              <option>Personal Finance</option>
              <option>Financial Management</option>
              <option>Marketing</option>
              <option>Other</option>
            </select>
            <div className="validation-error"> {this.state.errors.subject}</div>
          </div>
        </div>
        {/* <div className="row mt-10 mb-10">
                    <div className="col-md-3">Class Subject<span className="mandtory-star">*</span></div>
                    <div className="col-md-3">
                        <select
                            className="form-control mdb-select md-form"
                            name="subject"
                            value={this.state.classDetails.subject ? this.state.classDetails.subject : ''}
                            onBlur={async () => {
                                let error = await Validate.validateSelect(this.state.classDetails.subject, "subject", "class subject")
                                this.setState(Object.assign(this.state.errors, error.errors))

                            }}
                            onChange={(e) => this.updateUserState(e)}>
                            <option selected disabled value=''>Select</option>
                            <option>Investments</option>
                            <option>Portfolio Management</option>
                            <option>Personal Finance</option>
                            <option>Financial Management</option>
                            <option>Marketing</option>
                            <option>Other</option>
                        </select>
                        <div className="validation-error"> {this.state.errors.subject}</div>
                    </div>
                    <div className="col-md-3">
                        {this.props.location.state ? '' : <button className="btn btn-default next-btn" onClick={() => this.showModal()}>Copy Previous Class</button>}
                    </div>
                </div> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Description<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              name="description"
              value={
                this.state.contestDetails.description
                  ? this.state.contestDetails.description
                  : ""
              }
              onChange={(e) => this.updateUserState(e)}
              id="copyPreviousclass"
              aria-describedby="previousclass"
              onBlur={async () => {
                let error = await Validate.validateDescription(
                  this.state.contestDetails.description,
                  "description"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              placeholder="Description"
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.description}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5" style={{ display: 'none' }}>
          <div className="col-md-3" style={{ marginRight: 15 }}>
            Contest Fee<span className="mandtory-star"></span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 2, width: 200 }}>
            <input
              type="text"
              className="form-control"
              name="contestPrice"
              value={
                this.state.contestDetails.description
                  ? this.state.contestDetails.contestPrice
                  : ""
              }
              onChange={(e) => this.updateUserState(e)}
              id="contestPrice"
              aria-describedby="previousclass"
              // onBlur={async () => {
              //   let error = await Validate.validateContestPrice(
              //     this.state.contestDetails.contestPrice,
              //     "contestPrice"
              //   );
              //   this.setState(Object.assign(this.state.errors, error.errors));
              // }}
              placeholder="Contest Fee"
            />
            <i style={{ paddingLeft: 10, paddingRight: 10, marginTop: 'auto', marginBottom: 'auto' }} className="fas fa-dollar-sign"></i>
            {/* <div className="validation-error">
              {" "}
              {this.state.errors.contestPrice}
            </div> */}
          </div>
        </div>
        <div className="row mt-5 mb-5" style={{ display: 'none' }}>
          <div className="col-md-3">
            Contest Prize
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              name="contestPrize"
              value={
                this.state.contestDetails.description
                  ? this.state.contestDetails.contestPrize
                  : ""
              }
              onChange={(e) => this.updateUserState(e)}
              id="contestPrize"
              aria-describedby="previousclass"
              // onBlur={async () => {
              //   let error = await Validate.validateContestPrize(
              //     this.state.contestDetails.contestPrize,
              //     "contestPrize"
              //   );
              //   this.setState(Object.assign(this.state.errors, error.errors));
              // }}
              placeholder="Contest Prize"
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.contestPrize}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Registration Start Date<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  // margin="none"
                  format="MM/dd/yyyy"
                  // updateData
                  InputProps={{
                    disableUnderline: true,
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  value={this.state.contestDetails.regStartDate}
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.contestDetails.regStartDate,
                      "regStartDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  onChange={(e: any) => this.changeDate(e, "regStartDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  // defaultValue="none"
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.regStartDate}
            </div>
          </div>
          <div className="col-md-3">
            Registration End Date<span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  margin="none"
                  format="MM/dd/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  value={this.state.contestDetails.regEndDate}
                  onChange={(e: any) => this.changeDate(e, "regEndDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  // defaultValue="none"
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.contestDetails.regEndDate,
                      "regEndDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.regEndDate}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Time Zone<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-9">
            <Select
              value={this.state.contestDetails.timeZone}
              options={this.timeZone}
              styles={styleFn()}
              defaultInputValue={"America/New_York"}
              onBlur={async () => {
                let error = await Validate.validateTimeZone(
                  this.state.contestDetails.timeZone,
                  "timeZone"
                );
                this.setState(Object.assign(this.state.errors, error));
              }}
              onChange={(val: any) => {
                let obj = {} as ContestDetails;
                obj.timeZone = val;
                this.setState(Object.assign(this.state.contestDetails, obj));
              }}
            />

            <div className="validation-error">
              {" "}
              {this.state.errors.timeZone}
            </div>
          </div>
        </div>
        {/* <div className="row mt-10 mb-10">
                    <div className="col-md-3">Number of Students<span className="mandtory-star">*</span></div>
                    <div className="col-md-6">
                        <input
                            type="text"
                            className="form-control"
                            name="noofstudents"
                            value={this.state.classDetails.noofstudents ? this.state.classDetails.noofstudents : ''}
                            onChange={(e) => this.updateUserState(e, "number")}
                            id="copyPreviousclass"
                            onBlur={async () => {
                                let error = await Validate.validateNoStudent(this.state.classDetails.noofstudents, "noofstudents")
                                this.setState(Object.assign(this.state.errors, error.errors))

                            }}
                            aria-describedby="previousclass"

                            placeholder="Number of students" />
                        <div className="validation-error"> {this.state.errors.noofstudents}</div>
                    </div>
                </div> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Number of Participants<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              name="noofparticipants"
              value={
                this.state.contestDetails.noofparticipants
                  ? this.state.contestDetails.noofparticipants
                  : ""
              }
              onChange={(e) => this.updateUserState(e, "number")}
              id="copyPreviousclass"
              onBlur={async () => {
                let error = await Validate.validateNoParticipants(
                  this.state.contestDetails.noofparticipants,
                  "noofparticipants"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              aria-describedby="previousclass"
              placeholder="Number of participants"
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.noofparticipants}
            </div>
          </div>
          {/* <div className="col-md-2">Academics<span className="mandtory-star">*</span></div>
                    <div className="col-md-4">
                        <select
                            className="form-control bfh-timezones"
                            data-country="US" name="academic"
                            value={this.state.contestDetails.academic ? this.state.contestDetails.academic : ''}
                            onBlur={async () => {
                                let error = await Validate.validateSelect(this.state.contestDetails.academic, "academic", "academic level")
                                this.setState(Object.assign(this.state.errors, error.errors))

                            }}
                            onChange={(e) => this.updateUserState(e)} >
                            <option selected disabled value=''>Select</option>
                            <option>Post Graduate and above</option>
                            <option>Graduate</option>
                            <option>Bachelor's</option>
                            <option>12th</option>
                            <option>10th</option>
                        </select>
                        <div className="validation-error"> {this.state.errors.academic}</div>
                    </div> */}
        </div>
        {/* <div className="row mt-5 mb-5">
                    <div className="col-md-3">Registration Start Date<span className="mandtory-star">*</span></div>
                    <div className="col-md-3">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    // margin="none"
                                    format="MM/dd/yyyy"
                                    // updateData
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    value={this.state.classDetails.regStartDate}
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.regStartDate, "regStartDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    onChange={(e: any) => this.changeDate(e, 'regStartDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    // defaultValue="none"
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.regStartDate}</div>
                    </div>
                    <div className="col-md-3">Registration End Date<span className="mandtory-star">*</span></div>

                    <div className="col-md-3">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    margin="none"
                                    format="MM/dd/yyyy"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    value={this.state.classDetails.regEndDate}
                                    onChange={(e: any) => this.changeDate(e, 'regEndDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    // defaultValue="none"
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.regEndDate, "regEndDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.regEndDate}</div>
                    </div>
                </div> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Select Game Types<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-9">
            <form className="trading-rule-chk-box">
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="button btn"
                    name="Stocks"
                    checked={
                      this.checkStatus("Stocks") || this.state.checkbox_checked
                    }
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Stocks</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="button btn"
                    name="Bonds"
                    checked={
                      this.checkStatus("Bonds") || this.state.checkbox_checked
                    }
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Bonds</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    disabled={true}
                    name="Options"
                    checked={this.checkStatus("Options")}
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Options</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Technical Analysis"
                    disabled={true}
                    checked={this.checkStatus("Technical Analysis")}
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Technical Analysis</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Fixed Income"
                    disabled={true}
                    checked={this.checkStatus("Fixed Income")}
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Fixed Income</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Commodities"
                    disabled={true}
                    checked={this.checkStatus("Commodities")}
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Commodities</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Currencies"
                    disabled={true}
                    checked={this.checkStatus("Currencies")}
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Currencies</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Crypto-Commodities"
                    disabled={true}
                    checked={this.checkStatus("Crypto-Commodities")}
                    onChange={(e) => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Crypto-Commodities</label>
                </div>
                 <div className="col-md-6">
                 <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="All"
                    checked={this.checkStatus("All")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline">All</label>
                </div>
                {/*<div className="col-md-6">
                  <label
                    className="checkbox-inline "
                    onClick={() => {
                      this.SelectNoGameTypes();
                    }}
                  >
                    {" "}
                    Select None
                  </label>
                </div> */}

                {/* <div className="col-md-6">

<input
    type="checkbox"
    value=""
    className="chk-box-reg"
    disabled={true}
    name="All"
    checked={this.checkStatus("All")}
    onChange={(e) => this.updateCheckbox(e)} />
    <label className="checkbox-inline c">Select All
                </label>
                </div>
                                <div className="col-md-6">

<input
    type="checkbox"
    value=""
    className="chk-box-reg"
    disabled={true}
    name="DeSelectAll"
                                    checked={this.checkStatus("DeSelectAll")}
    onChange={(e) => this.updateCheckbox(e)} />
    <label className="checkbox-inline c">De Select All
                </label>
                </div> */}
              </div>
            </form>
            <div className="validation-error">
              {" "}
              {this.state.errors.tradeRules}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3" style={{ marginRight: 15 }}>
            Initial Capital<span className="mandtory-star">*</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 2, width: 158 }}>
            <input
              type="text"
              className="form-control"
              name="initialCapital"
              value={
                this.state.contestDetails.description
                  ? this.state.contestDetails.initialCapital
                  : ""
              }
              onChange={(e) => this.updateUserState(e)}
              id="initialCapital"
              aria-describedby="previousclass"
              onBlur={async () => {
                let error = await Validate.validateInitialCapital(
                  this.state.contestDetails.initialCapital,
                  "initialCapital"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              placeholder="Initial Capital"
            />
            <i style={{ paddingLeft: 10, paddingRight: 10, marginTop: 'auto', marginBottom: 'auto' }} className="fas fa-dollar-sign"></i>
            <div className="validation-error">
              {" "}
              {this.state.errors.initialCapital}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Rounds at each level<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="Number of Rounds"
              name="roundAteachLevel"
              onBlur={async () => {
                let error = await Validate.validateRound(
                  this.state.contestDetails.roundAteachLevel,
                  "roundAteachLevel"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              value={
                this.state.contestDetails.roundAteachLevel
                  ? this.state.contestDetails.roundAteachLevel
                  : ""
              }
              onChange={(e) => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.roundAteachLevel}
            </div>
          </div>
        </div>
        {/* <div className="row mt-5 mb-5">
                    <div className="col-md-3">Trading Start Date<span className="mandtory-star">*</span></div>
                    <div className="col-md-3">


                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    // margin="none"
                                    format="MM/dd/yyyy"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    value={this.state.classDetails.tradeStartDate}
                                    onChange={(e: any) => this.changeDate(e, 'tradeStartDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.tradeStartDate, "tradeStartDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    // defaultValue="none"
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.tradeStartDate}</div>
                    </div>
                    <div className="col-md-3">Trading End Date<span className="mandtory-star">*</span></div>

                    <div className="col-md-3">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    // margin="none"
                                    format="MM/dd/yyyy"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.tradeEndDate, "tradeEndDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    value={this.state.classDetails.tradeEndDate}
                                    onChange={(e: any) => this.changeDate(e, 'tradeEndDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    // defaultValue="yyyy/MM/dd"
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.tradeEndDate}</div>
                    </div>
                </div> */}
        {/* <button className="btn btn-primary pull-right next-btn" onClick={() => this.validatePage1()}>Next</button> */}
        {
          this.props.location.state && new Date() > new Date(this.state.contestDetails.regEndDate) ?
            <button
              onClick={() => this.props.history.push(ROUTES.HOSTED_CONTESTLIST)}
              className="btn btn-primary pull-right next-btn">Close</button> :
            <button
              className="btn btn-primary pull-right next-btn"
              disabled={this.state.isSubmit}
              onClick={() => {
                this.setState({ isSubmit: true });
                this.validatePage1();
              }}
            >
              {this.props.location.state ? "Update" : "Submit"}
            </button>
        }

        {/* <button className="btn btn-primary pull-right next-btn" onClick={() => this.setState({ Page: 1 })}>Back</button> */}
      </div>
    );
  }

  renderPage2() {
    return (
      <div className="step">
        <div className="row">
          <div className="col-md-12">
            <ul className="stepper stepper-horizontal">
              <li className="completed">
                <a href="#!">
                  <span className="circle">1</span>
                  <span className="label">Class Information</span>
                </a>
              </li>

              <li className="active">
                <a href="#!">
                  <span className="circle">2</span>
                  <span className="label">Curriculum Configuration</span>
                </a>
              </li>
              <li>
                <a href="#!">
                  <span className="circle">3</span>
                  <span className="label">Game Configuration</span>
                </a>
              </li>
              {/* <li >
                                <a href="#!">
                                    <span className="circle">4</span>
                                    <span className="label">Subscription</span>
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>

        {/* <h5 className="form-heading">Test Performance</h5> */}
        <div className="row mt-5 mb-5">
          <div
            className="row mt-5 mb-5 info-head"
            style={{ paddingLeft: "15px" }}
          >
            <h3 className="col-md-6 ">Dates Configuration</h3>
          </div>
          <div className="col-md-3">
            Registration Start Date<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  // margin="none"
                  format="MM/dd/yyyy"
                  // updateData
                  InputProps={{
                    disableUnderline: true,
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  value={this.state.contestDetails.regStartDate}
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.contestDetails.regStartDate,
                      "regStartDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  onChange={(e: any) => this.changeDate(e, "regStartDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  // defaultValue="none"
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.regStartDate}
            </div>
          </div>
          <div className="col-md-3">
            Registration End Date<span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  margin="none"
                  format="MM/dd/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  value={this.state.contestDetails.regEndDate}
                  onChange={(e: any) => this.changeDate(e, "regEndDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  // defaultValue="none"
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.contestDetails.regEndDate,
                      "regEndDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.regEndDate}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Trading Start Date<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  // margin="none"
                  format="MM/dd/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={this.state.contestDetails.tradeStartDate}
                  onChange={(e: any) => this.changeDate(e, "tradeStartDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.contestDetails.tradeStartDate,
                      "tradeStartDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  // defaultValue="none"
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.tradeStartDate}
            </div>
          </div>

          <div className="col-md-3">
            Trading End Date<span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  // margin="none"
                  format="MM/dd/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.contestDetails.tradeEndDate,
                      "tradeEndDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  value={this.state.contestDetails.tradeEndDate}
                  onChange={(e: any) => this.changeDate(e, "tradeEndDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  // defaultValue="yyyy/MM/dd"
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.tradeEndDate}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div
            className="row mt-5 mb-5 info-head"
            style={{ paddingLeft: "15px" }}
          >
            <h3 className="col-md-6 ">Test Configuration</h3>
          </div>
          <div className="col-md-3">
            Passing Percentage <span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              // placeholder="Passing percentage"
              name="passingScore"
              onBlur={async () => {
                let error = await Validate.validatePassingScore(
                  this.state.contestDetails.passingScore,
                  "passingScore"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              value={
                this.state.contestDetails.passingScore
                  ? this.state.contestDetails.passingScore
                  : ""
              }
              onChange={(e) => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.passingScore}
            </div>
          </div>
          <div className="col-md-3">
            Number of Attempts<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="No: of attempts"
              name="noofattempts"
              onBlur={async () => {
                let error = await Validate.validateNoofAttempt(
                  this.state.contestDetails.noofattempts,
                  "noofattempts"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              value={
                this.state.contestDetails.noofattempts
                  ? this.state.contestDetails.noofattempts
                  : ""
              }
              onChange={(e) => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.noofattempts}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Amount Awarded (per question){" "}
            <span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <select
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="Amount"
              name="amountAwarded"
              value={
                this.state.contestDetails.amountAwarded
                  ? this.state.contestDetails.amountAwarded
                  : ""
              }
              onBlur={async () => {
                let error = await Validate.validateSelect(
                  this.state.contestDetails.amountAwarded,
                  "amountAwarded",
                  "amount awarded"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              onChange={(e) => this.updateUserState(e)}
            >
              <option selected disabled value="">
                Select
              </option>
              <option>100</option>
              <option>200</option>
              <option>300</option>
              <option>400</option>
              <option>500</option>
            </select>
            <div className="validation-error">
              {" "}
              {this.state.errors.amountAwarded}
            </div>
          </div>
        </div>
        {/* <div className="row mt-10 mb-10">
                   
                </div> */}
        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.validatePage2()}
        >
          Next
        </button>
        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.setState({ Page: 1 })}
        >
          Back
        </button>
      </div>
    );
  }

  renderPage3() {
    return (
      <div className="step">
        <div className="row">
          <div className="col-md-12">
            <ul className="stepper stepper-horizontal">
              <li className="completed">
                <a href="#!">
                  <span className="circle">1</span>
                  <span className="label">Contest Information</span>
                </a>
              </li>

              <li className="completed">
                <a href="#!">
                  <span className="circle">2</span>
                  <span className="label">Curriculum Configuration</span>
                </a>
              </li>
              <li className="active">
                <a href="#!">
                  <span className="circle">3</span>
                  <span className="label">Game Configuration</span>
                </a>
              </li>
              {/* <li >
                                <a href="#!">
                                    <span className="circle">4</span>
                                    <span className="label">Subscription</span>
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>

        {/* <h5 className="form-heading">Game Configuration</h5> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Rounds at each level<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="Number of Rounds"
              name="roundAteachLevel"
              onBlur={async () => {
                let error = await Validate.validateRound(
                  this.state.contestDetails.roundAteachLevel,
                  "roundAteachLevel"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              value={
                this.state.contestDetails.roundAteachLevel
                  ? this.state.contestDetails.roundAteachLevel
                  : ""
              }
              onChange={(e) => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.roundAteachLevel}
            </div>
          </div>
          <div className="col-md-3">
            Minimum balance benchmark<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="Minimum Benchmark"
              name="minProfit"
              value={
                this.state.contestDetails.minProfit
                  ? this.state.contestDetails.minProfit
                  : ""
              }
              onBlur={async () => {
                let error = await Validate.validateMinBenchmark(
                  this.state.contestDetails.minProfit,
                  "minProfit"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              onChange={(e) => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.minProfit}
            </div>
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-md-3">Extra Credit (Top 5 Performers)</div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="Extra Credit"
              name="extraCredit"
              value={
                this.state.contestDetails.extraCredit
                  ? this.state.contestDetails.extraCredit
                  : ""
              }
              onChange={(e) => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.extraCredit}
            </div>
          </div>
        </div>

        {/* <button className="btn btn-primary pull-right next-btn" onClick={() => this.validatePage3()}>Next</button> */}
        {
          this.props.location.state && new Date() > new Date(this.state.contestDetails.regEndDate) ?
            <button
              onClick={() => this.props.history.push(ROUTES.HOSTED_CONTESTLIST)}
              className="btn btn-primary pull-right next-btn">Close</button> :
            <button
              className="btn btn-primary pull-right next-btn"
              disabled={this.state.isSubmit}
              onClick={() => {
                this.setState({ isSubmit: true });
                this.validatePage3();
              }}>
              {this.props.location.state && this.props.location.state.id
                ? "Update"
                : "Finish"}
            </button>
        }

        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.setState({ Page: 2 })}
        >
          Back
        </button>
      </div>
    );
  }

  renderPage4() {
    return (
      <div className="step">
        <div className="row">
          <div className="col-md-12">
            <ul className="stepper stepper-horizontal">
              <li className="completed">
                <a href="#!">
                  <span className="circle">1</span>
                  <span className="label">Contest Information</span>
                </a>
              </li>

              <li className="completed">
                <a href="#!">
                  <span className="circle">2</span>
                  <span className="label">Test Information</span>
                </a>
              </li>
              <li className="completed">
                <a href="#!">
                  <span className="circle">3</span>
                  <span className="label">Game Configuration</span>
                </a>
              </li>
              <li className="active">
                <a href="#!">
                  <span className="circle">4</span>
                  <span className="label">Subscription</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* <h5 className="form-heading">Subscription Information</h5> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-12">
            Select a subscription level<span className="mandtory-star">*</span>
          </div>
          <br />
          <div className="col-md-12">
            <table className="plan-table">
              <tr className="custom-row">
                <td style={{ fontWeight: "bold" }}></td>
                <td style={{ fontWeight: "bold" }}>Duration</td>
                <td style={{ fontWeight: "bold" }}>Number of Students</td>
                <td style={{ fontWeight: "bold" }}>Amount</td>
              </tr>
              <SubscriptionItem
                title="platinum"
                name="Platinum"
                duration="18 Weeks"
                strength="Unlimited"
                amount="$500"
                isCheck={
                  this.state.contestDetails.subscription == "platinum"
                    ? true
                    : false
                }
                checkhandler={this.updateRadioValue}
              />
              <SubscriptionItem
                title="gold"
                name="Gold"
                duration="18 Weeks"
                strength="30 Students"
                amount="$250"
                isCheck={
                  this.state.contestDetails.subscription == "gold"
                    ? true
                    : false
                }
                checkhandler={this.updateRadioValue}
              />
              <SubscriptionItem
                title="silver"
                name="Silver"
                duration="18 Weeks"
                strength="Unlimited"
                amount="$20 each student"
                isCheck={
                  this.state.contestDetails.subscription == "silver"
                    ? true
                    : false
                }
                checkhandler={this.updateRadioValue}
              />
              <SubscriptionItem
                title="trial"
                name="Trial"
                duration="2 Weeks"
                strength="30 Students"
                amount="Free"
                isCheck={
                  this.state.contestDetails.subscription == "trial"
                    ? true
                    : false
                }
                checkhandler={this.updateRadioValue}
              />
            </table>
            <div className="validation-error">
              {" "}
              {this.state.errors.subscription}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Payment Method<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-2">
            <input
              type="radio"
              name="paymethod"
              title="professor"
              checked={
                this.state.contestDetails.paymethod == "professor"
                  ? true
                  : false
              }
              onChange={(e) => this.updateRadioValue(e)}
            />
            &nbsp;&nbsp;Professor
          </div>
          <div className="col-md-2">
            <input
              type="radio"
              name="paymethod"
              title="student"
              checked={
                this.state.contestDetails.paymethod == "student" ? true : false
              }
              onChange={(e) => this.updateRadioValue(e)}
            />
            &nbsp;&nbsp;Student
          </div>
        </div>
        <div className="validation-error"> {this.state.errors.paymethod}</div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">Invoice Amount</div>
          <div className="col-md-2"></div>
        </div>
        {
          this.props.location.state && new Date() > new Date(this.state.contestDetails.regEndDate) ?
            <button
              onClick={() => this.props.history.push(ROUTES.HOSTED_CONTESTLIST)}
              className="btn btn-primary pull-right next-btn">Close</button> :
            <button
              className="btn btn-primary pull-right next-btn"
              disabled={this.state.isSubmit}
              onClick={() => {
                this.setState({ isSubmit: true });
                this.validatePage4();
              }}
            >
              {this.props.location.state && this.props.location.state.id
                ? "Update"
                : "Finish"}
            </button>
        }

        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.setState({ Page: 3 })}
        >
          Back
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div style={this.state.type === "Contest" ? {}:{display:'none'}} id="about" className="section wb">
          <div className="jumbotron backgroundImage">
            <div
              className="container registration-title"
              style={{ backgroundColor: "#1a73e8" }}
            >
              {this.state.type === "Contest" ? "Contest" : "Game"} Registration
            <i style={{ fontSize: 16, marginLeft: 12, cursor: 'pointer', paddingTop:4 }} onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}${this.props.location.state && this.props.location.state.id ? '#CreateContest':'#InviteStudents'}`,'_blank')} className="fas fa-info-circle help-icon"></i>
            </div>
          </div>
          <div className="container cmt20">
            <div className="row equal">
              {/* <div className="col-md-1"></div> */}
              {/* <div className="col-md-12 registration-title">Class Registration</div> */}
              <div className="col-md-4 reg-card btrr">
                <div
                  className="wizard"
                  id="myWizard"
                  style={{ paddingTop: "5vh" }}
                >
                  <div className="step" style={{ textAlign: "center" }}>
                    <img src={winnerSvg} width="320" height="auto" alt="" />
                    <div
                      className="row mt-15 mb-15 info highlight"
                      style={{ paddingTop: "12px" }}
                    >
                      <h2
                        className="col-md-12 "
                        style={{ textAlign: "center" }}
                      >
                        {this.state.type === "Contest" ? "Contest" : "Game"} Registration
                      </h2>
                    </div>
                    <p
                      className="form-left-text"
                      style={{ paddingTop: "20px" }}
                    >
                      Create {this.state.type === "Contest" ?"contest": "game"} using relevant configuration settings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 reg-card">
                {/* <div className="registration-title">Class Registration</div> */}
                <div className="wizard" id="myWizard">
                  {
                    this.state.Page == 1 ? this.renderPage1() : ""
                    // :
                    // this.state.Page == 2 ?
                    //     this.renderPage2()
                    //     :
                    //     // this.state.Page == 3 ?
                    //         this.renderPage3()
                    //         // :
                    //         // this.renderPage4()
                  }
                  <div className="step validation-error">
                    {" "}
                    All fields marked with an asterisk are required.
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
            </div>
          </div>
        </div>
        {this.showPreviousContest()}
        {Toast.successToastView()}
        {Toast.errorToastView()}
        {this.state.showLoader ? <LoadingWheel /> : null}
        {/* render models */}
        {
          this.state.showContestCreatedModel ?
            <ContestCreated
              onClose={() => this.props.history.push(ROUTES.HOSTED_CONTESTLIST)}
              data={this.state.contestHolder}
              history={this.props.history}
              authStore={authStore}
            /> : null
        }
        {/* toggle student invite model */}
        {
          this.state.showContestStudentInviteModel ?
            <ContestStudentInvite /> : null
        }
      </div>
    );
  }
}
export default withRouter(ContestReg);
