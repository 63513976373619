import React from "react";
import {
  withRouter,
  RouteChildrenProps,
  Redirect,
  RouteComponentProps
} from "react-router";
import ROUTES from "../../ROUTES";
import "./TopicList.css";
import Progressbar from "../../components/Progressbar";
import { MdAdd, MdRemove } from "react-icons/md";
import TopicListItem from "./TopicListItem";
import { ClassDetails } from "../../interfaces";
import studentService from "../../services/studentService";
import classService from "../../services/classService";
import authStore from "../../models/AuthStore";
import LoadingWheel from "../../components/LoadingWheel";
export interface TopicProps extends RouteComponentProps { }
export interface TopicState { }

class Topic extends React.Component<any, TopicState> {
  state = {
    tab: "learnStatus",
    showLoader: true,
    gameStatus: [] as any,
    expand: { Stocks: [], Bonds: [] } as any,
    quizStatus: {} as any,
    topics: [],
    attempts: { Stocks: {}, Bonds: {} } as any,
    classid: "",
    classname: ""
  };

  async componentDidMount() {
    let classDetails = await classService.getClassbyName(
      this.props.location.state
    );
    console.log(classDetails)
    // TO DO: Change Logic
    //this.setState({ topics: classDetails.tradeRules })
    this.setState({ topics: classDetails.data.tradeRules });

    this.setState({ classid: classDetails.data.id });
    this.setState({ classname: classDetails.data.classname });

    let quizResult = await studentService.getQuizResultStatusbyUser(
      classDetails.data.id
    );

    let gameResult = await studentService.getResultStatusbyUser(
      classDetails.data.id
    );
    let quizObj = { Stocks: {}, Bonds: {} } as any;
    let attempts = { Stocks: {}, Bonds: {} } as any;
    await quizResult.data.forEach((result: any) => {
      if (attempts[result.topic][result.level]) {
        attempts[result.topic][result.level] += 1;
      } else {
        attempts[result.topic][result.level] = 1;
      }

      if (quizObj[result.topic]) {
        quizObj[result.topic][result.level] = result.status;
      }
    });

    let gameObj = { Stocks: {}, Bonds: {} } as any;
    await gameResult.data.forEach((item: any) => {
      if (gameObj[item.gameType]) {
        gameObj[item.gameType][item.level] = item.status;
      }
    });

    this.setState({
      gameStatus: gameObj,
      attempts: attempts,
      quizStatus: quizObj,
      showLoader: false
    });
  }

  showTopicItem(topic: string, level: string) {
    // this.props.history.push({
    //   pathname: ROUTES.TOPICITEM,
    //   state: {
    //     topic: topic,
    //     level: level,
    //     classname: this.props.location.state ? this.props.location.state : ""
    //   }
    // });
    this.props.toggleTab(this.props.location.state ? this.props.location.state : "", topic, level)
  }

  toggleExpand(index: number, topic: string) {
    this.state.expand[topic][index] = !this.state.expand[topic][index];
    this.setState({ expand: this.state.expand });
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div style={{ backgroundColor: "#1a73e8", marginBottom: 10, marginLeft: '8.5%' }} className="registration-title">
            Coursework - {this.props.location.state}
          </div>
        </div>
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : (
            <div className="container cmt20" style={{ width: '83%' }}>
              <div className="row ">
                <div className="col-md-12 list-group">
                  {/* <div className="reg-card-topic"> */}
                  {/* <div className="list-group "> */}
                  <div className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="tab">
                      <button
                        style={{ height: 45, display: 'flex', alignItems: 'center', justifyContent:'center' }}
                        className={
                          this.state.tab == "learnStatus"
                            ? "tablinks-active"
                            : "tablinks"
                        }
                        onClick={() => this.setState({ tab: "learnStatus" })}
                      >
                        <strong>Learning Status</strong>
                      </button>
                      <button
                        style={{ height: 45, display: 'flex', alignItems: 'center', justifyContent:'center' }}
                        className={
                          this.state.tab == "gameStatus"
                            ? "tablinks-active"
                            : "tablinks"
                        }
                        onClick={() => this.setState({ tab: "gameStatus" })}
                      >
                        <strong>Game Status</strong>
                      </button>
                    </div>

                    {this.state.topics.map((item, index) => {
                      let prevTopic =
                        index == 0 ? undefined : this.state.topics[index - 1];
                      return (
                        <div>
                          <div className="topic-head" style={{ marginTop: 10, height:50, display:'flex', alignItems:'center', paddingLeft:20, fontSize:17.5 }}>{item}</div>
                          <small>
                            <ul className="link-list">
                              
                              <TopicListItem
                                classid={this.state.classid}
                                classname={this.state.classname}
                                index={0}
                                expand={
                                  this.state.expand[item]
                                    ? this.state.expand[item][0]
                                    : false
                                }
                                topictitle={item}
                                quizStatus={
                                  this.state.quizStatus[item]
                                    ? this.state.quizStatus[item]["Trader"]
                                    : 2 // 2-Pending Status
                                }
                                tab={this.state.tab}
                                attempts={
                                  this.state.attempts[item]["Trader"]
                                    ? this.state.attempts[item]["Trader"]
                                    : 0
                                }
                                prevGameStatus={
                                  prevTopic
                                    ? this.state.gameStatus[prevTopic]
                                      ? this.state.gameStatus[prevTopic][
                                      "Advanced Market Maker"
                                      ]
                                      : 2
                                    : 0
                                }
                                gameStatus={
                                  this.state.gameStatus[item]
                                    ? this.state.gameStatus[item]["Trader"]
                                    : 2
                                }
                                showCurriculum={(title: string, level: string) =>
                                  this.showTopicItem(title, level)
                                }
                                toggle={(index: number, topic: string) =>
                                  this.toggleExpand(index, topic)
                                }
                                topicname="Trader"
                              />

                              <TopicListItem
                                classid={this.state.classid}
                                classname={this.state.classname}
                                index={1}
                                expand={
                                  this.state.expand[item]
                                    ? this.state.expand[item][1]
                                    : false
                                }
                                attempts={
                                  this.state.attempts[item]["Market Maker"]
                                    ? this.state.attempts[item]["Market Maker"]
                                    : 0
                                }
                                quizStatus={
                                  this.state.quizStatus[item]
                                    ? this.state.quizStatus[item]["Market Maker"]
                                    : 2
                                }
                                prevGameStatus={
                                  this.state.gameStatus[item]
                                    ? this.state.gameStatus[item]["Trader"]
                                    : 2
                                }
                                gameStatus={
                                  this.state.gameStatus[item]
                                    ? this.state.gameStatus[item]["Market Maker"]
                                    : 2
                                }
                                tab={this.state.tab}
                                topictitle={item}
                                showCurriculum={(title: string, level: string) =>
                                  this.showTopicItem(title, level)
                                }
                                toggle={(index: number, topic: string) =>
                                  this.toggleExpand(index, topic)
                                }
                                topicname="Market Maker"
                              />

                              <TopicListItem
                                classid={this.state.classid}
                                classname={this.state.classname}
                                index={2}
                                expand={
                                  this.state.expand[item]
                                    ? this.state.expand[item][2]
                                    : false
                                }
                                attempts={
                                  this.state.attempts[item]["Advanced Trader"]
                                    ? this.state.attempts[item]["Advanced Trader"]
                                    : 0
                                }
                                quizStatus={
                                  this.state.quizStatus[item]
                                    ? this.state.quizStatus[item][
                                    "Advanced Trader"
                                    ]
                                    : 2
                                }
                                prevGameStatus={
                                  this.state.gameStatus[item]
                                    ? this.state.gameStatus[item]["Market Maker"]
                                    : 2
                                }
                                gameStatus={
                                  this.state.gameStatus[item]
                                    ? this.state.gameStatus[item][
                                    "Advanced Trader"
                                    ]
                                    : 2
                                }
                                tab={this.state.tab}
                                topictitle={item}
                                showCurriculum={(title: string, level: string) =>
                                  this.showTopicItem(title, level)
                                }
                                toggle={(index: number, topic: string) =>
                                  this.toggleExpand(index, topic)
                                }
                                topicname="Advanced Trader"
                              />

                              <TopicListItem
                                classid={this.state.classid}
                                classname={this.state.classname}
                                index={3}
                                expand={
                                  this.state.expand[item]
                                    ? this.state.expand[item][3]
                                    : false
                                }
                                attempts={
                                  this.state.attempts[item][
                                    "Advanced Market Maker"
                                  ]
                                    ? this.state.attempts[item][
                                    "Advanced Market Maker"
                                    ]
                                    : 0
                                }
                                quizStatus={
                                  this.state.quizStatus[item]
                                    ? this.state.quizStatus[item][
                                    "Advanced Market Maker"
                                    ]
                                    : 2
                                }
                                prevGameStatus={
                                  this.state.gameStatus[item]
                                    ? this.state.gameStatus[item][
                                    "Advanced Trader"
                                    ]
                                    : 2
                                }
                                gameStatus={
                                  this.state.gameStatus[item]
                                    ? this.state.gameStatus[item][
                                    "Advanced Market Maker"
                                    ]
                                    : 2
                                }
                                tab={this.state.tab}
                                topictitle={item}
                                showCurriculum={(title: string, level: string) =>
                                  this.showTopicItem(title, level)
                                }
                                toggle={(index: number, topic: string) =>
                                  this.toggleExpand(index, topic)
                                }
                                topicname="Advanced Market Maker"
                              />
                            </ul>
                          </small>
                        </div>
                      );
                    })}
                  </div>

                  {/* </div> */}

                  {/* </div> */}
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
export default withRouter(Topic);
