import React from 'react';
import './activeSession.scss'

export default (onClose) => {
    return <div className="active_session">
        <div>
            <div>
                <label>You have an active session. Please close it or click on continue to proceed here</label>
            </div>
            <div>
                <label onClick={() => window.location.reload()}>Continue</label>
            </div>
        </div>
    </div>
}