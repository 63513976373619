import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import commonService from "../../services/commonService";
import validation from "../../Helpers/Utility/Validation";
import Toast from "../../components/Toast";
import contactUsSvg from "../../contact_us.svg";
import AuthService from "../../services/authService";
import LoadingWheel from "../../components/LoadingWheel";

export interface DemoSignInAsProfessorProps extends RouteComponentProps {}

export interface DemoSignInAsProfessorState {}

export default class DemoSignInAsProfessor extends Component<
  DemoSignInAsProfessorProps,
  DemoSignInAsProfessorState
> {
  msg = "";
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);

  state = {
    errors: {} as any,
    email: "pft.professor1@gmail.com",
    password: "123456"
  };

  async componentDidMount() {
    let response = await AuthService.signInEmail(
      this.state.email,
      this.state.password
    );
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8", height: "6vh" }}
            className=" container registration-title"
          ></div>
        </div>
        <LoadingWheel />
      </div>
    );
  }
}
