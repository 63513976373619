import React from 'react';
import './index.scss';
// icons
import { MdAdd } from 'react-icons/md';

export default () => {
    return <div id="collaborate">
        {/* header */}
        <div id="header">
            Collaborate
        </div>
        {/* toolbar */}
        <div id="header1">
            <div className="button">
                <MdAdd />
                <label>New Topic</label>
            </div>
        </div>
        {/* container */}
        <div id="container">
            {/* aside */}
            <div id="aside"></div>
            {/* aside1 */}
            <div id="aside1"></div>
            {/* content */}
            <div id="content"></div>
        </div>
    </div>
}