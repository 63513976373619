

import { Component } from "react"
import Config from '../config/Config'
import { Post } from './apiServices'
import authStore from "../models/AuthStore";
class commService {

    async verifyPhoneNumber(phonenumber) {
        try {
            let response = await Post(Config.base_url + "/authManager/verifyphonenumber", authStore.authToken, JSON.stringify({ phonenumber: phonenumber }))
            if (response.status == 200) {
                return { status: 400, msg: 'Phone number already registered' }
            } else {
                return { status: 200, msg: '' }
            }
        }
        catch (error) {
            return { status: 200, msg: '' }
        }
    }

    async createContact(contactdetails) {

        try {
            let response = await Post(Config.base_url + "/adminManager/contactus", authStore.authToken, JSON.stringify(contactdetails))
            if (response.status == 200) {
                return { status: 200, msg: 'Success' }
            } else {
                return { status: 400, msg: 'Failed' }
            }
        }
        catch (error) {
            return { status: 400, msg: 'Failed' }
        }

    }

    async signUp(signUpDetails) {

        try {
            let response = await Post(Config.base_url + "/adminManager/futureupdates", authStore.authToken, JSON.stringify(signUpDetails))
            if (response.status == 200) {
                return { status: 200, msg: 'Success' }
            } else {
                return { status: 400, msg: 'Failed' }
            }
        }
        catch (error) {
            return { status: 400, msg: 'Failed' }
        }
    }
    async checkEmail(email) {

        try {
            let response = await Post(Config.base_url + "/adminManager/checkemail", authStore.authToken, JSON.stringify({ email: email }))
            console.log(response)
            let data = await response.json()
            console.log(data)
            if (response.status == 200) {
                return false
            } else if (response.status == 400) {
                return true
            } else {
                return false
            }
        }
        catch (error) {
            return true
        }
    }

    async checkIfStudentEmailIsAlreadyRegistered(email) {

        // try {
        //     let response = await Post(Config.base_url + "/adminManager/checkemail", authStore.authToken, JSON.stringify({ email: email }))
        //     console.log(response)
        //     let data = await response.json()
        //     console.log(data)
        //     if (response.status == 200) {
        //         return false
        //     } else if (response.status == 400) {
        //         return true
        //     } else {
        //         return false
        //     }
        // }
        // catch (error) {
        //     return true
        // }
    }

    async checkRole(email) {
        try {
            let response = await Post(Config.base_url + "/professorManager/validate_email", authStore.authToken, JSON.stringify({ email: email }))
            let data = await response.json()
            if (response.status == 200) {
                let data = await response.json()
                return false
            } else if (response.status == 400) {
                return true
            } else {
                return false
            }
        }
        catch (error) {
            return false
        }


    }
}



const commonService = new commService()
export default commonService