import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ClassDetails } from "../../interfaces";

import ROUTES from "../../ROUTES";
import classService from "../../services/classService";
import studentService from "../../services/studentService";
import profService from "../../services/profService";
import "./ClassList.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../../components/Toast";
import "jquery";
import DeleteConfirm from "../Class/DeleteConfirm";
import authStore from "../../models/AuthStore";
import LoadingWheel from "../../components/LoadingWheel";
import { Button } from "@material-ui/core";
// icons
import {GoChevronDown} from 'react-icons/go';

import {firestore, database} from '../../config/firebase';

// config
import config from '../../config.js'

// models
import ViewLeaders from '../../models/viewLeaders';
import Alert from '../../models/cust_alert'
import Dashboard from "../Dashboard/Dashboard";

import ReactTooltip from 'react-tooltip';
import { datePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import ShowActiveUsersModel from '../../models/showActiveUsers';
import { MdAdd } from "react-icons/md";

// import * as firebase from "../config/firebase";
declare var $: any;
export interface classProps extends RouteComponentProps { }

export interface classState { }


class classList extends Component<any, classState> {
  notifySuccess = () => Toast.success();
  notifyError = () => Toast.error();
  yearList = [] as any;
  state = {
    classes: Array<ClassDetails>(),
    id: "",
    showLoader: true,
    selectedYear: new Date().getFullYear(),
    studWiseresult: false,

    currentUser: null,
    collapsed: true,
    classname: "",
    warning: "",
    toggle_warning_toast: false,

    //toggle viewLeaderModel
    toggleViewLeaderModel: false,
    viewLeaderModelData: {},
    leaderBoardType: "CLASS",

    //for filter button
    toggleFilter: false,
    sortBy: "START_DSC",
    tempClasses: Array<ClassDetails>(),

    // alert
    toggleAlert: false,
    warningText: "",

    toggleStratButton: true,
    toggleActiveStudentsModel: false,
    activeStudentsShowClass: null,

    filtersSwitches: "ONGOING"
  };


  filterClicked = (type: any) => {
    this.setState(st => ({ ...st, sortBy: type }));
    switch (type) {
      case "START_DSC":
        this.setState(st => ({ ...st, classes: [...this.state.tempClasses] }))
        break;
      case "START_ASC":
        this.setState(st => ({ ...st, classes: [...this.state.tempClasses].reverse() }))
        break;
      case "NAME_DSC":
        this.setState(st => ({ ...st, classes: [...this.state.tempClasses].sort((a: any, b: any) => a.classname.toString().toLowerCase() < b.classname.toString().toLowerCase() ? 1 : -1) }))
        break;
      case "NAME_ASC":
        this.setState(st => ({ ...st, classes: [...this.state.tempClasses].sort((a: any, b: any) => a.classname.toString().toLowerCase() > b.classname.toString().toLowerCase() ? 1 : -1) }))
        break;
    }
  }

  addnewClass() {
    this.props.history.push(ROUTES.CLASSREG);
  }

  getClasses = () => {
    classService
      .getAllClassForProfessor()
      .then(async (res:any) => {
        const ref = await database.ref('activeUsers').once('value');
        const snapshot = await ref.val();
        const emails = Object.keys(snapshot);

        const clsses = res.data.map((x:any) => {
          let activeStudents:any = [];
          emails.forEach((email:any) => {
            const eml = email.replace(/,/g,".");
            if(x.students.includes(eml)){
              activeStudents.push(snapshot[email]);
            }
          });
          return {...x, activeStudents};
        });
        this.setState({ classes: clsses, tempClasses: clsses, showLoader: false });
      })
      .catch(error => { });
  };

  getUserclasses() {
    classService
      .getAllClassesForStudent()
      .then(res => {
        const data = res.data
        this.getCardStatus(data);
        this.setState({ classes: data, tempClasses: data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }
  getAllClassesForTeacher() {
    classService
      .getAllClassesForTeacher()
      .then(async(res:any) => {
        const ref = await database.ref('activeUsers').once('value');
        const snapshot = await ref.val();
        const emails = Object.keys(snapshot);

        const clsses = res.data.map((x:any) => {
          let activeStudents:any = [];
          emails.forEach((email:any) => {
            const eml = email.replace(/,/g,".");
            if(x.students.includes(eml)){
              activeStudents.push(snapshot[email]);
            }
          });
          return {...x, activeStudents};
        });
        this.setState({ classes: clsses, tempClasses: clsses, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }
  async componentDidMount() {

    // timmer to toggle start button
    setInterval(() => {
      this.setState((st:any) => ({...st, toggleStratButton: !st.toggleStratButton}));
    },500);

    this.setState({ showLoader: true });

    if (authStore.user.role == "PROFESSOR") {
      this.getClasses();
    } else if (authStore.user.role == "STUDENT") {
      this.getUserclasses();
    } else {
      this.getAllClassesForTeacher();
    }

    for (let i = 0; i < 4; i++) {
      this.yearList.push(this.state.selectedYear + i);
    }
  }

  // async  deleteClass(id: any) {
  //     if (authStore.user.role == 'PROFESSOR') {
  //         this.setState({ id: id }, () => {
  //             $("#classDeleteForm").modal("show");
  //         })
  //     } else {

  //     }
  // }

  editClass(id: any) {
    this.props.history.push({
      pathname: ROUTES.CLASSREG,
      state: { id: id }
    });
  }
  getProfessorName(id: any) {
    const professorName = profService.getProfessorName(id);
    return professorName;
  }

  convertDate(str: any) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, month, date.getFullYear()].join("-");
  }

  showTopic(classname: any) {
    this.props.history.push({ pathname: ROUTES.TOPIC, state: classname });
  }

  async setYearFilter(e: any) {
    await this.setState({ selectedYear: e.target.value });
  }
  showModal() {
    $("#studentInviteModal").modal("show");
  }
  divStyle = {
    "margin-top": "10px"
  };

  cardClicked = (item: any) => {
    if (authStore.user.role === "PROFESSOR" || authStore.user.role === "TEACHER") {
      this.editClass(item.id);
    } else if (authStore.user.role === "STUDENT") {
      if (new Date() > new Date(item.tradeEndDate)) {
        // this.setState(st => ({ ...st, toggleAlert: true, warningText: "Class is currently closed" }))
      } else {
        this.showTopic(item.classname);
      }
    }
  }

  getCardStatus = async (classes: any) => {
    let gameRefs:any = [];
    classes.forEach((clss:any) => {
      if(clss.tradeRules.length === 1){
        gameRefs.push(firestore.collection("game").where("uid","==",authStore.user.user_id).where('classID','==',clss.id).where('gameType','==',clss.tradeRules[0]).where('level','==','Advanced Market Maker').where('status','==',0).get())
      }else{
        gameRefs.push(firestore.collection("game").where("uid","==",authStore.user.user_id).where('classID','==',clss.id).where('gameType','==',"Bonds").where('level','==','Advanced Market Maker').where('status','==',0).get())
      }
    });
    
    const promises = await Promise.all(gameRefs);
    let completedClasses:any = [];
    promises.forEach((promise:any) =>{
      promise.forEach((game:any) => {
          completedClasses.push(game.data().classID);
      })
    })

    let newClasses = classes.map((clss:any) => {
      let today = new Date().getTime();
      let classEndDate = new Date(clss.tradeEndDate).getTime();

      if(completedClasses.includes(clss.id) || today > classEndDate){
        return {...clss,"studentStatus":"Completed"}
      }else{
        return {...clss,"studentStatus":"In Progress"}
      }
    })

    this.setState(st => ({...st, classes: newClasses, tempClasses:newClasses}));
  }

  getClassStatus = (item:any) => {
    let today = new Date().getTime();
    let classEndDate = new Date(item.tradeEndDate).getTime();
    if(classEndDate > today){
      return "In Progress";
    }else{
      return "Completed";
    }
  }

  prettyDate = (str: any) => {
    let date = new Date(str);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear();
  }

  searchCards = (e: any) => {
    const text: string = e.target.value.toLowerCase();
    if (text.length > 0) {
      const searchList = this.state.tempClasses.filter(x => x.classname !== undefined ? x.classname.toLowerCase().includes(text) : false);
      this.setState(st => ({ ...st, classes: searchList }))
    } else {
      this.setState(st => ({ ...st, classes: this.state.tempClasses }))
    }
  }

  render() {
    return (
      <div className="section wb">
        <div className="backgroundImage">
          <div className="container" style={{height:60, color:'white', display:'flex', flexDirection:'row', alignItems:'center'}}>
            <h3 style={{color:'white', paddingTop: 6, fontSize:20, fontWeight: 500}}>Registered Classes</h3>
            {/* add class */}
            {authStore.user.role == "PROFESSOR" ||
                  authStore.user.role == "TEACHER" ? (
                    <div onClick={() => this.addnewClass()} className="_addClass">
                      <MdAdd size={22} style={{fontWeight:900}}/>
                      <div style={{fontSize:15, marginLeft:2, fontWeight: 450}}>Create Class</div>
                    </div>
                  ) : null}
            {/* serach view */}
            <div className="card_search"  style={{ marginRight:20, marginLeft: authStore.user.role == "STUDENT" ? 'auto' : 0}}>
              <i className="fas fa-search"></i>
              <input onChange={this.searchCards} type="text" placeholder="Search by class name..."/>
            </div>

            {/* Drop down to apply filter */}
            <div className="filter_dropdown_1" style={{marginRight:20}}>
              <label onClick={() => this.setState(st => ({...st, filtersSwitches:"ALL"}))} className={this.state.filtersSwitches === "ALL" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>ALL</label>
              <label onClick={() => this.setState(st => ({...st, filtersSwitches:"ONGOING"}))} className={this.state.filtersSwitches === "ONGOING" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>ONGOING</label>
              <label onClick={() => this.setState(st => ({...st, filtersSwitches:"COMPLETED"}))} className={this.state.filtersSwitches === "COMPLETED" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>COMPLETED</label>
            </div>

            {/* Drop down to apply filter */}
            <div className="filter_dropdown">
              <div>
                <label style={{color:'white'}}>Sort by</label><i onClick={() => this.setState(st => ({ ...st, toggleFilter: !this.state.toggleFilter }))} className={"fas fa-chevron-down"}></i>
                <div>
                  <ul>
                    <li style={this.state.sortBy === "NAME_ASC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("NAME_ASC")}>Name(ASC to DSC)</li>
                    <li style={this.state.sortBy === "NAME_DSC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("NAME_DSC")}>Name(DSC to ASC)</li>
                    <li style={this.state.sortBy === "START_ASC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("START_ASC")}>Start date(ASC to DSC)</li>
                    <li style={this.state.sortBy === "START_DSC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("START_DSC")}>Start date(DSC to ASC)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container cmt20">
          {/* {this.state.classes && this.state.classes.length > 0 ? (
            <div className="form-group year-filter">
              <select
                className="form-control"
                onChange={e => this.setYearFilter(e)}
              >
                {this.yearList.map((item: any) => {
                  return item == this.state.selectedYear ? (
                    <option selected>{item}</option>
                  ) : (
                    <option>{item}</option>
                  );
                })}
              </select>
            </div>
          ) : null} */}
          
          <div className="row">
            <div className="col-md-12 reg-card reg-card-transparent-bg reg-card-r-br-0">
              {/* {authStore.user.role == "PROFESSOR" ||
                authStore.user.role == "TEACHER" ? (
                  <div style={{ marginTop: 30 }} className="row">
                    <div className="col-md-3 mt-20 ">
                      <a href="#" onClick={() => this.addnewClass()}>
                        <div
                          style={{ minHeight: "128px" }}
                          className="card text-center "
                        >
                          <div className="card-body add-new-class" id="card">
                            <span className="add-class-button">
                              <i className="fas fa-plus  fa-2x"></i>
                            </span>
                            <span className="add-class">Create Class</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                ) : null} */}

              <div className="row" style={{ marginTop: "100px" }}></div>
              {/* {authStore.user.role == "PROFESSOR" ? (
            <div className="row">
              <button
                type="button"
                className="btn next-btn add-new-btn"
                onClick={() => this.addnewClass()}
              >
                Add New
              </button>
            </div>
          ) : null} */}
              <div className="class-container row">
                {this.state &&
                  this.state.classes &&
                  this.state.classes.map((item: any, i:any) => {
                    if((this.state.filtersSwitches === "COMPLETED" && this.getClassStatus(item) === "Completed")||
                    (this.state.filtersSwitches === "ONGOING" && this.getClassStatus(item) === "In Progress")||
                    (this.state.filtersSwitches === "ALL")){
                      if (
                        item.regStartDate
                          ? new Date(item.regStartDate).getFullYear() ==
                          this.state.selectedYear
                          : false || item.regEndDate
                            ? new Date(item.regEndDate).getFullYear() ==
                            this.state.selectedYear
                            : false
                      ) {
                        return (
                          <div className="col-md-3 mt-15">
                            {authStore.user.role == "PROFESSOR" ||
                              authStore.user.role == "TEACHER" ? (
                                <div
                                  style={{ height: 35 }}
                                  className="card-footer card-invite-btn text-muted"
                                // onClick={e => {
                                //   e.preventDefault();
                                //   e.stopPropagation();
                                //   this.props.history.push({
                                //     pathname: ROUTES.DASHBOARD,
                                //     state: item.classname
                                //   });
                                // }}
                                // this.setState({ selectedYear: e.target.value });
                                >
                                  {/* <div className="col a-left">
                                <i class="fas fa-user-edit"></i>
                                <i className="fas fa-user-edit fa-2x"></i>
                              </div> */}
                                  {/* <i class="fas fa-tachometer-alt"></i> */}
                                  <div style={{ fontSize: 16, cursor: 'pointer', display: 'flex', alignItems: 'center', paddingRight: 8 }} className="col a-right">
                                    <i className="fas fa-user-plus fa-1x" style={{ marginLeft: 'auto', marginTop: 6, paddingLeft: 15 }}
                                      onClick={() => {
                                        if (new Date() < new Date(item.regEndDate)) {
                                          this.setState({
                                            collapsed: !this.state.collapsed
                                          });
                                          this.props.history.push({
                                            pathname: "",
                                            state: {
                                              id: item.id,
                                              classname: item.classname,
                                              generateurl: true,
                                              url: "",
                                              openedModal: true
                                            }
                                          });
                                          this.showModal();
                                        } else {
                                          this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registration window closed!" }));
                                        }
                                      }}></i>
                                    <label
                                      onClick={() => {
                                        if (new Date() < new Date(item.regEndDate)) {
                                          this.setState({
                                            collapsed: !this.state.collapsed
                                          });
                                          this.props.history.push({
                                            pathname: "",
                                            state: {
                                              id: item.id,
                                              classname: item.classname,
                                              generateurl: true,
                                              url: "",
                                              openedModal: true
                                            }
                                          });
                                          this.showModal();
                                        } else {
                                          this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registration window closed!" }));
                                        }
                                      }}
                                      style={{ marginBottom: 8, marginLeft: 7, fontWeight: 450, cursor: 'pointer' }}>Invite Student(s)</label>
                                    <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#InviteStudents`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 8 }} data-tip data-for='_class_create' className="fas fa-info-circle help-icon"></i>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            <div
                              className={
                                authStore.user.role == "PROFESSOR" ||
                                  authStore.user.role == "TEACHER"
                                  ? "card text-center card-body-btn "
                                  : authStore.user.role == "STUDENT"
                                    ? "card text-center card-body-btn student-card-btn"
                                    : "null"
                              }
                            >
                              {/* <img
                          className="card-img-top"
                          src="https://picsum.photos/1900/1080?image=327"
                          alt="Card image cap"
                        /> */}
  
                              <div id="card">
                                <h4 className="card-title" style={{ position: 'relative', marginTop: 10, height: 20,  }}>
                                  {
                                    authStore.user.role == "STUDENT" ? 
                                  <p data-tip data-for={"class_desc_tip"+i} onClick={() => this.cardClicked(item)} style={{ cursor: 'pointer' }}>{item.classname}</p>:
                                  <p onClick={() => this.getClassStatus(item) !== "Completed" ? this.cardClicked(item):null} style={{ cursor: 'pointer', marginRight: 30 }}>{item.classname}</p>
                                  }
                                  {
                                    authStore.user.role != "STUDENT" ? 
                                    <i onClick={() => this.getClassStatus(item) !== "Completed" ? this.cardClicked(item):null} style={{ paddingLeft: 20, fontSize: 20, color: '#009CCE', cursor: 'pointer', position: 'absolute', top: 0, right: 10, opacity: this.getClassStatus(item) !== "Completed" ? 1.0:0.6 }} className="fas fa-edit"></i> : null
                                  }
                                {/* {authStore.user.role == "STUDENT" ? <i data-tip data-for="class_desc_tip" className="fas fa-info-circle class_list_item_info_icon" ></i> : null} */}
                                <ReactTooltip multiline={true} id={"class_desc_tip"+i} backgroundColor="black" place="bottom" effect="solid"><div style={{ maxWidth: 340 }}>{item.description}</div></ReactTooltip>
                                {
                                  item.studentStatus != "Completed" ? 
                                  <ReactTooltip multiline={true} id={"start"+i} backgroundColor="black" place="right" effect="solid">Click here start</ReactTooltip>:
                                  <ReactTooltip multiline={true} id={"start"+i} backgroundColor="black" place="right" effect="solid">Course Completed</ReactTooltip>
                                } 
                                </h4>
  
                                {
                                  authStore.user.role === "STUDENT" ? 
                                  <label style={{ fontSize: 13, opacity: 0.6 }}>Status: {"studentStatus" in item ? item.studentStatus : "Loading"}</label> : 
                                <label style={{ fontSize: 13, opacity: 0.6 }}>Status: {this.getClassStatus(item)}</label>
                                }
                                {
                                  authStore.user.role !== "STUDENT"?
                                <a onClick={() => {
                                  if(item.activeStudents.length > 0){
                                    this.setState((st:any) => ({...st, toggleActiveStudentsModel:true, activeStudentsShowClass: item}))}
                                  }
                                } style={{display:'block', fontSize:13, textDecoration:'underline', opacity:item.activeStudents.length > 0 ? 1:0.4}}>Students Online: {item.activeStudents.length}</a>:null
                                }
                                {
                                    authStore.user.role == "STUDENT" ? 
                                    item.studentStatus == "Completed" ? 
                                    <img data-tip style={{width:45, height:45, cursor: 'pointer',display:'block', marginLeft:'45%', marginTop:4, marginBottom:8, opacity:0.4}} src={require('../../images/ft_start_orange.png')}/>:
                                    this.state.toggleStratButton ?
                                    <img data-tip data-for={"start"+i} onClick={() => this.cardClicked(item)} style={{width:45, height:45, cursor: 'pointer',display:'block', marginLeft:'45%', marginTop:4, marginBottom:8}} src={require('../../images/ft_start_blue.png')}/> :
                                    <img data-tip data-for={"start"+i} onClick={() => this.cardClicked(item)} style={{width:45, height:45, cursor: 'pointer',display:'block', marginLeft:'45%', marginTop:4, marginBottom:8}} src={require('../../images/ft_start_orange.png')}/> :null
                                  }
                                <div className="dates">
                                  {/* <strong>{"CLASS DATES"}</strong> */}
  
                                  <div className="start">
                                    <strong>CLASS STARTS</strong>
                                    {this.prettyDate(item.tradeStartDate)}
                                  </div>
                                  <div className="ends">
                                    <strong>CLASS ENDS</strong>
                                    {this.prettyDate(item.tradeEndDate)}
                                  </div>
                                </div>
                                {/* <div className="dates">
                                <strong >{"EXAM DATES"}</strong>
  
                                  <div className="start">
                                    <strong>STARTS</strong>
                                    {this.convertDate(item.regStartDate)}
                                    <span></span>
                                  </div>
                                  <div className="ends">
                                    <strong>ENDS</strong>
                                    {this.convertDate(item.regEndDate)}
                                  </div>
                                </div> */}
                                {authStore.user.role == "PROFESSOR" ||
                                  authStore.user.role == "TEACHER" ? (
                                    // <div className="dates">
                                    //   {/* <strong>{"STUDENTS"}</strong> */}
                                    //   {/* <div className="endss">
                                    //     <strong>Students</strong>
                                    //     {item.noofstudents}
                                    //   </div> */}
                                    //   {/* <div className="start">
                                    //     <strong>REGISTERED</strong>
                                    //     {item.noofstudents}
                                    //     <span></span>
                                    //   </div> */}
                                    //   {/* <div className="ends"> */}
                                    //   <strong>TOTAL CAPACITY</strong>
                                    //   {item.noofstudents}
                                    //   {/* </div> */}
                                    // </div>
  
                                    <div className="dates">
                                      {/* <strong>{"CAPACITY"}</strong> */}
  
                                      <div className="start">
                                        <strong>CLASS CAPACITY</strong>
                                        {parseInt(item.noofstudents) - (item.students.includes(`${item.classname}_TA@gmail.com`.replace(' ','').toLowerCase()) ? (authStore.user.role === "PROFESSOR" ? 2 : 1) : 0)}
                                      </div>
                                      <div className="ends">
                                        <strong>SLOTS TAKEN</strong>
                                        {/* {(
                                        parseInt(item.noofstudents) -
                                        parseInt(item.students.length)
                                      ).toString()} */}
                                        {authStore.user.role === "PROFESSOR" ? item.students.length - 2 : item.students.length - 1}
                                      </div>
                                    </div>
                                  ) : null}
                                {/* {authStore.user.role == "STUDENT" ? (
                                  <div className="dates">
                                    <strong>
                                      {this.getProfessorName(item.id)}
                                    </strong>
                                    <div className="endss">
                                      <strong>Students</strong>
                                      {item.noofstudents}
                                    </div>
                                    <div className="start">
                                      <strong>REGISTERED</strong>
                                      {item.noofstudents}
                                      <span></span>
                                    </div>
                                    <div className="ends">
                                      <strong>TOTAL</strong>
                                      {item.noofstudents}
                                    </div>
                                  </div>
                                ) : null} */}
                              </div>
  
  
                              {/* leadership board button starts*/}
                              {/* <div style={{ backgroundColor: '#2867E4', height: 40, paddingTop: 6, paddingBottom: 6, marginBottom: 2, color: 'white' }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (parseInt(item.students.length) > 0) {
                                  this.setState(st => ({ ...st, toggleViewLeaderModel: true, viewLeaderModelData: { "className": item.classname } }));
                                } else {
                                  this.setState(st => ({ ...st, toggleAlert: true, warningText: "NO SLOTS TAKEN" }))
                                }
                              }}>
                                <div style={{ fontSize: 16, cursor: 'pointer' }} className="col a-right">
                                  <i className="fas fa-crown" style={{ marginRight: 10 }}></i>
                                  Class Leaderboard
                                </div>
                              </div> */}
                              {/* leadership board button ends*/}
  
                              <div style={{ height: 40, fontSize: 16, opacity:(authStore.user.role === "STUDENT") || (authStore.user.role === "TEACHER" && item.students.length > 1) || (authStore.user.role === "PROFESSOR" && item.students.length > 2) ? 1:0.5 }} className="card-footer text-muted" >
                                <div style={{ fontSize: 16, cursor: 'pointer', display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }} className="col a-right">
                                  <i className="fas fa-crown fa-1x" style={{ marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8 }}></i>
                                  <label
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // this.props.history.push({
                                      //   pathname: ROUTES.DASHBOARD,
                                      //   state: item.classname
                                      // });
                                      if( (authStore.user.role === "STUDENT") || (authStore.user.role === "TEACHER" && item.students.length > 1) || (authStore.user.role === "PROFESSOR" && item.students.length > 2)){
                                        this.props.toggleDashboard(item.classname);
                                      }else{
                                        // this.setState(st => ({ ...st, toggleAlert: true, warningText: "No data found" }))
                                      }
                                    }}
                                    style={{ marginBottom: 8, marginLeft: 6, fontWeight: 430, cursor: 'pointer' }}>
                                    {authStore.user.role == "PROFESSOR" ||
                                      authStore.user.role == "TEACHER"
                                      ? "Class Dashboard"
                                      : "Student Dashboard"}
                                  </label>
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#MoniterStudentslearning`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  })}
              </div>
            </div>
          </div>

          {Toast.successToastView()}
          {Toast.errorToastView()}
          <DeleteConfirm id={this.state.id} getClasses={this.getClasses} />
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
        {/* Toogle models */}
        {
          this.state.toggleViewLeaderModel ?
            <ViewLeaders
              type={this.state.leaderBoardType}
              onClose={() => { this.setState(st => ({ ...st, toggleViewLeaderModel: false })) }}
              data={this.state.viewLeaderModelData} /> : null
        }
        {
          this.state.toggleActiveStudentsModel ? 
          <ShowActiveUsersModel activeStudentsShowClass={this.state.activeStudentsShowClass} onClose={() => this.setState((st:any) => ({...st, toggleActiveStudentsModel: !st.toggleActiveStudentsModel}))}/>:null
        }
        {/* Toast */}
        {this.state.toggleAlert ? <Alert text={this.state.warningText} onClose={() => this.setState(st => ({ ...st, toggleAlert: false }))} /> : null}

      </div >
    );
  }
}
export default withRouter(classList);
