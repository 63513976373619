import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ClassDetails } from "../../interfaces";
import SubscriptionItem from "./SubscriptionItem";
import clasService from "../../services/classService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../../components/Toast";
import ROUTES from "../../ROUTES";
import authStore from "../../models/AuthStore";
import Validate from "../../Helpers/Utility/Validation";
import "jquery";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import LoadingWheel from "../../components/LoadingWheel";
import { any } from "prop-types";
import PrintComponents from "react-print-components";
import ComponentToPrint from "./ComponentToPrint";
import Select from "react-select";
import wizardSvg from "../../wizard.svg";
import ClassCreated from '../../models/classCreated';
import './ClassReg.css';

// models
import StudentInvite from '../../components/StudentInvite';

// config
import config from '../../config';
import ReactTooltip from 'react-tooltip';


var QRCode = require("qrcode.react");
var $;
const ct = require("countries-and-timezones");
const moment = require("moment");

function styleFn(provided, state) {
  return { ...provided, theme: "danger" };
}

class ClassReg extends Component {
  state = {
    Page: 1,
    classDetails: { noofstudents: 25, passingScore: 70, amountAwarded: 100, roundAteachLevel: 5, minProfit: 500, extraCredit: 10 },
    errors: {},
    showLoader: false,
    classes: Array(),
    selectedClass: Array(),
    isDateValueNull: false,
    isSubmit: false,
    selectClassErrorMsg: "",
    checkbox_checked: false,
    //for model
    showClassCreatedModel: false,
    classHolder: null,
    showStudentInviteModel: false
  };

  msg = "";
  timeZone;
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);
  SelectAllGameTypes() {
    this.setState({ checkbox_checked: true });
  }
  SelectNoGameTypes() {
    this.setState({ checkbox_checked: false });
  }

  setDefaultValues() {
    var today = new Date();
    var week = 7 * 24 * 60 * 60 * 1000;
    var three_months = 12 * week;
    var dateAfterWeek = new Date(today.getTime() + week);
    var dateAfterThreeMonths = new Date(today.getTime() + three_months);

    this.state.classDetails.regStartDate = today;
    this.state.classDetails.regEndDate = dateAfterWeek;
    this.state.classDetails.tradeStartDate = dateAfterWeek;
    this.state.classDetails.tradeEndDate = three_months;
    this.setState({ ...this.state, classDetails: { ...this.state.classDetails, 'regStartDate': today, 'regEndDate': dateAfterWeek, 'tradeStartDate': dateAfterWeek, 'tradeEndDate': dateAfterThreeMonths } })

    this.setState(
      Object.assign(this.state.classDetails, this.state.classDetails)
    );
  }

  updateUserState = (e, type) => {
    if (type && e.target.value.length <= 100) {
      if (type == "text") {
        if (e.target.value.match(/^[A-Za-z\s]{0,}$/)) {
          let stateObject = {};
          stateObject[e.target.name] = e.target.value.trimLeft();
          this.setState(Object.assign(this.state.classDetails, stateObject));
        }
      } else if (type == "number") {
        if (
          e.target.value <= 10000 &&
          (e.target.value != 0 || e.target.value == "")
        ) {
          if (
            e.target.name == "noofstudents" ||
            e.target.name == "passingScore"
          ) {
            if (e.target.value <= 100) {
              let stateObject = {};
              stateObject[e.target.name] = e.target.value;
              this.setState(
                Object.assign(this.state.classDetails, stateObject)
              );
            }
          } else if (
            e.target.name == "roundAteachLevel" ||
            e.target.name == "noofattempts"
          ) {
            if (e.target.value <= 5) {
              let stateObject = {};
              stateObject[e.target.name] = e.target.value;
              this.setState(
                Object.assign(this.state.classDetails, stateObject)
              );
            }
          } else {
            let stateObject = {};
            stateObject[e.target.name] = e.target.value;
            this.setState(Object.assign(this.state.classDetails, stateObject));
          }
        }
      }
    } else if (e.target.value.length <= 100) {
      let stateObject = {};
      stateObject[e.target.name] = e.target.value;
      this.setState(Object.assign(this.state.classDetails, stateObject));
    }
  };

  updateRadioValue = (e) => {
    let stateObject = {};
    stateObject[e.target.name] = e.target.title;
    this.setState(Object.assign(this.state.classDetails, stateObject));
  };

  updateCheckbox = (e) => {
    this.state.classDetails.tradeRules =
      this.state.classDetails.tradeRules || [];
    if (e.target.name == "All") {
      if (e.target.checked) {
        this.state.classDetails.tradeRules = [
          "Stocks",
          "Bonds",
          // "Options",
          // "Technical Analysis",
          // "Fixed Income",
          // "Commodities",
          // "Currencies",
          // "Crypto-Commodities"
        ];
      } else {
        this.state.classDetails.tradeRules = [];
      }
    } else if (e.target.name == "None") {
      if (e.target.checked) this.state.classDetails.tradeRules = [];
    } else {
      if (e.target.checked) {
        if (this.state.classDetails.tradeRules) {
          this.state.classDetails.tradeRules.push(e.target.name);
        } else {
          this.state.classDetails.tradeRules = e.target.name;
        }
      } else {
        this.state.classDetails.tradeRules = this.state.classDetails.tradeRules.filter(
          (item) => item !== e.target.name
        );
      }
    }
    this.setState(
      Object.assign(this.state.classDetails, this.state.classDetails)
    );
  };

  async componentDidMount() {
    this.setState(Object.assign(this.state.classDetails, { "timeZone": "America/New_York" }));

    this.setTimeZone();

    if (this.props.location.state) {
      this.setState({ showLoader: true });
      let classDetails = await clasService.getClassbyId(
        this.props.location.state.id
      );
      this.setState({ showLoader: false });
      this.setState({ classDetails: { ...classDetails.data, timeZone: "America/New_York" } });
      // alert(JSON.stringify(classDetails.data));
    } else {
      this.setDefaultValues();
      if (this.state.classDetails.tradeRules) {
        this.state.classDetails.tradeRules = [];
      }

      clasService
        .getAllClassForProfessor()
        .then(res => {
          this.setState({ classes: res.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  async createClass() {
    this.setState({ showLoader: true });
    await clasService.createClass({ ...this.state.classDetails }).then(async (res) => {
      this.setState({ showLoader: false });
      if (res.status == 200) {
        this.msg = "Success";
        await this.notifySuccess();
        setTimeout(() => {
          this.setState(st => ({ ...st, classHolder: res.classData, showClassCreatedModel: true }))
        }, 2000);
      } else if (res.status == 401) {
        this.msg = "Unauthorized";
        this.notifyError();
        this.setState({ isSubmit: false });
      } else if (res.status === 400) {
        alert("Class name is already taken");
        this.msg = "Class name is already taken";
        this.notifyError();
        this.setState({ isSubmit: false });
      }
      else {
        this.msg = "Something went wrong. Please try again later";
        this.notifyError();
        this.setState({ isSubmit: false });
      }
    }).catch((e) => {
      this.setState({ showLoader: false });
      this.msg = "Something went wrong. Please try again later";
      this.notifyError();
      this.setState({ isSubmit: false });
    });

    // const response = await clasService.createClass(this.state.classDetails);
    // this.setState({ showLoader: false });
    // if (response.status) {
    //   if (response.status == 200) {
    //     this.msg = response.msg;
    //     await this.notifySuccess();
    //     setTimeout(() => {
    //       console.log("----------------------" + JSON.stringify(response))
    //       //this.props.history.push(ROUTES.CLASSLIST);
    //     }, 2000);
    //   } else {
    //     this.msg = response.msg;
    //     this.notifyError();
    //     this.setState({ isSubmit: false });
    //   }
    // } else {
    //   this.msg = response.msg;
    //   this.notifyError();
    //   this.setState({ isSubmit: false });
    // }
  }

  async updateClass() {
    this.setState({ showLoader: true });
    let response = await clasService.updateClass(this.state.classDetails);
    this.setState({ showLoader: false });
    if (response.status) {
      if (response.status == 200) {
        this.msg = response.msg;
        await this.notifySuccess();
        setTimeout(() => {
          // this.setState({ Page: 1 });
          this.props.history.push(ROUTES.CLASSLIST);
        }, 1500);
      } else {
        this.msg = response.msg;
        await this.notifyError();
      }
    } else {
      this.msg = response.msg;
      this.notifyError();
    }
  }

  selectedClass(value) {
    let obj = this.state.classes.filter(item => {
      if (item.classname == value) {
        return item;
      }
    });

    this.setState({ selectedClass: obj });
  }

  changeDate = (value, field) => {
    if (value != null) {
      if (value != "Invalid Date") {
        this.state.errors[field] = "";
        this.setState(Object.assign(this.state.errors, this.state.errors));
        this.state.classDetails[field] = value;
        this.setState(
          Object.assign(this.state.classDetails, this.state.classDetails)
        );
        this.setState({ isDateValueNull: false });
      } else {
        this.setState({ isDateValueNull: true });
      }
    } else {
      this.state.classDetails[field] = "";
      this.setState(
        Object.assign(this.state.classDetails, this.state.classDetails)
      );
    }
  };

  async copyPreviousClass() {
    if (this.state.selectedClass && this.state.selectedClass[0]) {
      delete this.state.selectedClass[0].classname;
      delete this.state.selectedClass[0].id;
      delete this.state.selectedClass[0].link;
      await this.setState(
        {
          selectClassErrorMsg: "",
          classDetails: this.state.selectedClass[0] || this.state.selectedClass
        },
        () => {
          $("#showClasses").modal("hide");
        }
      );
    } else {
      this.setState({ selectClassErrorMsg: "Select a class" });
    }
  }
  // validateSelectedClass() {
  //   if (this.state.selectedClass && this.state.selectedClass[0]) {
  //     this.setState({ selectClassErrorMsg: "" });
  //   } else {
  //     this.setState({ selectClassErrorMsg: "Select a class" });
  //   }
  // }
  // showPreviousClass() {
  //   return (
  //     <div
  //       className="modal fade"
  //       id="showClasses"
  //       role="dialog"
  //       aria-labelledby="showClassesLabel"
  //       aria-hidden="true"
  //     >
  //       <div className="modal-dialog sign-in-modal-dialog" role="document">
  //         <div className="modal-content">
  //           <div className="modal-header">
  //             <button
  //               type="button"
  //               className="close"
  //               data-dismiss="modal"
  //               aria-label="Close"
  //             >
  //               <span aria-hidden="true">&times;</span>
  //             </button>
  //           </div>
  //           <div className="modal-body signin-modal">
  //             <h2>Select Class</h2>
  //             <div className="row">
  //               <div className="col-xs-12 col-sm-12">
  //                 <select
  //                   className="form-control bfh-timezones"
  //                   onChange={e => this.selectedClass(e.target.value)}
  //                   onBlur={() => this.validateSelectedClass()}
  //                 >
  //                   <option selected disabled>
  //                     select
  //                   </option>
  //                   {this.state.classes.map(item => {
  //                     return <option>{item.classname}</option>;
  //                   })}
  //                 </select>
  //                 <div className="validation-error">
  //                   {this.state.selectClassErrorMsg}
  //                 </div>
  //                 <button
  //                   style={{ marginTop: 20 }}
  //                   className="btn btn-primary pull-right next-btn"
  //                   onClick={() => this.copyPreviousClass()}
  //                 >
  //                   Done
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
  isAnyErrorFound(error) {
    this.setState(Object.assign(this.state.errors, error.errors));
  }
  async validatePage1() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let formError;

    formError = await Validate.validateSelect(
      this.state.classDetails.subject,
      "subject",
      "class subject"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateDescription(
      this.state.classDetails.description,
      "description"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateSelect(
      this.state.classDetails.timeZone,
      "timeZone",
      "any timezone"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateNoStudent(
      this.state.classDetails.noofstudents,
      "noofstudents"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateSelect(
      this.state.classDetails.academic,
      "academic",
      "academic level"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateTradeRules(
      this.state.classDetails.tradeRules,
      "tradeRules"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateDate(
      this.state.classDetails.regStartDate,
      "regStartDate",
      "registration start",
      this.props.location.state,
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateDate(
      this.state.classDetails.regEndDate,
      "regEndDate",
      "registration end",
      this.props.location.state,
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateDate(
      this.state.classDetails.tradeStartDate,
      "tradeStartDate",
      "trading start",
      this.props.location.state,
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateDate(
      this.state.classDetails.tradeEndDate,
      "tradeEndDate",
      "trading end",
      this.props.location.state,
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.checkEnddateIsBeforeStartdate(
      this.state.classDetails.regStartDate,
      this.state.classDetails.regEndDate,
      "regEndDate"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.checkEnddateIsBeforeStartdate(
      this.state.classDetails.tradeStartDate,
      this.state.classDetails.tradeEndDate,
      "tradeEndDate"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateRegClassName(
        this.state.classDetails.classname,
        "classname"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }

    this.setState({ showLoader: false });

    if (formvalid == true) {
      this.setState({ Page: 2 });
    }
  }
  async validatePage4() {
    this.setState({ showLoader: true });
    let formError;
    let formvalid = true;
    formError = await Validate.validateSelect(
      this.state.classDetails.subscription,
      "subscription",
      "subscription plan"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateSelect(
      this.state.classDetails.paymethod,
      "paymethod",
      "payment method"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    this.setState({ showLoader: false });

    if (formvalid == true) {
      if (this.props.location.state && this.props.location.state.id) {
        this.updateClass();
      } else {
        this.createClass();
      }
    } else {
      this.setState({ isSubmit: false });
    }
  }
  async validatePage3() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let formError;
    formError = await Validate.validateRound(
      this.state.classDetails.roundAteachLevel,
      "roundAteachLevel"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    formError = await Validate.validateMinBenchmark(
      this.state.classDetails.minProfit,
      "minProfit"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    this.setState({ showLoader: false });

    // if (formvalid == true) {
    //     this.setState({ Page: 4 })

    // }
    if (formvalid == true) {
      if (this.props.location.state && this.props.location.state.id) {
        this.updateClass();
      } else {
        this.createClass();
      }
    } else {
      this.setState({ isSubmit: false });
    }
  }
  async validatePage2() {
    let formvalid = true;
    let formError;
    formError = await Validate.validatePassingScore(
      this.state.classDetails.passingScore,
      "passingScore"
    );
    if (!formError.isValid) {
      formvalid = false;
      this.isAnyErrorFound(formError);
    }
    // formError = await Validate.validateNoofAttempt(
    //   this.state.classDetails.noofattempts,
    //   "noofattempts"
    // );
    // if (!formError.isValid) {
    //   formvalid = false;
    //   this.isAnyErrorFound(formError);
    // }
    formError = await Validate.validateSelect(
      this.state.classDetails.amountAwarded,
      "amountAwarded",
      "amount awarded"
    );
    if (!formError.isValid) {
      formvalid = false;
      this.isAnyErrorFound(formError);
    }

    if (formvalid == true) {
      this.setState({ Page: 3 });
    }
  }
  checkStatus(value) {
    if (this.state.classDetails.tradeRules) {
      if (this.state.classDetails.tradeRules.length == 6) {
        return true;
      } else {
        if (value === "All") {
          if(this.state.classDetails.tradeRules.length == 2){
            return true;
          }else{
            return false;
          }
        } else {
          let array = this.state.classDetails.tradeRules.filter(
            (item) => item == value
          );
          if (array.length != 0) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  }
  showModal() {
    $("#showClasses").modal("show");
  }

  setTimeZone() {
    let timezones = ct.getAllTimezones();
    this.timeZone = Object.keys(timezones).map((item) => {
      let obj = {};
      obj.value = item;
      obj.label = item;
      return obj;
    });
  }

  renderPage1() {
    return (
      <div className="step">
        <div className="row">
          <div className="col-md-12">
            <ul className="stepper stepper-horizontal">
              <li className="active">
                <a href="#!">
                  <span className="circle">1</span>
                  <span className="label">Class Information</span>
                </a>
              </li>

              <li>
                <a href="#!">
                  <span className="circle">2</span>
                  <span className="label">Curriculum Configuration</span>
                </a>
              </li>
              <li>
                <a href="#!">
                  <span className="circle">3</span>
                  <span className="label">Game Configuration</span>
                </a>
              </li>
              {/* <li >
                                <a href="#!">
                                    <span className="circle">4</span>
                                    <span className="label">Subscription </span>
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>

        {/* <h5 className="form-heading">Class Information</h5> */}
        {this.state.classDetails.link && (new Date() < new Date(this.state.classDetails.regEndDate)) ? (
          <div className="row mt-5 mb-5">
            <div
              className="row mt-5 mb-5 info-head"
              style={{ paddingLeft: "15px" }}
            >
              <h3 className="col-md-6 ">Class Information</h3>
            </div>
            <PrintComponents
              trigger={
                <button className="print-button">
                  <span className="print-icon"></span> <br />
                  Print QR Code
                </button>
              }
            >
              <ComponentToPrint classDetails={this.state.classDetails} />
            </PrintComponents>
          </div>
        ) : null}

        {/* checking if edit/new form and to shows invite url field based on regEndDate(edit form) */}
        {(this.state.classDetails.link && (new Date() < new Date(this.state.classDetails.regEndDate))) ? (
          <div className="row mt-5 mb-5">
            <div className="col-md-3">Invite URL</div>
            <div className="col-md-9">
              <div className="col-md-12 url-div ">
                {this.state.classDetails.link}
              </div>
            </div>

            {/* <PrintComponents
                            trigger={<button className="print-button"><span className="print-icon"></span> <br />Print QR Code</button>}
                        >
                            <ComponentToPrint classDetails={this.state.classDetails} />
                        </PrintComponents> */}
          </div>
        ) : null}
        <div className="row mt-5 mb-5">
          {!this.state.classDetails.link ? (
            <div
              className="row mt-5 mb-5 info-head"
              style={{ paddingLeft: "15px" }}
            >
              <h3 className="col-md-6 ">Class Information</h3>
            </div>
          ) : null}
          <div className="col-md-3">
            Class Name<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              name="classname"
              id="copyPreviousclass"
              onChange={e => this.updateUserState(e)}
              value={
                this.state.classDetails.classname
                  ? this.state.classDetails.classname
                  : ""
              }
              onLoad={async () => {
                let error = await Validate.validateRegClassName(
                  this.state.classDetails.classname,
                  "classname"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              aria-describedby="previousclass"
              placeholder="Class Name"
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.classname}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Class Subject<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-6">
            <select
              className="form-control"
              name="subject"
              value={
                this.state.classDetails.subject
                  ? this.state.classDetails.subject
                  : ""
              }
              onBlur={async () => {
                let error = await Validate.validateSelect(
                  this.state.classDetails.subject,
                  "subject",
                  "class subject"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              onChange={e => this.updateUserState(e)}
            >
              <option selected disabled value="">
                Select
              </option>
              <option>Investments</option>
              <option>Portfolio Management</option>
              <option>Personal Finance</option>
              <option>Financial Management</option>
              <option>Marketing</option>
              <option>Others</option>
            </select>
            <div className="validation-error"> {this.state.errors.subject}</div>
          </div>
        </div>
        {/* <div className="row mt-10 mb-10">
                    <div className="col-md-3">Class Subject<span className="mandtory-star">*</span></div>
                    <div className="col-md-3">
                        <select
                            className="form-control mdb-select md-form"
                            name="subject"
                            value={this.state.classDetails.subject ? this.state.classDetails.subject : ''}
                            onBlur={async () => {
                                let error = await Validate.validateSelect(this.state.classDetails.subject, "subject", "class subject")
                                this.setState(Object.assign(this.state.errors, error.errors))

                            }}
                            onChange={(e) => this.updateUserState(e)}>
                            <option selected disabled value=''>Select</option>
                            <option>Investments</option>
                            <option>Portfolio Management</option>
                            <option>Personal Finance</option>
                            <option>Financial Management</option>
                            <option>Marketing</option>
                            <option>Other</option>
                        </select>
                        <div className="validation-error"> {this.state.errors.subject}</div>
                    </div>
                    <div className="col-md-3">
                        {this.props.location.state ? '' : <button className="btn btn-default next-btn" onClick={() => this.showModal()}>Copy Previous Class</button>}
                    </div>
                </div> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Description<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              name="description"
              value={
                this.state.classDetails.description
                  ? this.state.classDetails.description
                  : ""
              }
              onChange={e => this.updateUserState(e)}
              id="copyPreviousclass"
              aria-describedby="previousclass"
              onBlur={async () => {
                let error = await Validate.validateDescription(
                  this.state.classDetails.description,
                  "description"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              placeholder="Description"
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.description}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Time Zone<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-9">
            <Select
              placeholder={"Time Zone"}
              value={this.state.classDetails.timeZone}
              options={this.timeZone}
              styles={styleFn()}
              defaultInputValue={"America/New_York"}
              onBlur={async () => {
                let error = await Validate.validateTimeZone(
                  this.state.classDetails.timeZone,
                  "timeZone"
                );
                this.setState(Object.assign(this.state.errors, error));
              }}
              onChange={(val) => {
                alert(JSON.stringify(val))
                let obj = {};
                obj.timeZone = val;
                this.setState(Object.assign(this.state.classDetails, obj));
              }}
            />

            <div className="validation-error">
              {" "}
              {this.state.errors.timeZone}
            </div>
          </div>
        </div>
        {/* <div className="row mt-10 mb-10">
                    <div className="col-md-3">Number of Students<span className="mandtory-star">*</span></div>
                    <div className="col-md-6">
                        <input
                            type="text"
                            className="form-control"
                            name="noofstudents"
                            value={this.state.classDetails.noofstudents ? this.state.classDetails.noofstudents : ''}
                            onChange={(e) => this.updateUserState(e, "number")}
                            id="copyPreviousclass"
                            onBlur={async () => {
                                let error = await Validate.validateNoStudent(this.state.classDetails.noofstudents, "noofstudents")
                                this.setState(Object.assign(this.state.errors, error.errors))

                            }}
                            aria-describedby="previousclass"

                            placeholder="Number of students" />
                        <div className="validation-error"> {this.state.errors.noofstudents}</div>
                    </div>
                </div> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            No. of Students<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              name="noofstudents"
              value={
                this.state.classDetails.noofstudents
                  ? this.state.classDetails.noofstudents
                  : ""
              }
              onChange={e => this.updateUserState(e, "number")}
              id="copyPreviousclass"
              onBlur={async () => {
                let error = await Validate.validateNoStudent(
                  this.state.classDetails.noofstudents,
                  "noofstudents"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              aria-describedby="previousclass"
              placeholder="Number of students"
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.noofstudents}
            </div>
          </div>
          <div className="col-md-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            Academic Level
            <span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <select
              className="form-control bfh-timezones"
              data-country="US"
              name="academic"
              value={
                this.state.classDetails.academic
                  ? this.state.classDetails.academic
                  : ""
              }
              onBlur={async () => {
                let error = await Validate.validateSelect(
                  this.state.classDetails.academic,
                  "academic",
                  "academic level"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              onChange={e => this.updateUserState(e)}
            >
              <option selected disabled value="">
                Select
              </option>
              {
                authStore.user.role === "PROFESSOR" ?
                  <Fragment>
                    <option>Post Graduate and above</option>
                    <option>Graduate</option>
                    <option>Bachelor's</option>
                  </Fragment> : null
              }
              {
                authStore.user.role === "TEACHER" ?
                  <Fragment>
                    <option>High School</option>
                    <option>Middle School</option>
                  </Fragment> : null
              }
            </select>
            <div className="validation-error">
              {" "}
              {this.state.errors.academic}
            </div>
          </div>
        </div>
        {/* <div className="row mt-5 mb-5">
                    <div className="col-md-3">Registration Start Date<span className="mandtory-star">*</span></div>
                    <div className="col-md-3">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    // margin="none"
                                    format="MM/dd/yyyy"
                                    // updateData
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    value={this.state.classDetails.regStartDate}
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.regStartDate, "regStartDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    onChange={(e) => this.changeDate(e, 'regStartDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    // defaultValue="none"
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.regStartDate}</div>
                    </div>
                    <div className="col-md-3">Registration End Date<span className="mandtory-star">*</span></div>

                    <div className="col-md-3">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    margin="none"
                                    format="MM/dd/yyyy"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    value={this.state.classDetails.regEndDate}
                                    onChange={(e) => this.changeDate(e, 'regEndDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    // defaultValue="none"
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.regEndDate, "regEndDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.regEndDate}</div>
                    </div>
                </div> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Select Game Types<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-9">
            <form className="trading-rule-chk-box">
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Stocks"
                    checked={this.checkStatus("Stocks")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Stocks</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Bonds"
                    checked={this.checkStatus("Bonds")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Bonds</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    disabled={true}
                    name="Options"
                    checked={this.checkStatus("Options")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Options</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Technical Analysis"
                    disabled={true}
                    checked={this.checkStatus("Technical Analysis")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Technical Analysis</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Fixed Income"
                    disabled={true}
                    checked={this.checkStatus("Fixed Income")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Fixed Income</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Commodities"
                    disabled={true}
                    checked={this.checkStatus("Commodities")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Commodities</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Currencies"
                    disabled={true}
                    checked={this.checkStatus("Currencies")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Currencies</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="Crypto-Commodities"
                    disabled={true}
                    checked={this.checkStatus("Crypto-Commodities")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">Crypto-Commodities</label>
                </div>
                <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="All"
                    // disabled={true}
                    checked={this.checkStatus("All")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">All</label>
                </div>
                {/* <div className="col-md-6">
                  <input
                    type="checkbox"
                    value=""
                    className="chk-box-reg"
                    name="None"
                    // disabled={true}
                    // checked={this.checkStatus("None")}
                    onChange={e => this.updateCheckbox(e)}
                  />
                  <label className="checkbox-inline ">None</label>
                </div> */}
                {/* <div className="col-md-6">

<input
    type="checkbox"
    value=""
    className="chk-box-reg"
    disabled={true}
    name="All"
    checked={this.checkStatus("All")}
    onChange={(e) => this.updateCheckbox(e)} />
    <label className="checkbox-inline c">Select All
                </label>
                </div> */}
                {/* <div className="col-md-6">

<input
    type="checkbox"
    value=""
    className="chk-box-reg"
    disabled={true}
    name="DeSelectAll"
                                    checked={this.checkStatus("DeSelectAll")}
    onChange={(e) => this.updateCheckbox(e)} />
    <label className="checkbox-inline c">De Select All
                </label>
                </div> */}
              </div>
            </form>
            <div className="validation-error">
              {" "}
              {this.state.errors.tradeRules}
            </div>
          </div>
        </div>
        {/* <div className="row mt-5 mb-5">
                    <div className="col-md-3">Trading Start Date<span className="mandtory-star">*</span></div>
                    <div className="col-md-3">


                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    // margin="none"
                                    format="MM/dd/yyyy"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    value={this.state.classDetails.tradeStartDate}
                                    onChange={(e) => this.changeDate(e, 'tradeStartDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.tradeStartDate, "tradeStartDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    // defaultValue="none"
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.tradeStartDate}</div>
                    </div>
                    <div className="col-md-3">Trading End Date<span className="mandtory-star">*</span></div>

                    <div className="col-md-3">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" className="form-control date-picker-field ">

                                <KeyboardDatePicker
                                    // margin="none"
                                    format="MM/dd/yyyy"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    maxDateMessage=''
                                    minDateMessage=''
                                    invalidDateMessage=''
                                    onBlur={async () => {
                                        let error = await Validate.validateDate(this.state.classDetails.tradeEndDate, "tradeEndDate")
                                        this.setState(Object.assign(this.state.errors, error))

                                    }}
                                    value={this.state.classDetails.tradeEndDate}
                                    onChange={(e) => this.changeDate(e, 'tradeEndDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    // defaultValue="yyyy/MM/dd"
                                    disablePast={this.props.location.state ? false : true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <div className="validation-error"> {this.state.errors.tradeEndDate}</div>
                    </div>
                </div> */}
        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.validatePage1()}
        >
          Next
        </button>
        <div className="step validation-error">
          {" "}
                    All fields marked with an asterisk are required.
                  </div>
      </div>
    );
  }
  renderPage2() {
    return (
      <div className="step">
        <div className="row">
          <div className="col-md-12">
            <ul className="stepper stepper-horizontal">
              <li className="completed">
                <a href="#!">
                  <span className="circle">1</span>
                  <span className="label">Class Information</span>
                </a>
              </li>

              <li className="active">
                <a href="#!">
                  <span className="circle">2</span>
                  <span className="label">Curriculum Configuration</span>
                </a>
              </li>
              <li>
                <a href="#!">
                  <span className="circle">3</span>
                  <span className="label">Game Configuration</span>
                </a>
              </li>
              {/* <li >
                                <a href="#!">
                                    <span className="circle">4</span>
                                    <span className="label">Subscription</span>
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>

        {/* <h5 className="form-heading">Test Performance</h5> */}
        <div className="row mt-5 mb-5">
          <div
            className="row mt-5 mb-5 info-head"
            style={{ paddingLeft: "15px" }}
          >
            <h3 className="col-md-6 ">Dates Configuration</h3>
          </div>
          <div className="col-md-3">
            Registration Start Date<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  // margin="none"
                  format="MM/dd/yyyy"
                  // updateData
                  InputProps={{
                    disableUnderline: true
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  value={this.state.classDetails.regStartDate}
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.classDetails.regStartDate,
                      "regStartDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  onChange={(e) => this.changeDate(e, "regStartDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  // defaultValue="none"
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.regStartDate}
            </div>
          </div>
          <div className="col-md-3">
            Registration End Date<span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  margin="none"
                  format="MM/dd/yyyy"
                  InputProps={{
                    disableUnderline: true
                  }}
                  defaultValue={new Date(Date.now() + (7 * 24 * 60 * 60 * 1000))}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  value={this.state.classDetails.regEndDate}
                  onChange={(e) => this.changeDate(e, "regEndDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  // defaultValue="none"
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.classDetails.regEndDate,
                      "regEndDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.regEndDate}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Trading Start Date<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  // margin="none"
                  format="MM/dd/yyyy"
                  InputProps={{
                    disableUnderline: true
                  }}
                  value={this.state.classDetails.tradeStartDate}
                  onChange={(e) => this.changeDate(e, "tradeStartDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.classDetails.tradeStartDate,
                      "tradeStartDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  // defaultValue="none"
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.tradeStartDate}
            </div>
          </div>

          <div className="col-md-3">
            Trading End Date<span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                className="form-control date-picker-field "
              >
                <KeyboardDatePicker
                  // margin="none"
                  format="MM/dd/yyyy"
                  InputProps={{
                    disableUnderline: true
                  }}
                  maxDateMessage=""
                  minDateMessage=""
                  invalidDateMessage=""
                  onBlur={async () => {
                    let error = await Validate.validateDate(
                      this.state.classDetails.tradeEndDate,
                      "tradeEndDate"
                    );
                    this.setState(Object.assign(this.state.errors, error));
                  }}
                  value={this.state.classDetails.tradeEndDate}
                  onChange={(e) => this.changeDate(e, "tradeEndDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  // defaultValue="yyyy/MM/dd"
                  disablePast={this.props.location.state ? false : true}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div className="validation-error">
              {" "}
              {this.state.errors.tradeEndDate}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div
            className="row mt-5 mb-5 info-head"
            style={{ paddingLeft: "15px" }}
          >
            <h3 className="col-md-6 ">Test Configuration</h3>
          </div>
          <div className="col-md-3">
            Passing Percentage <span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 2 }}>
              <input
                type="text"
                className="form-control"
                id="copyPreviousclass"
                aria-describedby="previousclass"
                // placeholder="Passing percentage"
                name="passingScore"
                onBlur={async () => {
                  let error = await Validate.validatePassingScore(
                    this.state.classDetails.passingScore,
                    "passingScore"
                  );
                  this.setState(Object.assign(this.state.errors, error.errors));
                }}
                value={
                  this.state.classDetails.passingScore
                    ? this.state.classDetails.passingScore
                    : ""
                }
                onChange={e => this.updateUserState(e, "number")}
              />
              <i style={{ paddingLeft: 10, paddingRight: 10, marginTop: 'auto', marginBottom: 'auto' }} class="fas fa-percentage"></i>
            </div>
            <div className="validation-error">
              {" "}
              {this.state.errors.passingScore}
            </div>
          </div>
          <div className="col-md-3">
            Number of Attempts
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="No. of attempts"
              name="noofattempts"
              // onBlur={async () => {
              //   let error = await Validate.validateNoofAttempt(
              //     this.state.classDetails.noofattempts,
              //     "noofattempts"
              //   );
              //   this.setState(Object.assign(this.state.errors, error.errors));
              // }}
              value={
                this.state.classDetails.noofattempts
                  ? this.state.classDetails.noofattempts
                  : ""
              }
              onChange={e => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.noofattempts}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Amount Awarded (per question){" "}
            <span className="mandtory-star">*</span>
          </div>

          <div className="col-md-3">
            <select
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="Amount"
              name="amountAwarded"
              value={
                this.state.classDetails.amountAwarded
                  ? this.state.classDetails.amountAwarded
                  : ""
              }
              onBlur={async () => {
                let error = await Validate.validateSelect(
                  this.state.classDetails.amountAwarded,
                  "amountAwarded",
                  "amount awarded"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              onChange={e => this.updateUserState(e)}
            >
              <option selected disabled value="">
                Select
              </option>
              {
                ["100", "200", "300", "400", "500"].map((op, i) => (<option key={i}>{op}</option>))
              }
            </select>
            <div className="validation-error">
              {" "}
              {this.state.errors.amountAwarded}
            </div>
          </div>
        </div>
        {/* <div className="row mt-10 mb-10">
                   
                </div> */}
        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.validatePage2()}
        >
          Next
        </button>
        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.setState({ Page: 1 })}
        >
          Back
        </button>
      </div>
    );
  }

  renderPage3() {
    return (
      <div className="step">
        <div className="row">
          <div className="col-md-12">
            <ul className="stepper stepper-horizontal">
              <li className="completed">
                <a href="#!">
                  <span className="circle">1</span>
                  <span className="label">Class Information</span>
                </a>
              </li>

              <li className="completed">
                <a href="#!">
                  <span className="circle">2</span>
                  <span className="label">Curriculum Configuration</span>
                </a>
              </li>
              <li className="active">
                <a href="#!">
                  <span className="circle">3</span>
                  <span className="label">Game Configuration</span>
                </a>
              </li>
              {/* <li >
                                <a href="#!">
                                    <span className="circle">4</span>
                                    <span className="label">Subscription</span>
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>

        {/* <h5 className="form-heading">Game Configuration</h5> */}
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            Rounds at each level<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="copyPreviousclass"
              aria-describedby="previousclass"
              placeholder="Number of Rounds"
              name="roundAteachLevel"
              onBlur={async () => {
                let error = await Validate.validateRound(
                  this.state.classDetails.roundAteachLevel,
                  "roundAteachLevel"
                );
                this.setState(Object.assign(this.state.errors, error.errors));
              }}
              value={
                this.state.classDetails.roundAteachLevel
                  ? this.state.classDetails.roundAteachLevel
                  : ""
              }
              onChange={e => this.updateUserState(e, "number")}
            />
            <div className="validation-error">
              {" "}
              {this.state.errors.roundAteachLevel}
            </div>
          </div>
          <div className="col-md-3">
            Minimum balance benchmark<span className="mandtory-star">*</span>
          </div>
          <div className="col-md-3">
            <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 2 }}>
              <input
                type="text"
                className="form-control"
                id="copyPreviousclass"
                aria-describedby="previousclass"
                placeholder="Minimum Benchmark"
                name="minProfit"
                value={
                  this.state.classDetails.minProfit
                    ? this.state.classDetails.minProfit
                    : ""
                }
                onBlur={async () => {
                  let error = await Validate.validateMinBenchmark(
                    this.state.classDetails.minProfit,
                    "minProfit"
                  );
                  this.setState(Object.assign(this.state.errors, error.errors));
                }}
                onChange={e => this.updateUserState(e, "number")}
              />
              <i style={{ paddingLeft: 10, paddingRight: 10, marginTop: 'auto', marginBottom: 'auto' }} className="fas fa-dollar-sign"></i>
            </div>
            <div className="validation-error">
              {" "}
              {this.state.errors.minProfit}
            </div>

          </div>
        </div>

        {/* <div className="row mt-5 mb-5">
          <div className="col-md-3">Extra Credit (Top 5 Performers)</div>
          <div className="col-md-3">
            <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 2 }}>
              <input
                type="text"
                className="form-control"
                id="copyPreviousclass"
                aria-describedby="previousclass"
                placeholder="Extra Credit"
                name="extraCredit"
                value={
                  this.state.classDetails.extraCredit
                    ? this.state.classDetails.extraCredit
                    : ""
                }
                onChange={e => this.updateUserState(e, "number")}
              />
              <i style={{ paddingLeft: 10, paddingRight: 10, marginTop: 'auto', marginBottom: 'auto' }} class="fas fa-percentage"></i>
            </div>
            <div className="validation-error">
              {" "}
              {this.state.errors.extraCredit}
            </div>
          </div>
        </div> */}

        {/* <button className="btn btn-primary pull-right next-btn" onClick={() => this.validatePage3()}>Next</button> */}

        {
          new Date() > new Date(this.state.classDetails.regEndDate) ?
            <button
              onClick={() => this.props.history.push(ROUTES.CLASSLIST)}
              className="btn btn-primary pull-right next-btn">Close</button> :
            <button
              className="btn btn-primary pull-right next-btn"
              disabled={this.state.isSubmit}
              onClick={() => {
                this.setState({ isSubmit: true });
                this.validatePage3();
              }}
            >
              {this.props.location.state && this.props.location.state.id
                ? "Update"
                : "Finish"}
            </button>
        }
        <button
          className="btn btn-primary pull-right next-btn"
          onClick={() => this.setState({ Page: 2 })}
        >
          Back
        </button>
      </div >
    );
  }
  //   renderPage4() {
  //     return (
  //       <div className="step">
  //         <div className="row">
  //           <div className="col-md-12">
  //             <ul className="stepper stepper-horizontal">
  //               <li className="completed">
  //                 <a href="#!">
  //                   <span className="circle">1</span>
  //                   <span className="label">Class Information</span>
  //                 </a>
  //               </li>

  //               <li className="completed">
  //                 <a href="#!">
  //                   <span className="circle">2</span>
  //                   <span className="label">Test Information</span>
  //                 </a>
  //               </li>
  //               <li className="completed">
  //                 <a href="#!">
  //                   <span className="circle">3</span>
  //                   <span className="label">Game Configuration</span>
  //                 </a>
  //               </li>
  //               <li className="active">
  //                 <a href="#!">
  //                   <span className="circle">4</span>
  //                   <span className="label">Subscription</span>
  //                 </a>
  //               </li>
  //             </ul>
  //           </div>
  //         </div>

  //         {/* <h5 className="form-heading">Subscription Information</h5> */}
  //         <div className="row mt-5 mb-5">
  //           <div className="col-md-12">
  //             Select a subscription level<span className="mandtory-star">*</span>
  //           </div>
  //           <br />
  //           <div className="col-md-12">
  //             <table className="plan-table">
  //               <tr className="custom-row">
  //                 <td style={{ fontWeight: "bold" }}></td>
  //                 <td style={{ fontWeight: "bold" }}>Duration</td>
  //                 <td style={{ fontWeight: "bold" }}>Number of Students</td>
  //                 <td style={{ fontWeight: "bold" }}>Amount</td>
  //               </tr>
  //               <SubscriptionItem
  //                 title="platinum"
  //                 name="Platinum"
  //                 duration="18 Weeks"
  //                 strength="Unlimited"
  //                 amount="$500"
  //                 isCheck={
  //                   this.state.classDetails.subscription == "platinum"
  //                     ? true
  //                     : false
  //                 }
  //                 checkhandler={this.updateRadioValue}
  //               />
  //               <SubscriptionItem
  //                 title="gold"
  //                 name="Gold"
  //                 duration="18 Weeks"
  //                 strength="30 Students"
  //                 amount="$250"
  //                 isCheck={
  //                   this.state.classDetails.subscription == "gold" ? true : false
  //                 }
  //                 checkhandler={this.updateRadioValue}
  //               />
  //               <SubscriptionItem
  //                 title="silver"
  //                 name="Silver"
  //                 duration="18 Weeks"
  //                 strength="Unlimited"
  //                 amount="$20 each student"
  //                 isCheck={
  //                   this.state.classDetails.subscription == "silver"
  //                     ? true
  //                     : false
  //                 }
  //                 checkhandler={this.updateRadioValue}
  //               />
  //               <SubscriptionItem
  //                 title="trial"
  //                 name="Trial"
  //                 duration="2 Weeks"
  //                 strength="30 Students"
  //                 amount="Free"
  //                 isCheck={
  //                   this.state.classDetails.subscription == "trial" ? true : false
  //                 }
  //                 checkhandler={this.updateRadioValue}
  //               />
  //             </table>
  //             <div className="validation-error">
  //               {" "}
  //               {this.state.errors.subscription}
  //             </div>
  //           </div>
  //         </div>
  //         <div className="row mt-5 mb-5">
  //           <div className="col-md-3">
  //             Payment Method<span className="mandtory-star">*</span>
  //           </div>
  //           <div className="col-md-2">
  //             <input
  //               type="radio"
  //               name="paymethod"
  //               title="professor"
  //               checked={
  //                 this.state.classDetails.paymethod == "professor" ? true : false
  //               }
  //               onChange={e => this.updateRadioValue(e)}
  //             />
  //             &nbsp;&nbsp;Professor
  //           </div>
  //           <div className="col-md-2">
  //             <input
  //               type="radio"
  //               name="paymethod"
  //               title="student"
  //               checked={
  //                 this.state.classDetails.paymethod == "student" ? true : false
  //               }
  //               onChange={e => this.updateRadioValue(e)}
  //             />
  //             &nbsp;&nbsp;Student
  //           </div>
  //         </div>
  //         <div className="validation-error"> {this.state.errors.paymethod}</div>
  //         <div className="row mt-5 mb-5">
  //           <div className="col-md-3">Invoice Amount</div>
  //           <div className="col-md-2"></div>
  //         </div>

  //         <button
  //           className="btn btn-primary pull-right next-btn"
  //           disabled={this.state.isSubmit}
  //           onClick={() => {
  //             this.setState({ isSubmit: true });
  //             this.validatePage4();
  //           }}
  //         >
  //           {this.props.location.state && this.props.location.state.id
  //             ? "Update"
  //             : "Finish"}
  //         </button>
  //         <button
  //           className="btn btn-primary pull-right next-btn"
  //           onClick={() => this.setState({ Page: 3 })}
  //         >
  //           Back
  //         </button>
  //       </div>
  //     );
  //   }
  render() {
    return (
      <div>
        <div id="about" className="section wb">
          <div className="jumbotron backgroundImage">
            <div
              className="container"
              style={{ backgroundColor: "#1a73e8", display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
              <div className="registration-title">Class Registration</div>
              <i style={{ fontSize: 16, marginLeft: 12, cursor: 'pointer', paddingTop: 4 }} onClick={() => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#CreateClass`, '_blank')} className="fas fa-info-circle help-icon"></i>
            </div>
          </div>
          <div className="container cmt20">
            <div className="row equal">
              {/* <div className="col-md-1"></div> */}
              {/* <div className="col-md-12 registration-title">Class Registration</div> */}
              <div className="col-md-4 reg-card btrr">
                <div
                  className="wizard"
                  id="myWizard"
                  style={{ paddingTop: "5vh" }}
                >
                  <div className="step" style={{ textAlign: "center" }}>
                    <img src={wizardSvg} width="320" height="auto" alt="" />
                    <div
                      className="row mt-15 mb-15 info highlight"
                      style={{ paddingTop: "12px" }}
                    >
                      <h2
                        className="col-md-12 "
                        style={{ textAlign: "center" }}
                      >
                        Class Registration
                      </h2>
                    </div>
                    <p
                      className="form-left-text"
                      style={{ paddingTop: "20px" }}
                    >
                      Create class using relevant configuration settings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 reg-card">
                {/* <div className="registration-title">Class Registration</div> */}
                <div className="wizard" id="myWizard">
                  {this.state.Page == 1
                    ? this.renderPage1()
                    : this.state.Page == 2
                      ? this.renderPage2()
                      : // this.state.Page == 3 ?
                      this.renderPage3()
                    // :
                    // this.renderPage4()
                  }
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
            </div>
          </div>
        </div>
        {/* {this.showPreviousClass()} */}
        {Toast.successToastView()}
        {Toast.errorToastView()}
        {this.state.showLoader ? <LoadingWheel /> : null}
        {/* render models */}
        {
          this.state.showClassCreatedModel ?
            <ClassCreated
              onClose={() => this.props.history.push(ROUTES.CLASSLIST)}
              data={this.state.classHolder}
              history={this.props.history}
              authStore={authStore}
            /> : null
        }
        {/* toggle student invite model */}
        {
          this.state.showStudentInviteModel ?
            <StudentInvite onClose={() => this.setState(st => ({ ...st, showStudentInviteModel: false }))} showInviteModelFromClassReg={true} /> : null
        }
      </div>
    );
  }
}
export default withRouter(ClassReg);
