import React, { Component, createRef } from "react";
import { Link, withRouter, RouteComponentProps, Route } from "react-router-dom";

import ROUTES from "../ROUTES";
import "jquery";
import AuthService from "../services/authService";
import LoadingWheel from "./LoadingWheel";
import commonService from "../services/commonService";
import PhoneCode from "./PhoneCode";
import ForgotPassword from "./ForgotPassword";
import EmailVerify from "./EmailVerification";
import Validate from "../Helpers/Utility/Validation";
import ContestStudentSingleInvite from "../pages/Invite/ContestStudentSingleInvite";
import MultipleInvite from "../pages/Invite/MultipleInvite";
import authStore from "../models/AuthStore";
import { InviteData, ContestDetails, ContestInviteData } from "../interfaces";
import ProfService from "../services/profService";
import TeacherService from "../services/teacherService";
import PlayerService from "../services/playerService";
import ftlogo from "../logo.png";

declare var $: any;
export interface ContestStudentInviteProps extends RouteComponentProps { }
export interface ContestStudentInviteState { }

class ContestStudentInvite extends Component<ContestStudentInviteProps, ContestStudentInviteState> {
  state = {
    tab: "single",

    email: "",
    inviteDetails: {} as ContestInviteData,
    errors: {} as any,

    phonenumber: "",
    password: "",
    error: "",
    showLoader: false,
    contestname: "",
    generateurl: true
  };
  msg = "";
  closeModal() {
    console.log("Closed");
    $("#contestStudentInviteModal").modal("hide");
    this.setState({
      email: ""
    });

    this.props.history.push({
      state: {
        generateurl: true,
        url: "",
        openedModal: false,
        contestname: this.props.location.state
          ? this.props.location.state.contestname
          : ""
      }
    });
  }
  _handleDocumentClick() {
    console.log("_handleDocumentClick");
    this.setState({ email: "" });

    this.props.history.push({
      state: {
        generateurl: true,
        url: "",
        openedModal: false,
        contestname: this.props.location.state
          ? this.props.location.state.contestname
          : ""
      }
    });
  }
  componentDidMount() {
    $("#contestStudentInviteModal").on("hidden.bs.modal", (e: any) =>
      this._handleDocumentClick()
    );
  }
  async changeHandler(e: any) {
    let stateObject = {} as any;
    stateObject[e.target.name] = e.target.value;
    if (e.target.name == "contestname") {
      // let data = await this.state.classes.filter(item => {
      //   if (item.classname && item.classname.trim() == e.target.value.trim()) {
      //     this.state.errors.classname = "";
      //     this.setState({ errors: this.state.errors });
      //     return item;
      //   }
      // });
    }
    if (e.target.name == "email") {
      // let data = await this.state.classes.filter(item => {
      if (e.target.value.trim()) {
        // this.state.errors.classname = "";
        this.setState({ email: e.target.value.trim() });
        // return item;
      }
    }
    stateObject["id"] = this.props.location.state
      ? this.props.location.state.id
      : null;
    stateObject["contestname"] = this.props.location.state
      ? this.props.location.state.contestname
      : null;
    console.log("stateObject[id]" + stateObject["id"]);

    this.setState(Object.assign(this.state.inviteDetails, stateObject));
    this.setState({ generateurl: true });
  }
  componentDidUpdate() { }

  handleLoader(value: boolean) {
    this.setState({ showLoader: value });
  }
  async sendMultipleInvitesForMultipleEmailsOfContest() {
    this.setState({ showLoader: true });
    if (authStore.user.role == "PROFESSOR") {
      let res = await ProfService.sendMultipleInvitesForMultipleEmailsOfContest(
        this.state.inviteDetails.email,
        this.state.inviteDetails.id
        // this.state.csv,
        // this.state.inviteDetails.id
      );
      await this.setState({ showLoader: false }, () => (this.msg = res.msg));
      await this.setState({ generateurl: true }, () => (this.msg = res.msg));
    } else if (authStore.user.role == "PLAYER") {
      let res = await PlayerService.sendMultipleInvitesForMultipleEmails(
        this.state.inviteDetails.email,
        this.state.inviteDetails.id
      );
      await this.setState({ showLoader: false }, () => (this.msg = res.msg));
      await this.setState({ generateurl: true }, () => (this.msg = res.msg));
    } else {
      let res = await TeacherService.sendMultipleInvitesForMultipleEmailsOfContest(
        this.state.inviteDetails.email,
        this.state.inviteDetails.id
      );
      await this.setState({ showLoader: false }, () => (this.msg = res.msg));
      await this.setState({ generateurl: true }, () => (this.msg = res.msg));
    }
  }

  async validateForm() {
    this.setState({ showLoader: true });
    let formvalid = true;
    const errors: { [index: string]: any } = {};
    let inviteError;

    // inviteError = await Validate.validateSelect(
    //   this.state.inviteDetails.classname,
    //   "classname",
    //   "a class"
    // );
    // await this.setState(Object.assign(this.state.errors, inviteError.errors));
    // if (inviteError.isValid == false) {
    //   formvalid = false;
    // }

    inviteError = await Validate.validateEmails(
      this.state.inviteDetails.email,
      "email",
      "addcontest"
    );
    console.log("inviteError", inviteError);
    await this.setState(Object.assign(this.state.errors, inviteError.errors));
    if (inviteError.isValid == false) {
      formvalid = false;
    }

    this.setState({ showLoader: false });
    this.setState({ email: this.state.inviteDetails.email });

    if (formvalid == true) {
      this.sendMultipleInvitesForMultipleEmailsOfContest();
      this.setState({ generateurl: false });
      // this.setState({ copied: false, email: "" });
      await this.props.history.push({
        state: {
          generateurl: false,
          url: ROUTES.HOSTED_CONTESTLIST,
          openedModal: true,
          studentEmail: this.state.inviteDetails.email,
          email: this.state.inviteDetails.email,
          contestname: this.state.inviteDetails.contestname
        }
      });
    }
  }

  render() {
    return (
      <div
        className="modal fade"
        id="contestStudentInviteModal"
        role="dialog"
        aria-labelledby="contestStudentInviteModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog student-invite-modal-dialog"
          role="document"
        >
          {this.state.contestname}
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#3f3d56", display:'flex', alignItems:'center' }}
            >
              <img
                        style={{marginTop:6, marginRight:0, opacity:0.8, marginBottom:4}}
                        src={ftlogo}
                        width="33px"
                        height="25px"
                        alt="image"
                      />
              <h2 className="pad0000" style={{ marginLeft:'auto', marginRight:'auto', paddingRight:17 }}>
                Invite Participant(s) For{" "}
                {this.props.location.state
                  ? this.props.location.state.contestname
                  : null}{" "}
              </h2>
              <button
                  style={{ color: "#fff" }}
                  type="button"
                  className="close "
                  aria-label="Close"
                  onClick={() => this.closeModal()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="section ">
              <div className="container">
                <div className="col-md-7 reg-card">
                  <div className=""></div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      {" "}
                      Participant Email(s)
                      <span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <textarea
                        id="studentEmails"
                        name="email"
                        className="form-control"
                        placeholder="Participant email(s)"
                        onChange={async e => {
                          this.changeHandler(e);
                          console.log("e.value", e.target.value);
                          // let inviteError = await Validate.validateEmails(
                          //   this.state.inviteDetails.email,
                          //   "email",
                          //   "addclass"
                          // );
                          // await this.setState(
                          //   Object.assign(this.state.errors, inviteError.errors)
                          // );
                        }}
                        // onBlur={async () => {
                        //   let inviteError = await Validate.validateEmails(
                        //     this.state.inviteDetails.email,
                        //     "email",
                        //     "addcontest"
                        //   );
                        //   await this.setState(
                        //     Object.assign(this.state.errors, inviteError.errors)
                        //   );
                        // }}
                        // onChange={e => this.setState({ email: e.target.value })}
                        value={this.state.email}
                      ></textarea>
                      <div className="popup-note">
                        Enter multiple emails separated by commas
                      </div>
                      <div className="validation-error">
                        {" "}
                        {this.state.errors.email}{" "}
                      </div>
                    </div>
                  </div>
                  {this.props.location.state ? (
                    !this.props.location.state.generateurl ? (
                      <p className="popup-note">
                        Your request has been queued. Invitation(s) will be sent
                        out shortly.
                      </p>
                    ) : null
                  ) : null}

                  {this.props.location.state ? (
                    this.props.location.state.generateurl ? (
                      <button
                        className="btn btn-primary pull-right next-btn"
                        onClick={() => {
                          this.validateForm();
                          // console.log("Test" + this.state.url);
                        }}
                      >
                        {/* Generate URL */}
                        Send Invite
                      </button>
                    ) : null
                  ) : null}
                </div>
                <div className="col-md-3"></div>
                {/* </div> */}
              </div>
            </div>
            {/* <ContestStudentSingleInvite contestID="" /> */}
            {/* <StudentSingleInvite classID = {this.props.location.state.id}/> */}
            {/* {!this.state.showResetPassword ? (
              !this.state.isEmailVerify ? (
                this.renderLoginForm()
              ) : (
                <EmailVerify
                  email={this.state.email}
                  isLoading={(value: boolean) => this.handleLoader(value)}
                />
              )
            ) : (
              <ForgotPassword
                isLoading={(value: boolean) => this.handleLoader(value)}
              />
            )} */}
          </div>
        </div>
        {this.state.showLoader ? <LoadingWheel/> : null}
      </div>
    );
  }
}

//   render() {
//     return (
//       <div id="" className="">
//         {this.state.showLoader ? (
//           <LoadingWheel />
//         ) : (
//           <div className="jumbotron backgroundImage">
//             <div
//               className="container registration-title"
//               style={{ backgroundColor: "#1a73e8" }}
//             >
//               {authStore.user.role == "PROFESSOR" ? "Invite" : ""}
//             </div>
//           </div>
//         )}
//         {this.state.showLoader ? (
//           <LoadingWheel />
//         ) : (
//           <div className="container cmt20">
//             <div className="row">
//               {/* <div className="registration-title">
//                 {authStore.user.role == "PROFESSOR"
//                   ? "Class Dashboard"
//                   : "Student Dashboard"}
//                 {"   "} ({this.props.location.state})
//               </div> */}
//             </div>
//             <div className="row">
//               <div className="reg-card reg-card-dashboard">
//                 {/* <div className="dashboard-title"></div> */}
//                 <div className="tabresult">
//                   <button
//                     className={
//                       this.state.tab == "single"
//                         ? "tablinksresult-active"
//                         : "tablinksresult"
//                     }
//                     onClick={() => this.setState({ tab: "single" })}
//                   >
//                     Individual Invitation
//                   </button>
//                   <button
//                     className={
//                       this.state.tab == "multiple"
//                         ? "tablinksresult-active"
//                         : "tablinksresult"
//                     }
//                     onClick={() => this.setState({ tab: "multiple" })}
//                   >
//                     Multiple Invitation
//                   </button>
//                 </div>
//                 {this.state.tab == "single" ? (
//                   <SingleInvite />
//                 ) : (
//                   <MultipleInvite />
//                 )}
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     );
//   }
// }
export default withRouter(ContestStudentInvite);
