import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { ClassDetails } from '../../interfaces'
import "./ComponentToPrint.css"
var QRCode = require('qrcode.react');
export interface PrintProps extends RouteComponentProps {
    classDetails: ClassDetails,
}

class ComponentToPrint extends React.Component<PrintProps, any>{
    convertDate(str: any) {
        var date = new Date(str),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [day, month, date.getFullYear()].join("-");
    }
    render() {
        return (
            <div className="print-content-container">
                <div className="row">
                    <span className="col-md-4 print-text print-class-name "><b>Class Name: </b> {this.props.classDetails.classname}</span> <br/> <br/>
                    <span className="col-md-4 print-text print-class-decription "><b>Class Description: </b>&nbsp;{this.props.classDetails.description}</span>
                </div><br/>
               
                <div className="row ">
                    <span className="col-md-4 print-text print-date"><b>Start Date: </b>&nbsp;{this.convertDate(this.props.classDetails.regStartDate)}</span>
                    <span className="col-md-4 print-text print-date "><b>End Date: </b>&nbsp;{this.convertDate(this.props.classDetails.regEndDate)}</span>
                </div>

                <QRCode size={320} className="qr-code" value={this.props.classDetails.link} />
                <br />
                <div className="invite-text">Scan QR Code to register for the class</div>
            </div>
        )
    }
}

export default withRouter(ComponentToPrint)
