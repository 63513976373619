import React from "react";
import "./quizeg.css";
import { RouteComponentProps, withRouter } from "react-router";
import { resultList, questionList } from "../../interfaces";
import qstnService from "../../services/questionService";
import LoadingWheel from "../../components/LoadingWheel";
import Toast from "../../components/Toast";
import ROUTES from "../../ROUTES";
import img from "../../img.jpg";
import classService from "../../services/classService";
import { datePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import Timer from "./Timer";
import "jquery";
import './Quiz.scss';
declare var $: any;

function Option(props: any) {
  if (props.selected == true) {
    return (
      <div style={{ border: '1px solid rgba(0,0,0,0.1)', padding: '5px 20px' }} onClick={() => { props.onClick(props.option); }}>
        <input checked type='radio' />
        <label style={{ fontSize: 14, opacity: 0.8, fontWeight: 500, marginLeft: 20 }}>{props.data}</label>
      </div>
    );
  } else {
    return (
      <div style={{ border: '1px solid rgba(0,0,0,0.1)', padding: '5px 20px' }} onClick={() => { props.onClick(props.option); }}>
        {/* <span className="option-number">{props.option + 1}</span> */}
        <input type='radio' />
        <label style={{ fontSize: 14, opacity: 0.8, fontWeight: 500, marginLeft: 20 }}>{props.data}</label>
      </div>
    );
  }
}

// function Option(props: any) {
//   if (props.selected == true) {
//     return (
//       <div
//         className="select-option"
//         onClick={() => {
//           props.onClick(props.option);
//         }}
//       >
//         <span className="select-number">{props.option + 1}</span>
//         <span className="select-text">{props.data}</span>
//       </div>
//     );
//   } else {
//     return (
//       <div
//         className="answer-wrap"
//         onClick={() => {
//           props.onClick(props.option);
//         }}
//       >
//         <span className="option-number">{props.option + 1}</span>
//         <span className="option-text">{props.data}</span>
//       </div>
//     );
//   }
// }

export interface QuizProps extends RouteComponentProps { }

export interface QuizState { }

const qstnList = [
  {
    id: "1",
    question: "Choose the odd one out",
    option: ["123", "456", "789", "124"],
    rightOption: 3,
  },
  {
    id: "2",
    question: "Choose the odd one out",
    option: ["abc", "def", "ghi", "qse"],
    rightOption: 3,
  },
];

class Quiz extends React.Component<any, QuizState> {
  state = {
    resultList: Array<resultList>(),
    qstnNo: 0,
    selectedOption: [] as any,
    qstnList: Array<questionList>(),
    showLoader: true,
    showUpdateLoader: false,
    startDate: new Date(),
    isAnswer: [] as any,
    maximize: false,
  };
  msg = "";
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);

  async componentDidMount() {

        // closing all curriculum tabs
        this.props.closeAllCurriculumTabs()

    let classdetails = await classService.getClassbyName(
      this.props.classname
    );
    let result = await qstnService.getQuestions(
      classdetails.data.id,
      this.props.topic,
      this.props.level
    );
    if (result.status == 200) {
      this.setState({ qstnList: result.result, showLoader: false });
      let resultList = [] as Array<resultList>;

      await result.result.forEach(function (item: any) {
        let obj = {
          qstnId: item.id,
          question: item.question,
          option: item.option,
          rightOption: item.rightOption,
          imageURL: item.imageURL,
        } as resultList;
        resultList.push(obj);
      });
      this.setState({ resultList });
    }
  }

  async changeText(e: any) {
    let that = this;
    await this.state.resultList.forEach(function (item: any) {
      if (item.qstnId == that.state.qstnList[that.state.qstnNo].id) {
        item.selectedOption = e.target.value;
        that.state.selectedOption[that.state.qstnNo] = e.target.value;
      }
    });
  }
  async selectHandler(option: number) {
    var that = this;
    await this.state.resultList.forEach(function (item: any) {
      if (item.qstnId == that.state.qstnList[that.state.qstnNo].id) {
        if (item.selectedOption == option) {
          delete item.selectedOption;
          delete that.state.selectedOption[that.state.qstnNo];
          that.state.isAnswer[that.state.qstnNo] = false;
          that.setState({ isAnswer: that.state.isAnswer });
        } else {
          item.selectedOption = option;
          that.state.selectedOption[that.state.qstnNo] = option;
          that.state.isAnswer[that.state.qstnNo] = true;
          that.setState({ isAnswer: that.state.isAnswer });
        }
      }
    });
    this.setState({
      resultList: this.state.resultList,
      selectedOption: this.state.selectedOption,
    });
  }

  nextQstn() {
    if (this.state.qstnNo + 1 < this.state.qstnList.length) {
      this.setState({ qstnNo: this.state.qstnNo + 1 });
    }
  }

  prevQstn() {
    if (this.state.qstnNo > 0) {
      this.setState({ qstnNo: this.state.qstnNo - 1 });
    }
  }

  async submitTest() {
    this.setState({ showUpdateLoader: true });
    let classdetails = await classService.getClassbyName(
      this.props.classname
    );
    let response = await qstnService.submitTest(
      classdetails.data.id,
      this.props.topic,
      this.props.level,
      this.state.resultList
    );
    this.setState({ showUpdateLoader: false });
    if (response.status == 200) {
      this.msg = "Success";

      let obj = {
        firstname: response.result.firstname,
        lastname: response.result.lastname,
        amount: response.result.amount,
        classname: response.result.classname,
        startTime: response.result.startTime,
        endTime: response.result.endTime,
        score: response.result.score,
        correct: response.result.correct,
        incorrect: response.result.incorrect,
        topic: this.props.topic,
        level: this.props.level,
        totalqstns: response.result.quiz.length,
        attended: response.result.correct + response.result.incorrect,
        status: response.result.status,
      };
      this.notifySuccess();
      setTimeout(() => {
        this.props.history.push({
          pathname: ROUTES.RESULT,
          state: { result: obj },
        });
        this.props.close();
      }, 2000);
    }
  }

  renderQuiz() {
    return (
      <div className="reg-card quiz-card">
        <div className="row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#1A73E8', color: 'white', padding: '5px 20px', fontSize: 15 }}>Question {this.state.qstnNo + 1} of {this.state.qstnList.length}</div>
          <div style={{ marginLeft: 'auto' }}><Timer startDate={this.state.startDate} /></div>
        </div>
        <div className='row' style={{ backgroundColor: 'rgba(0,0,0,0.1)', border: '1px solid rgba(0,0,0,0.1)', boxShadow: '0px 0px 3px rgba(0,0,0,0.3)', marginTop: 20, padding: '5px 20px', fontSize: 15 }}>
          {this.state.qstnList[this.state.qstnNo]
            ? this.state.qstnList[this.state.qstnNo].question
            : ""}
        </div>

        {this.state.qstnList[this.state.qstnNo] &&
          this.state.qstnList[this.state.qstnNo].imageURL ? (
            <div className="q-img-section mb-3 image-question row">
              <img src={this.state.qstnList[this.state.qstnNo].imageURL} />
            </div>
          ) : null}

        {this.state.qstnList[this.state.qstnNo] &&
          this.state.qstnList[this.state.qstnNo].option ? (
            <div className="row" style={{ display: 'flex', flexDirection: "column", marginTop: 20 }}>
              {[0, 1, 2, 3].map(item => this.renderOption(item))}
            </div>
          ) : (
            <div className="col-md-11 mt-10">
              <textarea
                className="col-md-5 answer-text"
                placeholder="Answer"
                value={this.state.resultList[this.state.qstnNo].selectedOption}
                onChange={(e) => this.changeText(e)}
              />
            </div>
          )}
        <br />

        {this.renderButton()}
      </div>
    );
  }
  // renderQuiz() {
  //   return (
  //     <div className="reg-card quiz-card">
  //       {/* <h2 className="quiz-title">Quiz</h2> */}
  //       <Timer startDate={this.state.startDate} />
  //       <br />
  //       <br />
  //       <div className="row">
  //         <div className="col-md-1">
  //           <span className="q-no">Question {this.state.qstnNo + 1} of {this.state.qstnList.length}</span>
  //         </div>
  //         <div className="col-md-11">
  //           <span className="question-wrap">
  //             <p>
  //               {this.state.qstnList[this.state.qstnNo]
  //                 ? this.state.qstnList[this.state.qstnNo].question
  //                 : ""}
  //             </p>
  //           </span>
  //         </div>
  //       </div>

  //       {this.state.qstnList[this.state.qstnNo] &&
  //         this.state.qstnList[this.state.qstnNo].imageURL ? (
  //           <div className="q-img-section mb-3 image-question">
  //             <img src={this.state.qstnList[this.state.qstnNo].imageURL} />
  //           </div>
  //         ) : null}

  //       {this.state.qstnList[this.state.qstnNo] &&
  //         this.state.qstnList[this.state.qstnNo].option ? (
  //           <div>
  //             <div className="row mt-10 option-row" id="quiz-options">
  //               {this.renderOption(0)}
  //               {this.renderOption(1)}
  //             </div>

  //             <div className="row mt-10 option-row" id="quiz-options">
  //               {this.renderOption(2)}
  //               {this.renderOption(3)}
  //             </div>
  //           </div>
  //         ) : (
  //           <div className="col-md-11 mt-10">
  //             <textarea
  //               className="col-md-5 answer-text"
  //               placeholder="Answer"
  //               value={this.state.resultList[this.state.qstnNo].selectedOption}
  //               onChange={(e) => this.changeText(e)}
  //             />
  //           </div>
  //         )}
  //       <br />

  //       {this.renderButton()}
  //     </div>
  //   );
  // }


  renderOption(index: any) {
    if (
      this.state.qstnList[this.state.qstnNo] &&
      this.state.qstnList[this.state.qstnNo].option.length > index
    ) {
      return (
        <Option
          option={index}
          data={
            this.state.qstnList[this.state.qstnNo]
              ? this.state.qstnList[this.state.qstnNo].option[index]
              : ""
          }
          onClick={(option: any) => this.selectHandler(option)}
          selected={this.state.selectedOption[this.state.qstnNo] == index}
        />
      );
    }
  }

  // renderOption(index: any) {
  //   if (
  //     this.state.qstnList[this.state.qstnNo] &&
  //     this.state.qstnList[this.state.qstnNo].option.length > index
  //   ) {
  //     return (
  //       <Option
  //         option={index}
  //         data={
  //           this.state.qstnList[this.state.qstnNo]
  //             ? this.state.qstnList[this.state.qstnNo].option[index]
  //             : ""
  //         }
  //         onClick={(option: any) => this.selectHandler(option)}
  //         selected={this.state.selectedOption[this.state.qstnNo] == index}
  //       />
  //     );
  //   }
  // }

  renderButton() {
    return (
      <div className="btn-wrapper">
        <ul className="pagination flex-wrap pagination-wrapp">
          <li>
            <i
              style={this.state.qstnNo > 0 ? { fontSize: 20 } : { opacity: 0.7, fontSize: 20 }}
              className={
                !(this.state.qstnNo > 0)
                  ? "page-item prev-btn btn btn-pagination d-md-block btn-disable next-btn far fa-arrow-alt-circle-left"
                  : "page-item active prev-btn btn btn-pagination d-md-block next-btn far fa-arrow-alt-circle-left"
              }
              // disabled={!(this.state.qstnNo > 0)}
              onClick={(e) => {
                this.prevQstn();
              }} />
          </li>
          <li>
            <i
              style={this.state.isAnswer[this.state.qstnNo] ? { fontSize: 20 } : { opacity: 0.7, fontSize: 20 }}
              className={
                this.state.qstnNo + 1 < this.state.qstnList.length ?
                  this.state.isAnswer[this.state.qstnNo]
                    ? "page-item active btn btn-pagination d-md-block next-btn far fa-arrow-alt-circle-right"
                    : "page-item active btn btn-pagination d-md-block btn-disable next-btn far fa-arrow-alt-circle-right" : "page-item active btn btn-pagination d-md-block next-btn far"
              }
              // disabled={!this.state.isAnswer[this.state.qstnNo]}
              onClick={(e) => {
                if (this.state.isAnswer[this.state.qstnNo]) {
                  this.state.qstnNo + 1 < this.state.qstnList.length
                    ? this.nextQstn()
                    : this.submitTest();
                }
              }}
            >
              {/* {" "} */}
              {this.state.qstnNo + 1 < this.state.qstnList.length
                ? ""
                : "Submit"}
              {/* <i
                className="mdi mdi-chevron-right"
                data-name="mdi-chevron-right"
              ></i> */}
            </i>
          </li>
        </ul>
      </div>
    );
  }

  // renderButton() {
  //   return (
  //     <div className="btn-wrapper">
  //       <ul className="pagination flex-wrap pagination-wrapp">
  //         <li>
  //           <button
  //             className={
  //               !(this.state.qstnNo > 0)
  //                 ? "page-item prev-btn btn btn-pagination d-md-block btn-disable next-btn"
  //                 : "page-item active prev-btn btn btn-pagination d-md-block next-btn"
  //             }
  //             disabled={!(this.state.qstnNo > 0)}
  //             onClick={(e) => {
  //               this.prevQstn();
  //             }}
  //           >
  //             Previous
  //             <i
  //               className="mdi mdi-chevron-left chev-left"
  //               data-name="mdi-chevron-left"
  //             ></i>
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className={
  //               this.state.isAnswer[this.state.qstnNo]
  //                 ? "page-item active btn btn-pagination d-md-block next-btn"
  //                 : "page-item active btn btn-pagination d-md-block btn-disable next-btn"
  //             }
  //             disabled={!this.state.isAnswer[this.state.qstnNo]}
  //             onClick={(e) => {
  //               this.state.qstnNo + 1 < this.state.qstnList.length
  //                 ? this.nextQstn()
  //                 : this.submitTest();
  //             }}
  //           >
  //             {" "}
  //             {this.state.qstnNo + 1 < this.state.qstnList.length
  //               ? "Next"
  //               : "Submit"}
  //             <i
  //               className="mdi mdi-chevron-right"
  //               data-name="mdi-chevron-right"
  //             ></i>
  //           </button>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // }

  renderHeader() {
    return <div className="quiz_header">
      <label>Quiz - {this.props.classname}</label>
      <i onClick={this.props.minimize} className="fas fa-window-minimize minus"></i>
      {
        this.state.maximize ?
          <i onClick={() => this.setState((st: any) => ({ ...st, maximize: !st.maximize }))} className="fas fa-compress-arrows-alt maximize"></i> :
          <i onClick={() => this.setState((st: any) => ({ ...st, maximize: !st.maximize }))} className="fas fa-window-maximize maximize"></i>
      }
      <i onClick={this.props.close} className="fas fa-times"></i>
    </div >
  }

  render() {
    return (
      <div style={this.props.style}>
        <div className="quiz_view" style={this.state.maximize ? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
          {this.renderHeader()}
          {!this.state.showLoader ? this.renderQuiz() : <LoadingWheel />}

          {this.state.showUpdateLoader ? <LoadingWheel /> : null}

          {Toast.successToastView()}
          {Toast.errorToastView()}
        </div>
      </div>
    );
  }
}

export default withRouter(Quiz);
