import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ContestDetails } from "../../interfaces";
import ROUTES from "../../ROUTES";
import studentService from "../../services/studentService";
import contestService from "../../services/contestService";
import "./ContestList.css";
import Toast from "../../components/Toast";
import "jquery";
import DeleteConfirm from "../Contest/DeleteConfirm";
import authStore from "../../models/AuthStore";
import LoadingWheel from "../../components/LoadingWheel";
import CreateContest from "./components/CreateContest";
import * as queryString from "query-string";
import config from '../../config';
import {firestore} from '../../config/firebase';

// Models
import ViewLeaders from '../../models/viewLeaders';
import Alert from '../../models/cust_alert';

import ReactTooltip from "react-tooltip";

declare var $: any;
export interface contestProps extends RouteComponentProps { }

export interface contestState { }

class ProfessorInvitedContestList extends Component<any, contestState> {
  notifySuccess = () => Toast.success();
  notifyError = () => Toast.error();
  yearList = [] as any;
  state = {
    contests: Array<ContestDetails>(),
    invitedContests: Array<ContestDetails>(),
    id: "",
    showLoader: true,
    selectedYear: new Date().getFullYear(),
    studWiseresult: false,

    currentUser: null,
    collapsed: true,
    contestname: "",

    //toggle viewLeaderModel
    toggleViewLeaderModel: false,
    viewLeaderModelData: {},
    leaderBoardType: "CONTEST",

    //for filter button
    toggleFilter: false,
    sortBy:'START_DSC',
    tempcontests: Array<ContestDetails>(),

    // alert
    toggleAlert: false,
    warningText: "",

    toggleStratButton: true,

    filtersSwitches: "ONGOING"
  };

  addnewContest() {
    this.props.history.push(ROUTES.CONTESTREG);
  }

  getContests() {
    contestService
      .getAllContestsForProfessor()
      .then(res => {
        const sortedContests = res.data.filter((x:any) => !x.contestname.includes("/@#FT#@/"))
        this.setState({ contests: res.data, tempcontests: res.data, showLoader: false });
      })
      .catch(error => { });
  }

  getInvitedContestsForProfessor() {
    contestService
      .getAllInvitedContestsForProfessor()
      .then(res => {
        const sortedContests = res.data.filter((x:any) => !x.contestname.includes("/@#FT#@/"))
        this.setState({ contests: res.data, tempcontests: res.data, showLoader: false });
        this.getCardStatusForContest(res.data);
      })
      .catch(error => { });
  }

  getUsercontests() {
    contestService
      .getAllContestsForStudent()
      .then(res => {
        const sortedContests = res.data.filter((x:any) => !x.contestname.includes("/@#FT#@/"))
        this.setState({ contests: res.data, tempcontests: res.data, showLoader: false });
        this.getCardStatusForContest(res.data);
      })
      .catch(error => {
        this.setState({ showLoader: false });
        console.log(error);
      });
  }

  getUsercontestsForPlayer() {
    contestService
      .getAllContestsForPlayer()
      .then(res => {
        const sortedContests = res.data.filter((x:any) => !x.contestname.includes("/@#FT#@/"))
        this.setState({ contests: res.data, tempcontests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUserInvitedcontestsForPlayer() {
    contestService
      .getAllInvitedContestsForPlayer()
      .then(res => {
        const sortedContests = res.data.filter((x:any) => !x.contestname.includes("/@#FT#@/"))
        this.setState({ contests: sortedContests, tempcontests: sortedContests, showLoader: false });
        this.getCardStatusForContest(sortedContests);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUserInvitedcontestsForTeacher() {
    contestService
      .getAllInvitedContestsForTeacher()
      .then(res => {
        const sortedContests = res.data.filter((x:any) => !x.contestname.includes("/@#FT#@/"))
        this.setState({ contests: sortedContests, tempcontests: sortedContests, showLoader: false });
        this.getCardStatusForContest(sortedContests);
      })
      .catch(error => {
        console.log(error);
      });
  }

  filterClicked = (type: any) => {
    this.setState(st => ({ ...st, sortBy: type }));
    switch (type) {
      case "START_DSC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests] }))
        break;
      case "START_ASC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests].reverse() }))
        break;
      case "NAME_DSC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests].sort((a: any, b: any) => a.contestname.toString().toLowerCase() < b.contestname.toString().toLowerCase() ? 1 : -1) }))
        break;
      case "NAME_ASC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests].sort((a: any, b: any) => a.contestname.toString().toLowerCase() > b.contestname.toString().toLowerCase() ? 1 : -1) }))
        break;
    }
  }

  async componentDidMount() {
        // timmer to toggle start button
        setInterval(() => {
          this.setState((st:any) => ({...st, toggleStratButton: !st.toggleStratButton}));
        },500);

    let params = await queryString.parse(this.props.location.search);

    this.setState({ showLoader: true });

    if (authStore.user.role == "PROFESSOR") {
      if (this.props.location.pathname != "/invited-contestlist") {
        this.getContests();
      } else {
        this.getInvitedContestsForProfessor();
      }
    } else if (authStore.user.role == "TEACHER") {
      if (this.props.location.pathname == "/invited-contestlist") {
        this.getUserInvitedcontestsForTeacher();
      }
    } else if (authStore.user.role == "STUDENT") {
      this.getUsercontests();
    } else {
      if (this.props.location.pathname != "/invited-contestlist") {
        this.getUsercontestsForPlayer();
      } else {
        this.getUserInvitedcontestsForPlayer();
      }
      // this.getUsercontestsForPlayer();
      // this.getUserInvitedcontestsForPlayer();
    }
    for (let i = 0; i < 4; i++) {
      this.yearList.push(this.state.selectedYear + i);
    }
  }
  // async  deleteClass(id: any) {
  //     if (authStore.user.role == 'PROFESSOR') {
  //         this.setState({ id: id }, () => {
  //             $("#classDeleteForm").modal("show");
  //         })
  //     } else {

  //     }
  // }

  editContest({ id, tradeEndDate }: any) {
    if (new Date() > new Date(tradeEndDate)) {
      this.setState(st => ({ ...st, toggleAlert: true, warningText: "Contest is currently closed" }))
    } else {
      this.props.history.push({
        pathname: ROUTES.CONTESTREG,
        state: { id: id }
      });
    }
  }

  convertDate(str: any) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, month, date.getFullYear()].join("-");
  }

  showTopic(contestname: any) {
    this.props.history.push({ pathname: ROUTES.TOPIC, state: contestname });
  }

  showContestTopic({ contestname, tradeEndDate }: any) {
    if (new Date() > new Date(tradeEndDate)) {
      this.setState(st => ({ ...st, toggleAlert: true, warningText: "Participation ends. Can't open this contest" }))
    } else {
      this.props.history.push({
        pathname: ROUTES.CONTEST_TOPIC,
        state: contestname
      });
    }
  }

  async setYearFilter(e: any) {
    await this.setState({ selectedYear: e.target.value });
  }
  showModal() {
    $("#contestStudentInviteModal").modal("show");
  }
  divStyle = {
    "margin-top": "10px"
  };

  searchCards = (e: any) => {
    const text: string = e.target.value.toLowerCase();
    if (text.length > 0) {
      const searchList = this.state.tempcontests.filter(x => x.contestname !== undefined ? x.contestname.toLowerCase().includes(text) : false);
      this.setState(st => ({ ...st, contests: searchList }))
    } else {
      this.setState(st => ({ ...st, contests: this.state.tempcontests }))
    }
  }

  prettyDate = (str: any) => {
    let date = new Date(str);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear();
  }

  // getCardIconsStatus = (tradeEndDate:any) => new Date() > new Date(tradeEndDate);

  getCardStatusForContest = async (contests: any) => {
    let gameRefs:any = [];
    contests.forEach((contest:any) => {
      if(contest.tradeRules.length === 1){
        gameRefs.push(firestore.collection("game").where("uid","==",authStore.user.user_id).where('contestID','==',contest.id).where('gameType','==',contest.tradeRules[0]).where('level','==','Advanced Market Maker').where('status','==',0).get())
      }else{
        gameRefs.push(firestore.collection("game").where("uid","==",authStore.user.user_id).where('contestID','==',contest.id).where('gameType','==',"Bonds").where('level','==','Advanced Market Maker').where('status','==',0).get())
      }
    });
    
    const promises = await Promise.all(gameRefs);
    let completedContests:any = [];
    promises.forEach((promise:any) =>{
      promise.forEach((game:any) => {
          completedContests.push(game.data().contestID);
      })
    })

    let newContests = contests.map((clss:any) => {
      let today = new Date().getTime();
      let classEndDate = new Date(clss.tradeEndDate).getTime();

      if(completedContests.includes(clss.id) || today > classEndDate){
        return {...clss,"participantStatus":"Completed"}
      }else{
        return {...clss,"participantStatus":"In Progress"}
      }
    })

    this.setState(st => ({...st, contests: newContests, tempcontests:newContests}));
  }

  render() {
    return (
      <div className="section wb">
        <div className="backgroundImage">
          <div className="container" style={{height:60, color:'white', display:'flex', flexDirection:'row', alignItems:'center'}}>
            <h3 style={{color:'white', paddingTop: 6, fontSize:20, fontWeight: 500}}>Participating Contests</h3>

            {/* serach view */}
            <div className="card_search" style={{marginLeft:'auto', marginRight:20}}>
                <i className="fas fa-search"></i>
                <input onChange={this.searchCards} type="text" placeholder="Search by contest name..." />
              </div>

            {/* Drop down to apply filter */}
            <div className="filter_dropdown_1" style={{marginRight:20}}>
                <label onClick={() => this.setState(st => ({...st, filtersSwitches:"ALL"}))} className={this.state.filtersSwitches === "ALL" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>ALL</label>
                <label onClick={() => this.setState(st => ({...st, filtersSwitches:"ONGOING"}))} className={this.state.filtersSwitches === "ONGOING" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>ONGOING</label>
                <label onClick={() => this.setState(st => ({...st, filtersSwitches:"COMPLETED"}))} className={this.state.filtersSwitches === "COMPLETED" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>COMPLETED</label>
              </div>

            {/* Drop down to apply filter */}
            <div className="filter_dropdown" >
                <div>
                  <label style={{color:'white'}}>Sort by </label><i onClick={() => this.setState(st => ({ ...st, toggleFilter: !this.state.toggleFilter }))} className={"fas fa-chevron-down"}></i>
                  <div>
                    <ul>
                      <li style={this.state.sortBy === "NAME_ASC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("NAME_ASC")}>Name(ASC to DSC)</li>
                      <li style={this.state.sortBy === "NAME_DSC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("NAME_DSC")}>Name(DSC to ASC)</li>
                      <li style={this.state.sortBy === "START_ASC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("START_ASC")}>Start date(ASC to DSC)</li>
                      <li style={this.state.sortBy === "START_DSC" ? { opacity: 1 } : {}} onClick={() => this.filterClicked("START_DSC")}>Start date(DSC to ASC)</li>
                    </ul>
                  </div>
                </div>
              </div>

          </div>
        </div>
        <div className="container cmt20">
          {/* {this.state.classes && this.state.classes.length > 0 ? (
            <div className="form-group year-filter">
              <select
                className="form-control"
                onChange={e => this.setYearFilter(e)}
              >
                {this.yearList.map((item: any) => {
                  return item == this.state.selectedYear ? (
                    <option selected>{item}</option>
                  ) : (
                    <option>{item}</option>
                  );
                })}
              </select>
            </div>
          ) : null} */}
          <div className="row">
            <div className="col-md-12 reg-card reg-card-transparent-bg reg-card-r-br-0">
              {/* {authStore.user.role == "PROFESSOR" ||
              authStore.user.role == "PLAYER" ? (
                <div className=" row">
                  <div className="col-md-3 mt-20 ">
                    <a href="#" onClick={() => this.addnewContest()}>
                      <div
                        style={{ minHeight: "128px" }}
                        className="card text-center "
                      >
                        <div className="card-body add-new-class" id="card">
                          <span className="add-class-button">
                            <i className="fas fa-plus  fa-2x"></i>
                          </span>
                          <span className="add-class">Create Contest</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ) : null} */}


              <div className="row" style={{ marginTop: 115 }}></div>
              {this.props.location.pathname == "/invited-contestlist" ? (
                <div className="class-container row ">
                  <div className="row" style={{ marginTop: "10px" }}></div>

                  <div className="class-container row" style={{marginRight:1}}>
                    {this.state &&
                      this.state.contests &&
                      this.state.contests.map((item:any,i:any) => {
                        if((((this.state.filtersSwitches === "COMPLETED" && item.participantStatus === "Completed")||
                        (this.state.filtersSwitches === "ONGOING" && item.participantStatus === "In Progress")||
                        (this.state.filtersSwitches === "ALL")))){
                          if (
                            item.regStartDate
                              ? new Date(item.regStartDate).getFullYear() ==
                              this.state.selectedYear
                              : false || item.regEndDate
                                ? new Date(item.regEndDate).getFullYear() ==
                                this.state.selectedYear
                                : false
                          ) {
                            return (
                              <div className="col-md-3" style={{marginBottom:30}}>
                                <div className="card student-card-btn text-center card-body-btn">
                                <h4 className="card-title" style={{ position: 'relative', marginTop: 10, height: 20 }}>
                                    <p data-tip data-for={"class_desc_tip"+i} style={{ fontSize: 16, flex: 1, display: 'inline', overflow:'none', cursor:'pointer' }}>{item.contestname}</p>
                                    
                                    {/* <i style={{ paddingLeft: 10, fontSize: 17.5, color: 'rgba(0,0,0,0.9)', cursor: 'pointer', position: 'absolute', top: 0, right: 20 }} className="fas fa-edit"></i> */}
                                    {/* {authStore.user.role == "STUDENT" ? <i data-tip data-for="class_desc_tip" className="fas fa-info-circle class_list_item_info_icon" ></i> : null} */}
                                  </h4>
                                  <div>
                                    <ReactTooltip multiline={true} id={"class_desc_tip"+i} backgroundColor="black" place="bottom" effect="solid"><div style={{ maxWidth: 340 }}>{item.description}</div></ReactTooltip>
                                    {
                                      item.participantStatus == "Completed" ?
                                    <ReactTooltip multiline={true} id={"start"+i} backgroundColor="black" place="right" effect="solid"><div style={{ maxWidth: 340 }}>Participation ends</div></ReactTooltip>:
                                    <ReactTooltip multiline={true} id={"start"+i} backgroundColor="black" place="right" effect="solid"><div style={{ maxWidth: 340 }}>Click to start</div></ReactTooltip>
                                    }
                                  
                                    <label style={{ fontSize: 13, opacity: 0.6, marginTop:10 }}>Status: {item.participantStatus}</label>
                                    {
                                     item.participantStatus === "Completed" ? 
                                     <img data-tip data-for={"start"+i} style={{width:45, height:45, cursor: 'pointer',display:'block', marginLeft:'45%', marginTop:4, marginBottom:8, opacity:0.4}} src={require('../../images/ft_start_orange.png')}/>:
                                     this.state.toggleStratButton ?
                                     <img data-tip data-for={"start"+i} onClick={() => this.showContestTopic(item)} style={{width:45, height:45, cursor: 'pointer',display:'block', marginLeft:'45%', marginTop:4, marginBottom:8}} src={require('../../images/ft_start_blue.png')}/> :
                                     <img data-tip data-for={"start"+i} onClick={() => this.showContestTopic(item)} style={{width:45, height:45, cursor: 'pointer',display:'block', marginLeft:'45%', marginTop:4, marginBottom:8}} src={require('../../images/ft_start_orange.png')}/>
                                    }
  
                                    <div className="dates">
                                      <strong>{"PARTICIPATION DATES"}</strong>
  
                                      <div className="start">
                                        <strong>STARTS</strong>
                                        {this.prettyDate(item.tradeStartDate)}
                                      </div>
                                      <div className="ends">
                                        <strong>ENDS</strong>
                                        {this.prettyDate(item.tradeEndDate)}
                                      </div>
                                    </div>
  
                                    {/* {authStore.user.role == "PROFESSOR" ? (
                                      <div className="dates">
                                        <strong>TOTAL CAPACITY</strong>
                                        {item.noofstudents}
                                      </div>
                                    ) : null}
                                    {authStore.user.role == "PLAYER" ? ( //PLAYER AND CONTEST HOST
                                      <div className="dates">
                                        <strong>TOTAL CAPACITY</strong>
                                        {item.noofstudents}
                                      </div>
                                    ) : null} */}
                                  </div>
  
                                  {/* Leader Board Button */}
                                  {/* <div style={{ backgroundColor: '#2867E4', paddingTop: 6, paddingBottom: 6, marginBottom: 2, color: 'white', height: 40, cursor: 'pointer' }} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState(st => ({ ...st, toggleViewLeaderModel: true, viewLeaderModelData: { "className": item.contestname } }));
                                  }}>
                                    <div style={{ fontSize: 15, cursor: 'pointer' }} className="col a-right">
                                      <i className="fas fa-crown" style={{ marginRight: 6 }}></i>
                                  Contest Leaderboard
                                </div>
                                  </div> */}
                                  {/* Leader Board Button ends */}
  
                                  <div
                                    style={{ height: 40,opacity:item !== undefined && item.participants.length > 2 ? 1:0.5 }}
                                    className="card-footer text-muted"
                                  >
                                    <div style={{ display:'flex',fontSize: 15, cursor: 'pointer', paddingRight:10 }} className="col a-right">
                                      <i style={{marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8}} className="fas fa-compass fa-1x"></i>
                                      <label onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // this.props.history.push({
                                      //   pathname: ROUTES.CONTEST_DASHBOARD,
                                      //   state: item.contestname
                                      // });
                                      if(item !== undefined && item.participants.length > 2){
                                        this.props.toggleDashboard(item.contestname)
                                      }else{
                                        this.setState(st => ({ ...st, toggleAlert: true, warningText: "No data found" }));
                                      }
                                    }}>&nbsp;{" "}
                                      {authStore.user.role == "PROFESSOR" ||
                                        authStore.user.role == "TEACHER"
                                        ? "Performance Dashboard"
                                        : ""}
                                      {authStore.user.role == "STUDENT"
                                        ? "Performance Dashboard"
                                        : ""}
                                      {authStore.user.role == "PLAYER" //player and contest host
                                        ? "Performance Dashboard"
                                        : ""}</label>
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#MonitorGamePerformanceContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
  
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return <p>No Contests</p>;
                          }
                        }
                      })}
                  </div>
                </div>
              ) : (
                  //END OF INVITED CONTEST LIST BLOCK
                  //IF HOSTED
                  <div className="class-container row">
                    {this.state &&
                      this.state.contests &&
                      this.state.contests.map(item => {
                        if (
                          item.regStartDate
                            ? new Date(item.regStartDate).getFullYear() ==
                            this.state.selectedYear
                            : false || item.regEndDate
                              ? new Date(item.regEndDate).getFullYear() ==
                              this.state.selectedYear
                              : false
                        ) {
                          return (
                            <div className="col-md-3 mt-20">
                              {authStore.user.role == "PROFESSOR" ? (
                                <div
                                  className="card-footer card-invite-btn text-muted text-center"
                                  // onClick={e => {
                                  //   e.preventDefault();
                                  //   e.stopPropagation();
                                  //   this.props.history.push({
                                  //     pathname: ROUTES.DASHBOARD,
                                  //     state: item.classname
                                  //   });
                                  // }}
                                  onClick={() => {
                                    this.setState({
                                      collapsed: !this.state.collapsed
                                      // state: {
                                      // classname: item.classname,
                                      // id: item.id
                                      // }
                                    });
                                    this.props.history.push({
                                      pathname: "",
                                      state: {
                                        id: item.id,
                                        contestname: item.contestname,
                                        generateurl: true,
                                        openedModal: true,
                                        url: ""
                                      }
                                    });
                                    this.showModal();
                                  }}
                                // onClick={e => {
                                //   e.preventDefault();
                                //   e.stopPropagation();

                                // }}
                                >
                                  <div className="col a-right">
                                    <i className="fas fa-user-plus fa-1x"></i>
                                  &nbsp; {"Invite Student"}
                                  </div>
                                </div>
                              ) : (
                                  ""
                                )}
                              {authStore.user.role == "PLAYER" ? (
                                <div
                                  className="card-footer card-invite-btn text-muted text-center"
                                  // onClick={e => {
                                  //   e.preventDefault();
                                  //   e.stopPropagation();
                                  //   this.props.history.push({
                                  //     pathname: ROUTES.DASHBOARD,
                                  //     state: item.classname
                                  //   });
                                  // }}
                                  onClick={() => {
                                    this.setState({
                                      collapsed: !this.state.collapsed
                                      // state: {
                                      // classname: item.classname,
                                      // id: item.id
                                      // }
                                    });
                                    this.props.history.push({
                                      pathname: "",
                                      state: {
                                        id: item.id,
                                        contestname: item.contestname,
                                        generateurl: true,
                                        openedModal: true,
                                        url: ""
                                      }
                                    });
                                    this.showModal();
                                  }}
                                // onClick={e => {
                                //   e.preventDefault();
                                //   e.stopPropagation();

                                // }}
                                >
                                  <div className="col a-right">
                                    <i className="fas fa-user-plus fa-1x"></i>
                                  &nbsp; {"Invite Participant(s)"}
                                  </div>
                                </div>
                              ) : (
                                  ""
                                )}
                              <div
                                className={
                                  authStore.user.role == "PROFESSOR"
                                    ? "card text-center card-body-btn professor-card-btn"
                                    : authStore.user.role == "STUDENT"
                                      ? "card text-center card-body-btn student-card-btn"
                                      : authStore.user.role == "PLAYER"
                                        ? "card text-center card-body-btn "
                                        : ""
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  authStore.user.role == "PROFESSOR"
                                    ? this.editContest(item)
                                    : authStore.user.role == "STUDENT"
                                      ? this.showContestTopic(item)
                                      : authStore.user.role == "PLAYER" // PLAYER AND HOST
                                        ? this.editContest(item)
                                        : this.showContestTopic(item)
                                }
                              >
                                <div className="card-body" id="card">
                                  <h4 className="card-title" style={{ height: 35 }}>
                                    {item.contestname}<i style={{ paddingLeft: 20, fontSize: 15, color: 'rgba(0,0,0,0.9)' }} className="fas fa-edit"></i>
                                  </h4>
                                  <label style={{ fontSize: 13, opacity: 0.6, marginTop:3 }}>NA</label>
                                  <div className="dates">
                                    <strong>{"PARTICIPATION DATES"}</strong>

                                    <div className="start">
                                      <strong>STARTS</strong>
                                      {this.prettyDate(item.tradeStartDate)}
                                    </div>
                                    <div className="ends">
                                      <strong>ENDS</strong>
                                      {this.prettyDate(item.tradeEndDate)}
                                    </div>
                                  </div>
                                  {/* <div className="dates">
                              <strong >{"EXAM DATES"}</strong>

                                <div className="start">
                                  <strong>STARTS</strong>
                                  {this.prettyDate(item.tradeStartDate)}
                                  <span></span>
                                </div>
                                <div className="ends">
                                  <strong>ENDS</strong>
                                  {this.prettyDate(item.tradeEndDate)}
                                </div>
                              </div> */}
                                  {/* {authStore.user.role == "PROFESSOR" ? (
                                <div className="dates">
                                  <strong>{"PLAYERS"}</strong>
                                  <div className="endss">
                                    <strong>Students</strong>
                                    {item.noofstudents}
                                  </div>
                                  <div className="start">
                                    <strong>REGISTERED</strong>
                                    {item.noofplayers}
                                    <span></span>
                                  </div>
                                  <div className="ends">
                                    <strong>TOTAL</strong>
                                    {item.noofplayers}
                                  </div>
                                </div>
                              ) : null} */}
                                  {authStore.user.role == "PROFESSOR" ? (
                                    <div className="dates">
                                      <strong>TOTAL CAPACITY</strong>
                                      {item.noofparticipants}
                                    </div>
                                  ) : null}
                                  {authStore.user.role == "PLAYER" ? ( //PLAYER AND CONTEST HOST
                                    <div className="dates">
                                      <strong>TOTAL CAPACITY</strong>
                                      {item.noofparticipants}
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  style={{opacity:item !== undefined && item.participants.length > 2 ? 1:0.5}}
                                  className="card-footer text-muted"
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    // this.props.history.push({
                                    //   pathname: ROUTES.CONTEST_DASHBOARD,
                                    //   state: item.contestname
                                    // });
                                    if(item !== undefined && item.participants.length > 2){
                                      this.props.toggleDashboard(item.contestname)
                                    }else{
                                      this.setState(st => ({ ...st, toggleAlert: true, warningText: "No data found" }));
                                    }
                                  }}
                                >
                                  <div style={{ fontSize: 15, cursor: 'pointer', display:'flex', paddingRight:10 }} className="col a-right">
                                    <i style={{marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8}} className="fas fa-compass fa-1x"></i>
                                    <label>
                                    &nbsp;{" "}
                                    {authStore.user.role == "PROFESSOR"
                                      ? "Performance Dashboard"
                                      : ""}
                                    {authStore.user.role == "STUDENT"
                                      ? "Performance Dashboard"
                                      : ""}
                                    {authStore.user.role == "PLAYER" //player and contest host
                                      ? "Performance Dashboard"
                                      : ""}
                                    </label>
                                <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#MonitorGamePerformanceContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return <p>No Contests</p>;
                        }
                      })}
                  </div>
                )}
              {/* classcontiner */}
            </div>
          </div>

          {Toast.successToastView()}
          {Toast.errorToastView()}
          <DeleteConfirm id={this.state.id} getContests={this.getContests} />
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
        {/* Toogle models */}
        {
          this.state.toggleViewLeaderModel ?
            <ViewLeaders
              type={this.state.leaderBoardType}
              onClose={() => { this.setState(st => ({ ...st, toggleViewLeaderModel: false })) }}
              data={this.state.viewLeaderModelData} /> : null
        }

        {this.state.toggleAlert ? <Alert text={this.state.warningText} onClose={() => this.setState(st => ({ ...st, toggleAlert: false }))} /> : null}
      </div>
    );
  }
}
export default withRouter(ProfessorInvitedContestList);
