import React, { Component, createRef } from "react";
import { Link, withRouter, RouteComponentProps, Route } from "react-router-dom";

import ROUTES from "../ROUTES";
import "jquery";
import AuthService from "../services/authService";
import LoadingWheel from "./LoadingWheel";
import commonService from "../services/commonService";
import PhoneCode from "./PhoneCode";
import ForgotPassword from "./ForgotPassword";
import EmailVerify from "./EmailVerification";
import Validate from "../Helpers/Utility/Validation";
import ftlogo from "../logo.png";

declare var $: any;
export interface LoginProps extends RouteComponentProps { }
export interface LoginState { }
class Login extends Component<LoginProps, LoginState> {
  passwordInput = React.createRef<HTMLInputElement>();
  button = React.createRef<HTMLButtonElement>();
  state = {
    email: "",
    phonenumber: "",
    password: "",
    error: "",
    validateError: {} as any,
    isEmailLogin: true,
    isOtpRecieve: false,
    otp: "",
    showLoader: false,
    loginNumber: "",
    countrycode: "+1",
    countryName: "United States",
    showResetPassword: false,
    isEmailVerify: false
  };
  closeModal() {
    $("#exampleModal").modal("hide");
    this.setState({
      email: "",
      phonenumber: "",
      password: "",
      error: "",
      validateError: {},
      isEmailLogin: true,
      isOtpRecieve: false,
      otp: "",
      loginNumber: "",
      showLoader: false,
      showResetPassword: false,
      isEmailVerify: false
    });
  }

  changeSigninMethod(e: any) {
    if (e.target.checked) {
      this.setState({ isEmailLogin: !this.state.isEmailLogin });
    }
  }

  setValue(e: any) {
    if (
      e.target.name != "phonenumber" ||
      e.target.value.match(/^\+?[0-9]{0,15}$/)
    ) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  setOTP(e: any) {
    if (e.target.value.match(/^[0-9]{0,6}$/)) {
      this.setState({ otp: e.target.value });
    }
  }

  focusPasswordInput(e: any) {
    if (e.keyCode == 13) {
      let node = this.passwordInput.current;
      if (node) {
        node.focus();
      }
    }
  }

  clickLoginButton(e: any) {
    if (e.keyCode == 13) {
      let node = this.button.current;
      if (node) {
        node.click();
      }
    }
  }

  handleLoader(value: boolean) {
    this.setState({ showLoader: value });
  }

  async validationlogin() {
    await this.setState({
      error: "",
      validateError: {},
      loginNumber: this.state.countrycode + this.state.phonenumber
    });
    let formvalid = true;
    let obj = {} as any;
    let loginError;

    if (this.state.isEmailLogin) {
      let email = this.state.email.includes("@") ? this.state.email : `${this.state.email}@gmail.com`
      loginError = (await Validate.validateEmail(
        email,
        "email"
      )) as any;
      Object.assign(obj, loginError.errors);

      if (loginError.isValid == false) {
        formvalid = false;
      }

      loginError = (await Validate.validatePassword(
        this.state.password,
        "password"
      )) as any;
      Object.assign(obj, loginError.errors);

      if (loginError.isValid == false) {
        formvalid = false;
      }
    } else {
      loginError = (await Validate.validatePhone(
        this.state.loginNumber,
        "phonenumber",
        true,
        "login"
      )) as any;
      Object.assign(obj, loginError.errors);

      if (loginError.isValid == false) {
        formvalid = false;
      }
    }

    this.setState({ validateError: obj });

    if (formvalid == true) {
      this.state.isEmailLogin ? this.signInEmail() : this.signInPhone();
    }
  }

  async signInEmail() {
    this.setState({ showLoader: true });
    let response = await AuthService.signInEmail(
      this.state.email.includes("@") ? this.state.email.toLowerCase().toLowerCase() : `${this.state.email}@gmail.com`.toLowerCase(),
      this.state.password
    );
    this.setState({ showLoader: false });
    if (response.isValid) {
      this.closeModal();
      this.props.history.push(ROUTES.CLASSLIST);
    } else {
      this.setState({ error: response.message });
    }
  }

  async signInPhone() {
    let response = await AuthService.signInPhone(this.state.loginNumber);
    this.setState({ isOtpRecieve: response });
  }

  async validateOTP() {
    let loginError = (await Validate.validatePhone(
      this.state.loginNumber,
      "phonenumber",
      true,
      "login"
    )) as any;
    let obj = loginError.errors;
    this.setState(Object.assign(this.state.validateError, obj));
    if (loginError.isValid) {
      this.sendOTP();
    }
  }

  async sendOTP() {
    this.setState({ showLoader: true });
    let response = await AuthService.sendOTP(this.state.otp);
    this.setState({ showLoader: false });
    if (response.isValid) {
      this.closeModal();
      this.props.history.push(ROUTES.CLASSLIST);
    } else {
      this.setState({ error: response.message });
    }
  }

  renderEmailInput() {
    return (
      <div className="col-md-12">
        <input
          type="email"
          name="email"
          value={this.state.email}
          className="form-control login-input"
          onKeyDown={e => this.focusPasswordInput(e)}
          placeholder="Email/ Username"
          onChange={e => this.setValue(e)}
        />
        <div className="validation-error">
          {this.state.validateError.email}{" "}
        </div>
      </div>
    );
  }

  renderPhoneInput() {
    return (
      <div>
        <div className="col-md-12">
          <PhoneCode
            onSelect={(code: any, name: any) => {
              this.setState({ countrycode: code, countryName: name });
            }}
            defaultValue={
              this.state.countrycode ? this.state.countryName : "Select Country"
            }
            className="form-control"
          />
        </div>
        <div className="col-md-12">
          <input
            type="text"
            name="phonenumber"
            value={this.state.phonenumber}
            className="form-control login-input"
            onKeyDown={e => this.focusPasswordInput(e)}
            placeholder="Phone number"
            onChange={e => this.setValue(e)}
          />
          <div className="validation-error">
            {this.state.validateError.phonenumber}{" "}
          </div>
        </div>
      </div>
    );
  }

  renderPasswordOTP() {
    return (
      <div>
        <input
          type={
            this.state.isOtpRecieve && !this.state.isEmailLogin
              ? "text"
              : "password"
          }
          name={
            this.state.isOtpRecieve && !this.state.isEmailLogin
              ? "otp"
              : "password"
          }
          value={
            this.state.isOtpRecieve && !this.state.isEmailLogin
              ? this.state.otp
              : this.state.password
          }
          className="form-control login-input"
          ref={this.passwordInput}
          onKeyDown={e => this.clickLoginButton(e)}
          placeholder={
            !this.state.isOtpRecieve || this.state.isEmailLogin
              ? "Password"
              : "OTP"
          }
          onChange={e =>
            this.state.isOtpRecieve ? this.setOTP(e) : this.setValue(e)
          }
        />

        <div className="validation-error">
          {this.state.isEmailLogin && !this.state.isOtpRecieve
            ? this.state.validateError.password
            : this.state.validateError.otp}
        </div>
      </div>
    );
  }

  showLoginError() {
    return (
      <div
        id="error"
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          color: "red",
          fontSize: 14
        }}
      >
        <div>
          {this.state.error}{" "}
          {this.state.error == "Email for the user is not verified." ? (
            <div>
              <a
                className="emailverifybutton"
                onClick={() => this.setState({ isEmailVerify: true })}
              >
                {" "}
                click here{" "}
              </a>
              to resend verification email
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderChooseSigninMethod() {
    return (
      <div style={{ marginTop: 20, display:'flex', justifyContent:'center' }}>
        <input
          type="radio"
          checked={this.state.isEmailLogin}
          onChange={e => this.changeSigninMethod(e)}
          style={{marginTop:6.5}}
        />
        &nbsp; &nbsp;Email/ Username&nbsp;&nbsp;&nbsp;
        <input
          type="radio"
          checked={!this.state.isEmailLogin}
          onChange={e => this.changeSigninMethod(e)}
          style={{marginTop:6.5}}
        />
        &nbsp;&nbsp;Phone number
      </div>
    );
  }

  renderLoginForm() {
    return (
      <div className="modal-body signin-modal">
        {/* <h2></h2> */}
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <form>
              <div className="form-group">
                <div className="row mt-10 mb-10">
                  {this.state.isEmailLogin
                    ? this.renderEmailInput()
                    : this.renderPhoneInput()}
                </div>

                {this.state.isEmailLogin || this.state.isOtpRecieve
                  ? this.renderPasswordOTP()
                  : null}

                {this.renderChooseSigninMethod()}
              </div>
            </form>
          </div>

          {/* Keep me signed in */}
          <div className="col-xs-6 col-sm-6">
              <input onChange={(e) => {
                const {checked} = e.currentTarget;
                sessionStorage.setItem("Keep me signed in",checked+"");
              }} style={{marginRight:8, marginLeft:4, display:'inline'}} type="checkbox"/><p style={{display:'inline'}}>Keep me signed in</p>
          </div>

          {this.state.isEmailLogin ? (
            <div className="col-xs-6 col-sm-6 text-align-right frgt-pwd">
              <i
                className="text-button btn"
                onClick={() => this.setState({ showResetPassword: true })}
              >
                Forgot Password
              </i>
            </div>
          ) : null}

          <div className="col-xs-12 col-sm-12">
            <button
              style={{width:120, float:'right'}}
              type="button"
              id="sign-in-button"
              className="login-btn"
              ref={this.button}
              onClick={() =>
                this.state.isEmailLogin ? this.validationlogin()
                  : this.state.isOtpRecieve
                    ? this.validateOTP()
                    : this.validationlogin()
              }
            >
              {this.state.isEmailLogin || this.state.isOtpRecieve
                ? "Login"
                : "Request OTP"}
            </button>

            <br />
            <span className="sign-up-wrap"> </span>
            <div
              id="captcha-container"
              style={this.state.isEmailLogin ? { display: "none" } : {}}
            ></div>

            {this.state.error != "" ? this.showLoginError() : null}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className="modal fade"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog sign-in-modal-dialog" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#3f3d56", display:'flex', alignItems:'center' }}
            >
                      <img
                        style={{marginTop:6, marginRight:0, opacity:0.8,marginBottom:4}}
                        src={ftlogo}
                        width="33px"
                        height="25px"
                        alt="image"
                      />
              <h2 className="pad0000" style={{ textAlign: "center", marginLeft:'auto', marginRight:'auto', paddingRight:18 }}>
                Login
              </h2>
              <button
                  type="button"
                  style={{ color: "#fff" }}
                  className="close "
                  aria-label="Close"
                  onClick={() => this.closeModal()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            {!this.state.showResetPassword ? (
              !this.state.isEmailVerify ? (
                this.renderLoginForm()
              ) : (
                  <EmailVerify
                    email={this.state.email}
                    isLoading={(value: boolean) => this.handleLoader(value)}
                  />
                )
            ) : (
                <ForgotPassword
                  isLoading={(value: boolean) => this.handleLoader(value)}
                />
              )}
          </div>
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
      </div>
    );
  }
}
export default withRouter(Login);
