import React from 'react';
import './closeCurrentSession.scss';

export default ({ type, onLogout, onClose }) => {
    return <div className="closeCurrentSession">
        <div>
            <div>
                {
                    type === "ProfessorRegister" || type === "TeacherRegister" || type === "PlayerRegister" ?
                        <label>Please logout the current session to register as a {type.split("Register")[0]}</label> :
                        <label>Please logout the current session to signin as a Demo {type}</label>
                }
            </div>
            <div>
                <button className="btn btn-primary btn-md" onClick={onLogout}>Logout</button>
                <button className="btn btn-secondary btn-md" onClick={onClose}>Close</button>
            </div>
        </div>
    </div>
}