import React from 'react';
import './quizeg.css';
import { RouteComponentProps, withRouter } from 'react-router'
import LoadingWheel from '../../components/LoadingWheel'
import Toast from '../../components/Toast'
import ROUTES from '../../ROUTES'
import "./Timer.css"
const moment = require("moment")


export interface TimerProps extends RouteComponentProps {
    startDate: Date;
}

export interface TimerState { }

class Timer extends React.Component<TimerProps, TimerState> {


    state = {
        startDate: this.props.startDate,
        elapseTime: '00:00:00'
    }

    componentDidMount() {
        this.setTimer()
    }

    setTimer() {
        setInterval(() => {


            const startDate = moment(this.state.startDate);
            const timeEnd = moment(new Date());
            const diff = timeEnd.diff(startDate);
            const diffDuration = moment.duration(diff);
            let day = diffDuration.days() / 10 > 1 ? diffDuration.days() : "0" + diffDuration.days()
            let hour = diffDuration.hours() / 10 > 1 ? diffDuration.hours() : "0" + diffDuration.hours()
            let minute = diffDuration.minutes() / 10 > 1 ? diffDuration.minutes() : "0" + diffDuration.minutes()
            let second = diffDuration.seconds() / 10 > 1 ? diffDuration.seconds() : "0" + diffDuration.seconds()
            this.setState({
                elapseTime: hour + ":" + minute + ":" + second
            })
        }, 1000)
    }

    render() {

        return (
            <div className="elapse-timer">
                <div className="">{this.state.elapseTime}</div>
            </div>
        )

    }

}
export default withRouter(Timer)