import * as firebase from "firebase";
export const Post = async (url, token, params, header) => {
  let newToken = null;
  try {
    newToken = await firebase.auth().currentUser.getIdToken(true);
  } catch (err) { }

  let response = await fetch(url, {
    method: "POST",
    headers: header || {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + newToken
    },
    body: params,
  });
  console.log(response)
  return response;
};

export const Delete = async (url, token, params) => {
  let newToken = await firebase.auth().currentUser.getIdToken(true);

  let response = fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + newToken,
    },
    body: params,
  });
  return response;
};

export const Get = async (url, token, header) => {
  let newToken = await firebase.auth().currentUser.getIdToken(true);

  let response = fetch(url, {
    method: "GET",
    headers: header || {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + newToken,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
    },
  });
  return response;
};
