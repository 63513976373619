import React from "react";
import ProfService from "../../services/profService";
import clasService from "../../services/classService";
import contesttService from "../../services/contestService";
import commonService from "../../services/commonService";
import { isPossibleNumber } from "libphonenumber-js";
const moment = require("moment");

class Validation {
  validateName(name, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!name || name == "") {
      if (fieldname == "firstname") {
        isValid = false;
        errors[fieldname] = "First name  cannot be empty";
      } else {
        errors[fieldname] = "Last name  cannot be empty";
        isValid = false;
      }
    } else {
      if (!name.match(/^[a-zA-Z\s]{1,100}$/)) {
        if (fieldname == "firstname") {
          isValid = false;
          errors[fieldname] = "Enter valid first name";
        } else {
          errors[fieldname] = "Enter valid last name";
          isValid = false;
        }
      }
    }
    // console.log(isValid);
    return { errors, isValid };
  }

  validateCollegeName(name, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!name || name == "") {
      isValid = false;
      errors[fieldname] = "School/College name cannot be empty";
    }
    return { errors, isValid };
  }

  validateField(name, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!name || name == "") {
      isValid = false;
      errors[fieldname] = "This field cannot be empty";
    }
    return { errors, isValid };
  }

  validateDOB(dob, fieldname, isDateValid) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!dob || dob == "") {
      // isValid = false;
      // errors[fieldname] = "Select date of birth";
    } else if (moment(moment().subtract(15, "y")).diff(dob, "d") < 0) {
      isValid = false;
      errors[fieldname] = "Enter valid date of birth";
    } else if (
      moment(moment("1900-01-01", "YYYY-MM-DD").toDate()).diff(dob, "d") > 0
    ) {
      isValid = false;
      errors[fieldname] = "Enter valid date of birth";
    } else if (isDateValid == true) {
      isValid = false;
      errors[fieldname] = "Invalid date format";
    }
    return { errors, isValid };
  }

  async validateEmail(email, fieldname, checkEmailExist) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!email || email == "" || email.match(/^[\s]+$/)) {
      isValid = false;
      errors[fieldname] = "Email cannot be empty";
    } else if (
      !email.includes('@') && !email.includes('.')
      // !email.match(/^\w+([\.-_+]?\w+)*@\w+([\.-_+]?\w+)*(\.\w{2,3})+$/)
    ) {
      alert(email);
      errors[fieldname] = "Enter valid email";
      isValid = false;
    } else {
      if (checkEmailExist == true) {
        let res = await commonService.checkEmail(email);
        console.log("res" + res);
        if (!res) {
          isValid = false;
          errors[fieldname] = "Email already registered";
        }
      } else if (checkEmailExist == "addclass") {
        let res = await commonService.checkRole(email);
        if (!res) {
          isValid = false;
          errors[fieldname] = "Email already in use";
        }
      }
    }
    if (isValid == true) {
      errors[fieldname] = "";
    }
    return { errors, isValid };
  }
  async validateEmails(emails, fieldname, checkEmailExist) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!emails || emails == "" || emails.match(/^[\s]+$/)) {
      isValid = false;
      errors[fieldname] = "Emails field cannot be empty";
    }
    //  else {
    //   if (checkEmailExist == true) {
    //     let res = await commonService.checkEmail(email);
    //     console.log(res);
    //     if (!res) {
    //       isValid = false;
    //       errors[fieldname] = "Email already registered";
    //     }
    //   } else if (checkEmailExist == "addclass") {
    //     let res = await commonService.checkRole(email);
    //     if (!res) {
    //       isValid = false;
    //       errors[fieldname] = "Email already in use";
    //     }
    //   }
    // }
    console.log(emails);
    if (isValid == true) {
      errors[fieldname] = "";
    }
    return { errors, isValid };
  }

  async validatePhone(phonenumber, fieldname, checkPhoneNoExist, login) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";

    if (!phonenumber || phonenumber == "") {
      isValid = false;
      errors[fieldname] = "Phone number cannot be empty";
    } else if (!phonenumber.match(/^\+?\d{7,15}$/)) {
      isValid = false;
      errors[fieldname] = "Enter valid phone number";
    } else {
      if (checkPhoneNoExist) {
        let res = await commonService.verifyPhoneNumber(phonenumber);
        if (res.status != 200) {
          if (login == "login") {
            isValid = true;
          } else {
            isValid = false;
            errors[fieldname] = res.msg;
          }
        } else {
          if (login == "login") {
            isValid = false;
            errors[fieldname] = "Please enter a registered phone number";
          }
        }
      }
    }
    return { errors, isValid };
  }

  validateDescription(description, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!description || description == "") {
      errors[fieldname] = "This field cannot be empty";
      isValid = false;
    }
    return { errors, isValid };
  }

  validateCity(city, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!city || city == "") {
      // errors[fieldname] = 'City cannot be empty'
      // isValid = false;
    }
    return { errors, isValid };
  }

  validateOTP(otp, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!otp || otp == "") {
      errors[fieldname] = "Enter OTP";
      isValid = false;
    } else if (!otp.match(/^\d{6}$/)) {
      errors[fieldname] = "Enter valid OTP";
      isValid = false;
    }
    return { errors, isValid };
  }

  validateState(state, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!state || state == "") {
      // errors[fieldname] = 'State cannot be empty'
      // isValid = false;
    }
    return { errors, isValid };
  }

  validateZip(zip, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!zip || zip == "") {
      // errors[fieldname] = 'Zip/Postal code cannot be empty'
      // isValid = false;
    } else {
      if (!zip.match(/^[0-9]{5,6}$/)) {
        errors[fieldname] = "Invalid Zip/Postal code";
        isValid = false;
      }
    }
    return { errors, isValid };
  }

  validatePassword(password, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";

    if (!password || password == "") {
      errors[fieldname] = "Password cannot be empty";
      isValid = false;
    } else {
      if (password.length < 6) {
        errors[fieldname] = "Enter valid password";
        isValid = false;
      }
    }
    return { errors, isValid };
  }

  confirmPassword(password, confirmPassword, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!confirmPassword && confirmPassword == "") {
      errors[fieldname] = "Password cannot be empty";
      isValid = false;
    } else {
      if (password !== confirmPassword) {
        errors[fieldname] = "Passwords doesnot match";
        isValid = false;
      }
    }
    return { errors, isValid };
  }

  validateTradeRules(tradeRules, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!tradeRules || tradeRules == "") {
      isValid = false;
      errors[fieldname] = "Select game type";
    }
    return { errors, isValid };
  }

  validateCheckBox(isCheck, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!isCheck) {
      isValid = false;
      errors[fieldname] = "Please accept our terms and conditions";
    }
    return { errors, isValid };
  }

  async validateRegClassName(classname, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!classname || classname == "" || classname.match(/^[\s]+$/)) {
      errors[fieldname] = "Class name cannot be empty";
      isValid = false;
    } else if (!classname.match(/^[a-zA-Z0-9-_\s]{5,}$/)) {
      errors[fieldname] = "Enter valid class name";
      isValid = false;
    } else {
      await clasService.getClassbyName(classname).then(res => {
        if (res.data) {
          errors[fieldname] = "Class name already exist";
        }
      });
    }
    return { errors, isValid };
  }
  async validateRegContestName(contestname, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!contestname || contestname == "" || contestname.match(/^[\s]+$/)) {
      errors[fieldname] = "Contest name cannot be empty";
      isValid = false;
    } else if (!contestname.match(/^[a-zA-Z0-9-_\s]{5,}$/)) {
      errors[fieldname] = "Enter valid contest name";
      isValid = false;
    } else {
      await contesttService.getContestbyName(contestname).then(res => {
        if (res.data) {
          errors[fieldname] = "Contest name already exist";
        }
      });
    }
    return { errors, isValid };
  }

  async validateClassName(classname, fieldname) {
    let errors = {};
    let isValid = true;
    let classid = [];
    errors[fieldname] = "";
    if (!classname || classname == "") {
      errors[fieldname] = "Classname cannot be empty";
      isValid = false;
    } else {
      await clasService.getClassbyName(classname).then(res => {
        if (!res.data) {
          errors[fieldname] = "Class name doesnot exist";
          isValid = false;
        } else if (res.data.noofstudents <= res.data.students.length) {
          errors[fieldname] = "Maximum number of students reached";
          isValid = false;
        }
      });
    }
    return { errors, isValid, classid };
  }
  async validateContestName(contestname, fieldname) {
    let errors = {};
    let isValid = true;
    let contestid = [];
    errors[fieldname] = "";
    if (!contestname || contestname == "") {
      errors[fieldname] = "Contest name cannot be empty";
      isValid = false;
    } else {
      await contesttService.getContestbyName(contestname).then(res => {
        if (!res.data) {
          errors[fieldname] = "Contest name doesnot exist";
          isValid = false;
        } else if (
          res.data.noofparticipants <=
          res.data.students.length +
            res.data.players.length +
            res.data.professors.length
        ) {
          errors[fieldname] = "Maximum number of participants reached";
          isValid = false;
        }
      });
    }
    return { errors, isValid, contestid };
  }

  validateNoStudent(number, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!number || number == "") {
      errors[fieldname] = "Enter number of students (1-100)";
      isValid = false;
    } else {
      if (number > 100) {
        errors[fieldname] = "Enter valid number of students";
      }
    }
    return { errors, isValid };
  }
  validateNoParticipants(number, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!number || number == "") {
      errors[fieldname] = "Enter number of participants (1-100)";
      isValid = false;
    } else {
      if (number > 100) {
        errors[fieldname] = "Enter valid number of participants";
      }
    }
    return { errors, isValid };
  }

  validateDate(date, fieldname, msg, checkDate, isDateValid) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";

    if (!date || date == "") {
      isValid = false;
      errors[fieldname] = "Select " + msg + " date";
    } else if (moment().diff(date, "days") > 0 && !checkDate) {
      isValid = false;
      errors[fieldname] = "Past date is not allowed";
    } else if (
      moment(moment("2000-01-01", "YYYY-MM-DD").toDate()).diff(date, "d") > 0
    ) {
      isValid = false;
      errors[fieldname] = "Past date is not allowed";
    } else if (isDateValid == true) {
      isValid = false;
      errors[fieldname] = "Invalid date format";
    }
    return { errors, isValid };
  }

  checkEnddateIsBeforeStartdate(startDate, endDate, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (moment(endDate).diff(startDate, "days") < 0) {
      isValid = false;
      errors[fieldname] = "End date must be greater than start date";
    }
    return { errors, isValid };
  }

  validateRound(round, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!round || round == "") {
      errors[fieldname] = "Enter number of rounds ";
      isValid = false;
    }
    return { errors, isValid };
  }

  validateContestPrice(contestPrice, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!contestPrice || contestPrice == "") {
      errors[fieldname] = "Enter contest fee ";
      isValid = false;
    }
    return { errors, isValid };
  }
  validateContestPrize(contestPrize, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!contestPrize || contestPrize == "") {
      errors[fieldname] = "Enter contest prize ";
      isValid = false;
    }
    return { errors, isValid };
  }
  validateInitialCapital(initialCapital, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!initialCapital || initialCapital == "") {
      errors[fieldname] = "Enter initial capital ";
      isValid = false;
    }
    return { errors, isValid };
  }

  validateMinBenchmark(benchmark, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!benchmark) {
      errors[fieldname] = "Enter minimum benchmark";
      isValid = false;
    }

    return { errors, isValid };
  }

  validateSelect(value, fieldname, msg) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!value || value == "") {
      errors[fieldname] = "Select " + msg;
      isValid = false;
    }
    return { errors, isValid };
  }

  validateTimeZone(value, fieldname) {
    let errors = {};
    let isValid = true;
    errors["fieldname"] = "";
    if (!value || value == "") {
      errors[fieldname] = "Select time zone";
      isValid = false;
    }
    return { errors, isValid };
  }

  validatePassingScore(value, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!value || value == "") {
      errors[fieldname] = "Enter passing percentage";
      isValid = false;
    } else {
      if (parseInt(value) == 0 || parseInt(value) > 100) {
        errors[fieldname] = "Enter valid passing percentage";
        isValid = false;
      }
    }
    return { errors, isValid };
  }

  validateNoofAttempt(value, fieldname) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!value || value == "") {
      errors[fieldname] = "Enter no. of attempts";
      isValid = false;
    }
    return { errors, isValid };
  }

  validateAmountAwarded(value, fieldname, msg) {
    let errors = {};
    let isValid = true;
    errors[fieldname] = "";
    if (!value || value == "") {
      errors[fieldname] = "Select " + msg;
      isValid = false;
    }
    return { errors, isValid };
  }
}

const Validate = new Validation();
export default Validate;
