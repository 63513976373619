import { Component } from "react"
import Config from '../config/Config'
import * as firebase from '../config/firebase'
import * as firebaseApp from 'firebase'
import authStore from "../models/AuthStore";
class Authservice {

    signInPhone(phoneNumber) {

        window.recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier('captcha-container', {
            'size': 'normal',
            'callback': function (response) {
            },
            'expired-callback': function () {

            }
        });
        window.recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        });
        var appVerifier = window.recaptchaVerifier;
        return firebase.auth.signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                window.confirmationResult = confirmationResult;

                return true
            }).catch(function (error) {
                // SMS not sent
                return false
            });
    }
    sendOTP(code) {
        return window.confirmationResult.confirm(code).then(function (result) {
            // User signed in successfully.
            return { isValid: true, message: "Success" };

        }).catch(function (error) {
            // User couldn't sign in (bad verification code)
            return { isValid: false, message: "bad verification code" };
        });
    }
    signInEmail(email, password) {
        var that = this
        return firebase.auth.signInWithEmailAndPassword(email, password).then(result => {
            if (result.user.emailVerified) {
                // User signed in successfully.
                return { isValid: true, message: "Success" };
            }
            else {
                this.signOut() //User email not verified
                return { isValid: false, message: "Email for the user is not verified." };
            }
        }).catch(error => {
            return { isValid: false, message: "Invalid username or password" };
        })
    }

    resetPassword(email) {
        return firebase.auth.sendPasswordResetEmail(email)
            .then(function (user) {
                return { isSend: true }
            }).catch(function (e) {
                return { isSend: false }
            })
    }

    async resendVerification(email) {

        let res = await fetch(Config.base_url + 'adminManager/resend', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email
            })
        })


        if (res.status == 200) {
            return { status: 200, msg: 'Please check your email and click on the provided link to verify your email' }
        } else {
            return { status: 400, msg: 'Please provide a registered email' }
        }
    }

    signOut() {
        firebase.auth.signOut().then(function (result) {
        }).catch(function (error) {
            console.log("Error", error)
        });

    }


}

const appAuth = new Authservice()
export default appAuth