import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import clasService from "../../services/classService";
import contesttService from "../../services/contestService";
import * as queryString from "query-string";
import ROUTES from "../../ROUTES";
import LoadingWheel from "../../components/LoadingWheel";
import authStore from "../../models/AuthStore";
import ProfService from "../../services/profService";
import commonService from "../../services/commonService";
import Validate from "../../Helpers/Utility/Validation";
import envelopeSvg from "../../envelope.svg";

export interface InviteProps extends RouteComponentProps { }

export interface InviteState { }

class AddContest extends Component<InviteProps, InviteState> {
  state = {
    successMsg: "",
    showLoader: true,
    showUpdateLoader: false,
    askEmail: false,
    email: "",
    contestID: "",
    validMsg: "",
    contestname: "",
    showErrorMessage: false,
    errorMsg: "",
    isValid: false
  };

  async componentDidMount() {
    let params = await queryString.parse(this.props.location.search);
    if (params.guid) {
      //SpecificInvite
      this.ContestInvite(params);
    } else {
      //GenericInvite
      if (params.id) {
        console.log("GenericInvite");
        let data = await contesttService.getContestbyId(params.id);
        // if (data.status == 200) {
        //   this.ContestInvite(params);
        // } else if (data.status == 404) {
        console.log("GenericInvite data", data);

        if (data.status == 404) {
          /* when contest not found */
          this.setState({ showLoader: false, errorMsg: data.msg });
        } else {
          if (new Date() > new Date(data.data.regEndDate)) {
            this.setState({ showLoader: false, errorMsg: "Registration window closed!" });
          } else {
            this.setState({
              contestname: data.data.contestname,
              askEmail: true,
              showLoader: false,
              contestID: params.id
            });
          }
        }
      } else {
        console.log("--");
        this.setState({
          showLoader: false,
          errorMsg: "Make sure the URL is correct and try again."
        });
      }
    }
  }

  async ContestInvite(params: any) {
    console.log("params", params);
    this.setState({ showErrorMessage: false });

    if (!this.state.showLoader) {
      this.setState({ showUpdateLoader: true });
    } //showLoader
    
    let response = await contesttService.registerContest(params);
    if (response.status === 200) {
      if (new Date() > new Date(response.res.regEndDate)) {
        this.setState({
          errorMsg: "Registration window closed",
          showLoader: false,
          showUpdateLoader: false
        });
      } else {
        await this.setState({ successMsg: response.res });
        this.setState({ showLoader: false, showUpdateLoader: false })
      }
    } //200
    else if (response.status === 404) {
      if (new Date() > new Date(response.res.regEndDate)) {
        this.setState({
          errorMsg: "Registration window closed!",
          showLoader: false,
          showUpdateLoader: false
        });
      } else {
        this.setState({ showLoader: false, showUpdateLoader: false });

        if (response.res.created_by) {
          if (response.res.created_by === "player") {
            this.props.history.push({
              pathname: ROUTES.PLAYER_INVITATION,
              state: {
                contestname: response.res.contestname,
                email: response.res.inviteEmail,
                playerInvitation: true,
                contestid: response.res.contestid
              }
            });
          } else {
            this.props.history.push({
              pathname: ROUTES.PLAYER_INVITATION,
              state: {
                contestname: response.res.contestname,
                email: response.res.inviteEmail,
                playerInvitation: true,
                contestid: response.res.contestid
              }
            });
          } //
        }
        console.log(
          "response.status === 404",
          !authStore.user || authStore.user.user_id == response.res.inviteEmail
        );
        if (
          !authStore.user ||
          authStore.user.user_id == response.res.inviteEmail
        ) {
          this.setState({ showLoader: false, showUpdateLoader: false });
        } else {
          {
            let params = await queryString.parse(this.props.location.search);
            if (params.invite_type === "player") {
              this.props.history.push({
                pathname: ROUTES.PLAYER_INVITATION,
                state: {
                  contestname: response.res.contestname,
                  email: response.res.inviteEmail,
                  playerInvitation: true,
                  contestid: response.res.contestid
                }
              });
            } else if (params.invite_type === "student") {
              this.props.history.push({
                pathname: ROUTES.STUDENT_INVITATION,
                state: {
                  contestname: response.res.contestname,
                  email: response.res.inviteEmail,
                  playerInvitation: true,
                  contestid: response.res.contestid
                }
              });
            } else {
              this.props.history.push({
                pathname: ROUTES.PROFESSORREG,
                state: {
                  contestname: response.res.contestname,
                  email: response.res.inviteEmail,
                  playerInvitation: true,
                  contestid: response.res.contestid
                }
              });
            }
          }
        }
      }
    } //404
    else if (response.status == 400) {
      if (response.res == "Invalid invite link") {
        this.setState({
          errorMsg: response.msg,
          showLoader: false,
          showUpdateLoader: false
        });
      } else {
        this.setState({ showLoader: false, showUpdateLoader: false });
      }
    } //400
    else if (response.status == 402) {
      this.setState({ errorMsg: response.msg, showLoader: false });
    } //402
    else {
      this.setState({
        successMsg: response.res,
        showLoader: false,
        showUpdateLoader: false
      });
    }

    if (response.res.invite_type && new Date() < new Date(response.res.regEndDate)) {
      if (response.res.invite_type === "player") {
        this.props.history.push({
          pathname: ROUTES.PLAYER_INVITATION,
          state: {
            contestname: response.res.contestname,
            email: response.res.inviteEmail,
            playerInvitation: true,
            contestid: response.res.contestid
          }
        });
      } else if (response.res.invite_type === "student") {
        this.props.history.push({
          pathname: ROUTES.STUDENT_INVITATION,
          state: {
            contestname: response.res.contestname,
            email: response.res.inviteEmail,
            playerInvitation: true,
            contestid: response.res.contestid
          }
        });
      } else {
        this.props.history.push({
          pathname: ROUTES.PROFESSORREG,
          state: {
            contestname: response.res.contestname,
            email: response.res.inviteEmail,
            playerInvitation: true,
            contestid: response.res.contestid
          }
        });
      }
    } //res invite_type
    // this.props.history.push({
    //   pathname: ROUTES.STUDENT_INVITATION,
    //   // search: "?query=contest-invitation",
    //   state: {
    //     contestname: response.res.contestname,
    //     email: response.res.inviteEmail,
    //     playerInvitation: true,
    //     contestid: response.res.contestid
    //   }
    // });
  } //ContestInvite

  async validateEmail() {
    let valid = (await Validate.validateEmail(
      this.state.email,
      "email",
      "addcontest"
    )) as any;
    await this.setState({ validMsg: valid.errors.email });
    if (valid.isValid) {
      this.ContestInvite({
        email: this.state.email,
        contestID: this.state.contestID
      });
    }
  }

  showSuccessMsg() {
    return (
      <div className="invite-wrapper">
        <h2 className="invite-response-text">{this.state.successMsg}</h2>
      </div>
    );
  }

  showLogoutMsg() {
    return (
      <div className="invite-wrapper">
        <h2 className="invite-response-text">
          Please logout and revisit the link
        </h2>
      </div>
    );
  }

  showLimitExceedMsg() {
    return <div className="display-error">{this.state.errorMsg} </div>;
  }
  // renderRegistrationForm() {
  //   return <div></div>;
  // }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8" }}
            className=" container registration-title"
          >
            {/* {"Registration by Professor Invite"} */}
          </div>
        </div>
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : this.state.errorMsg && this.state.errorMsg != "" ? (
          <div className="display-error">{this.state.errorMsg} </div>
        ) : this.state.successMsg != "" ? (
          <div className="invite-wrapper">
            <h2 className="invite-response-text">{this.state.successMsg}</h2>
          </div>
        ) : !this.state.showErrorMessage ? (
          <div className="container cmt20">
            <div className="row equal">
              <div
                className="col-md-8 reg-card col-md-offset-2 "
                style={{ paddingTop: "20px" }}
              >
                <div
                  className="wizard"
                  id="myWizard"
                  style={{ paddingTop: "5vh" }}
                >
                  <div className="step" style={{ textAlign: "center" }}>
                    <img src={envelopeSvg} width="320" height="auto" alt="" />
                    <div
                      className="row mt-15 mb-15 info highlight"
                      style={{ paddingTop: "12px" }}
                    >
                      <h2
                        className="col-md-12 "
                        style={{ textAlign: "center" }}
                      >
                        Contest Registration for {this.state.contestname}
                      </h2>
                      <h3>
                        Please enter your email and press submit to Register
                      </h3>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <input
                        style={{ fontSize: 14, height: 40, width: 250 }}
                        type="email"
                        name="email"
                        className="form-control input-lg"
                        placeholder="Email"
                        value={this.state.email ? this.state.email : ""}
                        onChange={e => this.setState({ email: e.target.value })}
                        onBlur={async () => {
                          let valid = (await Validate.validateEmail(
                            this.state.email,
                            "email",
                            "addcontest"
                          )) as any;
                          await this.setState({ validMsg: valid.errors.email });
                        }}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.validMsg}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10 text-center">
                    {" "}
                    <button
                      className="btn btn-primary  next-btn "
                      onClick={() => this.validateEmail()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.state.showUpdateLoader ? <LoadingWheel /> : null}
          </div>
        ) : (
                  <div className="invite-wrapper">
                    <h2 className="invite-response-text">
                      Please logout and revisit the link
            </h2>
                  </div>
                )}
        <div className="label-detail">
          {" "}
          {/* Allows user to register for the invited class by providing his email */}
        </div>
      </div>
    );
  }
}

export default withRouter(AddContest);
