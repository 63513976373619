import { Components } from "react";
import Config from "../config/Config";
import * as firebase from "../config/firebase";
import authStore from "../models/AuthStore";
import "firebase/auth";
import { Post, Get } from "./apiServices";
class playersService {
  async createPlayer(playerDetails) {
    try {
      let response = await Post(
        Config.base_url + "/playerManager/create_player",
        authStore.authToken,
        JSON.stringify(playerDetails)
      );
      if (response) {
        if (response.status == 200) {
          return {
            status: 200,
            msg:
              "We have sent a verification link to your email address. Please click to validate your email address."
          };
        } else if (response.status == 401) {
          return { status: 401, msg: "Unauthorized User" };
        } else if (response.status == 400) {
          let data = await response.json();
          if (data.code == "auth/invalid-phone-number") {
            return {
              status: 400,
              msg: "Please enter valid phone number in step 1"
            };
          } else if (data.code == "auth/phone-number-already-exists") {
            return { status: 400, msg: "Phone number already exist" };
          } else {
            return { msg: "1: Something went wrong please try again later" };
          }
        } else {
          return { msg: "2: Something went wrong please try again later" };
        }
      }
    } catch (error) {
      // return { msg: 'Something went wrong please try again later' }
      return { msg: error };
    }
  }

  async updatePlayer(playerDetails) {
    try {
      let data = await Post(
        Config.base_url + "/playerManager/update_player",
        authStore.authToken,
        JSON.stringify(playerDetails)
      );
      return { status: 200, msg: "Profile Updated" };
    } catch (error) {
      console.log("Error: ", error);
      return { status: 400, msg: "Failed" };
    }
  }

  async getPlayerByEmail(teacherEmail) {
    const TeachObj = await firebase.firestore
      .collection("teacher")
      .where("email", "==", teacherEmail)
      .get();
  
    let teacher = {};
  
    TeachObj.docs.forEach((doc) => {
      teacher = doc.data();
    });
  
    return teacher;
  }

  async getPlayerDetails() {
    try {
      let response = await Get(
        Config.base_url + "/playerManager/player_details",
        authStore.authToken
      );
      let playerDetails = await response.json();
      return playerDetails;
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }

  async getPlayerByEmail(playerEmail) {
    const playerObj = await firebase.firestore
      .collection("player")
      .where("email", "==", playerEmail)
      .get();
    
    let player = {};

    playerObj.docs.forEach((doc) => {
      player = doc.data();
    });

    return player;
  }

  async sendMultipleInvitesForMultipleEmails(emails, contestid) {
    try {
      const invitationDetails = [emails, contestid];
      let response = await Post(
        Config.base_url + "/playerManager/multiple_invites_for_multiple_emails",
        "",
        invitationDetails,
        { Authorization: "Bearer " + authStore.authToken }
      );

      if (response.status == 200) {
        return { status: 200, msg: "Mail successfully sent!" };
      } else if (response.status == 401) {
        return { status: 401, msg: "Unauthorized!" };
      } else {
        return { status: 400, msg: "Failed!" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }

  async getGameStatusOfContestForPlayer(contestid) {
    try {
      let response = await Post(
        Config.base_url +
          "contestManager/get_game_status_of_contest_for_player",
        authStore.authToken,
        JSON.stringify({ contestid: contestid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async setInitialStateOfGameByPlayerForContest(
    contestid,
    topic,
    level,
    initialCapital
  ) {
    console.log("authStore.authToken", authStore.authToken);
    try {
      let response = await Post(
        Config.base_url +
          "/playerManager/set_initial_state_of_game_for_contest",
        authStore.authToken,
        JSON.stringify({
          contestID: contestid,
          topic: topic,
          level: level,
          initialCapital: initialCapital
        })
      );

      if (response.status == 200) {
        let data = await response.json();
        return { status: 200, result: data };
      } else {
        return { status: 400, result: "Error" };
      }
    } catch (error) {
      return { status: 400, result: "Error" };
    }
  }
}

const playerService = new playersService();
export default playerService;
