import { Components } from "react";
import Config from "../config/Config";
import * as firebase from "../config/firebase";
import authStore from "../models/AuthStore";
import "firebase/auth";
import studentService from "./studentService";
import { element } from "prop-types";
import { Post, Get } from "./apiServices";
class ProfessorService {
  async createProfessor(professorDeatils) {
    try {
      let response = await Post(
        Config.base_url + "/professorManager/create_professor",
        authStore.authToken,
        JSON.stringify(professorDeatils)
      );
      console.error("error" + response.status);

      if (response) {
        if (response.status == 200) {
          return {
            status: 200,
            msg:
              "We have sent a verification link to your email address. Please click to validate your email address."
          };
        } else if (response.status == 401) {
          return { status: 401, msg: "Unauthorized User" };
        } else if (response.status == 400) {
          let data = await response.json();
          if (data.code == "auth/invalid-phone-number") {
            return {
              status: 400,
              msg: "Please enter valid phone number in step 1"
            };
          } else if (data.code == "auth/phone-number-already-exists") {
            return { status: 400, msg: "Phone number already exist" };
          } else {
            return { msg: "Something went wrong please try again later" };
          }
        }
      } else {
        return { msg: "Something went wrong please try again later" };
      }
    } catch (error) {
      return { msg: "Something went wrong please try again later" };
    }
  }

  async updateProfessor(professorDeatils) {
    try {
      let data = await Post(
        Config.base_url + "/professorManager/update_professor",
        authStore.authToken,
        JSON.stringify(professorDeatils)
      );
      return { status: 200, msg: "Profile Updated" };
    } catch (error) {
      console.log("Error: ", error);
      return { status: 400, msg: "Failed" };
    }
  }
  async getProfessordetails() {
    // var dbPromise = indexedDB.open("firebaseLocalStorageDb", 1);

    // console.log("Sotrage: ", dbPromise);
    // console.log("Auth: ", authStore.authToken);
    try {
      let response = await Get(
        Config.base_url + "/professorManager/professor_details",
        authStore.authToken
      );
      let profDetails = await response.json();
      return profDetails;
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }
  async classInvite(inviteDetails) {
    try {
      let response = await Post(
        Config.base_url + "/professorManager/invite",
        authStore.authToken,
        JSON.stringify({
          classID: inviteDetails.id,
          email: inviteDetails.email
        })
      );
      if (response.status == 200) {
        let data = await response.json();
        return { status: 200, result: data };
      } else {
        let data = await response.json();
        return { status: 400, msg: data.message };
      }
    } catch (error) {
      console.error(error);
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }

  async contestInvite(inviteDetails) {
    try {
      let response = await Post(
        Config.base_url + "/professorManager/invite_student_to_contest",
        authStore.authToken,
        JSON.stringify({
          contestID: inviteDetails.id,
          email: inviteDetails.email
        })
      );
      if (response.status == 200) {
        let data = await response.json();
        return { status: 200, result: data };
      } else {
        let data = await response.json();
        return { status: 400, msg: data.message };
      }
    } catch (error) {
      console.error(error);
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }

  async sendInvite(csv, classid) {
    try {
      let formData = new FormData();
      formData.append("file", csv);
      formData.append("classID", classid);

      let response = await Post(
        Config.base_url + "/professorManager/multiple_invites",
        "",
        formData,
        { Authorization: "Bearer " + authStore.authToken }
      );

      if (response.status == 200) {
        return { status: 200, msg: "Mail successfully sent!" };
      } else if (response.status == 401) {
        return { status: 401, msg: "Unauthorized!" };
      } else {
        return { status: 400, msg: "Failed!" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }
  
  async sendMultipleInvitesForMultipleEmails(emails, classid) {
    try {
      // let formData = new FormData();
      // formData.append("file", csv);
      // formData.append("classID", classid);
      const invitationDetails = [emails, classid];
      let response = await Post(
        Config.base_url +
          "/professorManager/multiple_invites_for_multiple_emails",
        "",
        invitationDetails,
        { Authorization: "Bearer " + authStore.authToken }
      );

      if (response.status == 200) {
        return { status: 200, msg: "Mail successfully sent!" };
      } else if (response.status == 401) {
        return { status: 401, msg: "Unauthorized!" };
      } else {
        return { status: 400, msg: "Failed!" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }

  async sendMultipleInvitesForMultipleEmailsOfContest(emails, contestid) {
    try {
      // let formData = new FormData();
      // formData.append("file", csv);
      // formData.append("classID", classid);
      const invitationDetails = [emails, contestid];
      let response = await Post(
        Config.base_url +
          "/professorManager/contest_multiple_invites_for_multiple_emails",
        "",
        invitationDetails,
        { Authorization: "Bearer " + authStore.authToken }
      );

      if (response.status == 200) {
        return { status: 200, msg: "Mail successfully sent!" };
      } else if (response.status == 401) {
        return { status: 401, msg: "Unauthorized!" };
      } else {
        return { status: 400, msg: "Failed!" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong please try again later"
      };
    }
  }

  async getGameResultbyClass(classid) {
    try {
      let response = await Post(
        Config.base_url + "classManager/get_game_result_for_professor",
        authStore.authToken,
        JSON.stringify({ classid: classid })
      );
      if (response.status === 200) {
        let data = await response.json();
        let result = new Map(JSON.parse(data));
        return { msg: "Success", data: result, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getResultStatusOfContestbyUser(contestid) {
    try {
      let response = await Post(
        Config.base_url + "contestManager/get_game_status_of_contest_for_user",
        authStore.authToken,
        JSON.stringify({ contestid: contestid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getGameResultbyContest(contestid) {
    try {
      let response = await Post(
        Config.base_url + "contestManager/get_game_result_for_professor",
        authStore.authToken,
        JSON.stringify({ contestid: contestid })
      );
      if (response.status === 200) {
        let data = await response.json();
        let result = new Map(JSON.parse(data));
        return { msg: "Success", data: result, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getGameResultbyInvitedContest(contestid){
    
  }

  async getQuizResultbyClass(classid) {
    try {
      let response = await Post(
        Config.base_url + "classManager/get_quiz_result_for_professor",
        authStore.authToken,
        JSON.stringify({ classid: classid })
      );
      if (response.status === 200) {
        let data = await response.json();
        let result = new Map(JSON.parse(data));
        return { msg: "Success", data: result, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getNoofQuizAttempts(classID) {
    const result = await firebase.firestore
      .collection("result")
      .where("classID", "==", classID)
      .get();
    let attempt = {};
    result.docs.forEach(item => {
      let studID = item.data().studentID;
      let topic = item.data().topic;
      let level = item.data().level;
      attempt = {
        [studID]: {
          [topic]: {
            [level]: 0
          }
        }
      };

      attempt[studID][topic][level] += 1;
    });
    return attempt;
  }

  async getProfessorName(classID) {
    const classObj = await firebase.firestore
      .collection("class")
      .where("id", "==", classID)
      .get();
    let professorEmail = null;
    classObj.docs.forEach(item => {
      let uid = item.data().uid;
      professorEmail = uid;
    });

    const ProfObj = await firebase.firestore
      .collection("professor")
      .where("email", "==", professorEmail)
      .get();
    let firstname = null;
    let lastname = null;
    ProfObj.docs.forEach(item => {
      firstname = item.data().firstname;
      lastname = item.data().lastname;
    });

    return firstname + " " + lastname;
  }

  async getProfessorByEmail(professorEmail) {
    const ProfObj = await firebase.firestore
      .collection("professor")
      .where("email", "==", professorEmail)
      .get();
    
    let professor = {};

    ProfObj.docs.forEach((doc) => {
      professor = doc.data();
    });

    return professor;
  }

  async getGameStatusOfContestForProfessor(contestid) {
    try {
      let response = await Post(
        Config.base_url + "contestManager/get_game_status_of_contest_for_user",
        authStore.authToken,
        JSON.stringify({ contestid: contestid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async setInitialStateOfGameByProfessorForContest(
    contestid,
    topic,
    level,
    initialCapital
  ) {
    console.log("authStore.authToken", authStore.authToken);
    try {
      let response = await Post(
        Config.base_url +
          "/professorManager/set_initial_state_of_game_for_contest",
        authStore.authToken,
        JSON.stringify({
          contestID: contestid,
          topic: topic,
          level: level,
          initialCapital: initialCapital
        })
      );

      if (response.status == 200) {
        let data = await response.json();
        return { status: 200, result: data };
      } else {
        return { status: 400, result: "Error" };
      }
    } catch (error) {
      return { status: 400, result: "Error" };
    }
  }
}

export default new ProfessorService();
