import React from "react";
import {
  withRouter,
  RouteChildrenProps,
  Redirect,
  RouteComponentProps
} from "react-router";
import ROUTES from "../../ROUTES";
import "./TopicList.css";
import studentService from "../../services/studentService";
import "./TopicItem.css";
import LoadingWheel from "../../components/LoadingWheel";
import { threadId } from "worker_threads";
import contestService from "../../services/contestService";

export interface ContestTopicProps extends RouteComponentProps {}
export interface ContestTopicState {}

class ContestTopicItem extends React.Component<
  ContestTopicProps,
  ContestTopicState
> {
  state = {
    page: 1,
    topic: {} as any,
    showLoader: true
  };

  startQuiz() {
    this.props.history.push({
      pathname: ROUTES.QUIZ,
      state: {
        contestname: this.props.location.state.contestname,
        topic: this.props.location.state.topic,
        level: this.props.location.state.level
      }
    });
  }

  async componentDidMount() {
    let contestDetails = await contestService.getContestbyName(
      this.props.location.state.contestname
    );
    // let res = await studentService.getTopic(
    //   contestDetails.data.id,
    //   this.props.location.state.topic,
    //   this.props.location.state.level
    // );

    // this.setState({ topic: res, showLoader: false });
    this.setState({ showLoader: false });
  }

  prev() {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 });
    }
  }

  next() {
    if (this.state.page < 6) {
      this.setState({ page: this.state.page + 1 });
    }
  }

  isPageEndReached() {
    if (this.props.location.state.topic == "Stocks") {
      if (
        (this.state.page == 6 && this.props.location.state.level == "Trader") ||
        (this.state.page == 4 &&
          this.props.location.state.level == "Market Maker") ||
        (this.state.page == 3 &&
          this.props.location.state.level == "Advanced Trader") ||
        (this.state.page == 3 &&
          this.props.location.state.level == "Advanced Market Maker")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.props.location.state.topic == "Bonds") {
      if (
        (this.state.page == 5 && this.props.location.state.level == "Trader") ||
        (this.state.page == 5 &&
          this.props.location.state.level == "Market Maker") ||
        (this.state.page == 5 &&
          this.props.location.state.level == "Advanced Trader") ||
        (this.state.page == 5 &&
          this.props.location.state.level == "Advanced Market Maker")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  renderCurriculum(section: any) {
    return (
      <div>
        <ul>
          {section
            ? section.map((item: any) => {
                return (
                  <div>
                    <li className="list-item">{String(item.heading)}</li>
                    <ul>
                      {item.sub_heading
                        ? item.sub_heading.map((element: any) => {
                            return element.constructor != {}.constructor ? (
                              <li className="sublist-item">
                                {String(element)}
                              </li>
                            ) : (
                              <div>
                                <li className="sublist-item">
                                  {String(element.heading)}
                                </li>
                                <li className="sub-sublist-item">
                                  {String(element.sub_heading)}
                                </li>
                              </div>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                );
              })
            : null}
        </ul>

        <div className="btn-wrapper ">
          <ul className="pagination flex-wrap pagination-wrapp">
            <li>
              {this.state.page == 1 ? null : (
                <button
                  className="page-item prev-btn btn btn-pagination d-md-block next-btn"
                  onClick={e => this.prev()}
                >
                  {" "}
                  Previous
                  <i
                    className="mdi mdi-chevron-left chev-left"
                    data-name="mdi-chevron-left"
                  ></i>
                </button>
              )}
            </li>
            <li>
              <button
                className="page-item active btn btn-pagination d-md-block next-btn"
                onClick={e => {
                  this.isPageEndReached() ? this.startQuiz() : this.next();
                }}
              >
                {this.isPageEndReached() ? "Start Quiz" : "Next"}

                <i
                  className="mdi mdi-chevron-right"
                  data-name="mdi-chevron-right"
                ></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  // renderStocksTrader() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 6</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Basic Rules"
  //             : this.state.page == 2
  //             ? "Roles"
  //             : this.state.page == 3
  //             ? "Basic Strategy for Traders"
  //             : this.state.page == 4
  //             ? "Determine your Buy/Sell Point & Trade on your Perceived Edge"
  //             : this.state.page == 5
  //             ? "Trading on Breaking News Strategy"
  //             : this.state.page == 6
  //             ? "Calculating P&L to determine the Winner"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.basic_rules
  //             : this.state.page == 2
  //             ? this.state.topic.roles
  //             : this.state.page == 3
  //             ? this.state.topic.basic_strategy_for_traders
  //             : this.state.page == 4
  //             ? this.state.topic.buy_sell_point
  //             : this.state.page == 5
  //             ? this.state.topic.trading_breaking_news_strategy
  //             : this.state.page == 6
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }

  // renderBondsTrader() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 5</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Basic Rules"
  //             : this.state.page == 2
  //             ? "Basic Strategy for Traders"
  //             : this.state.page == 3
  //             ? "Determine your Buy/Sell Point & Trade on your Perceived Edge"
  //             : this.state.page == 4
  //             ? "Calculating P&L to determine the Winner"
  //             : this.state.page == 5
  //             ? "Trading on Breaking News Strategy"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.basic_rules
  //             : this.state.page == 2
  //             ? this.state.topic.basic_strategy_for_traders
  //             : this.state.page == 3
  //             ? this.state.topic.buy_sell_point
  //             : this.state.page == 4
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : this.state.page == 5
  //             ? this.state.topic.trading_breaking_news_strategy
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }

  // renderStocksMarketMaker() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 5</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Basic Rules"
  //             : this.state.page == 2
  //             ? "Roles"
  //             : this.state.page == 3
  //             ? "Basic Strategy for Market Makers"
  //             : this.state.page == 4
  //             ? "Determine your Buy/Sell Point & Trade on your Perceived Edge"
  //             : this.state.page == 5
  //             ? "Trading on Breaking News Strategy"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.basic_rules
  //             : this.state.page == 2
  //             ? this.state.topic.roles_of_market_maker
  //             : this.state.page == 3
  //             ? this.state.topic.basic_strategy_for_market_makers
  //             : this.state.page == 4
  //             ? this.state.topic.trading_breaking_news_strategy
  //             : this.state.page == 5
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }
  // renderBondsMarketMaker() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 5</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Basic Rules"
  //             : this.state.page == 2
  //             ? "Basic Strategy for Market Makers"
  //             : this.state.page == 3
  //             ? "Determine your Buy/Sell Point & Trade on your Perceived Edge"
  //             : this.state.page == 4
  //             ? "Trading on Breaking News Strategy"
  //             : this.state.page == 5
  //             ? "Bond Fundamentals"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.basic_rules
  //             : this.state.page == 2
  //             ? this.state.topic.basic_strategy_for_market_makers
  //             : this.state.page == 3
  //             ? this.state.topic.trading_breaking_news_strategy
  //             : this.state.page == 4
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : this.state.page == 5
  //             ? this.state.topic.bond_fundamentals
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }
  // renderStocksAdvancedTrader() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 3</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Advanced Strategy for Traders"
  //             : this.state.page == 2
  //             ? "Determine your Buy/Sell Point & Trade on your Perceived Edge"
  //             : this.state.page == 3
  //             ? "Trading on Breaking News Strategy"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.advanced_strategy_for_traders
  //             : this.state.page == 2
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : this.state.page == 3
  //             ? this.state.topic.trading_breaking_news_strategy
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }
  // renderBondsAdvancedTrader() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 5</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Advanced Strategy for Traders"
  //             : this.state.page == 2
  //             ? "Determine your Buy/Sell Point & Trade on your Perceived Edge"
  //             : this.state.page == 3
  //             ? "Trading on Breaking News Strategy"
  //             : this.state.page == 4
  //             ? "Bond Fundamentals"
  //             : this.state.page == 5
  //             ? "Economic Indicator Tutorial"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.advanced_strategy_for_traders
  //             : this.state.page == 2
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : this.state.page == 3
  //             ? this.state.topic.trading_breaking_news_strategy
  //             : this.state.page == 4
  //             ? this.state.topic.bond_fundamentals
  //             : this.state.page == 5
  //             ? this.state.topic.economic_indicator_tutorial
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }
  // renderStocksAdvancedMarketMaker() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 3</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Basic Rules"
  //             : this.state.page == 2
  //             ? "Advanced Strategy for Traders"
  //             : this.state.page == 3
  //             ? "Trading on Breaking News Strategy"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.basic_rules
  //             : this.state.page == 2
  //             ? this.state.topic.basic_strategy_for_market_makers
  //             : this.state.page == 3
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }
  // renderBondsAdvancedMarketMaker() {
  //   return (
  //     <div>
  //       <div className="page-number">Page {this.state.page} of 5</div>
  //       <div className="reg-card bottom-margin">
  //         <h1 className="topic-name">
  //           {this.props.location.state.topic +
  //             " (" +
  //             this.props.location.state.level +
  //             ")"}{" "}
  //         </h1>
  //         <h2 className="topic-heading">
  //           {this.state.page == 1
  //             ? "Basic Rules"
  //             : this.state.page == 2
  //             ? "Advanced Strategy for Traders"
  //             : this.state.page == 3
  //             ? "Trading on Breaking News Strategy"
  //             : this.state.page == 4
  //             ? "Bond Fundamentals"
  //             : this.state.page == 5
  //             ? "Economic Indicator Tutorial"
  //             : null}
  //         </h2>
  //         {this.renderCurriculum(
  //           this.state.page == 1
  //             ? this.state.topic.basic_rules
  //             : this.state.page == 2
  //             ? this.state.topic.strategy_for_market_makers
  //             : this.state.page == 3
  //             ? this.state.topic.calculating_p_l_and_winner
  //             : this.state.page == 4
  //             ? this.state.topic.bond_fundamentals
  //             : this.state.page == 5
  //             ? this.state.topic.economic_indicator_tutorial
  //             : null
  //         )}
  //       </div>
  //     </div>
  //   );
  // }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage"></div>
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : (
          <div className="container cmt20">
            {/* {this.props.location.state.topic == "Stocks"
              ? this.props.location.state.level == "Trader"
                ? this.renderStocksTrader()
                : this.props.location.state.level == "Market Maker"
                ? this.renderStocksMarketMaker()
                : this.props.location.state.level == "Advanced Trader"
                ? this.renderStocksAdvancedTrader()
                : this.props.location.state.level == "Advanced Market Maker"
                ? this.renderStocksAdvancedMarketMaker()
                : null
              : this.props.location.state.topic == "Bonds"
              ? this.props.location.state.level == "Trader"
                ? this.renderBondsTrader()
                : this.props.location.state.level == "Market Maker"
                ? this.renderBondsMarketMaker()
                : this.props.location.state.level == "Advanced Trader"
                ? this.renderBondsAdvancedTrader()
                : this.props.location.state.level == "Advanced Market Maker"
                ? this.renderBondsAdvancedMarketMaker()
                : null
              : null} */}
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(ContestTopicItem);
