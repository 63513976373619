import "react-app-polyfill/ie11";
import React, { Component, Fragment } from "react";
import './index.css';
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
  RouteComponentProps
} from "react-router-dom";
import ROUTES from "./ROUTES";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import LeaderShip from "./pages/Leadership/Leadership";
import Platform from "./pages/Platform/Platform";
import Tryout from "./pages/Tryout/Tryout";
import ClassReg from "./pages/Class/ClassReg";
import CardReg from "./pages/Card/CardReg";
import ClassList from "./pages/Class/ClassList";
// import CardList from "./pages/Card/CardList";
// import CardGrid from "./pages/Card/CardGrid";
import ContestReg from "./pages/Contest/ContestReg";
import ContestList from "./pages/Contest/ContestList";
import HostedContestList from "./pages/Contest/HostedContestList";
import InvitedContestList from "./pages/Contest/InvitedContestList";
import ProfessorReg from "./pages/Professor/ProfessorReg";
import TeacherReg from "./pages/Teacher/TeacherReg";
import StudentReg from "./pages/Student/StudentReg";
import StudentProfile from "./pages/Student/StudentProfile";
import StudentInvitation from "./pages/Student/StudentInvitation";
import Invite from "./pages/Invite/Invite";
import AppHome from "./pages/AppHome";
import Dashboard from "./pages/Dashboard/Dashboard";
import ContestDashboard from "./pages/Dashboard/ContestDashboard";
// import * as firebase from "firebase/app";
import "firebase/auth";
import appAuth from "./services/authService";
import authStore from "./models/AuthStore";
import "bootstrap";
import LoadingWheel from "./components/LoadingWheel";
import AddClass from "./pages/AddClass/AddClass";
import AddContest from "./pages/AddContest/AddContest";
import Quiz from "./pages/Quiz/Quiz";
import PlayerReg from "./pages/Player/PlayerReg";
import PlayerProfile from "./pages/Player/PlayerProfile";
import PlayerInvitation from "./pages/Player/PlayerInvitation";
import Result from "./pages/Quiz/Result";
import TopicItem from "./pages/Topic/TopicItem";
import ContestTopicItem from "./pages/Topic/ContestTopicItem";
import TopicList from "./pages/Topic/TopicList";
import ContestTopicList from "./pages/Topic/ContestTopicList";
import SignUp from "./pages/SignUp/SignUp";
import Example from "./pages/Example/Example";
import DemoSignInAsProfessor from "./pages/DemoSignIn/DemoSignInAsProfessor";
import DemoSignInAsTeacher from "./pages/DemoSignIn/DemoSignInAsTeacher";
import DemoSignInAsStudent from "./pages/DemoSignIn/DemoSignInAsStudent";
import DemoSignInAsPlayer from "./pages/DemoSignIn/DemoSignInAsPlayer";
import authService from './services/authService';
import ReactTooltip from "react-tooltip";
import MyGames from './pages/Games/myGames';
import {database} from './config/firebase';
import Collaborate from './pages/Collaborate';

// help pages
import Guide from './pages/Guide';

//models
import ActiveSessionModel from './models/activeSession';
import IdleTimeoutModel from './models/idleTimeout';

let idleTimmer: any;

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: null,
      blockScreen: false,
      idleTimeOut: false,

      // to store list of dashboards, topics
      tabs: [],
    };
  }

  resetTimmer = () => {
    clearTimeout(idleTimmer)
    idleTimmer = window.setTimeout(async () => {
      if (this.state.user !== null) {
        const kmsi = sessionStorage.getItem("Keep me signed in");
        if(kmsi !== null && kmsi !== "true"){
          authService.signOut();
          this.setState({ ...this.state, idleTimeOut: true, user: null })
        }
      }
    }, 900000);
  }

  componentDidMount() {
    //timeout if 15 min idle
      window.onload = this.resetTimmer;
      window.onkeypress = this.resetTimmer;
      window.onmousemove = this.resetTimmer;

    // //for blocking multiple session windows
    // localStorage.setItem("openpages", Date.now() + "")
    // const onLocalStorageEvent = (e: any) => {
    //   this.setState({ ...this.state, blockScreen: true });
    // }
    // window.addEventListener('storage', onLocalStorageEvent, false);

    authStore.addListener((data: any) => {
      this.setState({
        user: data.user
      });
        // in active
        window.onbeforeunload = (e:any) => {
          database.ref('activeUsers').child(data.user.email.toString().replace(/\./g,",")).remove();
        }
        //active
        database.ref('activeUsers').child(data.user.email.toString().replace(/\./g,",")).set({
          "email":data.user.email.toString().replace(/\./g,","),
          "timestamp": new Date().toString(),
          "name": data.user.name
        });
    });
    authStore.firebaseAuthListner();
  }

  toggleTabs = (name: any, type: any, gameType?: any, gameLevel?: any, contestType?: any) => {
    let found = this.state.tabs.find((x: any) => x.name === name && x.type === type);
    if (found) {
      this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: { name: any, visible: any, type: any }) => (x.name === name && x.type === type) ? { ...x, visible: true } : { ...x, visible: false }) }))
    } else {
      if (type === "Curriculum" || type === "Quiz") {
        let newList = [...this.state.tabs]
        newList = newList.map((y) => ({ ...y, visible: false }));
        this.setState((st: any) => ({ ...st, tabs: [...newList, { name, visible: true, type, gameType, gameLevel }] }));
      } else {
        let newList = [...this.state.tabs]
        newList = newList.map((y) => ({ ...y, visible: false }));
        this.setState((st: any) => ({ ...st, tabs: [...newList, { name, visible: true, type, contestType }] }));
      }
    }
  }

  _renderTabControls = () => {
    return <Fragment>
      {
        this.state.tabs.length > 0 ?
          this.state.tabs.map((dash: { name: any, visible: any, type: any, gameType?: any, gameLevel?: any, contestType?:any }, i: Number) => {
            const { name, visible, type, contestType } = dash;
            return type === "Class" ?
              <Dashboard style={{ display: visible ? 'block' : 'none' }} minimize={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: { name: any, visible: any }) => x.name === name ? { ...x, visible: false } : x) }))} key={i} close={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.filter((x: { name: any, visible: any }) => x.name !== name) }))} history={this.props.history} className={name} /> :
              type === "Contest" ?
                <ContestDashboard contestType={contestType} style={{ display: visible ? 'block' : 'none' }} minimize={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: { name: any, visible: any }) => x.name === name ? { ...x, visible: false } : x) }))} key={i} close={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.filter((x: { name: any, visible: any }) => x.name !== name) }))} history={this.props.history} contestName={name} /> :
                type === "Curriculum" ?
                  <TopicItem
                    toggleTabs={(name: any, gameType: any, gameLevel: any) => this.toggleTabs(name, "Quiz", gameType, gameLevel)}
                    topic={dash.gameType} level={dash.gameLevel}
                    classname={name}
                    style={{ display: visible ? 'block' : 'none' }}
                    minimize={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: { name: any, visible: any, gameType: any }) => ({ ...x, visible: false })) }))}
                    close={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.filter((x: { name: any, visible: any, type: any, gameType: any }) => x.name !== name && x.gameType === dash.gameType && type === x.type) }))}
                    history={this.props.history} /> :
                  <Quiz
                    closeAllCurriculumTabs = {() => this.setState((st:any) => ({...st, tabs: this.state.tabs.filter((x:any) => x.type !== "Curriculum")}))}
                    topic={dash.gameType}
                    level={dash.gameLevel}
                    classname={name}
                    style={{ display: visible ? 'block' : 'none' }}
                    minimize={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: { name: any, visible: any, gameType: any }) => ({ ...x, visible: false })) }))}
                    close={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.filter((x: { name: any, visible: any, type: any, gameType: any }) => type !== x.type) }))}
                    history={this.props.history} />
          }) : null
      }
      {
        this.state.tabs.length > 0 ?
          <div className="togglePanel">
            {
              this.state.tabs.map((dash: any, i: any) => {
                const { name, visible, type } = dash;
                return type === "Class" || type === "Contest" ?
                  <Fragment>
                    <div style={{ backgroundColor: visible ? 'rgba(0,0,0,0.1)' : 'white' }} data-tip data-for={`tab${i}`} key={i}><p onClick={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: any) => x.name === name && x.type === type ? { ...x, visible: !x.visible } : { ...x, visible: false }) }))}>Dashboard-{name.length > 0 ? name.toString().split("/@#FT#@/")[0].substr(0,12) : name}</p><i onClick={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.filter((x: any) => x.name !== name) }))} className="fas fa-times"></i></div>
                    <ReactTooltip multiline={true} id={`tab${i}`} backgroundColor="rgba(245,245,245,0.8)" place="bottom" effect="solid"><div style={{ maxWidth: 340, color: 'rgba(0,0,0,0.7)' }}>{name.length > 0 ? name.toString().split("/@#FT#@/")[0].substr(0,12) : name}</div></ReactTooltip>
                  </Fragment> :
                  <Fragment>
                    <div style={{ backgroundColor: visible ? 'rgba(0,0,0,0.1)' : 'white' }} data-tip data-for={`tab${i}`} key={i}><p onClick={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: any) => x.type === type ? { ...x, visible: !x.visible } : { ...x, visible: false }) }))}>{type}-{name}</p><i onClick={() => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.filter((x: any) => x.type !== type) }))} className="fas fa-times"></i></div>
                    <ReactTooltip multiline={true} id={`tab${i}`} backgroundColor="rgba(245,245,245,0.8)" place="bottom" effect="solid"><div style={{ maxWidth: 340, color: 'rgba(0,0,0,0.7)' }}>{name}</div></ReactTooltip>
                  </Fragment>
              })
            }
          </div> : null
      }
    </Fragment>
  }

  minimizeAllTabs = () => this.setState((st: any) => ({ ...st, tabs: this.state.tabs.map((x: { name: any, visible: any, gameType: any }) => ({ ...x, visible: false })) }))

  render() {
    if (this.state.idleTimeOut) {
      return <IdleTimeoutModel onClose={() => {
        this.setState({ ...this.state, idleTimeOut: false });
        window.location.reload();
      }} />
    } else {
      if (this.state.blockScreen) {
        return <ActiveSessionModel />
      } else {
        if (this.state.user) {
          if (this.state.user.role == "PROFESSOR") {
            return (
              <BrowserRouter>
                <Fragment>
                  <AppHome user={this.state.user} minimizeAllTabs={() => this.minimizeAllTabs()}>
                    <Switch>
                    <Route exact path={ROUTES.HOME} component={Home} />
                      <Route exact path={ROUTES.OURPLATFORM} component={Platform} />
                      <Route exact path={ROUTES.LEADERSHIP} component={LeaderShip} />
                      <Route exact path={ROUTES.TRYOUT} component={Tryout} />
                      <Route exact path={ROUTES.CONTACTUS} component={Contact} />
                      <Route exact path={ROUTES.CLASSREG} component={ClassReg} />
                      <Route exact path={ROUTES.CONTESTREG} component={ContestReg} />
                      <Route exact path={ROUTES.CLASSLIST} render={(props) => <ClassList toggleDashboard={(name: any) => this.toggleTabs(name, "Class")} />} />
                      <Route exact path={ROUTES.CONTESTLIST} component={ContestList} />
                      <Route exact path={ROUTES.COLLABORATE} component={Collaborate} />
                      <Route exact
                        path={ROUTES.INVITED_CONTESTLIST}
                        render={(props) => <InvitedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Invited")} />}
                      />
                      <Route exact
                        path={ROUTES.HOSTED_CONTESTLIST}
                        render={(props) => <HostedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Hosted")} />}
                      />
                      <Route exact path={ROUTES.PROFESSORREG} component={ProfessorReg} />
                      <Route exact path={ROUTES.INVITE} component={Invite} />
                      {/* <Route exact path={ROUTES.DASHBOARD} component={Dashboard} /> */}
                      <Route exact
                        path={ROUTES.CONTEST_DASHBOARD}
                        component={ContestDashboard}
                      />
                      <Route exact
                        path={ROUTES.CONTEST_TOPIC}
                        component={ContestTopicList}
                      />
                      <Route exact
                        path={ROUTES.CONTEST_TOPICITEM}
                        component={ContestTopicItem}
                      />
                      <Route exact path={ROUTES.ADDCLASS} component={AddClass} />
                      <Route exact path={ROUTES.ADDCONTEST} component={AddContest} />
                      <Route exact path={ROUTES.SIGNUP} component={SignUp} />
                      {/* guide routes */}
                      <Route exact
                        path={ROUTES.GUIDE_PROFESSOR}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_TEACHER}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_STUDENT}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_PLAYER}
                        component={Guide}
                      />
                      <Redirect to={ROUTES.CLASSLIST} />
                    </Switch>
                    {this._renderTabControls()}
                  </AppHome>
                </Fragment>
              </BrowserRouter>
            );
          }
          if (this.state.user.role == "TEACHER") {
            return (
              <BrowserRouter>
                <Fragment>
                  <AppHome user={this.state.user} minimizeAllTabs={() => this.minimizeAllTabs()}>
                    <Switch>
                    <Route exact path={ROUTES.HOME} component={Home} />
                      <Route exact path={ROUTES.OURPLATFORM} component={Platform} />
                      <Route exact path={ROUTES.LEADERSHIP} component={LeaderShip} />
                      <Route exact path={ROUTES.TRYOUT} component={Tryout} />
                      <Route exact path={ROUTES.CONTACTUS} component={Contact} />
                      <Route exact path={ROUTES.CLASSREG} component={ClassReg} />
                      <Route exact path={ROUTES.CONTESTREG} component={ContestReg} />
                      <Route exact path={ROUTES.CLASSLIST} render={(props) => <ClassList toggleDashboard={(name: any) => this.toggleTabs(name, "Class")} />} />
                      <Route exact path={ROUTES.COLLABORATE} component={Collaborate} />
                      <Route exact
                        path={ROUTES.INVITED_CONTESTLIST}
                        render={(props) => <InvitedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Invited")} />}
                      />
                      <Route exact
                        path={ROUTES.HOSTED_CONTESTLIST}
                        render={(props) => <HostedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Hosted")} />}
                      />
                      <Route exact path={ROUTES.TEACHERREG} component={TeacherReg} />
                      <Route exact path={ROUTES.INVITE} component={Invite} />
                      {/* <Route exact path={ROUTES.DASHBOARD} component={Dashboard} /> */}
                      <Route exact
                        path={ROUTES.CONTEST_DASHBOARD}
                        component={ContestDashboard}
                      />
                      <Route exact
                        path={ROUTES.CONTEST_TOPIC}
                        component={ContestTopicList}
                      />
                      <Route exact
                        path={ROUTES.CONTEST_TOPICITEM}
                        component={ContestTopicItem}
                      />
                      <Route exact path={ROUTES.ADDCLASS} component={AddClass} />
                      <Route exact path={ROUTES.ADDCONTEST} component={AddContest} />
                      <Route exact path={ROUTES.SIGNUP} component={SignUp} />
                      {/* guide routes */}
                      <Route exact
                        path={ROUTES.GUIDE_PROFESSOR}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_TEACHER}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_STUDENT}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_PLAYER}
                        component={Guide}
                      />
                      <Redirect to={ROUTES.CLASSLIST} />
                    </Switch>
                    {this._renderTabControls()}
                  </AppHome>
                </Fragment>
              </BrowserRouter>
            );
          } else if (this.state.user.role == "STUDENT") {
            return (
              <BrowserRouter>
                <Fragment>
                  <AppHome user={this.state.user} minimizeAllTabs={() => this.minimizeAllTabs()} >
                    <Switch>
                    <Route exact path={ROUTES.HOME} component={Home} />
                      <Route exact path={ROUTES.OURPLATFORM} component={Platform} />
                      <Route exact path={ROUTES.LEADERSHIP} component={LeaderShip} />
                      <Route exact path={ROUTES.TRYOUT} component={Tryout} />
                      <Route exact path={ROUTES.CONTACTUS} component={Contact} />
                      <Route exact path={ROUTES.CLASSREG} component={ClassReg} />
                      ContestReg
                      <Route exact path={ROUTES.CONTESTREG} component={ContestReg} />
                      <Route exact path={ROUTES.CLASSLIST} render={(props) => <ClassList toggleDashboard={(name: any) => this.toggleTabs(name, "Class")} />} />
                      <Route exact path={ROUTES.CONTESTLIST} component={ContestList} />
                      <Route exact path={ROUTES.COLLABORATE} component={Collaborate} />

                      <Route exact
                        path={ROUTES.INVITED_CONTESTLIST}
                        render={(props) => <InvitedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Invited")} />}
                      />
                      <Route exact
                        path={ROUTES.HOSTED_CONTESTLIST}
                        render={(props) => <HostedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Hosted")} />}
                      />
                      <Route exact path={ROUTES.STUDENTREG} component={StudentReg} />
                      {/* <Route exact path={ROUTES.DASHBOARD} component={Dashboard} /> */}
                      <Route exact
                        path={ROUTES.CONTEST_DASHBOARD}
                        component={ContestDashboard}
                      />
                      <Route exact path={ROUTES.ADDCLASS} component={AddClass} />
                      <Route exact path={ROUTES.ADDCONTEST} component={AddContest} />
                      <Route exact path={ROUTES.QUIZ} component={Quiz} />
                      <Route exact path={ROUTES.TOPIC} render={(props) => <TopicList toggleTab={(name: any, gameType: any, gameLevel: any) => this.toggleTabs(name, "Curriculum", gameType, gameLevel)} />} />
                      <Route exact
                        path={ROUTES.CONTEST_TOPIC}
                        component={ContestTopicList}
                      />
                      <Route exact
                        path={ROUTES.CONTEST_TOPICITEM}
                        component={ContestTopicItem}
                      />
                      {/* <Route exact path={ROUTES.TOPICITEM} component={TopicItem} /> */}
                      <Route exact path={ROUTES.RESULT} component={Result} />
                      <Route exact path={ROUTES.SIGNUP} component={SignUp} />
                      {/* guide routes */}
                      <Route exact
                        path={ROUTES.GUIDE_PROFESSOR}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_TEACHER}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_STUDENT}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_PLAYER}
                        component={Guide}
                      />
                      <Redirect to={ROUTES.CLASSLIST} />
                    </Switch>
                    {this._renderTabControls()}
                  </AppHome>
                </Fragment>
              </BrowserRouter>
            );
          }
          // else if (this.state.user.role == "SUPERADMIN") {
          //   return (
          //     <BrowserRouter>
          //       <Fragment>
          //         <AppHome minimizeAllTabs={() => this.minimizeAllTabs()}>
          //           <Switch>
          //             <Route exact path={ROUTES.CARDLIST} component={CardList} />
          //             <Route exact path={ROUTES.CARDREG} component={CardReg} />
          //             <Route exact path={ROUTES.CARDGRID} component={CardGrid} />
          //             <Redirect to={ROUTES.CARDLIST} />
          //           </Switch>
          //         </AppHome>
          //       </Fragment>
          //     </BrowserRouter>
          //   );
          // }
          else if (this.state.user.role == "PLAYER") {
            return (
              <BrowserRouter>
                <Fragment>
                  <AppHome user={this.state.user} minimizeAllTabs={() => this.minimizeAllTabs()}>
                    <Switch>
                      <Route exact path={ROUTES.HOME} component={Home} />
                      <Route exact path={ROUTES.CONTESTLIST} component={ContestList} />
                      <Route exact
                        path={ROUTES.INVITED_CONTESTLIST}
                        render={(props) => <InvitedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Invited")} />}
                      />
                      <Route exact
                        path={ROUTES.HOSTED_CONTESTLIST}
                        render={(props) => <HostedContestList toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Hosted")} />}
                      />
                      <Route exact path={ROUTES.MY_GAMES} render={(props) => <MyGames toggleDashboard={(name: any) => this.toggleTabs(name, "Contest","","","Invited")}/>}/>
                      <Route exact path={ROUTES.CONTESTREG} component={ContestReg} />
                      <Route exact path={ROUTES.ADDCONTEST} component={AddContest} />
                      <Route exact path={ROUTES.COLLABORATE} component={Collaborate} />
                      <Route exact
                        path={ROUTES.CONTEST_DASHBOARD}
                        component={ContestDashboard}
                      />
                      <Route exact path={ROUTES.TOPIC} render={(props) => <TopicList toggleTab={(name: any, gameType: any, gameLevel: any) => this.toggleTabs(name, "Curriculum", gameType, gameLevel)} />} />
                      <Route exact
                        path={ROUTES.CONTEST_TOPIC}
                        component={ContestTopicList}
                      />
                      {/* <Route exact path={ROUTES.TOPICITEM} component={TopicItem} /> */}
                      <Route exact
                        path={ROUTES.CONTEST_TOPICITEM}
                        component={ContestTopicItem}
                      />
                      <Route exact path={ROUTES.TRYOUT} component={Tryout} />
                      <Route exact path={ROUTES.CONTACTUS} component={Contact} />
                      <Route exact path={ROUTES.PLAYERREG} component={PlayerReg} />
                      <Route exact
                        path={ROUTES.PLAYER_PROFILE}
                        component={PlayerProfile}
                      />
                      <Route exact path={ROUTES.SIGNUP} component={SignUp} />
                      {/* guide routes */}
                      <Route exact
                        path={ROUTES.GUIDE_PROFESSOR}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_TEACHER}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_STUDENT}
                        component={Guide}
                      />
                      <Route exact
                        path={ROUTES.GUIDE_PLAYER}
                        component={Guide}
                      />
                      <Redirect to={ROUTES.HOSTED_CONTESTLIST} />
                    </Switch>
                    {this._renderTabControls()}
                  </AppHome>
                </Fragment>
              </BrowserRouter>
            );
          }
        } else {
          return (
            <BrowserRouter>
              <AppHome user={this.state.user} minimizeAllTabs={() => this.minimizeAllTabs()}>
                <Switch>
                  <Route exact path={ROUTES.HOME} component={Home} />
                  <Route exact path={ROUTES.OURPLATFORM} component={Platform} />
                  <Route exact path={ROUTES.LEADERSHIP} component={LeaderShip} />
                  <Route exact path={ROUTES.TRYOUT} component={Tryout} />
                  <Route exact path={ROUTES.CONTACTUS} component={Contact} />
                  <Route exact path={ROUTES.PROFESSORREG} component={ProfessorReg} />
                  <Route exact path={ROUTES.TEACHERREG} component={TeacherReg} />
                  <Route exact path={ROUTES.STUDENTREG} component={StudentReg} />
                  <Route exact path={ROUTES.STUDENT_PROFILE} component={StudentProfile} />
                  <Route exact
                    path={ROUTES.STUDENT_INVITATION}
                    component={StudentInvitation}
                  />
                  <Route exact path={ROUTES.PLAYERREG} component={PlayerReg} />
                  <Route exact path={ROUTES.PLAYER_PROFILE} component={PlayerProfile} />
                  <Route exact
                    path={ROUTES.PLAYER_INVITATION}
                    component={PlayerInvitation}
                  />
                  <Route exact path={ROUTES.ADDCLASS} component={AddClass} />
                  <Route exact path={ROUTES.ADDCONTEST} component={AddContest} />

                  <Route exact path={ROUTES.SIGNUP} component={SignUp} />
                  {/* <Route exact path={ROUTES.EXAMPLE} component={Example} /> */}
                  <Route exact
                    path={ROUTES.DEMO_SIGN_IN_PROFESSOR}
                    component={DemoSignInAsProfessor}
                  />
                  <Route exact
                    path={ROUTES.DEMO_SIGN_IN_TEACHER}
                    component={DemoSignInAsTeacher}
                  />
                  <Route exact
                    path={ROUTES.DEMO_SIGN_IN_STUDENT}
                    component={DemoSignInAsStudent}
                  />
                  <Route exact
                    path={ROUTES.DEMO_SIGN_IN_PLAYER}
                    component={DemoSignInAsPlayer}
                  />
                  {/* guide routes */}
                  <Route exact
                    path={ROUTES.GUIDE_PROFESSOR}
                    component={Guide}
                  />
                  <Route exact
                    path={ROUTES.GUIDE_TEACHER}
                    component={Guide}
                  />
                  <Route exact
                    path={ROUTES.GUIDE_STUDENT}
                    component={Guide}
                  />
                  <Route exact
                    path={ROUTES.GUIDE_PLAYER}
                    component={Guide}
                  />
                  {/* guide routes ends */}
                  {/* <Redirect to={ROUTES.HOME} /> */}
                </Switch>
              </AppHome>
            </BrowserRouter>
          );
        }
      }
    }
  }
}

export default App;
const RootDiv = document.getElementById("root");
ReactDOM.render(<App />, RootDiv);
