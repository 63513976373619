import React, { Component, createRef } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classService from "../../services/classService";
import ProfService from "../../services/profService";
import { InviteData } from "../../interfaces";
import { ClassDetails } from "../../interfaces";
import authStore from "../../models/AuthStore";
import { file } from "@babel/types";
import LoadingWheel from "../../components/LoadingWheel";
import Toast from "../../components/Toast";
import Validate from "../../Helpers/Utility/Validation";

export interface InviteProps extends RouteComponentProps {}

export interface InviteState {}

class MultipleInvite extends Component<InviteProps, InviteState> {
  private fileUpload = createRef<HTMLInputElement>();

  state = {
    inviteDetails: {} as InviteData,
    errors: {} as InviteData,
    classes: Array<ClassDetails>(),
    csv: {} as any,
    displayFileInput: false,
    showLoader: false
  };
  msg = "";
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);
  async componentDidMount() {
    let res = await classService.getAllClassForProfessor();
    this.setState({ classes: res.data });
  }

  async changeHandler(e: any) {
    let stateObject = {} as any;
    stateObject[e.target.name] = e.target.value;
    if (e.target.name == "classname") {
      let data = await this.state.classes.filter(item => {
        if (item.classname == e.target.value) {
          return item;
        }
      });
      stateObject["id"] = data[0].id;
    }
    this.setState(Object.assign(this.state.inviteDetails, stateObject));
  }

  async sendInvite() {
    this.setState({ showLoader: true });
    let res = await ProfService.sendInvite(
      this.state.csv,
      this.state.inviteDetails.id
    );
    await this.setState({ showLoader: false }, () => (this.msg = res.msg));
    if (res.status == 200) {
      this.notifySuccess();
    } else {
      this.notifyError();
    }
  }
  handleClick = () => {
    if (this.state.inviteDetails.id && this.state.inviteDetails.id != null) {
      const node = this.fileUpload.current;
      if (node) {
        node.click();
      }
    } else {
      this.state.errors.classname = "Please select a class";
      this.setState({ errors: this.state.errors });
    }
  };
  validateData() {
    let formCheck;

    formCheck = Validate.validateSelect(
      this.state.inviteDetails.classname,
      "classname",
      "a class"
    );
    this.setState(Object.assign(this.state.errors, formCheck.errors));

    if (formCheck.isValid == true) {
      this.sendInvite();
    }
  }
  IsClassSelected() {
    const errors: { [index: string]: any } = {};
    if (!this.state.inviteDetails.classname) {
      errors["classname"] = "Select a class";
    }
    this.setState({ errors: errors });
  }
  render() {
    return (
      <div className="section ">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 reg-card">
              <div className="registration-title">Invite Student</div>
              <div className="row mt-10 mb-10">
                <div className="col-md-4">
                  {" "}
                  Class<span className="mandtory-star">*</span>
                </div>
                <div className="col-md-8">
                  <select
                    className="form-control bfh-timezones"
                    data-country="US"
                    placeholder="date"
                    value={this.state.inviteDetails.classname}
                    name="classname"
                    onBlur={() => this.IsClassSelected()}
                    onChange={e => this.changeHandler(e)}
                  >
                    <option selected disabled>
                      Classes
                    </option>
                    {this.state.classes.map(item => {
                      return <option>{item.classname}</option>;
                    })}
                  </select>
                  <div className="validation-error">
                    {this.state.errors.classname}{" "}
                  </div>
                </div>
              </div>
              {(this.state.csv && this.state.csv.name) ||
              this.state.displayFileInput ? (
                <div className="row mt-10 mb-10">
                  <div className="col-md-4">
                    {" "}
                    File<span className="mandtory-star">*</span>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      disabled={true}
                      className="form-control bfh-timezones"
                      data-country="US"
                      value={this.state.csv.name}
                    />
                  </div>
                </div>
              ) : null}
              <div className="row mt-10 mb-10">
                <div className="col-md-8">
                  <input
                    type="file"
                    ref={this.fileUpload}
                    style={{ display: "none" }}
                    onChange={e =>
                      this.setState({
                        csv:
                          e.target.files &&
                          (e.target.files["length"] == 1
                            ? e.target.files[0]
                            : this.state.csv),
                        displayFileInput: e.target.files ? true : false
                      })
                    }
                    accept=".csv"
                  />
                </div>
              </div>
              {this.state.csv.name || this.state.displayFileInput ? (
                <button
                  className="btn btn-primary pull-right next-btn"
                  onClick={() => this.validateData()}
                >
                  Send Invitation
                </button>
              ) : null}
              <button
                className="btn btn-primary pull-right next-btn"
                onClick={this.handleClick}
              >
                Upload CSV
              </button>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
        {Toast.successToastView()}
        {Toast.errorToastView()}
        {/* <div className="label-detail"> Allow professor to generate email invitation for students </div> */}
      </div>
    );
  }
}
export default withRouter(MultipleInvite);
