import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import "./StudentReg.css";
import Toast from "../../components/Toast";
import { Student } from "../../interfaces";
import StudService from "../../services/studentService";
import clasService from "../../services/classService";
import contesttService from "../../services/contestService";
import ProfService from "../../services/profService";
import commonService from "../../services/commonService";
import ROUTES from "../../ROUTES";
import * as queryString from "query-string";
import LoadingWheel from "../../components/LoadingWheel";
import Constants from "../../Helpers/Constants/Constants";
import "jquery";
import Validate from "../../Helpers/Utility/Validation";
import moment from "moment";
import { string } from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import PhoneCode from "../../components/PhoneCode";
import { isPossibleNumber } from "libphonenumber-js";
import CommonService from "../../services/commonService";
import pSvg from "../../pSvg.svg";
import studentImg from "../../studentImg.svg";
import authStore from "../../models/AuthStore";

declare var $: any;
export interface RegProps extends RouteComponentProps {}

export interface RegState {}

class StudentReg extends Component<RegProps, RegState> {
  msg = "";
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);

  state = {
    Page: 1,
    studDetails: {} as Student,
    errors: {} as Student,
    checkAccept: false,
    checkError: "",
    showLoader: false,
    countrycode: "",
    countryName: "",
    phone: "",
    value: "",
    isDateValueNull: false,
    isSubmit: false
  };
  constructor(props: any) {
    super(props);
    this.state.studDetails.collegeCountry = "United States";
    this.state.studDetails.country = "United States";
    this.setState(
      Object.assign(this.state.studDetails, this.state.studDetails)
    );
  }
  async componentDidMount() {
    let params = await queryString.parse(this.props.location.search);
    console.log("params", params);
    this.setDefaultValues();
    this.isUserExist();
    this.isInvite();
  }

  setDefaultValues() {
    this.setState({ countrycode: "+1", countryName: "United States" });
    this.state.studDetails.dob = moment()
      .subtract(15, "years")
      .calendar();
    this.setState(
      Object.assign(this.state.studDetails, this.state.studDetails)
    );
  }

  async isUserExist() {
    if (this.props.location.state && this.props.location.state.id) {
      this.setState({ showLoader: true });
      let response = await StudService.getStudentdetails(
        this.props.location.state.id
      );
      if (response) {
        await this.setState({ showLoader: false, studDetails: response }, () =>
          console.log(this.state.studDetails)
        );
      }
    }
  }
  async isClassInvitation() {
    let params = await queryString.parse(this.props.location.search);
    if (params.query && params.query === "class-invitation") {
      return true;
    } else return false;
  }

  async isInvite() {
    if (this.isClassInvitation())
      if (this.props.location.state && this.props.location.state.classname) {
        this.setState({ showLoader: true });
        this.state.studDetails.classname = this.props.location.state.classname;
        this.state.studDetails.email = this.props.location.state.email;
        await this.setState({ studDetails: this.state.studDetails });
        this.setState({ showLoader: false });
      } else {
        this.setState({ showLoader: true });
        this.state.studDetails.contestname = this.props.location.state.contestname;
        this.state.studDetails.email = this.props.location.state.email;
        await this.setState({ studDetails: this.state.studDetails });
        this.setState({ showLoader: false });
      }
    // else if (
    //   this.props.location.state &&
    //   this.props.location.state.contestname
    // ) {
    //   this.setState({ showLoader: true });
    //   this.state.studDetails.contestname = this.props.location.state.contestname;
    //   this.state.studDetails.email = this.props.location.state.email;
    //   await this.setState({ studDetails: this.state.studDetails });
    //   this.setState({ showLoader: false });
    // } else {
    // }
  }

  checkhandler(e: any) {
    let checkAccept = e.target.checked ? true : false;
    this.setState({ checkAccept: checkAccept });
  }

  changeHandler(e: any, type?: any) {
    if (type) {
      if (type == "text") {
        if (e.target.value.match(/^[A-Za-z\s]{0,50}$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value.trimLeft();
          this.setState(Object.assign(this.state.studDetails, stateObject));
        }
      } else if (type == "number") {
        if (e.target.value.match(/^[\+]?[0-9]{0,12}$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value.trimLeft();
          this.setState(Object.assign(this.state.studDetails, stateObject));
        }
      } else if (type == "password") {
        if (e.target.value.match(/^[^\s]*$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value.trimLeft();
          this.setState(Object.assign(this.state.studDetails, stateObject));
        }
      }
    } else if (e.target.value.length <= 100) {
      let stateObject = {} as any;
      stateObject[e.target.name] = e.target.value.trimLeft();
      this.setState(Object.assign(this.state.studDetails, stateObject));
    }
  }

  changeDOB = (value: any) => {
    if (value !== null) {
      if (value != "Invalid Date") {
        let date = String(value.getDate());
        let month = String(value.getMonth() + 1);
        let year = String(value.getFullYear());

        this.state.errors.dob = "";
        this.state.studDetails.dob =
          year +
          (month.length == 1 ? "-0" : "-") +
          month +
          (date.length == 1 ? "-0" : "-") +
          date;

        this.setState(Object.assign(this.state.errors, this.state.errors));
        this.setState(
          Object.assign(this.state.studDetails, this.state.studDetails)
        );
        this.setState({ isDateValueNull: false });
      } else {
        this.setState({ isDateValueNull: true });
      }
    } else {
      this.state.studDetails.dob = value;
      this.setState(
        Object.assign(this.state.studDetails, this.state.studDetails)
      );
    }
  };

  async changePhoneNumber(e: any) {
    if (e.target.name != "phone" || e.target.value.match(/^[0-9]{0,12}$/)) {
      await this.setState({ [e.target.name]: e.target.value }, () => {
        this.state.studDetails.phonenumber =
          this.state.countrycode + this.state.phone;
      });
      Object.assign((this.state.studDetails, this.state.studDetails));
    }
  }

  async changePhoneNumberCode(code: any) {
    await this.setState(() => {
      this.state.studDetails.phonenumber = code + this.state.phone;
    });
  }

  async createStudent() {
    if (this.props.location.state && this.props.location.state.classname) {
      let classDetails = await clasService.getClassbyName(
        this.state.studDetails.classname
      );
      this.state.studDetails.classid = [classDetails.data.id];
    } else {
      let contestDetails = await contesttService.getContestbyName(
        this.state.studDetails.contestname
      );
      this.state.studDetails.contestid = [contestDetails.data.id];
    }

    this.setState({ showLoader: true });
    let response = await StudService.createStudent({...this.state.studDetails,"email":this.state.studDetails.email !== undefined ? this.state.studDetails.email.toLowerCase() : ""});
    this.setState({ showLoader: false });
    if (response.status) {
      if (response.status == 200) {
        this.msg = response.msg;
        this.notifySuccess();
        setTimeout(() => {
          this.props.history.push(ROUTES.HOME);
          $("#exampleModal").modal("show");
        });
      } else {
        this.msg = response.msg;
        this.notifyError();
        this.setState({ isSubmit: false });
      }
    } else {
      this.msg = response.msg;
      this.notifyError();
      this.setState({ isSubmit: false });
    }
  }

  async updateStudent() {
    this.setState({ showLoader: true });
    let response = await StudService.updateStudent(this.state.studDetails);
    this.setState({ showLoader: false });
    if (response.status) {
      if (response.status == 200) {
        this.msg = response.msg;
        this.notifySuccess();
        // console.log("after notifySuccess student")
        this.props.history.push(ROUTES.HOME);
        setTimeout(() => {
          this.setState({ isSubmit: false });
        }, 2000);
      } else {
        this.msg = response.msg;
        this.notifyError();
      }
    } else {
      this.msg = response.msg;
      this.notifyError();
    }
  }

  async validatePage() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let str = String(this.state.studDetails.phonenumber).substring(0, 1);
    if (this.state.studDetails.country && str != "+") {
      let country = await Constants.countries.filter(item => {
        if (item.name == this.state.studDetails.country) {
          return item;
        }
      });
      this.state.studDetails.phonenumber =
        country[0].dial_code + this.state.studDetails.phonenumber;
    }
    let formError;
    if (
      !(this.props.location.state && this.props.location.state.id) &&
      !authStore.user
    ) {
      if (this.props.location.state && this.props.location.state.classname) {
        formError = await Validate.validateClassName(
          this.state.studDetails.classname,
          "classname"
        );
        if (!formError.isValid) {
          this.isAnyErrorFound(formError);
          formvalid = false;
        }
        this.setState(Object.assign(this.state.studDetails, formError.classid));
      } else {
        formError = await Validate.validateContestName(
          this.state.studDetails.contestname,
          "contestname"
        );
        if (!formError.isValid) {
          this.isAnyErrorFound(formError);
          formvalid = false;
        }
        this.setState(
          Object.assign(this.state.studDetails, formError.contestid)
        );
      }
    }

    formError = await Validate.validateName(
      this.state.studDetails.firstname,
      "firstname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateName(
      this.state.studDetails.lastname,
      "lastname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateEmail(
        this.state.studDetails.email,
        "email",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
        alert(this.state.studDetails.email);
      }

      formError = await Validate.validatePhone(
        this.state.studDetails.phonenumber,
        "phonenumber",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }
    formError = await Validate.validateCollegeName(
      this.state.studDetails.collegeName,
      "collegeName"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    if (!(this.props.location.state && this.props.location.state.id)) {
      let formError;

      formError = await Validate.validatePassword(
        this.state.studDetails.password,
        "password"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.confirmPassword(
        this.state.studDetails.password,
        this.state.studDetails.confirmpassword,
        "confirmpassword"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validateCheckBox(
        this.state.checkAccept,
        "checkError"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }
    console.log("formvalid" + formvalid);
    if (formvalid == true) {
      if (!(this.props.location.state && this.props.location.state.id)) {
        this.createStudent();
      } else {
        this.updateStudent();
      }
    } else {
      this.setState({ isSubmit: false });
      this.setState({ showLoader: false });
    }

    return formvalid;
  }

  async validatePage1() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let str = String(this.state.studDetails.phonenumber).substring(0, 1);
    if (this.state.studDetails.country && str != "+") {
      let country = await Constants.countries.filter(item => {
        if (item.name == this.state.studDetails.country) {
          return item;
        }
      });
      this.state.studDetails.phonenumber =
        country[0].dial_code + this.state.studDetails.phonenumber;
    }
    let formError;
    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateClassName(
        this.state.studDetails.classname,
        "classname"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
      this.setState(Object.assign(this.state.studDetails, formError.classid));
    }

    formError = await Validate.validateName(
      this.state.studDetails.firstname,
      "firstname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateName(
      this.state.studDetails.lastname,
      "lastname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateDOB(
      this.state.studDetails.dob,
      "dob",
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateEmail(
        this.state.studDetails.email,
        "email",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      // formError = await Validate.validateState(this.state.studDetails.state, "state");
      // if (!formError.isValid) {
      //     this.isAnyErrorFound(formError);
      //     formvalid = false;
      // }

      formError = await Validate.validatePhone(
        this.state.studDetails.phonenumber,
        "phonenumber",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }

    // formError = await Validate.validateSelect(this.state.studDetails.gender, "gender", "your gender");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }
    this.setState({ showLoader: false });
    if (formvalid == true) {
      this.setState({ Page: 2 });
    }
  }

  async validatePage2() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let formError;
    formError = await Validate.validateSelect(
      this.state.studDetails.academic,
      "academic",
      "a degree"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateSelect(
      this.state.studDetails.academicbranch,
      "academicbranch",
      "the academic branch"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateState(
      this.state.studDetails.collegeName,
      "collegeName"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    // formError = await Validate.validateCity(this.state.studDetails.collegeCity, "collegeCity")
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateState(this.state.studDetails.collegeState, "collegeState");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateZip(this.state.studDetails.collegeZip, "collegeZip");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }
    this.setState({ showLoader: false });
    if (formvalid == true) {
      this.setState({ Page: 3 });
    }
  }
  isAnyErrorFound(error: any) {
    this.setState(Object.assign(this.state.errors, error.errors));
  }

  async validatePage3() {
    let formvalid = true;

    if (!(this.props.location.state && this.props.location.state.id)) {
      let formError;

      formError = await Validate.validatePassword(
        this.state.studDetails.password,
        "password"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
      formError = await Validate.confirmPassword(
        this.state.studDetails.password,
        this.state.studDetails.confirmpassword,
        "confirmpassword"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validateCheckBox(
        this.state.checkAccept,
        "checkError"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }
    if (formvalid == true) {
      if (this.props.location.state && this.props.location.state.id) {
        this.updateStudent();
      } else {
        this.createStudent();
      }
    } else {
      this.setState({ isSubmit: false });
    }
  }
  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8", height: "6vh" }}
            className=" container registration-title"
          ></div>
        </div>
        <div className="container cmt20">
          <div className="row equal">
            <div
              className="col-md-5 reg-card btrr"
              style={{ paddingTop: "20px" }}
            >
              <div
                className="wizard"
                id="myWizard"
                style={{ paddingTop: "5vh" }}
              >
                <div className="step" style={{ textAlign: "center" }}>
                  <img src={studentImg} width="320" height="auto" alt="" />
                  <div
                    className="row mt-15 mb-15 info highlight"
                    style={{ paddingTop: "12px" }}
                  >
                    <h2 className="col-md-12 " style={{ textAlign: "center" }}>
                      Student Registration
                    </h2>
                  </div>
                  <p className="form-left-text" style={{ paddingTop: "20px" }}>
                    Students can acquire practical trading and market knowledge
                    by using our innovative learning platform. After completion
                    of a quick registration you will be able to access our award
                    winning game. Additional classes may be added later through
                    your account.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-7 col-sm-12 reg-card btlr"
              style={{ paddingTop: "20px" }}
            >
              <div className="wizard" id="myWizard">
                <div className="step">
                  {!authStore.user ? (
                    <div className="row mt-15 mb-15 info-head">
                      {this.props.location.state &&
                      this.props.location.state.classname ? (
                        <h3 className="col-md-6">Class Information</h3>
                      ) : (
                        <h3 className="col-md-6">Contest Information</h3>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {!authStore.user ? (
                    <div className="row mt-10 mb-10">
                      {this.props.location.state &&
                      this.props.location.state.classname ? (
                        <div className="col-md-4">
                          Class Name<span className="mandtory-star">*</span>{" "}
                        </div>
                      ) : (
                        <div className="col-md-4">
                          Contest Name<span className="mandtory-star">*</span>{" "}
                        </div>
                      )}
                      {this.props.location.state &&
                      this.props.location.state.classname ? (
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            name="classname"
                            id="copyPreviousclass"
                            readOnly={
                              this.props.location.state &&
                              this.props.location.state.classname
                            }
                            aria-describedby="previousclass"
                            placeholder="Class Name"
                            value={
                              this.state.studDetails.classname
                                ? this.state.studDetails.classname
                                : ""
                            }
                            disabled={
                              this.props.location.state &&
                              (this.props.location.state.classname ||
                                this.props.location.state.id)
                            }
                            onBlur={async () => {
                              let error = await Validate.validateClassName(
                                this.state.studDetails.classname,
                                "classname"
                              );
                              this.setState(
                                Object.assign(this.state.errors, error.errors)
                              );
                            }}
                            onChange={e => this.changeHandler(e)}
                          />
                          <div className="validation-error">
                            {" "}
                            {this.state.errors.classname}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            name="contestname"
                            id="copyPreviousclass"
                            readOnly={
                              this.props.location.state &&
                              this.props.location.state.contestname
                            }
                            aria-describedby="previousclass"
                            placeholder="Contest Name"
                            value={
                              this.state.studDetails.contestname
                                ? this.state.studDetails.contestname
                                : ""
                            }
                            disabled={
                              this.props.location.state &&
                              (this.props.location.state.contestname ||
                                this.props.location.state.id)
                            }
                            onBlur={async () => {
                              let error = await Validate.validateContestName(
                                this.state.studDetails.contestname,
                                "contestname"
                              );
                              this.setState(
                                Object.assign(this.state.errors, error.errors)
                              );
                            }}
                            onChange={e => this.changeHandler(e)}
                          />
                          <div className="validation-error">
                            {" "}
                            {this.state.errors.contestname}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row mt-15 mb-15 info-head">
                    <h3 className="col-md-6">Personal Information</h3>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Name<span className="mandtory-star">*</span>{" "}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        placeholder="First Name"
                        // disabled={
                        //   this.props.location.state &&
                        //   this.props.location.state.id
                        // }
                        value={
                          this.state.studDetails.firstname
                            ? this.state.studDetails.firstname
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateName(
                            this.state.studDetails.firstname,
                            "firstname"
                          );
                          this.setState(
                            Object.assign(this.state.errors, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e, "text")}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.errors.firstname}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        placeholder="Last Name"
                        // disabled={
                        //   this.props.location.state &&
                        //   this.props.location.state.id
                        // }
                        value={
                          this.state.studDetails.lastname
                            ? this.state.studDetails.lastname
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateName(
                            this.state.studDetails.lastname,
                            "lastname"
                          );
                          this.setState(
                            Object.assign(this.state.errors, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e, "text")}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.errors.lastname}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Student's Email<span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="email"
                        name="email"
                        disabled={this.props.location.state}
                        className="form-control"
                        placeholder="Email"
                        value={
                          this.state.studDetails.email
                            ? this.state.studDetails.email
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateEmail(
                            this.state.studDetails.email !== undefined ? this.state.studDetails.email.toLowerCase() : "",
                            "email",
                            true
                          );
                          this.setState(
                            Object.assign(this.state.errors, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e)}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.errors.email}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Phone Number<span className="mandtory-star">*</span>
                    </div>
                    {this.props.location.state ? (
                      <div className="col-md-4">
                        <input
                          onChange={e => this.changeHandler(e)}
                          type="text"
                          name="phonenumber"
                          className="form-control"
                          placeholder="Phone Number"
                          // disabled={true}
                          value={this.state.studDetails.phonenumber !== undefined ? this.state.studDetails.phonenumber.includes("undefined") ? "" : this.state.studDetails.phonenumber : ""}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="col-md-4">
                          <PhoneCode
                            onSelect={(code: any, name: any) => {
                              this.setState({
                                countrycode: code,
                                countryName: name
                              });
                              this.changePhoneNumberCode(code);
                            }}
                            defaultValue={
                              this.state.countrycode
                                ? this.state.countryName
                                : "Select Country"
                            }
                            className="form-control"
                          />
                        </div>

                        <div className="col-md-4">
                          <input
                            type="text"
                            name="phone"
                            className="form-control phone-no-width"
                            placeholder="Phone Number"
                            value={this.state.phone ? this.state.phone : ""}
                            onBlur={async () => {
                              let error = (await Validate.validatePhone(
                                this.state.phone,
                                "phonenumber"
                              )) as any;
                              if (error.phonenumber == "") {
                                let res = await commonService.verifyPhoneNumber(
                                  this.state.studDetails.phonenumber
                                );
                                if (res.status != 200) {
                                  error.phonenumber = res.msg;
                                }
                              }
                              this.setState(
                                Object.assign(this.state.errors, error.errors)
                              );
                            }}
                            onChange={e => this.changePhoneNumber(e)}
                          />
                          <div className="validation-error">
                            {this.state.errors.phonenumber &&
                            this.state.errors.phonenumber != ""
                              ? this.state.errors.phonenumber
                              : ""}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row mt-15 mb-15 info-head">
                    <h3 className="col-md-6  ">School/College Information</h3>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      School/College Name
                      <span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        name="collegeName"
                        className="form-control"
                        placeholder="School/College Name"
                        onBlur={async () => {
                          let error = await Validate.validateCollegeName(
                            this.state.studDetails.collegeName,
                            "collegeName"
                          );
                          this.setState(
                            Object.assign(this.state.errors, error.errors)
                          );
                        }}
                        value={
                          this.state.studDetails.collegeName
                            ? this.state.studDetails.collegeName
                            : ""
                        }
                        onChange={e => this.changeHandler(e)}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.errors.collegeName}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-15 mb-15 info-head">
                    <h3 className="col-md-6 ">Login Information</h3>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">Email</div>
                    <div className="col-md-8">
                      <input
                        type="email"
                        className="form-control"
                        id="copyPreviousclass"
                        aria-describedby="previousclass"
                        disabled={true}
                        value={this.state.studDetails.email}
                      />
                    </div>
                  </div>
                  {!this.props.location.state ||
                  (this.props.location.state &&
                    !this.props.location.state.id) ? (
                    <div>
                      <div className="row mt-10 mb-10">
                        <div className="col-md-4">
                          {" "}
                          Password<span className="mandtory-star">*</span>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="password"
                            autoComplete="new-password"
                            name="password"
                            className="form-control"
                            placeholder="Min 6 characters"
                            onBlur={async () => {
                              let error = await Validate.validatePassword(
                                this.state.studDetails.password,
                                "password"
                              );
                              this.setState(
                                Object.assign(this.state.errors, error.errors)
                              );
                            }}
                            value={
                              this.state.studDetails.password
                                ? this.state.studDetails.password
                                : ""
                            }
                            onChange={e => this.changeHandler(e, "password")}
                          />
                          <div className="validation-error">
                            {" "}
                            {this.state.errors.password}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-10 mb-10">
                        <div className="col-md-4">
                          {" "}
                          Confirm Password
                          <span className="mandtory-star">*</span>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="password"
                            autoComplete="new-password"
                            name="confirmpassword"
                            className="form-control"
                            placeholder="Min 6 characters"
                            onBlur={async () => {
                              let error = await Validate.confirmPassword(
                                this.state.studDetails.password,
                                this.state.studDetails.confirmpassword,
                                "confirmPassword"
                              );
                              this.setState(
                                Object.assign(this.state.errors, error.errors)
                              );
                            }}
                            value={
                              this.state.studDetails.confirmpassword
                                ? this.state.studDetails.confirmpassword
                                : ""
                            }
                            onChange={e => this.changeHandler(e, "password")}
                          />
                          <div className="validation-error">
                            {this.state.errors.confirmpassword}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mt-10 mb-10">
                    <div className="col-md-12 ">
                      {!authStore.user ? (
                        <form className="trading-rule-chk-box mt-10">
                          <label className="checkbox-inline">
                            <input
                              type="checkbox"
                              value=""
                              name="accept"
                              onChange={e => this.checkhandler(e)}
                              checked={this.state.checkAccept}
                              className="chk-box-reg"
                            />
                            <div className="checkLabel">
                              I accept the
                              <a
                                className="terms-conditions"
                                href={"./terms.html"}
                                target="_blank"
                              >
                                {" "}
                                Terms and Conditions
                              </a>{" "}
                              and Privacy Policy of this site
                            </div>
                          </label>
                          <div className="validation-error">
                            {" "}
                            {this.state.errors.checkError}
                          </div>
                        </form>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className="row mt-10 mb-10"
                    style={{ textAlign: "center" }}
                  >
                    {/* <button
                                            className="btn btn-primary next-btn btn-lg"
                                            onClick={() => this.validatePage()}
                                        >
                                            Submit
                    </button>
                     */}
                    <button
                      className="btn btn-primary  next-btn"
                      disabled={this.state.isSubmit}
                      onClick={() => {
                        this.setState({ isSubmit: true });
                        this.validatePage();
                      }}
                    >
                      {this.props.location.state && this.props.location.state.id
                        ? "Update"
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="step validation-error">
                All fields marked with an asterisk are required.
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
      </div>
    );
  }
}

export default withRouter(StudentReg);
