import React from 'react';
import { data } from '../Helpers/Constants/phoneCountryCode';


class PhoneCode extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            namecode:'',
            country: ''
        }
        this.renderOptions = this.renderOptions.bind(this);
    }

    componentDidMount() {
        const { showFirst } = this.props;
        if (showFirst && Array.isArray(showFirst) && showFirst.length && data[showFirst[0]]) {
            this.props.onSelect(data[showFirst[0]].code,data[showFirst[0]].name);
        }        
    }

    renderOptions() {
        const options = [];
        const map = {};

        options.push(
            <option key='--' disabled selected value="" placeholder="" className={this.props.optionClassName}>{this.props.defaultValue || ''}</option>
        );
        Object.keys(data).forEach(c => {
            if (!map[c]) {
                options.push(
                    <option key={c} value={data[c].code} placeholder={data[c].name}
                        className={this.props.optionClassName}
                        selected = {this.props.selected == c}
                    >{data[c].name}
                    </option>                 
                );
                
            }
        });
        return options;
    }

    countryNameSelect() {
        let country = '';
        const map = {};
        Object.keys(data).forEach(c => {
            if (!map[c]) {
                if(data[c].code == this.state.namecode) {
                    country = data[c].name;                    
                }
            }
        });
        return country;
    }

    render() {
        return (
            <select
                name={this.props.name }
                id={this.props.id}                 
                className={this.props.className}
                onChange={async (e) => {
                    await this.setState({namecode:e.target.value});
                    let countryName = await this.countryNameSelect();
                    this.props.onSelect(this.state.namecode,countryName)
                }}
            >
            {this.renderOptions()}                       
            </select>
        );
    }
    
}

export default PhoneCode;
