import React, { Component } from "react";
import { RouteComponentProps, Link, Redirect } from 'react-router-dom'
import 'jquery'
import ROUTES from "../../ROUTES"
declare var $: any
export interface TryProps extends RouteComponentProps { }

export interface TryState { }

export default class Tryout extends Component<TryProps, TryState> {

    render() {
        return (
            <div className="webgl-content">
            </div>
        )
    }
}