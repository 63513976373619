import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import productCurvyLinesImg from "../../productCurvyLines.png";
import professorImg from "../../undraw.png";
import professorSvg from "../../undraw.svg";
import cToSvg from "../../cto.svg";
import advisorSvg from "../../advisor.svg";
import { grey } from "@material-ui/core/colors";

export interface LeaderProps extends RouteComponentProps {}

export interface LeaderState {}

export default class Leadership extends Component<LeaderProps, LeaderState> {
  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8" }}
            className=" container registration-title"
          >
            About Us
          </div>
        </div>

        <div className="container">
          <p>
            <h5
              className="featurette-heading"
              style={{ marginTop: "20px", color: "grey" }}
            >
              Our Leadership team has the right blend of experts from Financial
              Markets, Technology, and Academic domains – skilled and committed
              to mold and execute our vision.
            </h5>
          </p>
          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-md-7">
              <h5 className="featurette-heading">
                Andrew Cohen - Creator and Founder
              </h5>
              <p className="">
                Mr. Cohen had a very profitable Wall Street career as a trader
                and market maker and then used his game theory skills to become
                a very successful card player. As an award-winning educational
                entrepreneur, he has combined all his skills and experience with
                the best elements from Trading, Market Making and game theory to
                create FutureTraders to make learning fun and exponentially
                speed up the learning curve.{" "}
              </p>
            </div>
            <div className="col-md-5">
              <img
                className="featurette-image img-responsive center-block"
                src={professorSvg}
                alt="Generic placeholder image"
              />
            </div>
          </div>
          <hr className="featurette-divider" />

          {/* <div className="row featurette">
            <div className="col-md-7 col-md-push-5">
              <h5 className="featurette-heading">
                Radhakrishna (RK) Renukunta - Chief Technology Officer
              </h5>
              <p className="">
                Mr. Renukunta helped to convert FutureTraders game from a
                concept prototype to a product and now furthering it into a
                multi-dimensional learning experience. He is also actively
                engaged in the architecture, design, and development of this
                integrated learning platform by applying his two decades of IT
                expertise in building highly scalable enterprise systems,
                growing from a Software Engineer to an Innovative Technologist.
              </p>
            </div>
            <div className="col-md-5 col-md-pull-7">
              <img
                className="featurette-image img-responsive center-block"
                src={cToSvg}
                alt="Generic placeholder image"
              />
            </div>
          </div> */}
          <div className="row featurette">
            <div className="col-md-7 col-md-push-5">
              <h5 className="featurette-heading">
                Mr. Mark T. Brookshire - Advisor
              </h5>
              <p className="">
                Mr. Mark T. Brookshire is the creator and founder of StockTrak.
                As an Industry Leader and Innovator since 1990, he led the
                continuous development of the original StockTrak that features
                simulated trading of options, futures, bonds and equities from
                25 exchanges around the world. StockTrak has now partnered with
                the leading financial publications and college textbook
                publishers worldwide as StockTrak grows globally.
                </p>
            </div>
            <div className="col-md-5 col-md-pull-7">
              <img
                className="featurette-image img-responsive center-block"
                src={cToSvg}
                alt="Generic placeholder image"
              />
            </div>
          </div>
          <hr className="featurette-divider" />

          {/* <div className="row featurette">
            <div className="col-md-7">
              <h5 className="featurette-heading">
                Mr. Mark T. Brookshire - Advisor
              </h5>
              <p className="">
                Mr. Mark T. Brookshire is the creator and founder of StockTrak.
                As an Industry Leader and Innovator since 1990, he led the
                continuous development of the original StockTrak that features
                simulated trading of options, futures, bonds and equities from
                25 exchanges around the world. StockTrak has now partnered with
                the leading financial publications and college textbook
                publishers worldwide as StockTrak grows globally.
              </p>
            </div>
            <div className="col-md-5">
              <img
                className="featurette-image img-responsive center-block"
                src={advisorSvg}
                alt="Generic placeholder image"
              />
            </div>
          </div> */}

          <hr className="featurette-divider" />
        </div>
      </div>
    );
  }
}
