import { Components } from "react";
import Config from "../config/Config";
import * as firebase from "../config/firebase";
import authStore from "../models/AuthStore";
import { Post, Get } from "./apiServices";
class StudentService {
  
  async createStudent(studentDetails) {
    console.log(studentDetails)
    delete studentDetails.classname;
    try {
      let response = await Post(
        Config.base_url + "/studentManager/create_student",
        authStore.authToken,
        JSON.stringify(studentDetails)
      );
      if (response) {
        if (response.status == 200) {
          return {
            status: 200,
            msg:
              "We have sent a verification link to your email address. Please click to validate your email address.",
          };
        } else if (response.status == 401) {
          return { status: 401, msg: "Unauthorized User" };
        } else if (response.status == 400) {
          let data = await response.json();
          if (data.code == "auth/invalid-phone-number") {
            return {
              status: 400,
              msg: "Please enter valid phone number in step 1",
            };
          } else if (data.code == "auth/phone-number-already-exists") {
            return { status: 400, msg: "Phone number already exist" };
          } else {
            return { msg: "Something went wrong please try again later" };
          }
        } else {
          return { msg: "Something went wrong please try again later" };
        }
      } else {
        return { msg: "Something went wrong please try again later" };
      }
    } catch (error) {
      return { msg: "Something went wrong please try again later" };
    }
  }

  async updateStudent(studentDetails) {
    try {
      let data = await Post(
        Config.base_url + "/studentManager/update_student",
        authStore.authToken,
        JSON.stringify(studentDetails)
      );
      return { status: 200, msg: "Profile Updated" };
    } catch (error) {
      return { status: 400, msg: "Failed" };
    }
  }

  async getStudentdetails(id) {
    try {
      let response = await Get(
        Config.base_url + "/studentManager/student_details",
        authStore.authToken
      );
      let studDetails = await response.json();

      var promises = studDetails.classid.map((id) => {
        return Post(
          Config.base_url + "/classManager/get_class_by_id",
          authStore.authToken,
          JSON.stringify({ classID: id })
        );
      });

      let students = await Promise.all(promises);
      students.forEach(function (element) {
        let classDetails = element.json();
        if (!studDetails.classname) {
          studDetails.classname = classDetails.classname;
        } else {
          studDetails.classname += ", " + classDetails.classname;
        }
      });
      return studDetails;
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong please try again later",
      };
    }
  }

  async getGameResultbyUser(id, classid) {
    try {
      let response = await Post(
        Config.base_url + "classManager/get_game_result_for_student",
        authStore.authToken,
        JSON.stringify({ uid: id, classid: classid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return {
          msg: "Failed",
          res: "Something went wrong.Please try again later",
          status: 400,
        };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400,
      };
    }
  }

  async getQuizResultbyUser(classid, userid) {
    try {
      let response = await Post(
        Config.base_url + "classManager/get_quiz_result_for_student",
        authStore.authToken,
        JSON.stringify({ classid: classid, uid: userid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400,
      };
    }
  }

  async getTopic(classid, topic, level) {
    try {
      let response = await Post(
        Config.base_url + "/studentManager/curriculum",
        authStore.authToken,
        JSON.stringify({ classID: classid, topic: topic, level: level })
      );
      let url = await response.json();
      let curriculum = await Get(url.url, authStore.authToken, {});
      let data = curriculum.json();
      console.log("data", data);
      return data;
    } catch (error) {
      console.log("Error: "+error)
      return false;
    }
  }
  
  async getContestTopic(contestid, topic, level) {
    // try {
    //   let response = await Post(
    //     Config.base_url + "/studentManager/curriculum",
    //     authStore.authToken,
    //     JSON.stringify({ classID: classid, topic: topic, level: level })
    //   );
    //   let url = await response.json();
    //   let curriculum = await Get(url.url, authStore.authToken, {});
    //   let data = await curriculum.json();
    //   return data;
    // } catch (error) {
    //   return false;
    // }
  }

  async getQuizResultStatusbyUser(classid) {
    try {
      let response = await Post(
        Config.base_url + "classManager/get_quiz_result_status_for_student",
        authStore.authToken,
        JSON.stringify({ classid: classid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400,
      };
    }
  }

  async getResultStatusbyUser(classid) {
    try {
      let response = await Post(
        Config.base_url + "classManager/get_game_status_for_student",
        authStore.authToken,
        JSON.stringify({ classid: classid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400,
      };
    }
  }

  async getResultStatusOfContestbyUser(contestid) {
    try {
      let response = await Post(
        Config.base_url +
          "contestManager/get_game_status_of_contest_for_student",
        authStore.authToken,
        JSON.stringify({ contestid: contestid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400,
      };
    }
  }

  async setInitialStateOfGameByStudentForContest(
    contestid,
    topic,
    level,
    initialCapital
  ) {
    console.log("authStore.authToken", authStore.authToken);
    try {
      let response = await Post(
        Config.base_url +
          "studentManager/set_initial_state_of_game_for_contest_v1",
        authStore.authToken,
        JSON.stringify({
          contestID: contestid,
          topic: topic,
          level: level,
          initialCapital: initialCapital,
        })
      );

      if (response.status == 200) {
        let data = await response.json();
        return { status: 200, result: data };
      } else {
        return { status: 400, result: "Error" };
      }
    } catch (error) {
      return { status: 400, result: "Error" };
    }
  }

  async getGameResultOfStudentForContest(id, contestid) {
    try {
      let response = await Post(
        Config.base_url + "contestManager/get_game_result_for_student",
        authStore.authToken,
        JSON.stringify({ uid: id, contestid: contestid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return {
          msg: "Failed",
          res: "Something went wrong.Please try again later",
          status: 400,
        };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400,
      };
    }
  }
}

const StudService = new StudentService();
export default StudService;
