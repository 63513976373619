import * as firebase from "../config/firebase";

class AuthStore {
  user = null;
  authToken = null;
  authListener = null;

  addListener(callbackListener) {
    // console.log(callbackListener);

    if (callbackListener) {
      this.authListener = callbackListener;
    }
  }

  async firebaseAuthListner() {
    await firebase.auth.onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        user.getIdToken().then(token => {
          this.authToken = token;
          this.user = this.decodeJwt(token);

          if (this.authListener) {
            // console.log(this.user);
            this.authListener({ user: this.user, token: this.authToken });
          }
        });
      } else {
        this.authToken = null;
        this.user = null;
        if (this.authListener) {
          this.authListener({ user: this.user, token: this.authToken });
        }
      }
    });
  }
  
  decodeJwt(token) {
    var payload = token.split(".")[1];
    var base64 = payload.replace("-", "+").replace("_", "/");
    return JSON.parse(atob(base64));
  }
}

const authStore = new AuthStore();
export default authStore;
