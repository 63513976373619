import { Component } from 'react'
import Config from '../config/Config'
import authStore from '../models/AuthStore'
import ClasService from './classService'
import { Post } from './apiServices';

class questionService {

    async getQuestions(id, topic, level) {
        try {
            let response = await Post(Config.base_url + "/studentManager/start_quiz", authStore.authToken, JSON.stringify({ classID: id, topic: topic, level: level }))

            if (response.status == 200) {
                let data = await response.json()
                return { status: 200, result: data }
            } else {
                return { status: 400, result: 'Error' }
            }
        }
        catch (error) {
            return { status: 400, result: 'Error' }
        }
    }

    async submitTest(classid, topic, level, result) {
        try {
            let response = await Post(Config.base_url + "/studentManager/end_quiz", authStore.authToken, JSON.stringify({ classID: classid, topic: topic, level: level, quiz: result }))

            if (response.status == 200) {
                let data = await response.json()
                return { status: 200, result: data }
            } else {
                return { status: 400, result: 'Error' }
            }
        } catch (error) {
            return { status: 400, result: 'Error' }
        }
    }

}
const qstnService = new questionService()
export default qstnService