import React from 'react';
import './cust_alert.scss';

export default ({ text, onClose }) => {
    return <div className="cust_alert">
        <div className="cust_alert_view">
            <div>
                <i class="fas fa-exclamation-triangle"></i>
                <label>Warning</label>
                <i class="fas fa-times" onClick={onClose}></i>
            </div>
            <div>{text}</div>
            <div></div>
        </div>
    </div>
}