import React, { Component, createRef } from "react";
import { Link, withRouter, RouteComponentProps, Route } from "react-router-dom";

import ROUTES from "../ROUTES";
import "jquery";
import AuthService from "../services/authService";
import LoadingWheel from "./LoadingWheel";
import commonService from "../services/commonService";
import PhoneCode from "./PhoneCode";
import ForgotPassword from "./ForgotPassword";
import EmailVerify from "./EmailVerification";
import Validate from "../Helpers/Utility/Validation";
import StudentSingleInvite from "../pages/Invite/StudentSingleInvite";
import MultipleInvite from "../pages/Invite/MultipleInvite";
import authStore from "../models/AuthStore";
import { InviteData } from "../interfaces";
import classService from "../services/classService";
import ProfService from "../services/profService";
import TeacherService from "../services/teacherService";
import ftlogo from "../logo.png";

declare var $: any;
export interface StudentInviteProps extends RouteComponentProps { }
export interface StudentInviteState { }

class StudentInvite extends Component<StudentInviteProps, StudentInviteState> {
  passwordInput = React.createRef<HTMLInputElement>();
  button = React.createRef<HTMLButtonElement>();
  state = {
    tab: "single",
    inviteDetails: {} as InviteData,
    errors: {} as any,

    email: "",
    phonenumber: "",
    password: "",
    error: "",
    validateError: {} as any,
    isEmailLogin: true,
    isOtpRecieve: false,
    otp: "",
    showLoader: false,
    loginNumber: "",
    countrycode: "+1",
    countryName: "United States",
    showResetPassword: false,
    isEmailVerify: false,
    classname: "",
    generateurl: true
  };
  // this.setState({ generateurl: true });
  msg = "";

  closeModal() {
    console.log("Closed");
    $("#studentInviteModal").modal("hide");
    this.setState({
      email: "",
      phonenumber: "",
      password: "",
      error: "",
      validateError: {},
      isEmailLogin: true,
      isOtpRecieve: false,
      otp: "",
      loginNumber: "",
      showLoader: false,
      showResetPassword: false,
      isEmailVerify: false,
      classname: ""
    });

    this.props.history.push({
      state: {
        generateurl: true,
        url: "",
        openedModal: false
      }
    });
    if (this.props.showInviteModelFromClassReg) {
      this.props.onClose();
    }
  }
  _handleDocumentClick() {
    // console.log("_handleDocumentClick");
    this.setState({ email: "" });

    this.props.history.push({
      state: {
        generateurl: true,
        url: "",
        openedModal: false,
        email: ""
      }
    });
    this.props.history.push(ROUTES.CLASSLIST)
  }
  componentDidMount() {
    if (this.props.showInviteModelFromClassReg) {
      $("#studentInviteModal").modal('show');
    }
    $("#studentInviteModal").on("hidden.bs.modal", (e: any) =>
      this._handleDocumentClick()
    );
  }
  componentDidUpdate() { }

  handleLoader(value: boolean) {
    this.setState({ showLoader: value });
  }

  async changeHandler(e: any) {
    let stateObject = {} as any;
    stateObject[e.target.name] = e.target.value;
    if (e.target.name == "classname") {
      // let data = await this.state.classes.filter(item => {
      //   if (item.classname && item.classname.trim() == e.target.value.trim()) {
      //     this.state.errors.classname = "";
      //     this.setState({ errors: this.state.errors });
      //     return item;
      //   }
      // });
    }
    if (e.target.name == "email") {
      if (e.target.value.trim()) {
        this.setState({ email: e.target.value.trim() });
      }
    }
    stateObject["id"] = this.props.location.state
      ? this.props.location.state.id
      : null;
    stateObject["classname"] = this.props.location.state
      ? this.props.location.state.classname
      : null;
    // console.log("stateObject[id]" + stateObject["id"]);

    this.setState(Object.assign(this.state.inviteDetails, stateObject));
    this.setState({ generateurl: true });
  }
  async sendMultipleInvitesForMultipleEmails() {
    // console.log("sendMultipleInvitesForMultipleEmails");
    this.setState({ showLoader: true });
    if (authStore.user.role == "PROFESSOR") {
      let res = await ProfService.sendMultipleInvitesForMultipleEmails(
        this.state.inviteDetails.email,
        this.state.inviteDetails.id
      );
      await this.setState({ showLoader: false }, () => (this.msg = res.msg));
      await this.setState({ generateurl: true }, () => (this.msg = res.msg));
    } else {
      let res = await TeacherService.sendMultipleInvitesForMultipleEmails(
        this.state.inviteDetails.email,
        this.state.inviteDetails.id
      );
      await this.setState({ showLoader: false }, () => (this.msg = res.msg));
      await this.setState({ generateurl: true }, () => (this.msg = res.msg));
    }
  }

  async validateForm() {
    this.setState({ showLoader: true });
    let formvalid = true;
    const errors: { [index: string]: any } = {};
    let inviteError;

    // inviteError = await Validate.validateSelect(
    //   this.state.inviteDetails.classname,
    //   "classname",
    //   "a class"
    // );
    // await this.setState(Object.assign(this.state.errors, inviteError.errors));
    // if (inviteError.isValid == false) {
    //   formvalid = false;
    // }

    inviteError = await Validate.validateEmails(
      this.state.inviteDetails.email,
      "email",
      "addclass"
    );
    await this.setState(Object.assign(this.state.errors, inviteError.errors));
    if (inviteError.isValid == false) {
      formvalid = false;
    }

    this.setState({ showLoader: false });
    this.setState({ email: this.state.inviteDetails.email });
    if (formvalid == true) {
      // await this.notifySuccess();
      // console.log("notifySuccess()");

      this.sendMultipleInvitesForMultipleEmails();
      this.setState({ generateurl: false });

      await this.props.history.push({
        state: {
          generateurl: false,
          url: ROUTES.CLASSLIST,
          openedModal: true,
          studentEmail: this.state.inviteDetails.email,
          email: this.state.inviteDetails.email,
          classname: this.state.inviteDetails.classname
        }
      });
    } else {
    }
  }

  render() {
    const classID = "";
    return (
      <div
        className="modal fade"
        id="studentInviteModal"
        role="dialog"
        aria-labelledby="studentInviteModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog student-invite-modal-dialog"
          role="document"
        >
          {this.state.classname}
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#3f3d56", display:'flex', alignItems:'center' }}
            >
              <img
                        style={{marginTop:6, marginRight:0, opacity:0.8, marginBottom:4}}
                        src={ftlogo}
                        width="33px"
                        height="25px"
                        alt="image"
                      />
              <h2 className="pad0000" style={{ marginLeft:'auto', marginRight:'auto', paddingRight:17 }}>
                Invite Students For{" "}
                {this.props.location.state
                  ? this.props.location.state.classname
                  : null}{" "}
              </h2>
              <button
                  style={{ color: "#fff" }}
                  type="button"
                  className="close "
                  aria-label="Close"
                  onClick={() => this.closeModal()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="section ">
              <div className="container">
                <div className="col-md-7 reg-card">
                  <div className=""></div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      {" "}
                      Student Email(s)<span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <textarea
                        id="studentEmails"
                        name="email"
                        className="form-control"
                        placeholder="Student email(s)"
                        onChange={async e => {
                          this.changeHandler(e);
                          // console.log("e.value", e.target.value);
                        }}
                        value={this.state.email}
                      ></textarea>
                      {/* {console.log(
                        "this.props.location.state" + this.props.location.state
                      )} */}
                      <div className="popup-note">
                        Enter multiple emails separated by commas
                      </div>
                      <div className="validation-error">
                        {" "}
                        {this.state.errors.email}{" "}
                      </div>
                    </div>
                  </div>
                  {this.props.location.state ? (
                    !this.props.location.state.generateurl ? (
                      <p className="popup-note">
                        Your request has been queued. Invitation(s) will be sent
                        out shortly.
                      </p>
                    ) : null
                  ) : null}

                  {this.props.location.state ? (
                    this.props.location.state.generateurl ? (
                      <button
                        className="btn btn-primary pull-right next-btn"
                        onClick={() => {
                          this.validateForm();
                        }}
                      >
                        Send Invite
                      </button>
                    ) : null
                  ) : null}
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
      </div>
    );
  }
}

export default withRouter(StudentInvite);
