import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import clasService from "../../services/classService";
import * as queryString from "query-string";
import ROUTES from "../../ROUTES";
import LoadingWheel from "../../components/LoadingWheel";
import authStore from "../../models/AuthStore";
import ProfService from "../../services/profService";
import commonService from "../../services/commonService";
import Validate from "../../Helpers/Utility/Validation";
import envelopeSvg from "../../envelope.svg";

export interface InviteProps extends RouteComponentProps { }

export interface InviteState { }

class AddClass extends Component<InviteProps, InviteState> {
  state = {
    successMsg: "",
    showLoader: true,
    showUpdateLoader: false,
    askEmail: false,
    email: "",
    username: "",
    classID: "",
    validMsg: "",
    validMsg1: "",
    classname: "",
    academic: "",
    showErrorMessage: false,
    errorMsg: "",
    isValid: false
  };

  async componentDidMount() {
    let params = await queryString.parse(this.props.location.search);
    if (params.guid) {
      this.Invite(params);
    } else {
      if (params.id) {
        let data = await clasService.getClassbyId(params.id);
        if (data.status == 404) {
          this.setState({ showLoader: false, errorMsg: data.msg });
        } else {
          if(new Date() > new Date(data.data.regEndDate)){
            this.setState({ showLoader: false, errorMsg: "Registration window closed!" });
          }else{
          this.setState({
            classname: data.data.classname,
            askEmail: true,
            showLoader: false,
            classID: params.id,
            academic: data.data.academic
          });
          }
        }
      } else {
        this.setState({
          showLoader: false,
          errorMsg: "Make sure the URL is correct and try again."
        });
      }
    }
  }

  async Invite(params: any) {
    this.setState({ showErrorMessage: false });
    if (!this.state.showLoader) {
      this.setState({ showUpdateLoader: true });
    }
    let response;

    if ("username" in params) {
      params['email'] = `${params.username}@gmail.com`;
      delete params['username'];
    }

    // params['email'] = params['email'].toLowerCase();

    response = await clasService.registerClass(params);

    if (response.status === 200) {
      if(new Date() > new Date(response.res.regEndDate)){
        this.setState({errorMsg: "Registartion window closed!", showLoader: false});
      }else{
        await this.setState({ successMsg: response.res });
        this.setState({ showLoader: false, showUpdateLoader: false });
      }
    } else if (response.status === 404) {
      if(new Date() > new Date(response.res.regEndDate)){
        this.setState({errorMsg: "Registartion window closed!", showLoader: false});
      }else{
        this.setState({ showLoader: false, showUpdateLoader: false });

      if (!authStore.user || authStore.user.user_id == response.res.inviteEmail) {
          if (this.state.username.length > 0) {
            this.props.history.push({
              pathname: ROUTES.STUDENT_INVITATION,
              state: {
                classname: response.res.classname,
                username: response.res.inviteEmail.split("@")[0]
              }
            });
          } else {
            this.props.history.push({
              pathname: ROUTES.STUDENT_INVITATION,
              state: {
                classname: response.res.classname,
                email: response.res.inviteEmail
              }
            });
          }
      } else {
        this.setState({ showLoader: false, showUpdateLoader: false });
      }
      }
    } else if (response.status == 400) {
      if (response.res == "Invalid invite link") {
        this.setState({
          errorMsg: response.msg,
          showLoader: false,
          showUpdateLoader: false
        });
      } else {
        this.setState({ showLoader: false, showUpdateLoader: false });
      }
    } else if (response.status == 402) {
      this.setState({ errorMsg: response.msg, showLoader: false });
    } else {
      this.setState({
        successMsg: response.res,
        showLoader: false,
        showUpdateLoader: false
      });
    }
  }

  async validateEmail() {
    if ((this.state.academic === "High School" || this.state.academic === "Middle School") && this.state.username.length === 0) {
      this.setState({ validMsg1: "Enter valid username" });
    } else if (this.state.academic != "High School" && this.state.academic != "Middle School" && this.state.email.length === 0) {
      alert('here');
      this.setState({ validMsg: "Enter valid email" });
    } else {

      if (this.state.username.length > 0) {//this.state.academic === "High School" || this.state.academic === "Middle School" 
        let valid = (await Validate.validateEmail(`${this.state.username}@gmail.com`, "email", "addclass")) as any;
        await this.setState({ validMsg1: valid.errors.email });
        if (valid.isValid) {
          this.Invite({ username: this.state.username.toLowerCase(), classID: this.state.classID });
        }
      }
      //email login
      else {
        let valid = (await Validate.validateEmail(
          this.state.email,
          "email",
          "addclass"
        )) as any;
        await this.setState({ validMsg: valid.errors.email });
        if (valid.isValid) {
          this.Invite({ email: this.state.email, classID: this.state.classID });
        }
      }
    }
  }

  showSuccessMsg() {
    return (
      <div className="invite-wrapper">
        <h2 className="invite-response-text">{this.state.successMsg}</h2>
      </div>
    );
  }

  showLogoutMsg() {
    return (
      <div className="invite-wrapper">
        <h2 className="invite-response-text">
          Please logout and revisit the link
        </h2>
      </div>
    );
  }

  showLimitExceedMsg() {
    return <div className="display-error">{this.state.errorMsg} </div>;
  }
  
  renderRegistrationForm() {
    return (
      <div className="container cmt20" style={{ paddingBottom: 40 }}>
        <div className="row equal">
          <div
            className="col-md-8 reg-card col-md-offset-2 "
            style={{ paddingTop: "20px" }}
          >
            <div className="wizard" id="myWizard" style={{ paddingTop: "5vh" }}>
              <div className="step" style={{ textAlign: "center" }}>
                <img src={envelopeSvg} width="320" height="auto" alt="" />
                <div
                  className="row mt-15 mb-15 info highlight"
                  style={{ paddingTop: "12px" }}
                >
                  <h2 className="col-md-12 " style={{ textAlign: "center" }}>
                    Class Registration for {this.state.classname}
                  </h2>
                  <h3>{this.state.academic === "High School" || this.state.academic === "Middle School" ? "Please enter your username and press submit to Register" : "Please enter your email and press submit to Register"} </h3>
                </div>
              </div>
              {
                this.state.academic === "High School" || this.state.academic === "Middle School" ?
                  <div className="row mt-10 mb-10">
                    <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', flexDirection:'column' }}>
                      <input
                        style={{ fontSize: 14, height: 40, width: 250 }}
                        type="username"
                        name="username"
                        className="form-control input-lg"
                        placeholder="Username"
                        value={this.state.username ? this.state.username : ""}
                        onChange={e => this.setState({ username: e.target.value })}
                      />
                      <div className="validation-error"> {this.state.validMsg1}</div>
                    </div>
                  </div> :
                  <div className="row mt-10 mb-10">
                    <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', flexDirection:'column' }}>
                      <input
                        style={{ fontSize: 14, height: 40, width: 250 }}
                        type="email"
                        name="email"
                        className="form-control input-lg"
                        placeholder="Email"
                        value={this.state.email ? this.state.email : ""}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                      <div className="validation-error"> {this.state.validMsg}</div>
                    </div>
                  </div>
              }
              <div className="row mt-10 mb-10 text-center">
                {" "}
                <button
                  style={{ marginTop: 16 }}
                  className="btn btn-primary  next-btn"
                  onClick={() => this.validateEmail()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showUpdateLoader ? <LoadingWheel /> : null}
      </div>
    );
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8" }}
            className=" container registration-title"
          >
            {/* {"Registration by Professor Invite"} */}
          </div>
        </div>
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : this.state.errorMsg && this.state.errorMsg != "" ? (
          this.showLimitExceedMsg()
        ) : this.state.successMsg != "" ? (
          this.showSuccessMsg()
        ) : !this.state.showErrorMessage ? (
          this.renderRegistrationForm()
        ) : (
                  this.showLogoutMsg()
                )}
        <div className="label-detail">
          {" "}
          {/* Allows user to register for the invited class by providing his email */}
        </div>
      </div>
    );
  }
}

export default withRouter(AddClass);
