import React from 'react';
import './showActiveUsers.scss';
import ftlogo from "../logo.png";

export default ({ onClose, activeStudentsShowClass }) => {
    const getActiveSince = (timeStamp) => {
        let now = new Date().getTime()
        let since = new Date(timeStamp).getTime();
        let time = now - since;
        let minute = 60 * 1000;
        return Math.ceil(time/minute) + "mins ago"
    }

    return <div className="showActiveUsers">
        <div>
            {/* title */}
            <div>
                <img
                    src={ftlogo}
                    width="33px"
                    height="25px"
                    alt="image"
                />
                <label>Active Students</label>
                <label onClick={onClose}>X</label>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>#</th>
                            <th style={{ textAlign: 'center' }}>Name</th>
                            <th style={{ textAlign: 'center' }}>Active Since</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            activeStudentsShowClass !== null ? activeStudentsShowClass.activeStudents.map((x, i) => {
                                return <tr key={i}>
                                    <td style={{ textAlign: 'center' }}>{i + 1}</td>
                                    <td style={{ textAlign: 'center' }}>{x.name}</td>
                                    <td style={{ textAlign: 'center' }}>{getActiveSince(x.timestamp)}</td>
                                </tr>
                            }) : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}