import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ContestDetails } from "../../interfaces";
import ROUTES from "../../ROUTES";
import studentService from "../../services/studentService";
import contestService from "../../services/contestService";
import "./ContestList.css";
import Toast from "../../components/Toast";
import "jquery";
import DeleteConfirm from "../Contest/DeleteConfirm";
import authStore from "../../models/AuthStore";
import LoadingWheel from "../../components/LoadingWheel";
import CreateContest from "./components/CreateContest";
import * as queryString from "query-string";
import config from '../../config';

// models
import Alert from '../../models/cust_alert'

declare var $: any;
export interface contestProps extends RouteComponentProps { }

export interface contestState { }

class contestList extends Component<contestProps, contestState> {
  notifySuccess = () => Toast.success();
  notifyError = () => Toast.error();
  yearList = [] as any;
  state = {
    contests: Array<ContestDetails>(),
    invitedContests: Array<ContestDetails>(),
    id: "",
    showLoader: true,
    selectedYear: new Date().getFullYear(),
    studWiseresult: false,

    currentUser: null,
    collapsed: true,
    contestname: "",

    // alert
    toggleAlert: false,
    warningText: ""
  };

  addnewContest() {
    this.props.history.push(ROUTES.CONTESTREG);
  }

  getContests() {
    contestService
      .getAllContestsForProfessor()
      .then(res => {
        this.setState({ contests: res.data, showLoader: false });
      })
      .catch(error => { });
  }

  getUsercontests() {
    contestService
      .getAllContestsForStudent()
      .then(res => {
        this.setState({ contests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUsercontestsForPlayer() {
    contestService
      .getAllContestsForPlayer()
      .then(res => {
        this.setState({ contests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUserInvitedcontestsForPlayer() {
    contestService
      .getAllInvitedContestsForPlayer()
      .then(res => {
        this.setState({ invitedContests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }
  async componentDidMount() {
    let params = await queryString.parse(this.props.location.search);

    this.setState({ showLoader: true });

    if (authStore.user.role == "PROFESSOR") {
      this.getContests();
    } else if (authStore.user.role == "STUDENT") {
      this.getUsercontests();
    } else {
      this.getUsercontestsForPlayer();
      this.getUserInvitedcontestsForPlayer();
    }
    for (let i = 0; i < 4; i++) {
      this.yearList.push(this.state.selectedYear + i);
    }
  }
  // async  deleteClass(id: any) {
  //     if (authStore.user.role == 'PROFESSOR') {
  //         this.setState({ id: id }, () => {
  //             $("#classDeleteForm").modal("show");
  //         })
  //     } else {

  //     }
  // }

  editContest({ id, tradeEndDate }: any) {
    if (new Date() > new Date(tradeEndDate)) {
      alert("Participation ends. Can't edit this contest")
    } else {
      this.props.history.push({
        pathname: ROUTES.CONTESTREG,
        state: { id: id }
      });
    }
  }

  convertDate(str: any) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, month, date.getFullYear()].join("-");
  }

  showTopic(contestname: any) {
    this.props.history.push({ pathname: ROUTES.TOPIC, state: contestname });
  }
  showContestTopic(contestname: any) {
    alert("here")
    this.props.history.push({
      pathname: ROUTES.CONTEST_TOPIC,
      state: contestname
    });
  }

  async setYearFilter(e: any) {
    await this.setState({ selectedYear: e.target.value });
  }
  showModal() {
    $("#contestStudentInviteModal").modal("show");
  }
  divStyle = {
    "margin-top": "10px"
  };

  getCardStatus = (item: any) => {
    let { regStartDate, regEndDate, tradeStartDate, tradeEndDate } = item;
    let regStart = new Date(regStartDate);
    let regEnd = new Date(regEndDate);;
    let tradeStart = new Date(tradeStartDate);
    let tradeEnd = new Date(tradeEndDate);;
    let today = new Date();
    if (today > regEndDate) {
      return "Status: New"
    } else if (regEnd > today && tradeEnd > today) {
      return "Status: In Progress"
    } else {
      return "Status: Closed"
    }
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          {" "}
          <div
            className="container   registration-title"
            style={{ backgroundColor: "#1a73e8" }}
          >
            {!(this.props.location.search == "?type=invited")
              ? "Hosted Contests"
              : "Participating Contests"}
          </div>
        </div>
        <div className="container cmt20">
          {/* {this.state.classes && this.state.classes.length > 0 ? (
            <div className="form-group year-filter">
              <select
                className="form-control"
                onChange={e => this.setYearFilter(e)}
              >
                {this.yearList.map((item: any) => {
                  return item == this.state.selectedYear ? (
                    <option selected>{item}</option>
                  ) : (
                    <option>{item}</option>
                  );
                })}
              </select>
            </div>
          ) : null} */}
          <div className="row">
            <div className="col-md-12 reg-card reg-card-transparent-bg reg-card-r-br-0">
              {/* {authStore.user.role == "PROFESSOR" ||
              authStore.user.role == "PLAYER" ? (
                <div className=" row">
                  <div className="col-md-3 mt-20 ">
                    <a href="#" onClick={() => this.addnewContest()}>
                      <div
                        style={{ minHeight: "128px" }}
                        className="card text-center "
                      >
                        <div className="card-body add-new-class" id="card">
                          <span className="add-class-button">
                            <i className="fas fa-plus  fa-2x"></i>
                          </span>
                          <span className="add-class">Create Contest</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ) : null} */}
              {this.props.location.search == "?type=invited" ? null : (
                <CreateContest></CreateContest>
              )}
              <div className="row" style={{ marginTop: "10px" }}></div>
              {this.props.location.search == "?type=invited" ? (
                <div className="class-container row ">
                  <div className="row" style={{ marginTop: "10px" }}></div>

                  <div className="class-container row" style={authStore.user.role == "STUDENT" ? { marginTop: 50 } : {}}>
                    {this.state &&
                      this.state.invitedContests &&
                      this.state.invitedContests.map(item => {
                        if (
                          item.regStartDate
                            ? new Date(item.regStartDate).getFullYear() ==
                            this.state.selectedYear
                            : false || item.regEndDate
                              ? new Date(item.regEndDate).getFullYear() ==
                              this.state.selectedYear
                              : false
                        ) {
                          return (
                            <div
                              className="col-md-3 mt-20">
                              {authStore.user.role == "PROFESSOR" ? (
                                <div
                                  style={{ height: 40 }}
                                  className="card-footer card-invite-btn text-muted text-center"
                                  // onClick={e => {
                                  //   e.preventDefault();
                                  //   e.stopPropagation();
                                  //   this.props.history.push({
                                  //     pathname: ROUTES.DASHBOARD,
                                  //     state: item.classname
                                  //   });
                                  // }}
                                  onClick={() => {
                                    this.setState({
                                      collapsed: !this.state.collapsed
                                      // state: {
                                      // classname: item.classname,
                                      // id: item.id
                                      // }
                                    });
                                    this.props.history.push({
                                      pathname: "",
                                      state: {
                                        id: item.id,
                                        contestname: item.contestname,
                                        generateurl: true,
                                        openedModal: true,
                                        url: ""
                                      }
                                    });
                                    this.showModal();
                                  }}
                                // onClick={e => {
                                //   e.preventDefault();
                                //   e.stopPropagation();

                                // }}
                                >
                                  <div className="col a-right">
                                    <i className="fas fa-user-plus fa-1x"></i>
                                    &nbsp; {"Invite Student"}
                                  </div>
                                </div>
                              ) : (
                                  ""
                                )}
                              {authStore.user.role == "PLAYER" &&
                                !(
                                  this.props.location.search == "?type=invited"
                                ) ? (
                                  <div
                                    className="card-footer card-invite-btn text-muted text-center"
                                    // onClick={e => {
                                    //   e.preventDefault();
                                    //   e.stopPropagation();
                                    //   this.props.history.push({
                                    //     pathname: ROUTES.DASHBOARD,
                                    //     state: item.classname
                                    //   });
                                    // }}
                                    onClick={() => {
                                      let today = new Date();
                                      let { regEndDate } = item;
                                      let regEnd = new Date(regEndDate !== undefined ? regEndDate : "");
                                      if (today > regEnd) {
                                        this.setState({
                                          collapsed: !this.state.collapsed
                                        });
                                        this.props.history.push({
                                          pathname: "",
                                          state: {
                                            id: item.id,
                                            contestname: item.contestname,
                                            generateurl: true,
                                            openedModal: true,
                                            url: ""
                                          }
                                        });
                                        this.showModal();
                                      } else {
                                        alert("Registration window closed!");
                                      }
                                    }}
                                  >
                                    <div className="col a-right">
                                      <i className="fas fa-user-plus fa-1x"></i>
                                    &nbsp; {"Invite Participant(s)"}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              <div
                                className="card text-center card-body-btn"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  authStore.user.role == "PROFESSOR"
                                    ? this.editContest(item)
                                    : authStore.user.role == "STUDENT" ||
                                      authStore.user.role == "PLAYER"
                                      ? this.showContestTopic(item.contestname)
                                      : authStore.user.role == "PLAYER"
                                        ? this.editContest(item)
                                        : this.showContestTopic(item.contestname)
                                }
                              >
                                <div className="card-body" id="card">
                                  <h4 className="card-title">
                                    {item.contestname}<i style={{ paddingLeft: 20, fontSize: 15, color: 'rgba(0,0,0,0.9)' }} className="fas fa-edit"></i>
                                  </h4>
                                  <label style={{ fontSize: 13, opacity: 0.6 }}>{this.getCardStatus(item)}</label>

                                  <div className="dates">
                                    <strong>{"REGISTRATION DATES"}</strong>

                                    <div className="start">
                                      <strong>STARTS</strong>
                                      {this.convertDate(item.regStartDate)}
                                    </div>
                                    <div className="ends">
                                      <strong>ENDS</strong>
                                      {this.convertDate(item.regEndDate)}
                                    </div>
                                  </div>

                                  {authStore.user.role == "PROFESSOR" ? (
                                    <div className="dates">
                                      <strong>TOTAL CAPACITY</strong>
                                      {item.noofparticipants}
                                    </div>
                                  ) : null}
                                  {authStore.user.role == "PLAYER" ? ( //PLAYER AND CONTEST HOST
                                    <div className="dates">
                                      <strong>TOTAL CAPACITY</strong>
                                      {item.noofparticipants}
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  className="card-footer text-muted"
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.history.push({
                                      pathname: ROUTES.CONTEST_DASHBOARD,
                                      state: item.contestname
                                    });
                                  }}
                                >
                                  <div className="col a-right">
                                    <i className="fas fa-compass fa-1x"></i>
                                    &nbsp;{" "}
                                    {authStore.user.role == "PROFESSOR"
                                      ? "Contest Dashboard"
                                      : ""}
                                    {authStore.user.role == "STUDENT"
                                      ? "Performance Dashboard"
                                      : ""}
                                    {authStore.user.role == "PLAYER" //player and contest host
                                      ? "Contest Dashboard"
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return <p>No Contests</p>;
                        }
                      })}
                  </div>
                </div>
              ) : (
                  <div className="class-container row">
                    {this.state &&
                      this.state.contests &&
                      this.state.contests.map(item => {
                        if (
                          item.regStartDate
                            ? new Date(item.regStartDate).getFullYear() ==
                            this.state.selectedYear
                            : false || item.regEndDate
                              ? new Date(item.regEndDate).getFullYear() ==
                              this.state.selectedYear
                              : false
                        ) {
                          return (
                            <div className="col-md-3 mt-20">
                              {authStore.user.role == "PROFESSOR" ? (
                                <div
                                  className="card-footer card-invite-btn text-muted text-center"
                                  // onClick={e => {
                                  //   e.preventDefault();
                                  //   e.stopPropagation();
                                  //   this.props.history.push({
                                  //     pathname: ROUTES.DASHBOARD,
                                  //     state: item.classname
                                  //   });
                                  // }}
                                  onClick={() => {
                                    this.setState({
                                      collapsed: !this.state.collapsed
                                      // state: {
                                      // classname: item.classname,
                                      // id: item.id
                                      // }
                                    });
                                    this.props.history.push({
                                      pathname: "",
                                      state: {
                                        id: item.id,
                                        contestname: item.contestname,
                                        generateurl: true,
                                        openedModal: true,
                                        url: ""
                                      }
                                    });
                                    this.showModal();
                                  }}
                                // onClick={e => {
                                //   e.preventDefault();
                                //   e.stopPropagation();

                                // }}
                                >
                                  <div className="col a-right">
                                    <i className="fas fa-user-plus fa-1x"></i>
                                  &nbsp; {"Invite Student"}
                                  </div>
                                </div>
                              ) : (
                                  ""
                                )}
                              {authStore.user.role == "PLAYER" ? (
                                <div
                                  className="card-footer card-invite-btn text-muted text-center"
                                  // onClick={e => {
                                  //   e.preventDefault();
                                  //   e.stopPropagation();
                                  //   this.props.history.push({
                                  //     pathname: ROUTES.DASHBOARD,
                                  //     state: item.classname
                                  //   });
                                  // }}
                                  onClick={() => {
                                    this.setState({
                                      collapsed: !this.state.collapsed
                                      // state: {
                                      // classname: item.classname,
                                      // id: item.id
                                      // }
                                    });
                                    this.props.history.push({
                                      pathname: "",
                                      state: {
                                        id: item.id,
                                        contestname: item.contestname,
                                        generateurl: true,
                                        openedModal: true,
                                        url: ""
                                      }
                                    });
                                    this.showModal();
                                  }}
                                // onClick={e => {
                                //   e.preventDefault();
                                //   e.stopPropagation();

                                // }}
                                >
                                  <div className="col a-right">
                                    <i className="fas fa-user-plus fa-1x"></i>
                                  &nbsp; {"Invite Participant(s)"}
                                  </div>
                                </div>
                              ) : (
                                  ""
                                )}
                              <div
                                className={
                                  authStore.user.role == "PROFESSOR"
                                    ? "card text-center card-body-btn "
                                    : authStore.user.role == "STUDENT"
                                      ? "card text-center card-body-btn student-card-btn"
                                      : authStore.user.role == "PLAYER"
                                        ? "card text-center card-body-btn "
                                        : ""
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  authStore.user.role == "PROFESSOR"
                                    ? this.editContest(item)
                                    : authStore.user.role == "STUDENT"
                                      ? this.showContestTopic(item.contestname)
                                      : authStore.user.role == "PLAYER" // PLAYER AND HOST
                                        ? this.editContest(item)
                                        : this.showContestTopic(item.contestname)
                                }
                              >
                                <div className="card-body" id="card">
                                  <h4 className="card-title">
                                    {item.contestname}<i style={{ paddingLeft: 20, fontSize: 15, color: 'rgba(0,0,0,0.9)' }} className="fas fa-edit"></i>
                                  </h4>
                                  <label style={{ fontSize: 13, opacity: 0.6 }}>{this.getCardStatus(item)}</label>

                                  <div className="dates">
                                    <strong>{"REGISTRATION DATES"}</strong>

                                    <div className="start">
                                      <strong>STARTS</strong>
                                      {this.convertDate(item.regStartDate)}
                                    </div>
                                    <div className="ends">
                                      <strong>ENDS</strong>
                                      {this.convertDate(item.regEndDate)}
                                    </div>
                                  </div>
                                  {/* <div className="dates">
                              <strong >{"EXAM DATES"}</strong>

                                <div className="start">
                                  <strong>STARTS</strong>
                                  {this.convertDate(item.regStartDate)}
                                  <span></span>
                                </div>
                                <div className="ends">
                                  <strong>ENDS</strong>
                                  {this.convertDate(item.regEndDate)}
                                </div>
                              </div> */}
                                  {/* {authStore.user.role == "PROFESSOR" ? (
                                <div className="dates">
                                  <strong>{"PLAYERS"}</strong>
                                  <div className="endss">
                                    <strong>Students</strong>
                                    {item.noofstudents}
                                  </div>
                                  <div className="start">
                                    <strong>REGISTERED</strong>
                                    {item.noofplayers}
                                    <span></span>
                                  </div>
                                  <div className="ends">
                                    <strong>TOTAL</strong>
                                    {item.noofplayers}
                                  </div>
                                </div>
                              ) : null} */}
                                  {authStore.user.role == "PROFESSOR" ? (
                                    <div className="dates">
                                      <strong>TOTAL CAPACITY</strong>
                                      {item.noofparticipants}
                                    </div>
                                  ) : null}
                                  {authStore.user.role == "PLAYER" ? ( //PLAYER AND CONTEST HOST
                                    <div className="dates">
                                      <strong>TOTAL CAPACITY</strong>
                                      {item.noofparticipants}
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  className="card-footer text-muted"
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.history.push({
                                      pathname: ROUTES.CONTEST_DASHBOARD,
                                      state: item.contestname
                                    });
                                  }}
                                >
                                  <div className="col a-right">
                                    <i className="fas fa-compass fa-1x"></i>
                                  &nbsp;{" "}
                                    {authStore.user.role == "PROFESSOR"
                                      ? "Contest Dashboard"
                                      : ""}
                                    {authStore.user.role == "STUDENT"
                                      ? "Performance Dashboard"
                                      : ""}
                                    {authStore.user.role == "PLAYER" //player and contest host
                                      ? "Contest Dashboard"
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return <p>No Contests</p>;
                        }
                      })}
                  </div>
                )}
              {/* classcontiner */}
            </div>
          </div>

          {Toast.successToastView()}
          {Toast.errorToastView()}
          <DeleteConfirm id={this.state.id} getContests={this.getContests} />
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}

        {this.state.toggleAlert ? <Alert text={this.state.warningText} onClose={() => this.setState(st => ({ ...st, toggleAlert: false }))} /> : null}
      </div>
    );
  }
}
export default withRouter(contestList);
