import React, { useState, useEffect } from 'react';
import './classCreated.scss';
import $ from 'jquery';
import ROUTES from '../ROUTES';
import ProfService from '../services/profService';
import TeacherService from '../services/teacherService';
import LoadingWheel from '../components/LoadingWheel';
import ftlogo from "../logo.png";

export default ({ onClose, data, history, authStore }) => {

    const [{ inviteDetails }, setState] = useState({ inviteDetails: { 'email': '', 'id': '', 'className': '' }, generateurl: false });
    const [showLoader, toggleShowLoader] = useState(false);

    const changeHandler = async (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let stateObject = {};
        stateObject[name] = value;
        if (name == "email") {
            if (value.trim()) {
                setState(st => ({ ...st, email: value.trim() }));
            }
        }
        stateObject["id"] = data.id;
        stateObject["classname"] = history.state ? data.classname : null;

        setState(st => ({ ...st, inviteDetails: stateObject }));
        setState(st => ({ ...st, generateurl: true }));
    }

    const sendInvitationsButtonClicked = async () => {
        if (inviteDetails.email.trim().includes('@') && inviteDetails.email.trim().includes(".")) {
            toggleShowLoader(true);
            sendMultipleInvitesForMultipleEmails();
            setState(st => ({ ...st, generateurl: false }));

            await history.push({
                state: {
                    generateurl: false,
                    url: ROUTES.CLASSLIST,
                    openedModal: true,
                    studentEmail: inviteDetails.email,
                    email: inviteDetails.email,
                    classname: inviteDetails.classname
                }
            });
        }
    }

    const sendMultipleInvitesForMultipleEmails = async () => {
        if (authStore.user.role == "PROFESSOR") {
            let res = await ProfService.sendMultipleInvitesForMultipleEmails(
                inviteDetails.email,
                inviteDetails.id
            );
            setState(st => ({ ...st, generateurl: true }));
            toggleShowLoader(false);
            alert(res.msg)
        } else {
            let res = await TeacherService.sendMultipleInvitesForMultipleEmails(
                inviteDetails.email,
                inviteDetails.id
            );
            setState(st => ({ ...st, generateurl: true }));
            toggleShowLoader(false);
            alert(res.msg)
        }
    }

    const _renderScreen = () => {
        return <div className="screen1">
            {/* top */}
            <div>
                {/* <i style={{marginLeft:'auto'}} class="fas fa-check-circle"></i> */}
                <img
                    src={ftlogo}
                    width="33px"
                    height="25px"
                    alt="image"
                />
                <label>Class {data.classname} Created Successfully</label>
                <div onClick={onClose} style={{ marginLeft: 'auto', marginRight: 20, cursor: 'pointer' }}>X</div>
            </div>
            {
                _renderContainer()
            }
        </div>
    }

    const _renderContainer = () => {
        return <div className="screen2">
            <label>Invite students to the class {data.classname}</label>
            <label>Currently we support two ways of sending Student Invitations. You can either enter all the potential Student emails or copy the invitation link and broadcast it separately to all the potential Students.</label>
            <div>
                <textarea resize="none" placeholder="Enter multiple emails separated by commas" onChange={changeHandler} name="email" value={inviteDetails.email}></textarea>
                <i onClick={sendInvitationsButtonClicked} class="far fa-paper-plane"></i>
            </div>
            <label>OR</label>
            <div>
                <label>{data.link}</label>
                <div onClick={() => {
                    navigator.clipboard.writeText(data.link)
                    alert("URL copied to clipboard")
                }}>
                    <i class="far fa-copy"></i>
                </div>
            </div>
            <div>
                <div onClick={onClose}>Close</div>
            </div>
        </div>
    }

    return <div className="classCreated">
        {/* Main */}
        <div>{_renderScreen()}</div>
        {showLoader ? <LoadingWheel /> : null}
    </div>
}