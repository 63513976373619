import React from 'react'
import AuthService from '../services/authService'
import { RouteComponentProps, withRouter } from 'react-router'
import Validate from '../Helpers/Utility/Validation'
export interface EmailProps extends RouteComponentProps {
    email: any
    isLoading: any
}

class EmailVerification extends React.Component<EmailProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: this.props.email,
            validateMsg: '',
            statusMsg: ''
        }
    }

    setValue(e: any) {
        this.setState({ email: e.target.value })
    }

    async validateEmail() {

        let validationError = await Validate.validateEmail(this.state.email, "email") as any;
        this.setState({ validateMsg: validationError.errors.email })

        if (validationError.isValid) {
            this.resendEmailverification()
        }
    }


    async resendEmailverification() {

        this.props.isLoading(true)
        let response = await AuthService.resendVerification(this.state.email)
        this.setState({ statusMsg: response.msg })
        this.props.isLoading(false)

    }

    render() {
        return (
            <div className="modal-body signin-modal" >
                <h2>Email Verification</h2>
                <div className="row">
                    <div className="col-xs-12 col-sm-12">

                        <form>
                            <div className="form-group">
                                <div className="row mt-10 mb-10">
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="email"
                                            value={this.state.email}
                                            className="form-control login-input"
                                            placeholder="Email"
                                            onChange={(e) => this.setValue(e)}
                                        />
                                        <div className="validation-error">{this.state.validateMsg} </div>
                                    </div>
                                </div>
                                {this.state.statusMsg && this.state.statusMsg != '' ?

                                    <div className="col-xs-12 col-sm-12 resetMsg">
                                        {this.state.statusMsg}
                                    </div>
                                    :
                                    null
                                }
                                <button
                                    type="button"
                                    id="sign-in-button"
                                    className="login-btn"
                                    onClick={() => this.validateEmail()}>
                                    Resend Email verification
                                    </button>
                            </div>
                        </form>

                    </div>
                </div >
            </div>

        )
    }
}

export default withRouter(EmailVerification)