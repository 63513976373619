import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import commonService from "../../services/commonService";
import validation from "../../Helpers/Utility/Validation";
import Toast from "../../components/Toast";
import contactUsSvg from "../../contact_us.svg";
import AuthService from "../../services/authService";
import { ContactUs } from "../../interfaces";

export interface ContactProps extends RouteComponentProps {}

export interface ContactState {}

export default class Contact extends Component<ContactProps, ContactState> {
  msg = "";
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);

  state = {
    contactDetails: {} as any,
    errors: {} as ContactUs
    // email: "pft.professor1@gmail.com",
    // password: "123456"
  };

  updateFieldValue(e: any, type?: any) {
    let stateObject = {} as any;
    if (type == "text") {
      if (e.target.value.match(/^[A-Za-z\s]{0,30}$/)) {
        stateObject[e.target.name] = e.target.value.trimLeft();
      }
    } else if (type == "number") {
      if (e.target.value.match(/^[0-9]{0,15}$/)) {
        stateObject[e.target.name] = e.target.value.trimLeft();
      }
    } else {
      stateObject[e.target.name] = e.target.value.trimLeft();
    }
    this.setState(Object.assign(this.state.contactDetails, stateObject));
  }

  async validateFields() {
    let errors = {} as any;
    Object.assign(
      errors,
      await validation.validateName(
        this.state.contactDetails.firstname,
        "firstname"
      )
    );
    this.setState(Object.assign(this.state.errors, errors.errors));

    Object.assign(
      errors,
      await validation.validateName(
        this.state.contactDetails.lastname,
        "lastname"
      )
    );
    this.setState(Object.assign(this.state.errors, errors.errors));

    Object.assign(
      errors,
      await validation.validateEmail(this.state.contactDetails.email, "email")
    );

    this.setState(Object.assign(this.state.errors, errors.errors));
    Object.assign(
      errors,
      await validation.validateField(
        this.state.contactDetails.subject,
        "subject"
      )
    );
    this.setState(Object.assign(this.state.errors, errors.errors));

    Object.assign(
      errors,
      await validation.validatePhone(this.state.contactDetails.phone, "phone")
    );
    console.log(this.state.errors);

    // console.log(
    //   await validation.validatePhone(this.state.contactDetails.phone, "phone")
    // );
    this.setState(Object.assign(this.state.errors, errors.errors));

    // console.log(this.state.errors.firstname);
    // console.log(this.state.errors.lastname);
    // console.log(this.state.errors.email);
    return Object.keys(this.state.errors).length != 0;
  }
  //   async componentDidMount() {
  //     let response = await AuthService.signInEmail(
  //       this.state.email,
  //       this.state.password
  //     );
  //   }

  async submitForm() {
    console.log("submitForm");
    await this.validateFields();

    if (
      this.state.errors.firstname == "" &&
      this.state.errors.lastname == "" &&
      this.state.errors.email == "" &&
      this.state.errors.phone == ""
    ) {
      let res = await commonService.createContact(this.state.contactDetails);
      if (res.status == 200) {
        this.msg = res.msg;
        this.notifySuccess();
      } else {
        this.msg = res.msg;
        this.notifyError();
      }
    }
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8", height: "6vh" }}
            className=" container registration-title"
          ></div>
        </div>
        <div className="container cmt20">
          <div className="row equal">
            <div
              className="col-md-5 reg-card btrr "
              style={{ paddingTop: "20px" }}
            >
              <div
                className="wizard"
                id="myWizard"
                style={{ paddingTop: "5vh" }}
              >
                <div className="step" style={{ textAlign: "center" }}>
                  <img src={contactUsSvg} width="320" height="auto" alt="" />
                  <div
                    className="row mt-15 mb-15 info highlight"
                    style={{ paddingTop: "12px" }}
                  >
                    <h2 className="col-md-8 " style={{ textAlign: "center" }}>
                      Contact Us
                    </h2>
                  </div>
                  <p className="form-left-text" style={{ paddingTop: "20px" }}>
                    Provide details so that we can get back to you.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-7 col-sm-12 reg-card btlr"
              style={{ paddingTop: "20px" }}
            >
              <div className="wizard" id="myWizard">
                <div className="step">
                  <div className="row info-head">
                    <div className="col-md-12">
                      <div className="row mt-15 mb-15">
                        <h3 className="col-md-12 ">Get in touch</h3>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      First Name
                      <span className="mandtory-star">*</span>{" "}
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <input
                        type="text"
                        name="firstname"
                        className="form-control"
                        placeholder="First Name"
                        onChange={e => this.updateFieldValue(e, "text")}
                      />
                      <div className="validation-error move-top">
                        {" "}
                        {this.state.errors.firstname}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Last Name
                      <span className="mandtory-star">*</span>{" "}
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <input
                        type="text"
                        name="lastname"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={e => this.updateFieldValue(e, "text")}
                      />
                      <div className="validation-error move-top">
                        {" "}
                        {this.state.errors.lastname}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Subject
                      <span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <select className="form-control" name="subject">
                        <option selected disabled value="">
                          Select
                        </option>
                        <option>Request a demo</option>
                        <option>General Comments</option>
                        <option>Sales Enquiry</option>
                        <option>Partnering</option>
                        <option>Internship</option>
                        <option>Employment</option>
                        <option>Other</option>
                      </select>
                      <div className="validation-error  move-top">
                        {this.state.errors.subject}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Email
                      <span className="mandtory-star">*</span>{" "}
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                        onChange={e => this.updateFieldValue(e, "email")}
                      />
                      <div className="validation-error move-top">
                        {" "}
                        {this.state.errors.email}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Phone
                      <span className="mandtory-star">*</span>{" "}
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Your Phone"
                        onChange={e => this.updateFieldValue(e, "number")}
                      />
                      <div className="validation-error move-top">
                        {" "}
                        {this.state.errors.phone}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Comments
                      <span className="mandtory-star"></span>{" "}
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <textarea
                        className="form-control"
                        name="comments"
                        placeholder="Give us more details.."
                        onChange={e => this.updateFieldValue(e)}
                      ></textarea>
                      <div className="validation-error move-top"> </div>
                    </div>
                  </div>
                  <div
                    className="row mt-10 mb-10"
                    style={{ textAlign: "center" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-primary  next-btn "
                      onClick={() => this.submitForm()}
                    >
                      {"Submit"}
                    </button>
                  </div>
                  <div className="step validation-error">
                    All fields marked with an asterisk are required.
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
