import React from 'react';
import "./Progressbar.css"
import { withRouter, RouteComponentProps } from 'react-router'


export interface ProgressProps extends RouteComponentProps {
    status: any
}

export interface ProgressState { }

class Progressbar extends React.Component<ProgressProps, ProgressState> {

    render() {
        return (
            <div style={{margin:0, height:'75%', borderRadius:4, marginRight:5, padding:0, display:'flex', justifyContent:'center', alignItems:'center', fontSize:15, color:'rgba(245,245,245,1)', boxShadow:'0px 0px 3px rgba(0,0,0,0.3)'}} className={this.props.status == "Completed" ? "completed-bar":this.props.status == "In Progress" ? "inprogress-bar" : this.props.status == "Ready" ? "ready-bar" : "pending-bar"}>
               {this.props.status}
            </div>
        )
    }
}
export default withRouter(Progressbar)