import React, { Component, Fragment } from "react";
import { RouteComponentProps } from "react-router-dom";
import authStore from "../../models/AuthStore";
import productCurvyLinesImg from "../../productCurvyLines.png";
import fmaImg from "../../fma.png";
import professorSvg from "../../undraw.svg";
import homeHeroImage from "../../homepage_hero_1.svg";
import professorImg from "../../professorImg.svg";
import studentImg from "../../studentImg.svg";
import playerImg from "../../playerImg.svg";
import newsletterSvg from "../../newsletter.svg";
import commonService from "../../services/commonService";
import validation from "../../Helpers/Utility/Validation";
import Toast from "../../components/Toast";
import ROUTES from "../../ROUTES";
import authService from '../../services/authService';
import ftlogo from '../../logo.png'
// import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import './Home.css';
import {IoIosArrowBack ,IoIosArrowForward} from 'react-icons/io';
import {MdPlayCircleOutline} from 'react-icons/md';
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";
import CloseCurrentSessionModel from '../../models/closeCurrentSession';

import {home} from '../../resource/strings';

export interface HomeProps extends RouteComponentProps { }

export interface HomeState { }

const banerDetails = [
  {
    "title":"AWARD WINNING GAME!",
    "description":'FutureTraders is an active learning platform and simulation to “Gamify” investment concepts to optimize student engagement and exponentially speed the learning curve. Our platform was awarded the 2019 "Innovation in Teaching" Award by the Financial Management Association (FMA).'
  },
  {
    "title":"MASTER THE MARKETS WITH FUTURETRADERS!",
    "description":"FutureTraders is an active learning platform developed for anyone interested in learning the nuances to become a successful Trader and Market Maker."
  }
];

export default class Home extends Component<HomeProps, HomeState> {
  msg = "";
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);

  state = {
    signUpDetails: {} as any,
    errors: {} as any,
    showLoader: false,
    contactDetails: { subject: "Register for announcements" } as any,

    showCloseCurrentSessionModel: false,
    ccsType: "",
    ccsRoute: "",
    activeBanerItem:0,
    ftVideoPlayed: false
  };

  updateFieldValue(e: any, type?: any) {
    let stateObject = {} as any;
    if (type == "text") {
      if (e.target.value.match(/^[A-Za-z\s]{0,30}$/)) {
        stateObject[e.target.name] = e.target.value.trimLeft();
      }
    } else {
      stateObject[e.target.name] = e.target.value.trimLeft();
    }
    this.setState(Object.assign(this.state.signUpDetails, stateObject));
  }

  async validateFields() {
    let errors = {} as any;

    Object.assign(
      errors,
      await validation.validateEmail(this.state.signUpDetails.email, "email", false)
    );

    this.setState(Object.assign(this.state.errors, errors));
    // /console.log(this.state.errors.email);
    return Object.keys(this.state.errors).length != 0;
  }

  async submitForm() {
    await this.validateFields();
    console.log("working");
    if (this.state.errors.email == "") {
      let res = await commonService.signUp(this.state.signUpDetails);
      if (res.status == 200) {
        this.msg = res.msg;
        this.notifySuccess();
      } else {
        this.msg = res.msg;
        this.notifyError();
      }
    }
  }

  componentDidMount() { 
    // controlling iframe
    const player = document.getElementById('player')
    if(player !== null){
      player.oncanplaythrough = () => {
        alert("Go")
      }
    }
    // toggle baner items
    setInterval(() => {
      this.setState((st:any) => ({...st, activeBanerItem: st.activeBanerItem === 0 ? 1 : 0}))
    },10000);
  }
  // scrollToGetStartedNowOne() {
  //   console.log("Scroll.animateScroll.scrollTo(1000)")
  //   Scroll.animateScroll.scrollMore(1220);
  // }
  // scrollToGetStartedNowTwo() {
  //   console.log("Scroll.animateScroll.scrollTo(1000)")
  //   Scroll.animateScroll.scrollMore(1000);
  // }

  updateFieldValueContact(e: any, type?: any) {
    let stateObject = {} as any;
    if (type == "text") {
      if (e.target.value.match(/^[A-Za-z\s]{0,30}$/)) {
        stateObject[e.target.name] = e.target.value.trimLeft();
      }
    } else if (type == "number") {
      if (e.target.value.match(/^[0-9]{0,15}$/)) {
        stateObject[e.target.name] = e.target.value.trimLeft();
      }
    } else {
      stateObject[e.target.name] = e.target.value.trimLeft();
    }
    this.setState(Object.assign(this.state.contactDetails, stateObject));
  }

  async validateFieldsForContactUs() {
    let errors = {} as any;
    Object.assign(
      errors,
      await validation.validateName(
        this.state.contactDetails.firstname,
        "firstname"
      )
    );
    this.setState(Object.assign(this.state.errors, errors.errors));

    Object.assign(
      errors,
      await validation.validateName(
        this.state.contactDetails.lastname,
        "lastname"
      )
    );
    this.setState(Object.assign(this.state.errors, errors.errors));

    Object.assign(
      errors,
      await validation.validateEmail(this.state.contactDetails.email, "email", false)
    );

    this.setState(Object.assign(this.state.errors, errors.errors));
    Object.assign(
      errors,
      await validation.validateField(
        this.state.contactDetails.subject,
        "subject"
      )
    );
    this.setState(Object.assign(this.state.errors, errors.errors));

    // Object.assign(
    //   errors,
    //   await validation.validatePhone(this.state.contactDetails.phone, "phone")
    // );

    // console.log(
    //   await validation.validatePhone(this.state.contactDetails.phone, "phone")
    // );
    // this.setState(Object.assign(this.state.errors, errors.errors));

    // console.log(this.state.errors.firstname);
    // console.log(this.state.errors.lastname);
    // console.log(this.state.errors.email);
    return Object.keys(this.state.errors).length != 0;
  }

  async submitFormContactUs() {
    console.log("submitForm");
    await this.validateFieldsForContactUs();
    console.log("valid all fields")
    if (
      this.state.errors.firstname == "" &&
      this.state.errors.lastname == "" &&
      this.state.errors.email == ""
    ) {
      let res = await commonService.createContact(this.state.contactDetails);
      if (res.status == 200) {
        this.msg = res.msg;
        this.notifySuccess();
        console.log("Sent successfully")
      } else {
        this.msg = res.msg;
        this.notifyError();
        console.log("Error to submit")
      }
    }
  }

  _renderContactus = () => {
    return <div id="contact_us" className="col-md-7 col-md-push-5">
      <div className="row info-head">
        <div className="col-md-12">
          <div className="row mt-15 mb-15">
            <h3 className="col-md-12 ">Get in touch</h3>
          </div>
        </div>
      </div>

      <div className="row mt-10 mb-10">
        <div className="col-md-4">
          First Name
        <span className="mandtory-star">*</span>{" "}
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <input
            type="text"
            name="firstname"
            className="form-control"
            placeholder="First Name"
            onChange={e => this.updateFieldValueContact(e, "text")}
          />
          <div className="validation-error move-top">
            {" "}
            {this.state.errors.firstname}
          </div>
        </div>
      </div>
      <div className="row mt-10 mb-10">
        <div className="col-md-4">
          Last Name
        <span className="mandtory-star">*</span>{" "}
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <input
            type="text"
            name="lastname"
            className="form-control"
            placeholder="Last Name"
            onChange={e => this.updateFieldValueContact(e, "text")}
          />
          <div className="validation-error move-top">
            {" "}
            {this.state.errors.lastname}
          </div>
        </div>
      </div>
      <div className="row mt-10 mb-10">
        <div className="col-md-4">
          Subject
        <span className="mandtory-star">*</span>
        </div>
        <div className="col-md-8">
          <select onChange={e => this.updateFieldValueContact(e, 'text')} value={this.state.contactDetails.subject} className="form-control" name="subject">
            {/* <option selected disabled value="">
              Select
          </option> */}
            <option>Register for announcements</option>
            <option>Request a demo</option>
            <option>General Comments</option>
            <option>Sales Enquiry</option>
            <option>Partnering</option>
            <option>Internship</option>
            <option>Employment</option>
            <option>Others</option>
          </select>
          <div className="validation-error  move-top">
            {this.state.errors.subject}
          </div>
        </div>
      </div>
      <div className="row mt-10 mb-10">
        <div className="col-md-4">
          Email
        <span className="mandtory-star">*</span>{" "}
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <input
            type="text"
            name="email"
            className="form-control"
            placeholder="Your Email"
            onChange={e => this.updateFieldValueContact(e, "email")}
          />
          <div className="validation-error move-top">
            {" "}
            {this.state.errors.email}
          </div>
        </div>
      </div>
      <div className="row mt-10 mb-10">
        <div className="col-md-4">
          Phone
        {/* <span className="mandtory-star">*</span>{" "} */}
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <input
            type="text"
            name="phone"
            className="form-control"
            placeholder="Your Phone"
            onChange={e => this.updateFieldValueContact(e, "number")}
          />
          <div className="validation-error move-top">
            {" "}
            {this.state.errors.phone}
          </div>
        </div>
      </div>
      <div className="row mt-10 mb-10">
        <div className="col-md-4">
          Comments
        <span className="mandtory-star"></span>{" "}
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <textarea
            className="form-control"
            name="comments"
            placeholder="Give us more details.."
            onChange={e => this.updateFieldValueContact(e)}
          ></textarea>
          <div className="validation-error move-top"> </div>
        </div>
      </div>
      <div
        className="row mt-10 mb-10"
        style={{ float:'right', marginRight:2 }}
      >
        <button
          type="submit"
          className="btn btn-primary btn-md"
          onClick={() => this.submitFormContactUs()}
        >
          {"Submit"}
        </button>
      </div>
      <div className="step validation-error">
        All fields marked with an asterisk are required.
    </div>

    </div>
  }

  oldbaner = () => {
    return <div className="slider-area ">
      <div
        id="myCarousel"
        className="carousel slide slide-area"
        data-ride="carousel"
      >
        {/* <ol className="carousel-indicators">
          <li
            data-target="#myCarousel"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
        </ol> */}

        <div className="carousel-inner backgroundImage" style={{height:300, paddingBottom:40}}>
          <div className="item active">
            <div className="container marketing "></div>
            <div className=" fma-award">
              <div className="container">
                <div className="row featurette">
                  <div className="col-md-1"></div>
                  <div className="col-md-8">
                    <h1 className="featurette-heading">
                      <strong>Award Winning Game!</strong>
                    </h1>

                    <p className="fw-480" style={{ color: "white" }}>
                      FutureTraders is an active learning platform and simulation to “Gamify” investment concepts to optimize student engagement and exponentially speed the learning curve.{" "}
                      <a
                        className="award-link"
                        href="https://www.fma.org/index.php?option=com_content&view=article&id=180:innovation-award&catid=20:site-content"
                        title="More about the award..."
                        target="_blank"
                      >
                        Our platform was awarded the 2019 Innovation in
                        Teaching Award by the Financial Management
                        Association (FMA).
                      </a>
                    </p>
                  </div>

                  <div className="col-md-3">
                    <img
                      style={{ marginTop: "60px" }}
                      className="featurette-image img-responsive center-block"
                      src={fmaImg}
                      alt="Generic placeholder image"
                    />
                  </div>
                </div>

                {/* get strated button */}
                <div className="row" style={{marginBottom:10}}>
                <div style={{ textAlign: "center"}}>
                    {/* <a
                      
                      className="btn btn-primary btn-lg register-button-o"
                      // onClick={this.scrollToGetStartedNowOne}
                    >
                      Get Started Now
                    </a> */}
                    <Link
                      id="get-started-now"
                      offset={-80}
                      activeClass="active"
                      className="btn btn-primary register-button-o"
                      to="register-section"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      <div style={{padding:'4px 10px'}}>Get Started Now</div>
                    </Link>
                    <Link
                      style={{marginLeft:20}}
                      id="learn_more1"
                      offset={-80}
                      activeClass="active"
                      className="btn btn-primary register-button-o"
                      to="what-is-ft"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      <div style={{padding:'4px 10px'}}>Learn More</div>
                    </Link>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="item ">
            <div className="container marketing  "></div>
            <div className="  fma-award">
              <div className="container">
                
                <div
                  className="row featurette"
                  // style={{ marginBottom: "64px" }}
                >
                  <div className="col-md-1"></div>
                  <div className="col-md-7">
                    <h1 className="featurette-heading">
                      <strong>
                        Master The Markets with FutureTraders!{" "}
                      </strong>
                    </h1>
                    <p className="fw-480" style={{ color: "#fff" }}>
                      FutureTraders is an active learning platform developed for anyone interested in learning the nuances to become a successful Trader and Market Maker.
                    </p>
                  </div>
                  <div className="col-md-4" style={{ width: "30%" }}>
                    <img
                      style={{ width: "75%", marginTop:35, marginLeft:60 }}
                      className="featurette-image img-responsive center-block"
                      src={homeHeroImage}
                      alt="Generic placeholder image"
                      height="180px !important "
                    />
                  </div>
                </div>
              </div>

              {/* Get started button 2 */}
              <div className="row">
              <div style={{ textAlign: "center" }}>
                  {/* <a
                    id="learn-more-ft"
                    className="btn btn-primary btn-lg register-button-o"
                    // onClick={this.scrollToGetStartedNowTwo}
                  >
                    Get Started Now
                  </a> */}
                  <Link
                    id="get-started-now"
                    offset={-60}
                    activeClass="active"
                    className="btn btn-primary register-button-o"
                    to="register-section"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                      <div style={{padding:'4px 10px'}}>Get Started Now</div>
                  </Link>
                  <Link
                      style={{marginLeft:20}}
                      id="learn_more2"
                      offset={-80}
                      activeClass="active"
                      className="btn btn-primary register-button-o"
                      to="what-is-ft"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      <div style={{padding:'4px 10px'}}>Learn More</div>
                    </Link>
                </div>
              </div>

            </div>
          </div>
        </div>

        <a
          className="left carousel-control"
          href="#myCarousel"
          data-slide="prev"
        >
          <span className="glyphicon glyphicon-chevron-left"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control"
          href="#myCarousel"
          data-slide="next"
        >
          <span className="glyphicon glyphicon-chevron-right"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  }

  renderBaner = () => {
    return <div className="baner backgroundImage">
    <div className="left">
        <IoIosArrowBack onClick={() => this.setState((st:any) => ({...st,activeBanerItem:st.activeBanerItem === 0 ? 1 : 0}))} className="ion-icon"/>
        <div className="scroll_view">
          {
            banerDetails.map((baner:any, i:any) => {
              return <div style={this.state.activeBanerItem === 0 ? {left:0} : {left:'-100%'}} key={i} className="left-container">
              <div className="left-container-left">
                  <div className="baner_heading">{baner.title}</div>
                  <p>{baner.description}</p>
                  <div>
                    <div onClick={() => window.location.href="#register-section"} className="btn btn-primary btn-lg register-button-o btn-text">Get Started Now</div>
                    <div onClick={() => window.location.href="#what-is-ft"} className="btn btn-primary btn-lg register-button-o btn-text">Learn More</div>
                  </div>
              </div>
              <div className="left-container-right">
                <img style={i === 1 ? {width:200} : {}} src={i === 0 ? fmaImg : homeHeroImage}/>
              </div>
          </div>
            })
          }
        </div>
        <IoIosArrowForward onClick={() => this.setState((st:any) => ({...st,activeBanerItem:st.activeBanerItem === 0 ? 1 : 0}))} className="ion-icon"/>
    </div>
    <div className="right">
          {/* <div className="baner_heading">Why FT?</div> */}
          {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minima explicabo voluptate unde adipisci quia, debitis molestias</p> */}
          {
            this.state.ftVideoPlayed ? 
            <video autoPlay style={{ width:'100%'}} controls src={require('../../videos/GMT20200622-223024_Andrew-Coh_1440x900.mp4')}></video>
            :<div onClick={() => this.setState(st => ({...st, ftVideoPlayed: true}))} className="ft_video_controllers">
              <label>WHY FUTURETRADERS?</label>
              <MdPlayCircleOutline className="play_button"/>
              <p>WATCH THE VIDEO</p>
            </div>
          }
    </div>
</div>
  }

  _renderFTLearningPlatform = () => {
    return <Fragment>
                <h1
            id="what-is-ft"
            className="featurette-heading backgroundImage-g  "
            style={{
              textAlign: "left",
              fontSize: "24px",
              marginTop: "24px",
              color: "white",
              padding: "10px"
            }}
          >
            FT Learning Platform
          </h1>
          
          <hr className="featurette-divider" />

          <div className="row featurette featurette-p">
            <div className="col-md-7">
              <p>
                FutureTraders is an interactive learning platform designed to
                educate students from different perspectives such as a Market Maker and a Trader and
                to hone professional skills in the investment industry.
                Specifically, this multi-dimensional learning platform utilizes
                a unique mathematical and informational ecosystem to both
                optimize and exponentially accelerate the learning process. The
                uniquely tiered complexity and excitement levels are excellent
                for exposing students to products such as Stocks, Bonds,
                Commodities, Options, and Crypto Currencies. This learning tool
                places students against a mix of bots and peers to establish a
                deeper market understanding through action.
              </p>
              <p>You can use our <a style={{fontWeight:'bolder'}} href="/guide?page=professor" target="_blank">Interactive Guide</a> to learn more.</p>
            </div>
            <div className="col-md-5">
              <div className="youtube-player" data-id="nqlo2PhA18s"></div>
            </div>
          </div>
          <div className="row featurette featurette-p">
            <div className="col-md-12 mt-25" style={{ textAlign: "center" }}>
              <h2>
                <span className="highlight" style={{ textAlign: "center" }}>
                  Experience Our FutureTraders Platform
                </span>
              </h2>
              <p>
                Experience our FutureTraders web platform without creating any
                account with the following options.
              </p>
            </div>
            <div className="col-md-12 " style={{ textAlign: "center" }}>
              <a className="col-md-1 p10   m10"> </a>
              <a
                className="col-md-2 p10 btn btn-primary btn-lg register-button m10"
                onClick={() => (authStore.user === null) ? window.location.href="/DemoSignInAsProfessor" : this.setState((st => ({...st, showCloseCurrentSessionModel:true, ccsType:"Professor", ccsRoute:"/DemoSignInAsProfessor"})))}
                // href="/DemoSignInAsProfessor" 
                style={{ height: 45 }}
              >
                <i style={{ marginRight: 14, fontSize: 16, paddingBottom: 8 }} className="fas fa-user-tie"></i>
                As Professor
              </a>
              <a
                onClick={() => (authStore.user === null) ? window.location.href="/DemoSignInAsTeacher" : this.setState((st => ({...st, showCloseCurrentSessionModel:true, ccsType:"Teacher", ccsRoute:"/DemoSignInAsTeacher"})))}
                className="col-md-2 p10 btn btn-primary btn-lg register-button m10"
                // href="/DemoSignInAsTeacher" 
                style={{ height: 45 }}
              >
                <i style={{ marginRight: 14, fontSize: 16, paddingBottom: 8 }} className="fas fa-user-tie"></i>
                As Teacher
              </a>
              <a
                onClick={() => (authStore.user === null) ? window.location.href="/DemoSignInAsStudent" : this.setState((st => ({...st, showCloseCurrentSessionModel:true, ccsType:"Student", ccsRoute:"/DemoSignInAsStudent"})))}
                className="col-md-2 p10 btn btn-primary btn-lg register-button m10"
                // href="/DemoSignInAsStudent" 
                style={{ height: 45 }}
              >
                <i style={{ marginRight: 14, fontSize: 16, paddingBottom: 8 }} className="fas fa-user-graduate"></i>
                As Student
              </a>
              <a
                onClick={() => (authStore.user === null) ? window.location.href="/DemoSignInAsPlayer" : this.setState((st => ({...st, showCloseCurrentSessionModel:true, ccsType:"Player", ccsRoute:"/DemoSignInAsPlayer"})))}
                // href="/DemoSignInAsPlayer" 
                style={{ height: 45 }}
                className="col-md-2 p10 btn btn-primary btn-lg register-button m10"
              >
                <i style={{ marginRight: 14, fontSize: 16, paddingBottom: 8 }} className="fas fa-user"></i>
                As Player
              </a>
            </div>
          </div>
          
          <hr className="featurette-divider" />
    </Fragment>
  }

  _renderFTGame = () => {
    return <Fragment>
                <h1
            id="video-tutorials"
            className="featurette-heading backgroundImage-g "
            style={{
              textAlign: "left",
              fontSize: "24px",
              marginTop: "24px",
              color: "white",
              padding: "10px",
              display:'flex',
              alignItems:'center'
            }}
          >
            FT GAME
          </h1>

          <hr className="featurette-divider" />
          <div className="row featurette featurette-p">

            <div style={{marginLeft:18, marginRight:10}}>
              <p>
                FutureTraders is an active learning game developed for anyone interested in learning the nuances to become a successful Trader and Market Maker.            
              </p>
              <p>You can use our <a style={{fontWeight:'bolder'}} href="/guide?page=professor" target="_blank">Interactive Guide</a> to learn more.</p>
            </div>

            <div className="row featurette featurette-p">
            <div className="col-md-12 mt-25" style={{ textAlign: "center" }}>
              <h2>
                <span className="highlight" style={{ textAlign: "center" }}>
                  Experience Our FutureTraders GAME
                </span>
              </h2>
              <p>
                Experience our innovative FutureTraders game to learn practical skills quickly and fun-filled way.
              </p>
            </div>
            
            {/* play demo buttons */}
            <div className="col-md-12 " style={{ textAlign: "center", display:'flex', justifyContent:'center' }}>
              <a
                className="col-md-2 p10 btn btn-primary btn-lg register-button m10"
                onClick={() => window.open('https://playfuturetradergame.firebaseapp.com/','_blank')}
                style={{ height: 45 }}
              >
                <img
                        style={{marginRight:8, marginBottom:4}}
                        src={ftlogo}
                        width="30px"
                        height="21px"
                        alt="image"
                      />
                Play Demo
              </a>
            </div>
          </div>
          {/* play demo button ends */}

            <div className="col-md-7 col-md-push-5">
              <h2 className="featurette-heading featurette-heading-l">
                {" "}
                <span className="highlight">
                  Play as a Beginner Trader
                </span>{" "}
              </h2>
              <p className="">
                "Beginner Traders" will be introduced to the rules of the game,
                which will aid in their mastery of trading theory and the
                overall mechanics of the game. Analysis of the post-game
                transactional history will help players adjust their tactics
                against their competitors.
              </p>
            </div>
            <div className="col-md-5 col-md-pull-7">
              <div className="youtube-player" data-id="bk6YnvNKiPk"></div>
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="row featurette featurette-p">
            <div className="col-md-7">
              <h2 className="featurette-heading featurette-heading-l">
                <span className="highlight">Play as a Market Maker</span>{" "}
              </h2>
              <p className="">
                In addition to sharpening a player’s trading skills, this
                training video will further enhance the player’s Financial
                Literacy and the player’s understanding of Market Efficiency.
                Completion of this video will aid the player in learning and
                executing complex trading strategies in a fast-paced
                environment.
              </p>
            </div>
            <div className="col-md-5">
              <div className="youtube-player" data-id="dUS3SwmvX3E"></div>
            </div>
          </div>
          <hr className="featurette-divider" />
          <div className="row featurette featurette-p">
            <div className="col-md-7 col-md-push-5">
              <h2 className="featurette-heading featurette-heading-l">
                {" "}
                <span className="highlight">Play as an Advanced Trader</span>{" "}
              </h2>
              <p className="">
                This video will help players understand how to create markets,
                leverage bids and offers, recognize trends, understand
                liquidity, evaluate the impact of new events, and quickly react
                to new information.
              </p>
            </div>
            <div className="col-md-5 col-md-pull-7">
              <div className="youtube-player" data-id="MWU7KLQjXRI"></div>
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="row featurette featurette-p">
            <div className="col-md-7">
              <h2 className="featurette-heading featurette-heading-l">
                <span className="highlight">
                  Play as an Advanced Market Maker
                </span>{" "}
              </h2>
              <p className="">
              Players will learn how to adjust a market in a complex environment based on multiple open contracts. This video will help players to quickly understand the effect of their trading decisions concerning final profits and losses.
              </p>
            </div>
            <div className="col-md-5">
              <div className="youtube-player" data-id="9o-lrQC41jw"></div>
            </div>
          </div>
          <hr className="featurette-divider" />
    </Fragment>
  }

  _renderGettingStarted = () => {
    return <Fragment>
                <h1
            id="register-section"
            className="featurette-heading backgroundImage-g  "
            style={{
              textAlign: "left",
              fontSize: "24px",
              marginTop: "24px",
              color: "white",
              padding: "10px"
            }}
          >
            Getting Started
          </h1>
          <hr className="featurette-divider" />
          <div className="row featurette featurette-p">
            <div className="col-md-7">
              <h2 className="featurette-heading featurette-heading-l">
                {" "}
                <span className="highlight">Teach</span>{" "}
              </h2>
              <p className="">
                Professors and teachers can enhance their classes by leveraging
                our innovative and seamless learning platform. In just a few minutes, you can register and have your first-class setup and
                running. The rest is a breeze!
              </p>
              <div className="col-md-12 " style={{ textAlign: "center" }}>
                <button className="btn btn-primary btn-lg register-button col-md-4 mr-10">
                  <a 
                    onClick={() => (authStore.user === null) ? window.location.href="/professorregister" : this.setState((st => ({...st, showCloseCurrentSessionModel:true, ccsType:"ProfessorRegister", ccsRoute:"/professorregister"})))}
                    // href="/professorregister" 
                    style={{ color: "#fff" }}>
                    {" "}
                    Register As Professor
                  </a>
                </button>
                <button className="btn btn-primary btn-lg register-button col-md-4 ">
                  <a 
                    onClick={() => (authStore.user === null) ? window.location.href="/teacherregister" : this.setState((st => ({...st, showCloseCurrentSessionModel:true, ccsType:"TeacherRegister", ccsRoute:"/teacherregister"})))}
                    // href="/teacherregister" 
                    style={{ color: "#fff" }}>
                    {" "}
                    Register As Teacher
                  </a>
                </button>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="featurette-image img-responsive center-block fma-image"
                src={professorImg}
                alt="Generic placeholder image"
              />
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="row featurette featurette-p">
            <div className="col-md-7 col-md-push-5">
              <h2 className="featurette-heading featurette-heading-l">
                {" "}
                <span className="highlight">Learn</span>{" "}
              </h2>
              <p className="">
              Students can acquire practical trading and market knowledge using our adaptive learning platform. It takes a student two quick minutes to be completely registered and signed up for their first class. By quick QR scan, students can join the additional classes
              </p>
              <p>
                <strong>
                  Student Registration is available only through invites.
                </strong>
              </p>
              {/* <div style={{ textAlign: "center" }}>
                <button className="btn btn-primary btn-lg register-button">
                  <a href="/studentregistration" style={{ color: "#fff" }}>
                    {" "}
                    Register As Student
                  </a>
                </button>
              </div> */}
            </div>
            <div className="col-md-5 col-md-pull-7">
              <img
                className="featurette-image img-responsive center-block"
                src={studentImg}
                alt="Generic placeholder image"
              />
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette featurette-p">
            <div className="col-md-7">
              <h2 className="featurette-heading featurette-heading-l">
                <span className="highlight">Enhance</span>{" "}
              </h2>
              <p className="">
                Players can enhance their market and trading skills
                by using FutureTraders to deeply learn the various
                investment vehicles and products. In a few minutes, you
                can register and begin your individualized course.
              </p>
              <div style={{ textAlign: "center" }}>
                <button className="btn btn-primary btn-lg register-button">
                  <a
                    onClick={() => (authStore.user === null) ? window.location.href="/playeregistration" : this.setState((st => ({...st, showCloseCurrentSessionModel:true, ccsType:"PlayerRegister", ccsRoute:"/playeregistration"})))}
                    // href="/playeregistration" 
                    style={{ color: "#fff" }}>
                    {" "}
                    Register As Player
                  </a>
                </button>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="featurette-image img-responsive center-block"
                src={playerImg}
                alt="Generic placeholder image"
              />
            </div>
          </div>
    </Fragment>
  }

  render() {
    return (
      <div>
        {this.renderBaner()}
        <div className="container marketing " style={{ marginTop: "10px" }}>
          
          <div style={{height:190}}className="row">
            <div className="col-lg-4">
              <i className="fas fa-graduation-cap fa-2x p-color"></i>
              <h2>SCHOLASTIC</h2>
              <p className="feature-text">
              {home.SCHOLASTIC}
              </p>
            </div>
            <div className="col-lg-4">
              <i className="fas fa-building fa-2x p-color"></i>
              <h2>FINANCIAL MARKETS</h2>
              <p className="feature-text">
              Our platform offers an innovative and efficient career induction solution for financial firms to evaluate, train, and hire talent.
              {/* Our platform offers an innovative and efficient career induction solution for businesses to evaluate, train, and hire talent who will become vital assets to the company. */}
              </p>
            </div>
            <div className="col-lg-4">
              <i className="fas fa-user-tie fa-2x p-color"></i>
              <h2>INDIVIDUAL PLAYERS</h2>
              <p className="feature-text">
              {/* Future Traders offers our engaging innovative learning platform to individuals wanting to master at their own pace in trading. */}
              {/* Future Traders offers our engaging innovative learning platform to individuals wanting to master in trading at their own pace. */}
              {/* Future Traders offers our engaging innovative learning platform to individuals wanting to learn how to trade at their own pace. */}
              Future Traders offers our engaging, innovative learning platform to individuals wanting to learn and master how to trade at their own pace.
              </p>
            </div>
          </div>

          {this._renderFTLearningPlatform()}
          {this._renderFTGame()}
          {this._renderGettingStarted()}

          <h1
            id="newsletter"
            className="featurette-heading backgroundImage-g  "
            style={{
              textAlign: "left",
              fontSize: "24px",
              marginTop: "24px",
              color: "white",
              padding: "10px"
            }}
          >
            CONTACT US
          </h1>
          <hr className="featurette-divider" />

          <div className="row featurette featurette-p">

            {this._renderContactus()}

            {/* <div className="col-md-7 col-md-push-5">
              <h2 className="featurette-heading featurette-heading-l">
                {" "}
                <span className="highlight">Contact Us</span>{" "}
              </h2>
              <p className="" style={{ paddingTop: "5px" }}>
                Enter your email address to receive platform related information
                and notifications of our upcoming events!
              </p>
              <div className="row mt-15 mb-10">
                <div className="col-md-2 input-lg">
                  {" "}
                  Email<span className="mandtory-star">*</span>
                </div>
                <div className="col-md-7">
                  <input
                    type="email"
                    name="email"
                    className="form-control input-lg"
                    placeholder="Email"
                    onChange={e => this.updateFieldValue(e)}
                  />
                  <div className="validation-error">
                    {" "}
                    {this.state.errors.email}
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    type="submit"
                    className="btn btn-primary  next-btn btn-lg "
                    onClick={() => this.submitForm()}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
             */}
            <div className="col-md-5 col-md-pull-7">
              <img
                className="featurette-image img-responsive center-block"
                src={newsletterSvg}
                alt="Generic placeholder image"
              />
            </div>

          </div>

          {/* render models */}
          {
            this.state.showCloseCurrentSessionModel ? 
            <CloseCurrentSessionModel type={this.state.ccsType} onLogout={() => {authService.signOut(); window.location.href=this.state.ccsRoute}} onClose={() => this.setState(st => ({...st,showCloseCurrentSessionModel:false}))}/>:null
          }
          {/* model render ends */}

          <hr className="featurette-divider" />

          {Toast.successToastView()}
          {Toast.errorToastView()}
          <div className="row" style={{ height: "60px" }}></div>
        </div>
      </div>
    );
  }
}
