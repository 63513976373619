import React, { Component } from "react"
import { withRouter, RouteComponentProps } from "react-router";

export interface SubProp extends RouteComponentProps {
    name: string;
    duration: string;
    strength: string;
    amount: string;
    checkhandler?: any;
    title: string;
    isCheck?: boolean;
}

class SubscriptionItem extends Component<SubProp, any> {
    render() {
        return (
            <tr className="custom-row">
                <td>
                    <div className="radio">
                        &nbsp;<label><input type="radio" name="subscription" title={this.props.title} checked={this.props.isCheck ? true : false}
                            onChange={(e) => this.props.checkhandler(e)} /></label>
                    </div>
                </td>
                <td>{this.props.duration}</td>
                <td>{this.props.strength}</td>
                <td>{this.props.amount}</td>
            </tr>
        )
    }

}
export default withRouter(SubscriptionItem)