import React, { useState, useEffect, Fragment } from 'react';
import './Dashboard.scss';
import { HorizontalBar, Bar, Pie, Line, Doughnut } from 'react-chartjs-2';
import ReactTooltip from 'react-tooltip';
import ROUTES from '../../ROUTES';
import classService from "../../services/classService";
import authStore from "../../models/AuthStore";
import studentService from "../../services/studentService";
import ProfessorService from "../../services/profService";
import TeacherService from '../../services/teacherService';
import NewWindow from 'react-new-window'
import LoadingWheel from "../../components/LoadingWheel";
import ShowReportCardModel from '../../models/showReportCard';
import ShowGameReportModel from '../../models/showGameReport';

let timmer;
let tempForDataFetch = 0;

export default (props) => {
    const [classRecord, setClassRecord] = useState(null);
    const [professorRecord, setProfessorRecord] = useState(null);
    const [resultArray, setResultArray] = useState(null);
    const [quizResultArray, setQuizResultArray] = useState(null);
    const [showClassDetails, toggleClassDetails] = useState(true);
    const [quizTotalForEachStudent, setQuizTotalForEachStudent] = useState(null);
    // states for graphs
    const [horizontalBarData, setHorizontalBarData] = useState(null);
    const [pieData, setPieData] = useState(null);
    const [pieData1, setPieData1] = useState(null);
    const [donutData, setDonutData] = useState(null);
    const [gamevsquizData, setGamevsQuizData] = useState(null);
    const [allStudentsPerformance, setAllStudentsPerformance] = useState(null);
    // table expands
    const [expandQuizTable, toggleExpandQuizTable] = useState(0);
    const [expandGameTable, toggleExpandGameTable] = useState(0);
    // hide all charts
    const [showCharts, toggleCharts] = useState(true);
    // loading status
    const [status, setStatus] = useState("Loading...");
    // rank for student
    const [myRank, setMyRank] = useState("");
    // dashboard widnow maximize
    const [maximize, toggleMaximize] = useState(false);
    // to toggle demo account
    const [showDemoAccounts, toggleDemoAccounts] = useState(true);
    const [showGameDemoAccounts, toggleGameDemoAccounts] = useState(true);

    // data fetch

    useEffect(() => {
        loadinitSetup();
    }, [])

    //after getting required data map to all the graphs
    useEffect(() => {
        if (authStore.user.role !== "STUDENT") {
            if (resultArray !== null && quizResultArray !== null) {
                if (resultArray.find(x => 'gameEvents' in x) !== undefined && quizResultArray.find(x => 'quiz' in x) !== undefined) {
                    clearTimeout(timmer);

                    getTop5Students();
                    getPieChartData();
                    getPieChart2Data();
                    getLineChartData();
                    getVerticalChartData();
                    getDonutChartData();
                } else {
                    toggleCharts(false);
                    setStatus("NO DATA FOUND")
                    setTimeout(() => { props.close() }, 600);
                }
            }
        } else {
            let timmer1;
            if (resultArray !== null && quizResultArray !== null) {
                clearTimeout(timmer1);

                toggleCharts(true);
                getPieChartData();
                getDonutChartData();
                getPieChart2Data();
                getLineChartData();
            } else {
                toggleCharts(false);
                if (timmer1 !== undefined) {
                    timmer1 = setTimeout(() => {
                        setStatus("NO DATA FOUND")
                        setTimeout(() => { props.close() }, 1000);
                    }, 10000);
                }
            }
        }
    }, [resultArray, quizResultArray]);

    const loadinitSetup = async () => {
        let classdetails = await classService.getClassbyName(props.className);
        setClassRecord(classdetails.data);
        if (authStore.user.role == "PROFESSOR" || authStore.user.role == "TEACHER") {
            await getProfessorScores(classdetails.data.id);
            loadProfessorRecord(classdetails.data.uid);
        } else {
            // student
            await getStudentScores(classdetails.data.id);
            loadProfessorRecord(classdetails.data.uid);
            calculateMyRank(classdetails.data.id, classdetails.data.academic);
        }
    }

    const calculateMyRank = async (classid, academic) => {
        let resultList;
        let quizResult;
        //professor
        if (academic === "Post Graduate and above" || academic === "Graduate" || academic === "Bachelor's") {
            resultList = await ProfessorService.getGameResultbyClass(classid)
            quizResult = await ProfessorService.getQuizResultbyClass(classid)
        }
        // teacher
        else {
            resultList = await TeacherService.getGameResultbyClass(classid)
            quizResult = await TeacherService.getQuizResultbyClass(classid)
        }
        let sortedResultList = [];
        let sortedQuizList = [];

        resultList.data.forEach((value, key) => sortedResultList.push(value));
        quizResult.data.forEach((value, key) => sortedQuizList.push(value));

        let results = [];
        let myEmail = authStore.user.email;

        sortedQuizList.forEach((sQuiz) => {
            if ('quiz' in sQuiz) {
                let total = sQuiz.quiz.reduce((total, x) => total + x.amount, 0);
                results.push({ 'email': sQuiz.email, 'amount': total })
            }
        });

        sortedResultList.forEach((sGame) => {
            if ("gameEvents" in sGame) {
                let total = sGame.gameEvents.reduce((total, x) => total + x.profitLoss.reduce((total1, y) => total1 + y, 0), 0);
                results = results.map(x => x.email === sGame.email ? { ...x, amount: x.amount + total } : x);
            }
        });

        let decendingResults = results.sort((x, y) => y.amount - x.amount);
        let rank = decendingResults.findIndex(x => x.email === myEmail) + 1;
        setMyRank(rank);
    }

    const loadProfessorRecord = async (email) => {
        let result = await ProfessorService.getProfessorByEmail(email);
        if (Object.keys(result).length > 0) {
            setProfessorRecord(result);
        } else {
            let result1 = await TeacherService.getTeacherByEmail(email);
            setProfessorRecord(result1);
        }
    }

    const getProfessorScores = async (classid) => {
        let resultList;
        let quizResult;
        if (authStore.user.role === "TEACHER") {
            resultList = await TeacherService.getGameResultbyClass(classid)
            quizResult = await TeacherService.getQuizResultbyClass(classid)
        } else {
            resultList = await ProfessorService.getGameResultbyClass(classid)
            quizResult = await ProfessorService.getQuizResultbyClass(classid)
        }

        calculateGameScore(resultList.data);
        calculateQuizScore(quizResult.data);
    }

    const getStudentScores = async (classid) => {

        let resultList = await studentService.getGameResultbyUser(authStore.user.user_id, classid);
        let quizResult = await studentService.getQuizResultbyUser(classid, authStore.user.user_id);
        calculateGameScore(resultList.data);
        calculateQuizScore(quizResult.data);
    }

    const calculateGameScore = (resultList) => {
        if (authStore.user.role === "PROFESSOR" || authStore.user.role === "TEACHER") {
            let result = [];
            resultList.forEach((value, key) => result.push(value));

            let sortedResult = result.sort((a, b) => b.timeStamp - a.timeStamp).map((sr) => {
                if (sr.gameEvents !== undefined) {
                    let topicLevelList = [];
                    const filterdGameList = sr.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp).map(x => {
                        if (!topicLevelList.includes(x.gameType + x.level)) {
                            topicLevelList.push(x.gameType + x.level);
                            return x;
                        }
                    }).filter(x => x !== undefined);
                    return { ...sr, gameEvents: filterdGameList }
                } else {
                    return sr
                }
            });
            sortedResult = [...sortedResult.filter(x => !x.firstname.toUpperCase().includes('_PROF') && !x.firstname.toUpperCase().includes('_TA')), ...sortedResult.filter(x => x.firstname.toUpperCase().includes('_PROF') || x.firstname.toUpperCase().includes('_TA'))];
            setResultArray(sortedResult);
        } else if (authStore.user.role === "STUDENT") {
            let topicLevelList = [];
            let sortedResult = resultList.sort((a, b) => b.timeStamp - a.timeStamp).map((sr) => {
                if (!topicLevelList.includes(sr.gameType + sr.level)) {
                    topicLevelList.push(sr.gameType + sr.level);
                    return sr;
                }
            }).filter(x => x !== undefined);
            setResultArray(sortedResult);
        }
    }

    const calculateQuizScore = (quizList) => {
        if (authStore.user.role === "PROFESSOR" || authStore.user.role === "TEACHER") {
            let result = [];
            quizList.forEach((value, key) => result.push(value));

            let sortedResult = result.sort((a, b) => b.timeStamp - a.timeStamp).map((sr) => {
                const topicLevelList = [];
                if (sr.quiz !== undefined) {
                    const filterdQuizList = sr.quiz.filter(x => {
                        if (!topicLevelList.includes(x.topic + x.level)) {
                            topicLevelList.push(x.topic + x.level);
                            return x;
                        }
                    })
                    return { ...sr, quiz: filterdQuizList }
                } else {
                    return sr
                }
            });
            sortedResult = [...sortedResult.filter(x => !x.firstname.toUpperCase().includes('_PROF') && !x.firstname.toUpperCase().includes('_TA')), ...sortedResult.filter(x => x.firstname.toUpperCase().includes('_PROF') || x.firstname.toUpperCase().includes('_TA'))];
            setQuizResultArray(sortedResult);
        } else if (authStore.user.role === "STUDENT") {
            let topicLevelList = [];
            let sortedResult = quizList.sort((a, b) => b.timeStamp - a.timeStamp).map((sr) => {
                if (sr.status === 0) {
                    if (!topicLevelList.includes(sr.topic + sr.level)) {
                        topicLevelList.push(sr.topic + sr.level);
                        return sr;
                    }
                }
            }).filter(x => x !== undefined);
            setQuizResultArray(sortedResult)
        }
    }

    const getEndingBalance = (result) => {
        let balance = 0;
        result.gameEvents.forEach((gEvent) => {
            const cTotal = gEvent.profitLoss.reduce((total, x) => total + x, 0);
            balance += cTotal
        })
        const endingBalance = parseInt(balance);
        return endingBalance < 0 ? `-$${Math.abs(endingBalance)}` : `$${endingBalance}`;
    }

    // getUserStatus only for level stocks and bonds
    const getUserStatus = (userRecords) => {
        if (classRecord.tradeRules.length === 2) {
            if (userRecords.gameEvents.find(x => x.gameType === "Bonds" && x.level === "Advanced Market Maker") !== undefined) {
                return "Completed";
            } else {
                return "In Progress"
            }
        } else {
            if (userRecords.gameEvents.find(x => x.level === "Advanced Market Maker") !== undefined) {
                return "Completed";
            } else {
                return "In Progress"
            }
        }
    }

    const getUserStatusForQuiz = (userRecords) => {
        if (classRecord.tradeRules.length === 2) {
            if (userRecords.quiz.find(x => x.type === "Bonds" && x.level === "Advanced Market Maker") !== undefined) {
                return "Completed";
            } else {
                return "In Progress"
            }
        } else {
            if (userRecords.quiz.find(x => x.level === "Advanced Market Maker") !== undefined) {
                return "Completed";
            } else {
                return "In Progress"
            }
        }
    }

    // data fetch ends
    const prettyDate = (str) => {
        let date = new Date(str);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear();
    }

    const _renderClassDetailsCard = () => {
        return <div style={showClassDetails ? { height: 250, padding: 20, margin: 10 } : { height: 0 }}>
            <div>
                <div>
                    <label>Professor: <label>{professorRecord.firstname + " " + professorRecord.lastname}</label></label>
                    <label>Academic Level: <label>{classRecord.academic}</label></label>
                    <label>Subject: <label>{classRecord.subject}</label></label>
                    <label>Game Type: <label>{classRecord.tradeRules.reduce((total, x) => total.length > 0 ? total + ", " + x : total + x, "")}</label></label>
                    <label>Registration Date: <label>{prettyDate(classRecord.regStartDate) + " - " + prettyDate(classRecord.regEndDate)}</label></label>
                    <label>Trading Date: <label>{prettyDate(classRecord.tradeStartDate) + " - " + prettyDate(classRecord.tradeEndDate)}</label></label>
                </div>
                <div>
                    <label>Extra Credit: <label>{classRecord.extraCredit}</label></label>
                    <label>No Of Attempts: <label>{classRecord.noofattempts}</label></label>
                    <label>No Of Students: <label>{classRecord.noofstudents}</label></label>
                    <label>Min Profit: <label>{classRecord.minProfit}</label></label>
                    <label>Rounds At Each Level: <label>{classRecord.roundAteachLevel}</label></label>
                    <label>Amount Awarded For Each Question: <label>{classRecord.amountAwarded}</label></label>
                </div>
            </div>
            <div>
                <label>Description</label>
                <p>{classRecord.description}</p>
            </div>
        </div>
    }

    const _renderCPTable = () => {
        if (authStore.user.role !== "STUDENT") {
            return <table className="table" >
                <thead className="thead-dark" >
                    <tr >
                        <th scope="col" > # </th>
                        <th style={{ textAlign: 'center', width: 300 }} scope="col" > Player </th>
                        {/* <th style={{ textAlign: 'center' }} scope="col" > Topic </th> */}
                        {/* <th style={{ textAlign: 'center' }} scope="col" > Level </th> */}
                        <th style={{ textAlign: 'right' }} scope="col" > Score(%) </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Passing Score(%) </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Status </th>
                        {/* <th style={{ textAlign: 'center' }} scope="col" > Result </th> */}
                        {/* <th style={{ textAlign: 'center' }} scope="col" > Amount Earned($) </th> */}
                        {/* <th style={{ textAlign: 'center' }} scope="col" > </th> */}
                        {/* <th ></th> */}
                    </tr >
                </thead>
                <tbody > {
                    quizResultArray.map((result, i) => {
                        const player = "quiz" in result ? result.quiz.sort((a, b) => b.timeStamp - a.timeStamp)[0].firstname + " " + result.quiz.sort((a, b) => b.timeStamp - a.timeStamp)[0].lastname : "";
                        return ("quiz" in result) && (showDemoAccounts ? true : !player.toUpperCase().includes('_PROF') && !player.toUpperCase().includes('_TA')) ?
                            <Fragment >
                                <tr key={i} >
                                    <th scope="row" > {i + 1} </th>
                                    <td> <a onClick={() => toggleExpandQuizTable(st => st === i + 1 ? 0 : i + 1)}>{player}</a> </td>
                                    {/* <td style={{ textAlign: 'center' }}> Multiple </td> */}
                                    {/* <td style={{ textAlign: 'center' }}> Multiple </td> */}
                                    <td style={{ textAlign: 'right' }} >{(result.quiz.reduce((total, qz) => total + parseInt(((100 / (parseInt(qz.correct) + parseInt(qz.incorrect))) * qz.correct).toString().substr(0, 4)), 0)) / result.quiz.length}% </td>
                                    <td style={{ textAlign: 'right' }} > {result.quiz[0].passingScore}% </td>
                                    <td style={{ textAlign: 'right' }} > {getUserStatusForQuiz(result)} </td>
                                    {/* <td style={{ textAlign: 'center' }} > $ {result.quiz.sort((a, b) => b.timeStamp - a.timeStamp).reduce((total, x) => total + x.amount, 0)} </td> */}
                                    {/* <td style={{ cursor: 'pointer' }}
                                        onClick={() => toggleExpandQuizTable(st => st === i + 1 ? 0 : i + 1)} > {
                                            (i + 1) === expandQuizTable ? "Hide" : "View"
                                        }History </td> */}
                                </tr >
                                <Fragment > {
                                    (i + 1) === expandQuizTable ? <ShowReportCardModel classRecord={classRecord} data={result} onClose={() => toggleExpandQuizTable(st => st === i + 1 ? 0 : i + 1)} /> : null} </Fragment>
                            </Fragment > :
                            (showDemoAccounts ? true : !result.firstname.toUpperCase().includes('_PROF') && !result.firstname.toUpperCase().includes('_TA')) ?
                                <tr key={i} >
                                    <th> {i + 1} </th>
                                    <td> {result.firstname} </td>
                                    {/* <td style={{ textAlign: 'center' }}> - </td> */}
                                    {/* <td style={{ textAlign: 'center' }}> - </td> */}
                                    <td style={{ textAlign: 'right' }} > - </td>
                                    <td style={{ textAlign: 'right' }} > - </td>
                                    <td style={{ textAlign: 'right' }} > - </td>
                                    {/* <td style={{ textAlign: 'center' }} > - </td> */}
                                    {/* <td style={{ textAlign: 'center' }} > - </td> */}
                                    {/* <td > View History </td> */}
                                </tr > : null
                    })}
                </tbody>
            </table >
        }
        else {
            return <table className="table" >
                <thead className="thead-dark" >
                    <tr >
                        <th > # </th>
                        {/* <th style={{ textAlign: 'center' }} scope="col"> Player </th> */}
                        <th style={{ textAlign: 'center' }} scope="col" > Topic </th>
                        <th style={{ textAlign: 'center', width: 150 }} scope="col" > Level </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Correct Answer </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Incorrect Answer </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Score </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Passing Score </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Amount Earned </th>
                        <th style={{ textAlign: 'center' }} scope="col" > Result </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody> {
                    quizResultArray.map((qz, i, arr) => {
                        return <tr key={i} >
                            <td> {i + 1} </td>
                            {/* <td style={{ color: 'rgba(0,0,0,0.6)' }} > {authStore.user.name} </td> */}
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'center' }} > {qz.topic} </td>
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'center' }} > {qz.level} </td>
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > {qz.correct} </td>
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > {qz.incorrect} </td>
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > {qz.score} </td>
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > {qz.passingScore} </td>
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > {qz.amount} </td>
                            <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'center' }} > {qz.status == 0 ? "Pass" : qz.status == 3 ? "Fail" : "-"} </td>
                            <td > </td>
                        </tr >
                    })
                } </tbody>
            </table >
        }
    }

    const _renderGPTable = () => {
        if (authStore.user.role !== "STUDENT") {
            return <table className="table" >
                <thead className="thead-dark" >
                    <tr>
                        <th style={{ textAlign: 'left' }} scope="col" > # </th>
                        <th style={{ textAlign: 'center', width: 300 }} scope="col" > Player </th>
                        {/* <th style={{ textAlign: 'center' }} scope="col" > Game Type </th> */}
                        {/* <th style={{ textAlign: 'center' }} scope="col" > Level </th> */}
                        {/* <th style={{ textAlign: 'right' }} scope="col" > Opening Balance </th> */}
                        {/* {
                            [...Array(parseInt(classRecord.roundAteachLevel)).keys()].map((x, i) => (<th scope="col" style={{ textAlign: 'right' }}>{`Round#${i + 1}`}</th>))
                        } */}
                        <th style={{ textAlign: 'right' }} scope="col" > Ending Balance </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Overall Status </th>
                        {/* <th style={{ textAlign: 'center' }} scope="col" > </th> */}
                    </tr >
                </thead>
                <tbody >
                    {
                        resultArray.map((result, i) => {
                            if ('gameEvents' in result && (showGameDemoAccounts ? true : !result.firstname.toUpperCase().includes('_PROF') && !result.firstname.toUpperCase().includes('_TA'))) {
                                return <Fragment >
                                    <tr >
                                        <th scope="row" > {i + 1} </th>
                                        <td> <a onClick={() => toggleExpandGameTable(st => st === i + 1 ? 0 : i + 1)}>{result.firstname + " " + result.lastname}</a> </td>
                                        {/* <td style={{ textAlign: 'center' }}> Multiple </td> */}
                                        {/* <td style={{ textAlign: 'center' }}> Multiple </td> */}
                                        {/* <td style={{ textAlign: 'right' }} >-</td> */}

                                        {/* {
                                            [...Array(parseInt(classRecord.roundAteachLevel)).keys()].map((x) => (<th scope="col" style={{ textAlign: 'right' }}>-</th>))
                                        } */}

                                        <td style={{ textAlign: 'right' }} >{getEndingBalance(result)}</td>
                                        <td style={{ textAlign: 'right' }} >{getUserStatus(result)}</td>
                                        {/* <td style={{ cursor: 'pointer' }}
                                            onClick={
                                                () => toggleExpandGameTable(st => st === i + 1 ? 0 : i + 1)
                                            } > {
                                                (i + 1) === expandGameTable ? "Hide" : "View"
                                            }History </td> */}
                                    </tr >
                                    <Fragment >
                                        {
                                            (i + 1) === expandGameTable ?
                                                <ShowGameReportModel classRecord={classRecord} data={result} onClose={() => toggleExpandGameTable(st => st === i + 1 ? 0 : i + 1)} />
                                                : null
                                        }
                                    </Fragment>
                                </Fragment >
                            } else {
                                if ((showGameDemoAccounts ? true : !result.firstname.toUpperCase().includes('_PROF') && !result.firstname.toUpperCase().includes('_TA'))) {
                                    return <tr >
                                        <th scope="row" > {i + 1} </th>
                                        <td > {result.firstname + " " + result.lastname} </td>
                                        {/* <td style={{ textAlign: 'center' }}> - </td> */}
                                        {/* <td style={{ textAlign: 'center' }}> - </td> */}
                                        {/* <td style={{ textAlign: 'right' }} > - </td> */}
                                        {/* {
                                            [...Array(parseInt(classRecord.roundAteachLevel)).keys()].map((x) => (<th scope="col" style={{ textAlign: 'right' }}>-</th>))
                                        } */}
                                        <td style={{ textAlign: 'right' }} > - </td>
                                        <td style={{ textAlign: 'right' }} > - </td>
                                        {/* < td > View History </td> */}
                                    </tr >
                                }
                            }
                        })
                    } </tbody>
            </table >
        }
        else {
            return <table className="table" >
                <thead className="thead-dark" >
                    <tr>
                        <th> # </th>
                        <th style={{ textAlign: 'left' }} scope="col" > Game Type </th>
                        <th style={{ textAlign: 'center', width: 150 }} scope="col" > Level </th>
                        <th style={{ textAlign: 'right' }} scope="col" > Opening Balance </th>
                        {
                            [...Array(parseInt(classRecord.roundAteachLevel)).keys()].map((x, key) => {
                                return <th key={key} style={{ textAlign: 'right' }} scope="col" > Round{key + 1} </th>
                            })
                        }
                        <th style={{ textAlign: 'right' }} scope="col" > Ending Balance </th>
                        <th style={{ textAlign: 'center' }} scope="col" > Status </th>
                        <th style={{ textAlign: 'center' }} scope="col" > </th>
                    </tr >
                </thead>
                <tbody >
                    {
                        resultArray.sort((a,b) => b.timeStamp - a.timeStamp).map((qz, i, arr) => {
                            let arry = [...arr].slice(0, 8); //mm , tra
                            let openingBalance = [...arry].reverse().slice(0, arry.length - i - 1).reduce((total, x) => total + x.profitLoss.reduce((t, y) => t + y, 0), 0);
                            let endingBalance = openingBalance + qz.profitLoss.reduce((total, x) => x + total, 0);
                            return <tr key={i} >
                                <td> {i + 1} </td>
                                {/* <td> {authStore.user.name} </td> */}
                                <td > {qz.gameType} </td>
                                <td style={{ textAlign: 'center' }}> {qz.level} </td>
                                <td style={{ textAlign: 'right' }} > {openingBalance < 0 ? `-$${Math.abs(openingBalance)}` : `$${Math.abs(openingBalance)}`} </td>
                                {classRecord.roundAteachLevel >= 1 ? <td style={{ textAlign: 'right' }} >
                                    {
                                        qz.profitLoss != undefined && qz.profitLoss[0] != undefined ?
                                            qz.profitLoss[0] < 0 ?
                                                String(qz.profitLoss[0]).replace("-", "-$") :
                                                "$" + qz.profitLoss[0] : "-"
                                    }
                                </td> : null}
                                {
                                    classRecord.roundAteachLevel >= 2 ?
                                        <td style={{ textAlign: 'right' }} >
                                            {
                                                qz.profitLoss != undefined && qz.profitLoss[1] != undefined ?
                                                    qz.profitLoss[1] < 0 ?
                                                        String(qz.profitLoss[1]).replace("-", "-$") :
                                                        "$" + qz.profitLoss[1] : "-"
                                            }
                                        </td> : null
                                }
                                {
                                    classRecord.roundAteachLevel >= 3 ?
                                        <td style={{ textAlign: 'right' }} > {
                                            qz.profitLoss != undefined && qz.profitLoss[2] != undefined ?
                                                qz.profitLoss[2] < 0 ?
                                                    String(qz.profitLoss[2]).replace("-", "-$") :
                                                    "$" + qz.profitLoss[2] : "-"
                                        }
                                        </td> : null
                                }
                                {
                                    classRecord.roundAteachLevel >= 4 ?
                                        <td style={{ textAlign: 'right' }} > {
                                            qz.profitLoss != undefined && qz.profitLoss[3] != undefined ?
                                                qz.profitLoss[3] < 0 ?
                                                    String(qz.profitLoss[3]).replace("-", "-$") :
                                                    "$" + qz.profitLoss[3] : "-"
                                        }
                                        </td> : null
                                }
                                {
                                    classRecord.roundAteachLevel >= 5 ?
                                        <td style={{ textAlign: 'right' }} > {
                                            qz.profitLoss != undefined && qz.profitLoss[4] != undefined ?
                                                qz.profitLoss[4] < 0 ?
                                                    String(qz.profitLoss[4]).replace("-", "-$") :
                                                    "$" + qz.profitLoss[4] : "-"
                                        }
                                        </td> : null
                                }
                                <td style={{ textAlign: 'right' }} >
                                    {
                                        endingBalance < 0 ? `-$${Math.abs(endingBalance)}` : `$${Math.abs(endingBalance)}`
                                    }
                                </td>
                                <td style={{ textAlign: 'center' }}> Completed </td>
                                <td > </td>
                            </tr >
                        })}
                </tbody>
            </table >
        }
    }

    const getCompletedPlayers = () => {
        if (resultArray.find(x => "gameEvents" in x) != undefined) {
            if (classRecord.tradeRules.includes("Stocks") && classRecord.tradeRules.includes("Bonds")) {
                let count = 0;
                resultArray.forEach((gResult) => {
                    if ('gameEvents' in gResult) {
                        let result = gResult.gameEvents.filter(x => x.status === 0).length;
                        if (result === 8) {
                            count++;
                        }
                    } else {
                        return 0;
                    }
                });
                return count;
            } else {
                let count = 0;
                resultArray.forEach((gResult) => {
                    if ('gameEvents' in gResult) {
                        let result = gResult.gameEvents.filter(x => x.status === 0).length;
                        if (result === 4) {
                            count++;
                        }
                    } else {
                        return 0;
                    }
                });
                return count;
            }
        } else {
            return 0;
        }
    }

    const getTop5Students = () => {
        let students = [];
        quizResultArray.forEach((qResult) => {
            if (qResult.quiz !== undefined) {
                let amount = qResult.quiz.reduce((total, x) => total + parseInt(x.amount), 0);
                students.push({ "firstname": qResult.firstname, "lastname": qResult.lastname, amount });
            }
        });

        resultArray.forEach((gResult) => {
            if (gResult.gameEvents !== undefined) {
                let amount = gResult.gameEvents.reduce((total, x) => total + parseInt(x.openingBalance) + parseInt(x.profitLoss.reduce((sum, y) => sum + parseInt(y), 0)), 0);
                students = students.map((student) => student.firstname + student.lastname === gResult.firstname + gResult.lastname ? { ...student, amount: parseInt(student.amount) + parseInt(amount) } : student);
            }
        });

        students = students.sort((a, b) => b.amount - a.amount)
        const names = students.map(x => x.firstname);
        const amounts = students.map(x => x.amount);
        const hBarData = {
            labels: names.length <= 5 ? names : names.slice(0, 5),
            datasets: [
                {
                    label: '',
                    backgroundColor: ['rgba(10,182,4,1)', 'rgba(10,182,4,0.8)', 'rgba(10,182,4,0.65)', 'rgba(10,182,4,0.5)', 'rgba(10,182,4,0.4)',],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: amounts.slice(0, 5)
                }
            ]
        };
        setHorizontalBarData(hBarData);
    }

    const getPieChartData = async () => {
        if (authStore.user.role !== "STUDENT") {
            let students = [];
            quizResultArray.forEach((qResult) => {
                if (qResult.quiz !== undefined) {
                    let amountForQuestion = classRecord.amountAwarded;
                    let amount = qResult.quiz.reduce((total, x) => total + parseInt(x.amount), 0);
                    let maxAmount = qResult.quiz.reduce((total, x) => total + (parseInt(amountForQuestion) * (parseInt(x.correct) + parseInt(x.incorrect))), 0);
                    if (amount > ((maxAmount / 100) * 90))
                        students.push({ "firstname": qResult.firstname, "lastname": qResult.lastname, status: 'Proficient', percentage: 90 });
                    else if (amount > ((maxAmount / 100) * 80))
                        students.push({ "firstname": qResult.firstname, "lastname": qResult.lastname, status: 'Advanced', percentage: 80 });
                    else if (amount > ((maxAmount / 100) * classRecord.passingScore))
                        students.push({ "firstname": qResult.firstname, "lastname": qResult.lastname, status: 'Pass', percentage: classRecord.passingScore });
                    else
                        students.push({ "firstname": qResult.firstname, "lastname": qResult.lastname, status: 'Failed', percentage: null });
                } else {
                    students.push({ "firstname": qResult.firstname, "lastname": qResult.lastname, status: 'In Progress', percentage: null });
                }
            });
            const data = {
                labels: ['Proficient', 'Advanced', 'Passed', 'Failed', 'In Progress'],
                datasets: [{
                    data: [students.filter(x => x['status'] === 'Proficient').length, students.filter(x => x['status'] === 'Advanced').length, students.filter(x => x['status'] === 'Pass').length, students.filter(x => x['status'] === 'Failed').length, students.filter(x => x['status'] === 'In Progress').length],
                    backgroundColor: [
                        '#FFCE56',
                        '#FF6384',
                        '#36A2EB',
                        '#F15435',
                        '#F104A5'
                    ],
                    hoverBackgroundColor: [
                        '#F15435',
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#F104A5'
                    ]
                }]
            }
            setPieData(data);
        } else {
            let earned = 0;
            let lost = 0;
            quizResultArray.forEach((qResult) => {
                if ('amount' in qResult) {
                    // earned += qResult.correct * classRecord.amountAwarded;
                    earned += qResult.correct;
                    // lost += qResult.incorrect * classRecord.amountAwarded;
                    lost += qResult.incorrect;
                }
            });
            const data = {
                labels: ['Correct', 'Incorrect'],
                datasets: [{
                    data: [earned, lost],
                    backgroundColor: [
                        '#FFCE56',
                        '#FF6384',
                    ],
                    hoverBackgroundColor: [
                        '#F15435',
                        '#FF6384',
                    ]
                }]
            }
            setPieData(data);
        }
    }

    const getPieChart2Data = async () => {
        if (authStore.user.role !== "STUDENT") {
            let students = [];
            let yetToPlayStudents = [];
            resultArray.forEach((gResult) => {
                if ('gameEvents' in gResult) {
                    let student = { firstname: gResult.firstname, lastname: gResult.lastname, amount: 0 }
                    gResult.gameEvents.forEach((gEvent) => {
                        const amount = gEvent.profitLoss.reduce((total, x) => total + x, 0);
                        student['amount'] = student['amount'] + amount;
                    });
                    students.push(student);
                } else {
                    yetToPlayStudents.push(gResult);
                }
            });
            let sortedList = students.sort((x, y) => y.amount - x.amount);
            let amounts = sortedList.map(x => x.amount);
            let sumofamounts = amounts.reduce((total, x) => total + x, 0);
            let numofamounts = amounts.length;
            let avgofamounts = sumofamounts / numofamounts;
            let list1 = sortedList.filter(x => x.amount <= 0);
            let list2 = sortedList.filter(x => x.amount > 0 && x.amount < avgofamounts);
            let list3 = sortedList.filter(x => x.amount >= avgofamounts && x.amount > 0);

            const data = {
                labels: ['Low Earners', `Mid Earners`, `High Earners`, `Yet to Play`],
                datasets: [{
                    data: [list1.length, list2.length, list3.length, yetToPlayStudents.length],
                    backgroundColor: [
                        '#F15435',
                        '#FF6384',
                        '#8E41D6',
                        '#8040D6',
                    ],
                    hoverBackgroundColor: [
                        '#FFCE56',
                        '#FF6384',
                        '#8E41D6',
                        '#8040D6',
                    ]
                }]
            }
            setPieData1(data);
        } else {
            let profit = 0;
            let loss = 0;
            resultArray.forEach((gResult) => {
                gResult.profitLoss.forEach((val) => {
                    if (val > 0) {
                        profit += val
                    } else {
                        loss += Math.abs(val);
                    }
                })
            });

            const data = {
                labels: ["Profit", "Loss"],
                datasets: [{
                    data: [profit, loss],
                    backgroundColor: [
                        '#53D504',
                        '#F15435',
                    ],
                    hoverBackgroundColor: [
                        '#51D800',
                        '#FFCE56',
                    ]
                }]
            }
            setPieData1(data);
        }
    }

    const getLineChartData = () => {
        const stockLevels = ['Trader(Stocks)', 'Market Maker', 'Advanced Trader', 'Advanced Market Maker'];
        const bondLevels = ['Trader(Bonds)', 'Market Maker', 'Advanced Trader', 'Advanced Market Maker']
        let levels = [];
        let students = [];
        if (classRecord.tradeRules.includes("Stocks") && classRecord.tradeRules.includes("Bonds")) {
            levels = [...stockLevels, ...bondLevels];
        } else if (classRecord.tradeRules.includes("Stocks")) {
            levels = stockLevels;
        } else {
            levels = bondLevels;
        }

        if (authStore.user.role !== "STUDENT") {
            quizResultArray.forEach((qResult) => {
                if (qResult.quiz !== undefined) {
                    qResult.quiz.forEach((eResult) => {
                        students.push({ firstname: qResult.firstname, lastname: qResult.lastname, "type": eResult.topic, level: eResult.level, amount: eResult.amount });
                    })
                }
            });

            resultArray.forEach((gResult) => {
                if (gResult.gameEvents !== undefined) {
                    gResult.gameEvents.forEach((eEvent) => {
                        students.push({ firstname: gResult.firstname, lastname: gResult.lastname, "type": eEvent.gameType, level: eEvent.level, amount: (eEvent.openingBalance + eEvent.profitLoss.reduce((total, x) => total + x, 0)) });
                    })
                }
            });
        } else {
            quizResultArray.forEach((qResult) => {
                if ("amount" in qResult) {
                    students.push({ email: qResult.studentID, "type": qResult.topic, level: qResult.level, amount: qResult.amount });
                }
            });

            resultArray.forEach((gResult) => {
                students.push({ email: gResult.uid, "type": gResult.gameType, level: gResult.level, amount: (gResult.openingBalance + gResult.profitLoss.reduce((total, x) => total + x, 0)) });
            });
        }

        const data = {
            labels: levels,
            datasets: [
                {
                    label: "",
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: '#7935F1',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [students.filter(x => x.type === "Stocks" && x.level === "Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Stocks" && x.level === "Market Maker").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Stocks" && x.level === "Advanced Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Stocks" && x.level === "Advanced Market Maker").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Market Maker").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Advanced Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Advanced Market Maker").reduce((total, x) => total + parseInt(x.amount), 0)]
                }
            ]
        };
        setGamevsQuizData(data);
    }

    const getVerticalChartData = () => {
        let students = [];
        quizResultArray.forEach(async (qResult) => {
            if (qResult.quiz !== undefined) {
                let amount = qResult.quiz.reduce((total, x) => total + parseInt(x.amount), 0);
                students.push({ "firstname": qResult.firstname, "lastname": qResult.lastname, amount });
            }
        });

        resultArray.forEach(async (gResult) => {
            if (gResult.gameEvents !== undefined) {
                let amount = gResult.gameEvents.reduce((total, x) => total + parseInt(x.openingBalance) + parseInt(x.profitLoss.reduce((sum, y) => sum + parseInt(y), 0)), 0);
                students = students.map((student) => student.firstname + student.lastname === gResult.firstname + gResult.lastname ? { ...student, amount: parseInt(student.amount) + parseInt(amount) } : student);
            }
        });

        const names = students.map(x => x.firstname);
        const amounts = students.map(x => x.amount);

        const data = {
            labels: [...names],
            datasets: [
                {
                    label: '',
                    backgroundColor: [...new Array(names.length).keys()].map((val, i) => '#35C1F1'),
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: [...amounts]
                }
            ]
        };
        setAllStudentsPerformance(data);
    }

    const getDonutChartData = async () => {
        if (authStore.user.role === "STUDENT") {
            const quizBalance = await quizResultArray.reduce((total, x) => 'amount' in x ? total + x.amount : total, 0);
            const gameBalance = await resultArray.reduce((total, x) => total + x.profitLoss.reduce((total1, y) => total1 + y, 0), 0);
            const data = {
                labels: [
                    'Quiz Balance',
                    'Game Balance',
                ],
                datasets: [{
                    data: [quizBalance, gameBalance],
                    backgroundColor: [
                        '#A5C00C',
                        '#7F0A96',
                    ],
                    hoverBackgroundColor: [
                        '#A5C00C',
                        '#7F0A96',
                    ]
                }]
            };
            setDonutData(data);
        } else {
            const completedPlayerValue = getCompletedPlayers();
            const data = {
                labels: [
                    'Course InProgress',
                    'Course Completed'
                ],
                datasets: [{
                    data: [(classRecord.students.length - completedPlayerValue), completedPlayerValue],
                    backgroundColor: [
                        '#D69041',
                        '#BD327A'
                    ],
                    hoverBackgroundColor: [
                        '#D69041',
                        '#BD327A'
                    ]
                }]
            };
            setDonutData(data);
        }
    }

    const getStudentStatus = () => {
        if (classRecord.tradeRules.includes("Stocks") && classRecord.tradeRules.includes("Bonds")) {
            let res = resultArray.find(x => x['status'] === 0 && x.gameType === "Bonds" && x.level === "Advanced Market Maker")
            if (res) {
                return "Completed"
            } else
                return "In Progress"
        } else {
            let res = resultArray.find(x => x['status'] === 0 && x.level === "Advanced Market Maker")
            if (res) {
                return "Completed"
            } else
                return "In Progress"
        }
    }

    const getStudentBestPerformedInQuiz = () => {
        const list = quizResultArray.filter(x => "amount" in x);
        let sortedList = list.sort((a, b) => b.amount - a.amount)[0];
        return sortedList !== undefined ? `Quiz: ${sortedList.topic} - ${sortedList.level}` : "NA";
    }

    const getStudentBestPerformedInGame = () => {
        let sortedList = resultArray.sort((a, b) => b.profitLoss.reduce((total, x) => total + x, 0) - a.profitLoss.reduce((total, x) => total + x, 0))[0];
        return sortedList !== undefined ? `Game: ${sortedList.gameType} - ${sortedList.level}` : "NA";
    }

    return (classRecord && professorRecord && resultArray && quizResultArray) && showCharts ?
        <div style={maximize ? { ...props.style, top: 0, bottom: 0, zIndex:250 } : { ...props.style, top: 45, bottom: 80 }} className="class_dashboard" >
            <div className='dashboard_navbar'>
                <label>Class Dashboard - {classRecord.classname}</label>
                <i onClick={() => toggleClassDetails(!showClassDetails)} data-tip data-for='classlist' className="fas fa-clipboard-list"></i>
                <i onClick={() => window.print()} data-tip data-for='print' className="fas fa-print"></i>
                <i onClick={props.minimize} className="fas fa-window-minimize minus"></i>
                {
                    maximize ?
                        <i onClick={() => toggleMaximize(false)} className="fas fa-compress-arrows-alt maximize"></i> :
                        <i onClick={() => toggleMaximize(true)} className="fas fa-window-maximize maximize"></i>
                }
                <i onClick={props.close} data-tip data-for='close' className="fas fa-times"></i>
                <ReactTooltip multiline={false} place="bottom" id='classlist'>Click to toggle the detailed class informaion</ReactTooltip>
                <ReactTooltip multiline={false} place="bottom" id='print'>Print</ReactTooltip>
                <ReactTooltip multiline={false} place="bottom" id='close'>Close</ReactTooltip>
            </div>

            {_renderClassDetailsCard()}

            <div>
                <div>
                    <label style={{ textAlign: 'center', display: 'block' }}>{authStore.user.role !== "STUDENT" ? "Overall Progress" : "Game VS Quiz Earnings"}</label>
                    <Doughnut height={250} data={showCharts ? donutData : null} />
                </div>
                {/* Top 5 students */}
                {
                    authStore.user.role !== "STUDENT" ?
                        <div>
                            <label style={{ textAlign: 'center', display: 'block' }}>TOP 5 Students</label>
                            <HorizontalBar width={210} height={170} data={showCharts ? horizontalBarData : null} />
                        </div> :
                        <div style={{ display: 'flex', flexDirection: 'column', boxShadow: 'none' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginBottom: 10 }}>
                                <div style={{ display: 'flex', flex: 1, boxShadow: '0px 0px 3px rgba(0,0,0,0.3)', borderRadius: 10, marginLeft: 5, justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    <img style={{ width: '90%', height: '70%', opacity: 0.2 }} src={require('./res/img/Path 11.png')} />
                                    <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <label style={{ fontSize: 16 }}>Current Status</label>
                                        <label style={{ fontSize: 14, opacity: 0.8 }}>{getStudentStatus()}</label>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flex: 1, boxShadow: '0px 0px 3px rgba(0,0,0,0.3)', borderRadius: 10, marginLeft: 5, justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    <img style={{ width: '90%', height: '70%', opacity: 0.2 }} src={require('./res/img/Path 14.png')} />
                                    <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <label style={{ fontSize: 16 }}>My Rank</label>
                                        <label style={{ fontSize: 17, opacity: 0.8 }}>{myRank}</label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                                <div style={{ display: 'flex', flex: 1, boxShadow: '0px 0px 3px rgba(0,0,0,0.3)', borderRadius: 10, marginRight: 5, justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    <img style={{ width: '90%', height: '70%', opacity: 0.2 }} src={require('./res/img/Path 13.png')} />
                                    <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <label style={{ fontSize: 16 }}>Best Performed Quiz-Game Level</label>
                                        <label style={{ fontSize: 14, opacity: 0.8 }}>{getStudentBestPerformedInQuiz()}</label>
                                        <label style={{ fontSize: 14, opacity: 0.8 }}>{getStudentBestPerformedInGame()}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <div>
                    <label style={{ textAlign: 'center', display: 'block' }}>Coursework Results</label>
                    <Pie height={240} data={showCharts ? pieData : null} />
                </div>
                <div>
                    <label style={{ textAlign: 'center', display: 'block' }}>Game Results</label>
                    <Pie height={240} data={showCharts ? pieData1 : null} />
                </div>
            </div>
            {/* row 2 */}
            <div>
                <label style={{ textAlign: 'center', display: 'block' }}>{authStore.user.role === "STUDENT" ? 'Performance on Each Level' : 'Average Performance Per Game Type/Level & Curriculum Topic/Level'}</label>
                <Line height={70} data={showCharts ? gamevsquizData : null} />
            </div>
            {/* row 3 */}
            {
                authStore.user.role !== "STUDENT" ?
                    <div>
                        <label style={{ textAlign: 'center', display: 'block' }}>Overall Students Performance</label>
                        <Bar height={80} data={showCharts ? allStudentsPerformance : null} />
                    </div> : <div style={{ display: 'none' }}></div>
            }
            {/* row 4 */}
            <div>
                <div><label>Coursework Performance</label> <div>{authStore.user.role !== "STUDENT" ? <div><input onChange={() => toggleDemoAccounts(st => !st)} type="checkbox" checked={showDemoAccounts} />{!showDemoAccounts ? "  Show Support Accounts" : "  Show Support Accounts"}</div> : null}</div></div>
                {_renderCPTable()}
            </div>
            {/* row 5 */}
            <div>
                <div><label>Game Performance</label><div>{authStore.user.role !== "STUDENT" ? <div><input onChange={() => toggleGameDemoAccounts(st => !st)} type="checkbox" checked={showGameDemoAccounts} />{!showGameDemoAccounts ? "  Show Support Accounts" : "  Show Support Accounts"}</div> : null}</div></div>
                {_renderGPTable()}
            </div>
        </div > :
        <div style={{ position: 'fixed', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: 0, bottom: 0, left: 0, right: 0, zIndex: 999 }}>
            {status !== "NO DATA FOUND" ? <LoadingWheel /> : <div style={{ padding: '10px 20px', backgroundColor: 'white', boxShadow: '0px 0px 3px rgba(0,0,0,0.3)', fontSize: 14 }}>{status}</div>}
        </div>
}