import React from "react";
import AuthService from "../services/authService";
import { RouteComponentProps, withRouter } from "react-router";
import Validate from "../Helpers/Utility/Validation";

export interface ForgotProps extends RouteComponentProps {
  isLoading: any;
}

class ForgotPassword extends React.Component<ForgotProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: "",
      validateMsg: "",
      emailSent: false,
      resetStatus: false
    };
  }

  setValue(e: any) {
    this.setState({ email: e.target.value });
  }

  async validateResetEmail() {
    let validationError = (await Validate.validateEmail(
      this.state.email,
      "email"
    )) as any;
    this.setState({ validateMsg: validationError.errors.email });

    if (validationError.isValid) {
      this.sendResetEmail();
    }
  }

  async sendResetEmail() {
    this.props.isLoading(true);

    if (this.state.validateMsg == "") {
      let response = await AuthService.resetPassword(this.state.email);
      this.setState({ resetStatus: true });
      this.setState({ emailSent: response.isSend });
    }

    this.props.isLoading(false);
  }

  render() {
    return (
      <div className="modal-body signin-modal">
        <h2>Forgot Password</h2>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <form>
              <div className="form-group">
                <div className="row mt-10 mb-10">
                  <div className="col-md-12">
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      className="form-control login-input"
                      placeholder="Email"
                      onChange={e => this.setValue(e)}
                    />
                    <div className="validation-error">
                      {this.state.validateMsg}{" "}
                    </div>
                  </div>
                </div>

                {!this.state.resetStatus ? null : this.state.emailSent ? (
                  <div className="col-xs-12 col-sm-12 resetMsg">
                    Please check your email and click on the provided link to
                    reset your password
                  </div>
                ) : (
                  <div className="col-xs-12 col-sm-12 resetMsg">
                    Please provide a registered email
                  </div>
                )}

                <button
                  style={{width:160, float:'right'}}
                  type="button"
                  id="sign-in-button"
                  className="login-btn"
                  onClick={() => this.validateResetEmail()}
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ForgotPassword);
