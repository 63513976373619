import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ContestDetails } from "../../interfaces";
import ROUTES from "../../ROUTES";
import studentService from "../../services/studentService";
import contestService from "../../services/contestService";
import "./ContestList.css";
import Toast from "../../components/Toast";
import "jquery";
import DeleteConfirm from "../Contest/DeleteConfirm";
import authStore from "../../models/AuthStore";
import LoadingWheel from "../../components/LoadingWheel";
import CreateContest from "./components/CreateContest";
import * as queryString from "query-string";
import config from '../../config';

// Models
import ViewLeaders from '../../models/viewLeaders';
import Alert from '../../models/cust_alert'
// icons
import { MdAdd } from "react-icons/md";

declare var $: any;
export interface contestProps extends RouteComponentProps { }

export interface contestState { }

class ProfessorHostedContestList extends Component<any, contestState> {
  notifySuccess = () => Toast.success();
  notifyError = () => Toast.error();
  yearList = [] as any;
  state = {
    contests: Array<ContestDetails>(),
    invitedContests: Array<ContestDetails>(),
    id: "",
    showLoader: true,
    selectedYear: new Date().getFullYear(),
    studWiseresult: false,

    currentUser: null,
    collapsed: true,
    contestname: "",

    //toggle viewLeaderModel
    toggleViewLeaderModel: false,
    viewLeaderModelData: {},
    leaderBoardType: "CONTEST",

    //for filter button
    toggleFilter: false,
    sortBy: "START_DSC",
    tempcontests: Array<ContestDetails>(),
    tempInvitedContests: Array<ContestDetails>(),

    // alert
    toggleAlert: false,
    warningText: "",

    filtersSwitches: "ONGOING"
  };

  addnewContest() {
    this.props.history.push(ROUTES.CONTESTREG);
  }

  getContests() {
    contestService
      .getAllContestsForProfessor()
      .then(res => {
        this.setState({ contests: res.data, tempcontests: res.data, showLoader: false });
      })
      .catch(error => { });
  }

  getUsercontests() {
    contestService
      .getAllContestsForStudent()
      .then(res => {
        this.setState({ contests: res.data, tempcontests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUsercontestsForPlayer() {
    contestService
      .getAllContestsForPlayer()
      .then(res => {
        const sortedContests = res.data.filter((x:any) => !x.contestname.includes("/@#FT#@/"));
        this.setState({ contests: sortedContests, tempcontests: sortedContests, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUserInvitedcontestsForPlayer() {
    contestService
      .getAllInvitedContestsForPlayer()
      .then(res => {
        this.setState({ invitedContests: res.data, tempInvitedContests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUsercontestsForTeacher() {
    contestService
      .getAllContestsForTeacher()
      .then(res => {
        this.setState({ contests: res.data, tempcontests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getUserInvitedcontestsForTeacher() {
    contestService
      .getAllInvitedContestsForPlayer()
      .then(res => {
        this.setState({ invitedContests: res.data, tempInvitedContests: res.data, showLoader: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  filterClicked = (type: any) => {
    this.setState(st => ({ ...st, sortBy: type }));
    switch (type) {
      case "START_DSC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests] }))
        break;
      case "START_ASC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests].reverse() }))
        break;
      case "NAME_DSC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests].sort((a: any, b: any) => a.contestname.toString().toLowerCase() < b.contestname.toString().toLowerCase() ? 1 : -1) }))
        break;
      case "NAME_ASC":
        this.setState(st => ({ ...st, contests: [...this.state.tempcontests].sort((a: any, b: any) => a.contestname.toString().toLowerCase() > b.contestname.toString().toLowerCase() ? 1 : -1) }))
        break;
    }
  }

  async componentDidMount() {
    let params = await queryString.parse(this.props.location.search);

    this.setState({ showLoader: true });

    if (authStore.user.role == "PROFESSOR") {
      this.getContests();
    } else if (authStore.user.role == "STUDENT") {
      this.getUsercontests();
    } else if (authStore.user.role == "TEACHER") {
      this.getUsercontestsForTeacher();
    } else {
      this.getUsercontestsForPlayer();
      // this.getUserInvitedcontestsForPlayer();
    }
    for (let i = 0; i < 4; i++) {
      this.yearList.push(this.state.selectedYear + i);
    }
  }

  editContest({ id, tradeEndDate }: any) {
    this.props.history.push({
      pathname: ROUTES.CONTESTREG,
      state: { id: id }
    });
  }

  convertDate(str: any) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, month, date.getFullYear()].join("-");
  }

  showTopic(contestname: any) {
    this.props.history.push({ pathname: ROUTES.TOPIC, state: contestname });
  }
  showContestTopic(contestname: any) {
    console.log("showContestTopic");
    this.props.history.push({
      pathname: ROUTES.CONTEST_TOPIC,
      state: contestname
    });
  }

  async setYearFilter(e: any) {
    await this.setState({ selectedYear: e.target.value });
  }
  showModal() {
    $("#contestStudentInviteModal").modal("show");
  }
  divStyle = {
    "margin-top": "10px"
  };

  getCardStatus = (item: any) => {
    let { regStartDate, regEndDate, tradeStartDate, tradeEndDate } = item;
    let regStart = new Date(regStartDate);
    let regEnd = new Date(regEndDate);;
    let tradeStart = new Date(tradeStartDate);
    let tradeEnd = new Date(tradeEndDate);;
    let today = new Date();
    if (today > regEndDate) {
      return "Status: New"
    } else if (regEnd > today && tradeEnd > today) {
      return "Status: In Progress"
    } else {
      return "Status: Closed"
    }
  }

  searchCards = (e: any) => {
    const text: string = e.target.value.toLowerCase();
    if (text.length > 0) {
      const searchList = this.state.tempcontests.filter(x => x.contestname !== undefined ? x.contestname.toLowerCase().includes(text) : false);
      this.setState(st => ({ ...st, contests: searchList }))
    } else {
      this.setState(st => ({ ...st, contests: this.state.tempcontests }))
    }
  }

  prettyDate = (str: any) => {
    let date = new Date(str);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear();
  }

  render() {
    return (
      <div className="section wb">
        <div className="backgroundImage">
          <div className="container" style={{height:60, color:'white', display:'flex', flexDirection:'row', alignItems:'center'}}>
            <h3 style={{color:'white', paddingTop: 6, fontSize:20, fontWeight: 500}}>Hosted Contests</h3>
            
            <div onClick={() => this.addnewContest()} className="_addClass">
                <MdAdd size={22} style={{fontWeight:900}}/>
                <div style={{fontSize:15, marginLeft:2, fontWeight: 450}}>Create Contest</div>
            </div>

            {/* serach view */}
            <div className="card_search" style={{marginRight:20}}>
              <i className="fas fa-search"></i>
              <input onChange={this.searchCards} type="text" placeholder="Search by contest name..." />
            </div>

            {/* Drop down to apply filter */}
            <div className="filter_dropdown_1" style={{marginRight:20}}>
              <label onClick={() => this.setState(st => ({...st, filtersSwitches:"ALL"}))} className={this.state.filtersSwitches === "ALL" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>ALL</label>
              <label onClick={() => this.setState(st => ({...st, filtersSwitches:"ONGOING"}))} className={this.state.filtersSwitches === "ONGOING" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>ONGOING</label>
              <label onClick={() => this.setState(st => ({...st, filtersSwitches:"COMPLETED"}))} className={this.state.filtersSwitches === "COMPLETED" ? "active filter_dropdown_1_item":"filter_dropdown_1_item"}>COMPLETED</label>
            </div>

            {/* Drop down to apply filter */}
            <div className="filter_dropdown">
              <div>
                <label style={{color:'white'}}>Sort by </label><i color="white" onClick={() => this.setState(st => ({ ...st, toggleFilter: !this.state.toggleFilter }))} className={"fas fa-chevron-down"}></i>
                <div>
                  <ul>
                    <li style={this.state.sortBy === "NAME_ASC" ? { opacity: 1  } :{}} onClick={() => this.filterClicked("NAME_ASC")}>Name(ASC to DSC)</li>
                    <li style={this.state.sortBy === "NAME_DSC" ? { opacity: 1  } :{}} onClick={() => this.filterClicked("NAME_DSC")}>Name(DSC to ASC)</li>
                    <li style={this.state.sortBy === "START_ASC" ? { opacity: 1  } :{}} onClick={() => this.filterClicked("START_ASC")}>Start date(ASC to DSC)</li>
                    <li style={this.state.sortBy === "START_DSC" ? { opacity: 1  } :{}} onClick={() => this.filterClicked("START_DSC")}>Start date(DSC to ASC)</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="container cmt20">
          {/* {this.state.classes && this.state.classes.length > 0 ? (
            <div className="form-group year-filter">
              <select
                className="form-control"
                onChange={e => this.setYearFilter(e)}
              >
                {this.yearList.map((item: any) => {
                  return item == this.state.selectedYear ? (
                    <option selected>{item}</option>
                  ) : (
                    <option>{item}</option>
                  );
                })}
              </select>
            </div>
          ) : null} */}
          <div className="row">
            <div className="col-md-12 reg-card reg-card-transparent-bg reg-card-r-br-0">
              {/* {authStore.user.role == "PROFESSOR" ||
              authStore.user.role == "PLAYER" ? (
                <div className=" row">
                  <div className="col-md-3 mt-20 ">
                    <a href="#" onClick={() => this.addnewContest()}>
                      <div
                        style={{ minHeight: "128px" }}
                        className="card text-center "
                      >
                        <div className="card-body add-new-class" id="card">
                          <span className="add-class-button">
                            <i className="fas fa-plus  fa-2x"></i>
                          </span>
                          <span className="add-class">Create Contest</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ) : null} */}

              <div className="row" style={{ marginTop: "100px" }}></div>
              {this.props.location.search == "?type=invited" ? (
                <div className="class-container row ">
                  <div className="row" style={{ marginTop: "10px" }}></div>

                  <div className="class-container row">
                    {this.state &&
                      this.state.invitedContests &&
                      this.state.invitedContests.map(item => {
                        if(((this.state.filtersSwitches === "COMPLETED" && this.getCardStatus(item) === "Status: Closed")||
                        (this.state.filtersSwitches === "ONGOING" && this.getCardStatus(item) === "Status: In Progress")||
                        (this.state.filtersSwitches === "ALL"))){
                          if (
                            item.regStartDate
                              ? new Date(item.regStartDate).getFullYear() ==
                              this.state.selectedYear
                              : false || item.regEndDate
                                ? new Date(item.regEndDate).getFullYear() ==
                                this.state.selectedYear
                                : false
                          ) {
                            return (
                              <div className="col-md-3 mt-15">
                                {authStore.user.role == "PROFESSOR" ||
                                  authStore.user.role == "TEACHER" ? (
                                    <div
                                      style={{ height: 40, cursor: 'pointer' }}
                                      className="card-footer card-invite-btn text-muted text-center"
                                      // onClick={e => {
                                      //   e.preventDefault();
                                      //   e.stopPropagation();
                                      //   this.props.history.push({
                                      //     pathname: ROUTES.DASHBOARD,
                                      //     state: item.classname
                                      //   });
                                      // }}
                                    // onClick={e => {
                                    //   e.preventDefault();
                                    //   e.stopPropagation();
  
                                    // }}
                                    >
                                      <div className="col a-right" style={{ display:'flex',marginBottom: 8, marginLeft: 7, fontWeight: 450, cursor: 'pointer', fontSize: 15, paddingRight:10 }}>
                                        <i style={{marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8}} className="fas fa-user-plus fa-1x"></i>
                                      <label onClick={() => {
                                        if (new Date() < new Date(item.regEndDate !== undefined ? item.regEndDate : "")) {
                                          this.setState({
                                            collapsed: !this.state.collapsed
                                          });
                                          this.props.history.push({
                                            pathname: "",
                                            state: {
                                              id: item.id,
                                              contestname: item.contestname,
                                              generateurl: true,
                                              openedModal: true,
                                              url: ""
                                            }
                                          });
                                          this.showModal();
                                        } else {
                                          this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registartion widnow closed!" }));
                                        }
                                      }}>
                                      &nbsp; {"Invite Participant(s)"}
                                      </label>
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#InviteStudentsContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                {authStore.user.role == "PLAYER" &&
                                  !(
                                    this.props.location.search == "?type=invited"
                                  ) ? (
                                    <div
                                      style={{ height: 40 }}
                                      className="card-footer card-invite-btn text-muted text-center"
                                      // onClick={e => {
                                      //   e.preventDefault();
                                      //   e.stopPropagation();
                                      //   this.props.history.push({
                                      //     pathname: ROUTES.DASHBOARD,
                                      //     state: item.classname
                                      //   });
                                      // }}
  
                                    // onClick={e => {
                                    //   e.preventDefault();
                                    //   e.stopPropagation();
  
                                    // }}
                                    >
                                      <div className="col a-right" style={{ display:'flex',marginBottom: 8, marginLeft: 7, fontWeight: 450, cursor: 'pointer', fontSize: 15, paddingRight:10 }}>
                                        <i style={{marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8}} className="fas fa-user-plus fa-1x"></i>
                                      <label onClick={() => {
                                        if (new Date() < new Date(item.regEndDate !== undefined ? item.regEndDate : "")) {
                                          this.setState({
                                            collapsed: !this.state.collapsed
                                          });
                                          this.props.history.push({
                                            pathname: "",
                                            state: {
                                              id: item.id,
                                              contestname: item.contestname,
                                              generateurl: true,
                                              openedModal: true,
                                              url: ""
                                            }
                                          });
                                          this.showModal();
                                        } else {
                                          this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registartion window closed!" }))
                                        }
                                      }}>&nbsp; {"Invite Participant(s)"}</label>
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#InviteStudentsContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                <div
                                  className="card text-center card-body-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="card-body" id="card">
                                    <h4 className="card-title" style={{paddingLeft:10, paddingRight:10}}>
                                      {item.contestname}<i onClick={() =>
                                    authStore.user.role == "PROFESSOR" ||
                                      authStore.user.role == "TEACHER"
                                      ? this.editContest(item)
                                      : authStore.user.role == "STUDENT" ||
                                        (authStore.user.role == "PLAYER" &&
                                          this.props.location.search ==
                                          "?type=invited")
                                        ? this.showContestTopic(item.contestname)
                                        : authStore.user.role == "PLAYER"
                                          ? this.editContest(item)
                                          : this.showContestTopic(item.contestname)
                                  } style={{ paddingLeft: 20, fontSize: 17.5, color: 'rgba(0,0,0,0.9)' }} className="fas fa-edit"></i>
                                    </h4>
                                    <label style={{ fontSize: 13, opacity: 0.6 }}>{this.getCardStatus(item)}</label>
  
                                    <div className="dates">
                                      <strong>{"REGISTRATION DATES"}</strong>
  
                                      <div className="start">
                                        <strong>STARTS</strong>
                                        {this.prettyDate(item.regStartDate)}
                                      </div>
                                      <div className="ends">
                                        <strong>ENDS</strong>
                                        {this.prettyDate(item.regEndDate)}
                                      </div>
                                    </div>
  
                                    {authStore.user.role == "PROFESSOR" ||
                                      authStore.user.role == "TEACHER" ? (
                                        <div className="dates">
                                          <strong>TOTAL CAPACITY</strong>
                                          {item.noofparticipants}
                                        </div>
                                      ) : null}
                                    {authStore.user.role == "PLAYER" ? ( //PLAYER AND CONTEST HOST
                                      <div className="dates">
                                        <strong>TOTAL CAPACITY</strong>
                                        {item.noofparticipants}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    style={{opacity:item !== undefined && item.participants.length > 2 ? 1:0.5}}
                                    className="card-footer text-muted"
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // this.props.history.push({
                                      //   pathname: ROUTES.CONTEST_DASHBOARD,
                                      //   state: item.contestname
                                      // });
                                      if(item !== undefined && item.participants.length > 2){
                                        this.props.toggleDashboard(item.contestname)
                                      }else{
                                        // this.setState(st => ({ ...st, toggleAlert: true, warningText: "No data found" }));
                                      }
                                    }}
                                  >
                                    <div className="col a-right">
                                      <i className="fas fa-compass fa-1x"></i>
                                      &nbsp;{" "}
                                      {authStore.user.role == "PROFESSOR" ||
                                        authStore.user.role == "TEACHER"
                                        ? "Contest Dashboard"
                                        : ""}
                                      {authStore.user.role == "STUDENT"
                                        ? "Performance Dashboard"
                                        : ""}
                                      {authStore.user.role == "PLAYER" //player and contest host
                                        ? "Contest Dashboard"
                                        : ""}
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#MonitorGamePerformanceContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return <p>No Contests</p>;
                          }
                        }
                      })}
                  </div>
                </div>
              ) : (
                  <div className="class-container row">
                    {this.state &&
                      this.state.contests &&
                      this.state.contests.map((item: any) => {
                        if(((this.state.filtersSwitches === "COMPLETED" && this.getCardStatus(item) === "Status: Closed")||
                        (this.state.filtersSwitches === "ONGOING" && this.getCardStatus(item) === "Status: In Progress")||
                        (this.state.filtersSwitches === "ALL"))){
                          item.contestname.includes()
                          if (
                            item.regStartDate
                              ? new Date(item.regStartDate).getFullYear() ==
                              this.state.selectedYear
                              : false || item.regEndDate
                                ? new Date(item.regEndDate).getFullYear() ==
                                this.state.selectedYear
                                : false
                          ) {
                            return (
                              <div className="col-md-3 mt-15">
                                {authStore.user.role == "PROFESSOR" ||
                                  authStore.user.role == "TEACHER" ? (
                                    <div
                                      style={{ height: 40 }}
                                      className="card-footer card-invite-btn text-muted text-center"
                                      // onClick={e => {
                                      //   e.preventDefault();
                                      //   e.stopPropagation();
                                      //   this.props.history.push({
                                      //     pathname: ROUTES.DASHBOARD,
                                      //     state: item.classname
                                      //   });
                                      // }}
                                      onClick={() => {
                                        if (new Date() < new Date(item.regEndDate !== undefined ? item.regEndDate : "")) {
                                          this.setState({
                                            collapsed: !this.state.collapsed
                                          });
                                          this.props.history.push({
                                            pathname: "",
                                            state: {
                                              id: item.id,
                                              contestname: item.contestname,
                                              generateurl: true,
                                              openedModal: true,
                                              url: ""
                                            }
                                          });
                                          this.showModal();
                                        } else {
                                          this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registartion window closed!" }))
                                        }
                                      }}
                                    // onClick={e => {
                                    //   e.preventDefault();
                                    //   e.stopPropagation();
  
                                    // }}
                                    >
                                      <div style={{ display:'flex',fontSize: 15, cursor: 'pointer', marginBottom: 8, marginLeft: 7, fontWeight: 450, paddingRight:10 }} className="col a-right">
                                        <i style={{marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8}} className="fas fa-user-plus fa-1x"></i>
                                    <label onClick={() => {
                                        if (new Date() < new Date(item.regEndDate !== undefined ? item.regEndDate : "")) {
                                          this.setState({
                                            collapsed: !this.state.collapsed
                                          });
                                          this.props.history.push({
                                            pathname: "",
                                            state: {
                                              id: item.id,
                                              contestname: item.contestname,
                                              generateurl: true,
                                              openedModal: true,
                                              url: ""
                                            }
                                          });
                                          this.showModal();
                                        } else {
                                          this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registartion window closed!" }))
                                        }
                                      }}>&nbsp; {"Invite Participant(s)"}</label>
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#InviteStudentsContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                {authStore.user.role == "PLAYER" ? (
                                  <div
                                    style={{ height: 40 }}
                                    className="card-footer card-invite-btn text-muted text-center"
                                    // onClick={e => {
                                    //   e.preventDefault();
                                    //   e.stopPropagation();
                                    //   this.props.history.push({
                                    //     pathname: ROUTES.DASHBOARD,
                                    //     state: item.classname
                                    //   });
                                    // }}
                                    onClick={() => {
                                      if (new Date() < new Date(item.regEndDate !== undefined ? item.regEndDate : "")) {
                                        this.setState({
                                          collapsed: !this.state.collapsed
                                        });
                                        this.props.history.push({
                                          pathname: "",
                                          state: {
                                            id: item.id,
                                            contestname: item.contestname,
                                            generateurl: true,
                                            openedModal: true,
                                            url: ""
                                          }
                                        });
                                        this.showModal();
                                      } else {
                                        this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registartion window closed!" }))
                                      }
                                    }}
                                  // onClick={e => {
                                  //   e.preventDefault();
                                  //   e.stopPropagation();
  
                                  // }}
                                  >
                                    <div className="col a-right" style={{ display:'flex', marginBottom: 8, marginLeft: 7, fontWeight: 450, cursor: 'pointer', fontSize: 15, paddingRight:10 }}>
                                      <i style={{marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8}} className="fas fa-user-plus fa-1x"></i>
                                    <label onClick={() => {
                                        if (new Date() < new Date(item.regEndDate !== undefined ? item.regEndDate : "")) {
                                          this.setState({
                                            collapsed: !this.state.collapsed
                                          });
                                          this.props.history.push({
                                            pathname: "",
                                            state: {
                                              id: item.id,
                                              contestname: item.contestname,
                                              generateurl: true,
                                              openedModal: true,
                                              url: ""
                                            }
                                          });
                                          this.showModal();
                                        } else {
                                          this.setState(st => ({ ...st, toggleAlert: true, warningText: "Registartion window closed!" }))
                                        }
                                      }}>&nbsp; {"Invite Participant(s)"}</label>
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#InviteStudentsContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                    </div>
                                  </div>
                                ) : (
                                    ""
                                  )}
                                <div
                                  className={
                                    authStore.user.role == "PROFESSOR" ||
                                      authStore.user.role == "TEACHER"
                                      ? "card text-center card-body-btn "
                                      : authStore.user.role == "STUDENT"
                                        ? "card text-center card-body-btn student-card-btn"
                                        : authStore.user.role == "PLAYER"
                                          ? "card text-center card-body-btn "
                                          : ""
                                  }
                                >
                                  <div id="card">
                                    <h4 className="card-title" style={{ height: 30, cursor: 'pointer',paddingLeft:10, paddingRight:10 }}>
                                      {item.contestname}<i onClick={() =>
                                    authStore.user.role == "PROFESSOR" ||
                                      authStore.user.role == "TEACHER"
                                      ? this.getCardStatus(item) !== "Status: Closed" ? this.editContest(item):null
                                      : authStore.user.role == "STUDENT"
                                        ? this.getCardStatus(item) !== "Status: Closed" ? this.showContestTopic(item.contestname) : null
                                        : authStore.user.role == "PLAYER" // PLAYER AND HOST
                                          ? this.getCardStatus(item) !== "Status: Closed" ? this.editContest(item):null
                                          : this.getCardStatus(item) !== "Status: Closed" ? this.showContestTopic(item.contestname) : null
                                  } style={{ paddingLeft: 20, fontSize: 20, color: '#009CCE', cursor: 'pointer', position: 'absolute', top: 0, right: 12, opacity: this.getCardStatus(item) !== "Status: Closed" ? 1.0:0.6 }} className="fas fa-edit"></i>
                                    </h4>
                                    <label style={{ fontSize: 13, opacity: 0.6 }}>{this.getCardStatus(item)}</label>
                                    <div className="dates">
                                      <strong>{"REGISTRATION DATES"}</strong>
  
                                      <div className="start">
                                        <strong>STARTS</strong>
                                        {this.prettyDate(item.regStartDate)}
                                      </div>
                                      <div className="ends">
                                        <strong>ENDS</strong>
                                        {this.prettyDate(item.regEndDate)}
                                      </div>
                                    </div>
                                    {/* <div className="dates">
                                <strong >{"EXAM DATES"}</strong>
  
                                  <div className="start">
                                    <strong>STARTS</strong>
                                    {this.convertDate(item.regStartDate)}
                                    <span></span>
                                  </div>
                                  <div className="ends">
                                    <strong>ENDS</strong>
                                    {this.convertDate(item.regEndDate)}
                                  </div>
                                </div> */}
                                    {/* {authStore.user.role == "PROFESSOR" ? (
                                  <div className="dates">
                                    <strong>{"PLAYERS"}</strong>
                                    <div className="endss">
                                      <strong>Students</strong>
                                      {item.noofstudents}
                                    </div>
                                    <div className="start">
                                      <strong>REGISTERED</strong>
                                      {item.noofplayers}
                                      <span></span>
                                    </div>
                                    <div className="ends">
                                      <strong>TOTAL</strong>
                                      {item.noofplayers}
                                    </div>
                                  </div>
                                ) : null} */}
                                    {authStore.user.role == "PROFESSOR" ||
                                      authStore.user.role == "TEACHER" ? (
                                        <div className="dates">
                                          <strong>{"CAPACITY"}</strong>
  
                                          <div className="start">
                                            <strong>TOTAL SLOTS</strong>
                                            {item.noofparticipants}
                                          </div>
                                          <div className="ends">
                                            <strong>SLOTS TAKEN</strong>
                                            {authStore.user.role == "PROFESSOR" ? item.participants.length - 2 : item.participants.length - 1}
                                          </div>
                                        </div>
                                      ) : null}
                                    {authStore.user.role == "PLAYER" ? ( //PLAYER AND CONTEST HOST
                                      <div className="dates">
                                        <strong>{"CAPACITY"}</strong>
  
                                        <div className="start">
                                          <strong>TOTAL SLOTS</strong>
                                          {item.noofparticipants}
                                        </div>
                                        <div className="ends">
                                          <strong>SLOTS TAKEN</strong>
                                          {item.participants.length - 1}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
  
                                  {/* Leader Board Button */}
                                  {/* <div style={{ backgroundColor: '#2867E4', paddingTop: 6, paddingBottom: 6, marginBottom: 2, color: 'white', height: 40 }} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (parseInt(item.participants.length) > 0) {
                                      this.setState(st => ({ ...st, toggleViewLeaderModel: true, viewLeaderModelData: { "className": item.contestname } }));
                                    } else {
                                      this.setState(st => ({ ...st, toggleAlert: true, warningText: "NO SLOTS TAKEN" }))
                                    }
                                  }}>
                                    <div style={{ fontSize: 15, cursor: 'pointer' }} className="col a-right">
                                      <i className="fas fa-crown" style={{ marginRight: 6 }}></i>
                                  Contest Leaderboard
                                </div>
                                  </div> */}
                                  {/* Leader Board Button ends */}
  
                                  <div className="card-footer text-muted" style={{ height: 40, opacity:item !== undefined && item.participants.length > 2 ? 1:0.5 }}
                                  >
                                    <div style={{ display:'flex',fontSize: 15, cursor: 'pointer', paddingRight: 10 }} className="col a-right">
                                      <i className="fas fa-compass fa-1x" style={{ marginLeft: 'auto', marginTop: 6, marginRight: 8, paddingLeft: 8 }}></i>
                                    <label onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      // this.props.history.push({
                                      //   pathname: ROUTES.CONTEST_DASHBOARD,
                                      //   state: item.contestname
                                      // });
                                      if(item !== undefined && item.participants.length > 2){
                                        this.props.toggleDashboard(item.contestname)
                                      }else{
                                        // this.setState(st => ({ ...st, toggleAlert: true, warningText: "No data found" }));
                                      }
                                    }}>
                                    &nbsp;{" "}
                                      {authStore.user.role == "PROFESSOR" ||
                                        authStore.user.role == "TEACHER"
                                        ? "Contest Dashboard"
                                        : ""}
                                      {authStore.user.role == "STUDENT"
                                        ? "Performance Dashboard"
                                        : ""}
                                      {authStore.user.role == "PLAYER" //player and contest host
                                        ? "Contest Dashboard"
                                        : ""}
                                    </label>
                                  <i onClick={ () => window.open(`${config.url}/guide?page=${authStore.user.role.toLowerCase()}#MonitorGamePerformanceContest`,'_blank')} style={{ fontSize: 16, cursor: 'pointer', marginLeft: 'auto', marginTop: 6 }} data-tip data-for='_dashboard' className="fas fa-info-circle help-icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return <p>No Contests</p>;
                          }
                        }
                        
                      })}
                  </div>
                )}
              {/* classcontiner */}
            </div>
          </div>

          {Toast.successToastView()}
          {Toast.errorToastView()}
          <DeleteConfirm id={this.state.id} getContests={this.getContests} />
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
        {/* Toogle models */}
        {
          this.state.toggleViewLeaderModel ?
            <ViewLeaders
              type={this.state.leaderBoardType}
              onClose={() => { this.setState(st => ({ ...st, toggleViewLeaderModel: false })) }}
              data={this.state.viewLeaderModelData} /> : null
        }

        {this.state.toggleAlert ? <Alert text={this.state.warningText} onClose={() => this.setState(st => ({ ...st, toggleAlert: false }))} /> : null}
      </div>
    );
  }
}
export default withRouter(ProfessorHostedContestList);
