import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import SingleInvite from "./SingleInvite";
import MultipleInvite from "./MultipleInvite";
import LoadingWheel from "../../components/LoadingWheel";
import authStore from "../../models/AuthStore";

export interface InviteProps extends RouteComponentProps {}

class Invite extends React.Component<InviteProps, any> {
  state = {
    tab: "single",
    showLoader: true
  };
  async componentDidMount() {
    this.setState({ showLoader: false });
  }
  render() {
    return (
      <div id="" className="">
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : (
          <div className="jumbotron backgroundImage">
            <div
              className="container registration-title"
              style={{ backgroundColor: "#1a73e8" }}
            >
              {authStore.user.role == "PROFESSOR" ? "Invite" : ""}
            </div>
          </div>
        )}
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : (
          <div className="container cmt20">
            <div className="row">
              {/* <div className="registration-title">
                {authStore.user.role == "PROFESSOR"
                  ? "Class Dashboard"
                  : "Student Dashboard"}
                {"   "} ({this.props.location.state})
              </div> */}
            </div>
            <div className="row">
              <div className="reg-card reg-card-dashboard">
                {/* <div className="dashboard-title"></div> */}
                <div className="tabresult">
                  <button
                    className={
                      this.state.tab == "single"
                        ? "tablinksresult-active"
                        : "tablinksresult"
                    }
                    onClick={() => this.setState({ tab: "single" })}
                  >
                    Individual Invitation
                  </button>
                  <button
                    className={
                      this.state.tab == "multiple"
                        ? "tablinksresult-active"
                        : "tablinksresult"
                    }
                    onClick={() => this.setState({ tab: "multiple" })}
                  >
                    Multiple Invitation
                  </button>
                </div>
                {this.state.tab == "single" ? (
                  <SingleInvite />
                ) : (
                  <MultipleInvite />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Invite);
