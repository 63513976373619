import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { MdAdd, MdRemove } from "react-icons/md";
import Progressbar from "../../components/Progressbar";
import ROUTES from "../../ROUTES";
import Config from "../../config/Config";
import authStore from "../../models/AuthStore";
import profService from "../../services/profService";
import studentService from "../../services/studentService";
import playerService from "../../services/playerService";

export interface ContestTopicProps extends RouteComponentProps {
  // expand: boolean;
  index: number;
  // toggle: any;
  topictitle: string;
  topicname: string;
  showCurriculum?: any;
  //   quizStatus: any;
  gameStatus?: any;
  tab?: any;
  prevGameStatus?: any;
  contestid?: any;
  contestname?: any;
  initialCapital?: any;
  //   attempts?: any;
}

{
  /* <a
  className="col-md-3 p10 btn btn-primary btn-lg register-button m10"
  href="/DemoSignInAsProfessor"
>
  {" "}
  As Professor
              </a> */
}

{
  /* <button
  type="button"
  className="btn next-btn add-new-btn"
  onClick={() => this.addnewClass()}
>
  Add New
              </button> */
}

class ContestTopicListItem extends React.Component<ContestTopicProps, any> {
  state = {
    showLoader: true,
    contestid: "",
    contestname: "",
  };
  // contestid,
  // topic,
  // level,
  // // initialCapital
  // async setInitialStateOfGameForContest(
  //   contestid: any,
  //   topic: any,
  //   level: any,
  //   initialCapital: any
  // ) {
  //   console.log("authStore.user.role", authStore.user.role);
  //   if (authStore.user.role === "PROFESSOR") {
  //     let gameInitialState = await profService.setInitialStateOfGameByProfessorForContest(
  //       contestid,
  //       topic,
  //       level,
  //       initialCapital
  //     );
  //   } else if (authStore.user.role === "STUDENT") {
  //     let gameInitialState = await studentService.setInitialStateOfGameByStudentForContest(
  //       contestid,
  //       topic,
  //       level,
  //       initialCapital
  //     );
  //   } else {
  //     let gameInitialState = await playerService.setInitialStateOfGameByPlayerForContest(
  //       contestid,
  //       topic,
  //       level,
  //       initialCapital
  //     );
  //   }
  //   window.location.replace(
  //     "http://localhost/PROD-BUILD-CONTEST/?token=" + authStore.authToken
  //   );
  // }

  gameStatus() {
    return (
      <Fragment>
        <li className="topic-progress-group" style={{ height: 43, display: 'flex', alignItems: 'center' }}>
          {this.props.gameStatus == 1 || this.props.gameStatus == 2 ? (
            <a
              style={{ fontSize: 15, opacity: 0.8, height: 43, padding: '10px 20px' }}
              href={
                Config.base_url.includes("staging")
                  ? "https://playftcontestgamestg.firebaseapp.com?token=" +
                  authStore.authToken +
                  "&current_contest_name=" +
                  this.props.contestid
                  : "https://playftcontestgame.firebaseapp.com?token=" +
                  authStore.authToken +
                  "&current_contest_name=" +
                  this.props.contestid
              }
              target="_blank"
            >
              {this.props.topicname}
            </a>
          ) : (
              <a style={{ fontSize: 15, opacity: 0.8, height: 43, padding: '10px 20px' }}>{this.props.topicname}</a>
            )}

          <Progressbar
            status={
              this.props.gameStatus == 0
                ? <p style={{width:'100%', height:'100%', backgroundColor:'#51D600', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:4, marginTop:10, color:'white',cursor:'no-drop'}}>Completed</p>
                : this.props.gameStatus == 1
                  ? <p onClick={this.gamePlayClicked} style={{ cursor:'pointer', width:'100%', height:'100%', backgroundColor:'#FF942C', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:4, marginTop:10, color:'white'}}>Start</p>
                  : this.props.gameStatus == 2
                    ? <p onClick={this.gamePlayClicked} style={{ cursor:'pointer', width:'100%', height:'100%', backgroundColor:'#FF942C', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:4, marginTop:10, color:'white'}}>In Progress</p>
                    : this.props.gameStatus == 3
                      ? <p style={{marginTop:9, cursor:'no-drop'}}>Pending</p>
                      : <p style={{marginTop:9, cursor:'no-drop'}}>Pending</p>
            }
          />
        </li>
      </Fragment>
    );
  }

  gamePlayClicked = () => {
    window.open(Config.base_url.includes("staging")
    ? "https://playftcontestgamestg.firebaseapp.com?token=" +
    authStore.authToken +
    "&current_contest_name=" +
    this.props.contestid
    : "https://playftcontestgame.firebaseapp.com?token=" +
    authStore.authToken +
    "&current_contest_name=" +
    this.props.contestid
    ,"_blank")
  }

  render() {
    return this.gameStatus();
  }
}
export default withRouter(ContestTopicListItem);
