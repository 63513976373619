import React, { Fragment } from "react";
import {
  withRouter,
  RouteChildrenProps,
  Redirect,
  RouteComponentProps,
} from "react-router";
import ROUTES from "../../ROUTES";
import "./TopicList.css";
import studentService from "../../services/studentService";
import "./TopicItem.css";
import LoadingWheel from "../../components/LoadingWheel";
import { threadId } from "worker_threads";
import classService from "../../services/classService";
import './TopicItem.scss';

export interface TopicProps extends RouteComponentProps { }
export interface TopicState { }

const StockTraderTopicHeadings = ["Basic Rules", "Roles", "Basic Strategy for Traders", "Determine your Buy/Sell Point & Trade on your Perceived Edge", "Trading on Breaking News Strategy", "Calculating P&L to determine the Winner", "Stocks", "References"]
const StockMarketMakerTopicHeadings = ["Basic Rules", "Roles", "Basic Strategy for Market Makers", "Determine your Buy/Sell Point & Trade on your Perceived Edge", "Trading on Breaking News Strategy", "Stocks", "References"]
const StocksAdvancedTraderHeadings = ["Advanced Strategy for Traders", "Determine your Buy/Sell Point & Trade on your Perceived Edge", "Trading on Breaking News Strategy", "Stocks", "References"]
const StocksAdvancedMarketMakerHeadings = ["Basic Rules", "Advanced Strategy for Traders", "Trading on Breaking News Strategy", "Stocks", "References"]
const BondsTraderTopicHeadings = ["Basic Rules", "Basic Strategy for Traders", "Determine your Buy/Sell Point & Trade on your Perceived Edge", "Calculating P&L to determine the Winner", "Trading on Breaking News Strategy", "Bonds", "References"]
const BondsMarketMakerTopicHeadings = ["Basic Rules", "Basic Strategy for Traders", "Determine your Buy/Sell Point & Trade on your Perceived Edge", "Trading on Breaking News Strategy", "Bond Fundamentals", "Bonds", "References"]
const BondsAdvancedTraderHeadings = ["Advanced Strategy for Traders", "Determine your Buy/Sell Point & Trade on your Perceived Edge", "Trading on Breaking News Strategy", "Bond Fundamentals", "Economic Indicator Tutorial", "Bonds", "References"]
const BondsAdvancedMarketMakerHeadings = ["Basic Rules", "Advanced Strategy for Traders", "Trading on Breaking News Strategy", "Bond Fundamentals", "Economic Indicator Tutorial", "Bonds", "References"]

class TopicItem extends React.Component<any, TopicState> {
  state = {
    page: 1,
    topic: {} as any,
    showLoader: true,
    maximize: false
  };

  startQuiz() {
    // this.props.history.push({
    //   pathname: ROUTES.QUIZ,
    //   state: {
    //     classname: this.props.classname,
    //     topic: this.props.topic,
    //     level: this.props.level,
    //   },
    // });
    this.props.toggleTabs(this.props.classname, this.props.topic, this.props.level);
  }

  async componentDidMount() {
    let classDetails = await classService.getClassbyName(
      this.props.classname
    );
    let res = await studentService.getTopic(
      classDetails.data.id,
      this.props.topic,
      this.props.level
    );

    this.setState({ topic: res, showLoader: false });
  }

  prev() {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 });
    }
  }

  next() {
    if (this.state.page < 6) {
      this.setState({ page: this.state.page + 1 });
    }
  }

  isPageEndReached() {
    if (this.props.topic == "Stocks") {
      if (
        (this.state.page == 6 && this.props.level == "Trader") ||
        (this.state.page == 4 &&
          this.props.level == "Market Maker") ||
        (this.state.page == 3 &&
          this.props.level == "Advanced Trader") ||
        (this.state.page == 3 &&
          this.props.level == "Advanced Market Maker")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.props.topic == "Bonds") {
      if (
        (this.state.page == 5 && this.props.level == "Trader") ||
        (this.state.page == 5 &&
          this.props.level == "Market Maker") ||
        (this.state.page == 5 &&
          this.props.level == "Advanced Trader") ||
        (this.state.page == 5 &&
          this.props.level == "Advanced Market Maker")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  renderCurriculum(section: any) {
    return (
      <div>
        <ul>
          {section
            ? section.map((item: any) => {
              return (
                <div>
                  <li className="list-item" style={{ fontSize: 15 }}>{String(item.heading)}</li>
                  <ul>
                    {item.sub_heading
                      ? item.sub_heading.map((element: any) => {
                        return element.constructor != {}.constructor ? (
                          <li className="sublist-item" style={{ fontSize: 14 }}>
                            {String(element)}
                          </li>
                        ) : (
                            <div>
                              <li className="sublist-item" style={{ fontSize: 14 }}>
                                {String(element.heading)}
                              </li>
                              <li className="sub-sublist-item" style={{ fontSize: 14 }}>
                                {String(element.sub_heading)}
                              </li>
                            </div>
                          );
                      })
                      : null}
                  </ul>
                </div>
              );
            })
            : null}
        </ul>
        {/* <div className="btn-wrapper ">
          <ul className="pagination flex-wrap pagination-wrapp">
            <li>
              {this.state.page == 1 ? null : (
                <button
                  className="page-item prev-btn btn btn-pagination d-md-block next-btn"
                  onClick={(e) => this.prev()}
                >
                  {" "}
                  Previous
                  <i
                    className="mdi mdi-chevron-left chev-left"
                    data-name="mdi-chevron-left"
                  ></i>
                </button>
              )}
            </li>
            <li>
              <button
                className="page-item active btn btn-pagination d-md-block next-btn"
                onClick={(e) => {
                  this.isPageEndReached() ? this.startQuiz() : this.next();
                }}
              >
                {this.isPageEndReached() ? "Start Quiz" : "Next"}

                <i
                  className="mdi mdi-chevron-right"
                  data-name="mdi-chevron-right"
                ></i>
              </button>
            </li>
          </ul>
              </div>*/}
      </div>
    );
  }

  renderStocksRef() {
    return <div>
      <label>Sources</label>
      <p>https://www.investopedia.com/terms/s/stock.asp</p>
    </div>
  }

  renderBondsRef() {
    return <div>
      <label>Sources</label>
      <p>https://www.investopedia.com/terms/b/bond.asp</p>
    </div>
  }

  renderStocks() {
    return <div>
      <h4>2.1 Stocks Overview</h4>

      <label>What Is a Stock? </label>
      <p>A stock (also known as equity) is a security that represents the ownership of a fraction of a corporation.
      This entitles the owner of the stock to a proportion of the corporation's assets and profits equal to how
        much stock they own. Units of stock are called "shares."</p>
      <p>Stocks are bought and sold predominantly on stock exchanges, though there can be private sales as well, and
      are the foundations of many individual investors' portfolios. These transactions have to conform to
      government regulations which are meant to protect investors from fraudulent practices. Historically, they
      have outperformed most other investments over the long run. These investments can be purchased from most
        online stock brokers. Stock investment differs greatly from real estate investment.</p>

      <label>Understanding Stocks </label>
      <p>Corporations issue (sell) stock to raise funds to operate their businesses such as leasing an office,
      building a factory, buying equipment and furniture, hiring employees etc. The holder of stock (a
      shareholder) has now bought a piece of the corporation and, depending on the type of shares held, may have a
      claim to a part of its assets and earnings. In other words, a shareholder is now one of the owners of the
      issuing company. Ownership is determined by the number of shares a person owns relative to the number of
      outstanding shares. For example, if a company has 1,000 shares of stock outstanding and one person owns 100
        shares, that person would own and have claim to 10% of the company's assets and earnings.</p>
      <p>
        Stock holders do not own corporations; they own shares issued by corporations. But corporations are a
        special type of organization because the law treats them as legal persons. In other words, corporations file
        taxes, can borrow, can own property, can be sued, etc.</p>

      <label>Stockholders and Equity Ownership </label>
      <p>What shareholders actually own are shares issued by the corporation; and the corporation owns the assets held
      by a firm. So if you own 33% of the shares of a company, it is incorrect to assert that you own one-third of
      that company; it is instead correct to state that you own 100% of one-third of the company’s shares.
      Shareholders cannot do as they please with a corporation or its assets. A shareholder can’t walk out with a
      chair because the corporation owns that chair, not the shareholder. This is known as the “separation of
        ownership and control.”</p>
      <p>Owning stock gives you the right to vote in shareholder meetings, receive dividends (which are the company’s
      profits) if and when they are distributed, and it gives you the right to sell your shares to somebody else.
    </p>
      <p>If you own a majority of shares, your voting power increases so that you can indirectly control the direction
      of a company by appointing its board of directors. This becomes most apparent when one company buys another:
      the acquiring company doesn’t go around buying up the building, the chairs, the employees; it buys up all
      the shares. The board of directors is responsible for increasing the value of the corporation, and often
        does so by hiring professional managers, or officers, such as the Chief Executive Officer, or CEO.</p>
      <p>For most ordinary shareholders, not being able to manage the company isn't such a big deal. The importance of
      being a shareholder is that you are entitled to a portion of the company's profits, which, as we will see,
      is the foundation of a stock’s value. The more shares you own, the larger the portion of the profits you
      get. Many stocks, however, do not pay out dividends, and instead reinvest profits back into growing the
        company. These retained earnings, however, are still reflected in the value of a stock.</p>

      <label>Common vs. Preferred Stock </label>
      <p>There are two main types of stock: common and preferred. Common stock usually entitles the owner to vote at
      shareholders' meetings and to receive any dividends paid out by the corporation. Preferred stockholders
      generally do not have voting rights, though they have a higher claim on assets and earnings than the common
      stockholders. For example, owners of preferred stock (such as Larry Page) receive dividends before common
        shareholders and have priority in the event that a company goes bankrupt and is liquidated.</p>
      <p>Companies can issue new shares whenever there is a need to raise additional cash. This process dilutes the
      ownership and rights of existing shareholders (provided they do not buy any of the new offerings).
      Corporations can also engage in stock buy-backs which would benefit existing shareholders as it would cause
        their shares to appreciate in value.</p>

      <h4>2.2. Stocks Overview</h4>
      <p>Steps – buyer to broker, bid ask, settle on order, exchange of $$ and shares, pay offs </p>
      <p>Other info – risks/rewards, liquidity</p>
      <label>Basic Elements Required For Stock Trading </label>
      <p>The below listed elements are critical for a Stock transaction to successfully take place: </p>
      <ul>
        <li>Buyer</li>
        <li>Seller</li>
        <li>Broker</li>
        <li>Stock Exchange</li>
        <li>Regulators</li>
      </ul>

      <p>Buyers – These can be individual investors or traders, institutional investors (Pension Funds, Unions,
      Colleges and Universities etc.), Brokers and Market Makers looking to buy Share(s) from potential Sellers.
    </p>
      <p>Sellers – These can be individual investors or traders, institutional investors (Pension Funds, Unions,
      Colleges and Universities etc.), Brokers and Market Makers looking to sell Share(s) to potential Buyers.
    </p>
      <p>Broker – A Stock Broker acts as the middleman between the buyer and seller. Getting a Stock Broker is most
      commonly accomplished by creating an account with a well-established retail broker such as Fidelity
      Investments, TD Ameritrade, and Charles Schwab etc.
    </p>
      <p>Stock Exchanges – These are markets where existing owners of Shares can transact with potential buyers.
      Popular Stock Exchanges are New York Stock Exchange (NYSE), NASDAQ, and Philadelphia Stock Exchange (PHLX)
      etc. When a company lists its stock on an exchange, it makes the company’s shares popular for investors to
      buy. An exchange facilitates ready liquidity, availability of abundant of shares and also the investors
      wanting to transact them, to all the investors and companies who have listed their Stock on the exchange. A
      Market Maker in an exchange sets the prices in the trading system based on supply and demand and profits
      based on the bid-ask spread, which is the amount by which the ask price (or the selling price) exceeds the
      bid price (or the buying price) of a market asset.
    </p>
      <p>Regulators – Regulatory bodies are established by governments or other organizations to oversee the
      functioning and fairness of financial markets and the firms that engage in financial activity. The goal of
      regulation is to prevent and investigate fraud, keep markets efficient and transparent, and make sure
      customers and clients are treated fairly and honestly. Several different regulatory bodies exist from the
      Federal Reserve Board which oversees the commercial banking sector to FINRA and the SEC which monitor
      brokers and stock exchanges.
    </p>
      <label>How Does a Stock Transaction Happen?
    </label>
      <p> The below listed steps are typical of a regular Stock transaction:
    </p>
      <ul>
        <li>Buyers (traders, investors) express their interest to buy Share(s) of a Stock
        </li>
        <li>Sellers (traders, investors) express their interest to sell Share(s) of a Stock
        </li>
        <li>Buyers and Sellers approach their brokers to execute the transaction
        </li>
        <li>Respective Brokers will engage with a Stock Exchange to execute the transaction
        </li>
        <li>Market Makers of the exchange will set the bid-ask prices
        </li>
        <li>Trade Orders are created and executed
        </li>
        <li>Payoffs and Accounts are settled on the books of both Buyers and Sellers
        </li>
      </ul>
      <label>How Share Prices Are Set
    </label>

      <p>The prices of shares on a stock market can be set in a number of ways, but the most common way is through an
      auction process where buyers and sellers place bids and offers to buy or sell. A bid is the price at which
      somebody wishes to buy, and an offer (or ask) is the price at which somebody wishes to sell. When the bid
      and ask coincide, a trade is made.
    </p>
      <p>The overall market is made up of millions of investors and traders, who may have differing ideas about the
      value of a specific stock and thus the price at which they are willing to buy or sell it. The thousands of
      transactions that occur as these investors and traders convert their intentions to actions by buying and/or
      selling a stock cause minute-by-minute gyrations in it over the course of a trading day. A stock exchange
      provides a platform where such trading can be easily conducted by matching buyers and sellers of stocks. For
      the average person to get access to these exchanges, they would need a stockbroker. This stockbroker acts as
      the middleman between the buyer and the seller. Getting a stockbroker is most commonly accomplished by
      creating an account with a well-established retail broker.
    </p>
      <label>Stock Market Supply and Demand
    </label>
      <p>The stock market also offers a fascinating example of the laws of supply and demand at work in real time. For
      every stock transaction, there must be a buyer and a seller. Because of the immutable laws of supply and
      demand, if there are more buyers for a specific stock than there are sellers of it, the stock price will
      trend up. Conversely, if there are more sellers of the stock than buyers, the price will trend down.
    </p>
      <p>The bid-ask or bid-offer spread—the difference between the bid price for a stock and its ask or offer
      price—represents the difference between the highest price that a buyer is willing to pay or bid for a stock
      and the lowest price at which a seller is offering the stock. A trade transaction occurs either when a buyer
      accepts the ask price or a seller takes the bid price. If buyers outnumber sellers, they may be willing to
      raise their bids in order to acquire the stock; sellers will, therefore, ask higher prices for it,
      ratcheting the price up. If sellers outnumber buyers, they may be willing to accept lower offers for the
      stock, while buyers will also lower their bids, effectively forcing the price down.
    </p>
      <label>Matching Buyers to Sellers
    </label>
      <p>Some stock markets rely on professional traders to maintain continuous bids and offers since a motivated
      buyer or seller may not find each other at any given moment. These are known as specialists or market
      makers. A two-sided market consists of the bid and the offer, and the spread is the difference in price
      between the bid and the offer. The more narrow the price spread and the larger size of the bids and offers
      (the amount of shares on each side), the greater the liquidity of the stock. Moreover, if there are many
      buyers and sellers at sequentially higher and lower prices, the market is said to have good depth. Stock
      markets of high quality generally tend to have small bid-ask spreads, high liquidity, and good depth.
      Likewise, individual stocks of high quality, large companies tend to have the same characteristics.
    </p>
      <p>Matching buyers and sellers of stocks on an exchange was initially done manually, but it is now increasingly
      carried out through computerized trading systems. The manual method of trading was based on a system known
      as "open outcry," in which traders used verbal and hand signal communications to buy and sell large blocks
      of stocks in the "trading pit" or the floor of an exchange.
    </p>
      <p>However, the open outcry system has been superseded by electronic trading systems at most exchanges.﻿ These
      systems can match buyers and sellers far more efficiently and rapidly than humans can, resulting in
      significant benefits such as lower trading costs and faster trade execution.
    </p>
      <label>Order Execution
    </label>
      <p>Once the Bid-Ask prices are clear to a Buyer, an Order is created and placed. An order is an investor's
      instructions to a broker or brokerage firm to purchase or sell a security on the investor's behalf. Orders
      are typically placed over the phone or online through a trading platform. Orders fall into different
      available types which allow investors to place restrictions on their orders affecting the price and time at
      which the order can be executed. These order instructions will affect the investor's profit or loss on the
      transaction and, in some cases, whether the order is executed at all.
    </p>
      <p>There are multiple order types which will affect what price the investor buys or sells at, when they will buy
      or sell, or if their order will be filled or not. Which order type to use depends on the trader's outlook
      for the asset, whether they want to get in and out quickly, and/or how concerned they are about the price
      they get.
    </p>
      <p>On most markets, orders are accepted from both individual and institutional investors. Most individuals trade
      through broker-dealers which require them to place one of many order types when making a trade. Markets
      facilitate different order types that provide for some investing discretion when planning a trade.
    </p>
      <p>Here are the basic order types:
    </p>
      <ul>
        <li>A market order instructs the brokerage to complete the order at the next available price. Market orders
        have no specific price and are generally always executed unless there is no trading liquidity. Market
        orders are typically used if the trader wants in or out of a trade quickly and is not concerned about
        the price they get.
        </li>
        <li>A limit buy order instructs the brokerage to buy a security at or below a specified price. Limit orders
        ensure that a buyer pays only a specific price to purchase a security. Limit orders can remain in effect
        until they are executed, expire, or are canceled.
        </li>
        <li>A limit sell order instructs the broker to sell the asset at a price which is above the current price.
        For Sellers, this order type is used to take profits when the price has moved higher after buying.
        </li>
        <li>A sell stop order instructs the brokerage to sell if an asset reaches a specified price below the
        current price.
        </li>
        <li>A buy stop order instructs the broker to buy an asset when it reaches a specified price above the
        current price.
        </li>
        <li>A stop order can be a market order meaning it takes any price once triggered, or it can be a stop limit
        order where it can only execute within a certain price range (limit) after being triggered.
        </li>
        <li>A day order must be executed during the same trading day that the order is placed.
        </li>
        <li>Good till canceled orders remain in effect until they are filled or canceled.
        </li>
        <li>If an order is not a day order or a good till canceled order, the trader typically sets an expiry for
        the order.
        </li>
        <li>A fill or kill order must be completed immediately and completely or not at all.
        </li>
      </ul>
      <p>The order types used can greatly affect the results of a trade. When trying to buy, for example, placing a
      buy limit at a lower price than what the asset is trading at currently may give the trader a better price if
      the asset drops in value (compared to buying now). But putting it too low may mean the price never reaches
      the limit order, and the trader may miss out if the price moves higher.
    </p>
      <p>One order type isn't better than another. Each order type serves a purpose and will be the prudent choice in
      different situations.
    </p>
    </div>
  }

  renderBonds() {
    return <div>
      <h4>3.1 Bonds Overview</h4>
      <label>What Is a Bond?
    </label>
      <p>A bond is a fixed income instrument that represents a loan made by an investor to a borrower (typically
      corporate or government). A bond could be thought of as an I.O.U. between the lender and borrower that
      includes the details of the loan and its payments. Bonds are used by companies, municipalities, states, and
      sovereign governments to finance projects and operations. Owners of bonds are debtholders, or creditors, of
      the issuer. Bond details include the end date when the principal of the loan is due to be paid to the bond
      owner and usually includes the terms for variable or fixed interest payments made by the borrower.
    </p>
      <label>How Bonds Work
    </label>
      <p>Bonds are commonly referred to as fixed income securities. Many corporate and government bonds are publicly
      traded; others are traded only over-the-counter (OTC) or privately between the borrower and lender.
    </p>
      <p>When companies or other entities need to raise money to finance new projects, maintain ongoing operations, or
      refinance existing debts, they may issue bonds directly to investors. The borrower (issuer) issues a bond
      that includes the terms of the loan, interest payments that will be made, and the time at which the loaned
      funds (bond principal) must be paid back (maturity date). The interest payment (the coupon) is part of the
      return that bondholders earn for loaning their funds to the issuer. The interest rate that determines the
      payment is called the coupon rate.
    </p>
      <p>The initial price of most bonds is typically set at par, usually $100 or $1,000 face value per individual
      bond. The actual market price of a bond depends on a number of factors: the credit quality of the issuer,
      the length of time until expiration, and the coupon rate compared to the general interest rate environment
      at the time. The face value of the bond is what will be paid back to the borrower once the bond matures.
    </p>
      <p>Most bonds can be sold by the initial bondholder to other investors after they have been issued. In other
      words, a bond investor does not have to hold a bond all the way through to its maturity date. It is also
      common for bonds to be repurchased by the borrower if interest rates decline, or if the borrower’s credit
      has improved, and it can reissue new bonds at a lower cost.
    </p>
      <label>Characteristics of Bonds
    </label>
      <p>Most bonds share some common basic characteristics including:
    </p>
      <ul>
        <li>Face value is the money amount the bond will be worth at maturity; it is also the reference amount the
        bond issuer uses when calculating interest payments. For example, say an investor purchases a bond at a
        premium $1,090 and another investor buys the same bond later when it is trading at a discount for $980.
        When the bond matures, both investors will receive the $1,000 face value of the bond.
        </li>
        <li>The coupon rate is the rate of interest the bond issuer will pay on the face value of the bond,
        expressed as a percentage. For example, a 5% coupon rate means that bondholders will receive 5% x $1000
        face value = $50 every year.
        </li>
        <li>Coupon dates are the dates on which the bond issuer will make interest payments. Payments can be made in
        any interval, but the standard is semiannual payments.
        </li>
        <li>The maturity date is the date on which the bond will mature and the bond issuer will pay the bondholder
        the face value of the bond.
        </li>
        <li>The issue price is the price at which the bond issuer originally sells the bonds.
        </li>
      </ul>
      <p>Two features of a bond—credit quality and time to maturity—are the principal determinants of a bond's coupon
      rate. If the issuer has a poor credit rating, the risk of default is greater, and these bonds pay more
      interest. Bonds that have a very long maturity date also usually pay a higher interest rate. This higher
      compensation is because the bondholder is more exposed to interest rate and inflation risks for an extended
      period.
    </p>
      <p>Credit ratings for a company and its bonds are generated by credit rating agencies like Standard and Poor’s,
      Moody’s, and Fitch Ratings. The very highest quality bonds are called “investment grade” and include debt
      issued by the U.S. government and very stable companies, like many utilities. Bonds that are not considered
      investment grade, but are not in default, are called “high yield” or “junk” bonds. These bonds have a higher
      risk of default in the future and investors demand a higher coupon payment to compensate them for that risk.
    </p>
      <p>Bonds and bond portfolios will rise or fall in value as interest rates change. The sensitivity to changes in
      the interest rate environment is called “duration.” The use of the term duration in this context can be
      confusing to new bond investors because it does not refer to the length of time the bond has before
      maturity. Instead, duration describes how much a bond’s price will rise or fall with a change in interest
      rates.
    </p>
      <p>The rate of change of a bond’s or bond portfolio’s sensitivity to interest rates (duration) is called
      “convexity”. These factors are difficult to calculate, and the analysis required is usually done by
      professionals.
    </p>
      <label>Categories of Bonds
    </label>
      <p>There are four primary categories of bonds sold in the markets. However, you may also see foreign bonds
      issued by corporations and governments on some platforms.
    </p>
      <ul>
        <li>Corporate bonds are issued by companies. Companies issue bonds rather than seek bank loans for debt
        financing in many cases because bond markets offer more favorable terms and lower interest rates.
        </li>
        <li>Municipal bonds are issued by states and municipalities. Some municipal bonds offer tax-free coupon
        income for investors.
        </li>
        <li>Government bonds such as those issued by the U.S. Treasury. Bonds issued by the Treasury with a year or
        less to maturity are called “Bills”; bonds issued with 1–10 years to maturity are called “notes”; and
        bonds issued with more than 10 years to maturity are called “bonds”. The entire category of bonds issued
        by a government treasury is often collectively referred to as "treasuries." Government bonds issued by
        national governments may be referred to as sovereign debt.
        </li>
        <li>Agency bonds are those issued by government-affiliated organizations such as Fannie Mae or Freddie Mac.
        </li>
      </ul>
      <label>Varieties of Bonds
    </label>
      <p>The bonds available for investors come in many different varieties. They can be separated by the rate or type
      of interest or coupon payment, being recalled by the issuer, or have other attributes.
    </p>
      <ul>
        <li>Zero-coupon bonds do not pay coupon payments and instead are issued at a discount to their par value
        that will generate a return once the bondholder is paid the full face value when the bond matures. U.S.
        Treasury bills are a zero-coupon bond.
        </li>
        <li>Convertible bonds are debt instruments with an embedded option that allows bondholders to convert their
        debt into stock (equity) at some point, depending on certain conditions like the share price. For
        example, imagine a company that needs to borrow $1 million to fund a new project. They could borrow by
        issuing bonds with a 12% coupon that matures in 10 years. However, if they knew that there were some
        investors willing to buy bonds with an 8% coupon that allowed them to convert the bond into stock if the
        stock’s price rose above a certain value, they might prefer to issue those.
        </li>
        <li>The convertible bond may the best solution for the company because they would have lower interest
        payments while the project was in its early stages. If the investors converted their bonds, the other
        shareholders would be diluted, but the company would not have to pay any more interest or the principal
        of the bond.
        </li>
        <li>The investors who purchased a convertible bond may think this is a great solution because they can
        profit from the upside in the stock if the project is successful. They are taking more risk by accepting
        a lower coupon payment, but the potential reward if the bonds are converted could make that trade-off
        acceptable.
        </li>
        <li>Callable bonds also have an embedded option but it is different than what is found in a convertible
        bond. A callable bond is one that can be “called” back by the company before it matures. Assume that a
        company has borrowed $1 million by issuing bonds with a 10% coupon that mature in 10 years. If interest
        rates decline (or the company’s credit rating improves) in year 5 when the company could borrow for 8%,
        they will call or buy the bonds back from the bondholders for the principal amount and reissue new bonds
        at a lower coupon rate.
        </li>
        <li>A callable bond is riskier for the bond buyer because the bond is more likely to be called when it is
        rising in value. Remember, when interest rates are falling, bond prices rise. Because of this, callable
        bonds are not as valuable as bonds that aren’t callable with the same maturity, credit rating, and
        coupon rate.
        </li>
        <li>A Puttable bond allows the bondholders to put or sell the bond back to the company before it has
        matured. This is valuable for investors who are worried that a bond may fall in value, or if they think
        interest rates will rise and they want to get their principal back before the bond falls in value.
        </li>
        <li>The bond issuer may include a put option in the bond that benefits the bondholders in return for a lower
        coupon rate or just to induce the bond sellers to make the initial loan. A puttable bond usually trades
        at a higher value than a bond without a put option but with the same credit rating, maturity, and coupon
        rate because it is more valuable to the bondholders.
        </li>
        <li>The possible combinations of embedded puts, calls, and convertibility rights in a bond are endless and
        each one is unique. There isn’t a strict standard for each of these rights and some bonds will contain
        more than one kind of “option” which can make comparisons difficult. Generally, individual investors
        rely on bond professionals to select individual bonds or bond funds that meet their investing goals.
        </li>
      </ul>
      <label>Pricing Bonds
    </label>
      <p>The market prices bonds based on their particular characteristics. A bond's price changes on a daily basis,
      just like that of any other publicly-traded security, where supply and demand in any given moment determines
      that observed price. But there is logic to how bonds are valued. Up to this point, we've talked about bonds
      as if every investor holds them to maturity. It's true that if you do this you're guaranteed to get your
      principal back plus interest; however, a bond does not have to be held to maturity. At any time, a
      bondholder can sell their bonds in the open market, where the price can fluctuate, sometimes dramatically.
    </p>
      <p>The price of a bond changes in response to changes in interest rates in the economy. This is due to the fact
      that for a fixed-rate bond, the issuer has promised to pay a coupon based on the face value of the bond—so
      for a $1,000 par, 10% annual coupon bond, the issuer will pay the bondholder $100 each year.
    </p>
      <p>Say that prevailing interest rates are also 10% at the time that this bond is issued, as determined by the
      rate on a short-term government bond. An investor would be indifferent investing in the corporate bond or
      the government bond since both would return $100. However, imagine a little while later, that the economy
      has taken a turn for the worse and interest rates dropped to 5%. Now, the investor can only receive $50 from
      the government bond, but would still receive $100 from the corporate bond.
    </p>
      <p>This difference makes the corporate bond much more attractive. So, investors in the market will bid up to the
      price of the bond until it trades at a premium that equalizes the prevailing interest rate environment—in
      this case, the bond will trade at a price of $2,000 so that the $100 coupon represents 5%. Likewise, if
      interest rates soared to 15%, then an investor could make $150 from the government bond and would not pay
      $1,000 to earn just $100. This bond would be sold until it reached a price that equalized the yields, in
      this case to a price of $666.67.
    </p>
      <label>Inverse to Interest Rates
    </label>
      <p>This is why the famous statement that a bond’s price varies inversely with interest rates works. When
      interest rates go up, bond prices fall in order to have the effect of equalizing the interest rate on the
      bond with prevailing rates, and vice versa.
    </p>
      <p>Another way of illustrating this concept is to consider what the yield on our bond would be given a price
      change, instead of given an interest rate change. For example, if the price were to go down from $1,000 to
      $800, then the yield goes up to 12.5%. This happens because you are getting the same guaranteed $100 on an
      asset that is worth $800 ($100/$800). Conversely, if the bond goes up in price to $1,200, the yield shrinks
      to 8.33% ($100/$1,200).
    </p>
      <label>Yield-to-Maturity (YTM)
    </label>
      <p>The yield-to-maturity (YTM) of a bond is another way of considering a bond’s price. YTM is the total return
      anticipated on a bond if the bond is held until the end of its lifetime. Yield to maturity is considered a
      long-term bond yield but is expressed as an annual rate. In other words, it is the internal rate of return
      of an investment in a bond if the investor holds the bond until maturity and if all payments are made as
      scheduled. YTM is a complex calculation but is quite useful as a concept evaluating the attractiveness of
      one bond relative to other bonds of different coupon and maturity in the market. The formula for YTM
      involves solving for the interest rate in the following equation, which is no easy task, and therefore most
      bond investors interested in YTM will use a computer:
    </p>
      <label>{"{YTM} = SQUARE ROOT[n] {{ Face Value } / { Present Value }} - 1"}</label>
      <p>We can also measure the anticipated changes in bond prices given a change in interest rates with a measure
      known as the duration of a bond. Duration is expressed in units of the number of years since it originally
      referred to zero-coupon bonds, whose duration is its maturity.
    </p>
      <p>For practical purposes, however, duration represents the price change in a bond given a 1% change in interest
      rates. We call this second, more practical definition the modified duration of a bond.
    </p>
      <p>The duration can be calculated to determine the price sensitivity to interest rate changes of a single bond,
      or for a portfolio of many bonds. In general, bonds with long maturities, and also bonds with low coupons
      have the greatest sensitivity to interest rate changes. A bond’s duration is not a linear risk measure,
      meaning that as prices and rates change, the duration itself changes, and convexity measures this
      relationship.
    </p>
      <label>Real World Bond Example
    </label>
      <p>Imagine a bond that was issued with a coupon rate of 5% and a $1,000 par value. The bondholder will be paid
      $50 in interest income annually (most bond coupons are split in half and paid semiannually). As long as
      nothing else changes in the interest rate environment, the price of the bond should remain at its par value.
    </p>
      <p>However, if interest rates begin to decline and similar bonds are now issued with a 4% coupon, the original
      bond has become more valuable. Investors who want a higher coupon rate will have to pay extra for the bond
      in order to entice the original owner to sell. The increased price will bring the bond’s total yield down to
      4% for new investors because they will have to pay an amount above par value to purchase the bond.
    </p>
      <p>On the other hand, if interest rates rise and the coupon rate for bonds like this one rise to 6%, the 5%
      coupon is no longer attractive. The bond’s price will decrease and begin selling at a discount compared to
      the par value until its effective return is 6%.
    </p>
      <p>The bond market tends to move inversely with interest rates because bonds will trade at a discount when
      interest rates are rising and at a premium when interest rates are falling.
    </p>
      <label>Stocks vs. Bonds
    </label>
      <p>Stocks are issued by companies to raise capital, paid-up or share, in order to grow the business or undertake new projects. There are important distinctions between whether somebody buys shares directly from the company when it issues them (in the primary market) or from another shareholder (on the secondary market). When the corporation issues shares, it does so in return for money.
    </p>
      <p>Bonds are fundamentally different from stocks in a number of ways. First, bondholders are creditors to the corporation, and are entitled to interest as well as repayment of principal. Creditors are given legal priority over other stakeholders in the event of a bankruptcy and will be made whole first if a company is forced to sell assets in order to repay them. Shareholders, on the other hand, are last in line and often receive nothing, or mere pennies on the dollar, in the event of bankruptcy. This implies that stocks are inherently riskier investments that bonds.
    </p>
    </div>
  }

  renderHeader() {
    return <div>
      <label>Curriculum - {this.props.classname}</label>
      <i onClick={this.props.minimize} className="fas fa-window-minimize minus"></i>
      {
        this.state.maximize ?
          <i onClick={() => this.setState((st: any) => ({ ...st, maximize: !st.maximize }))} className="fas fa-compress-arrows-alt maximize"></i> :
          <i onClick={() => this.setState((st: any) => ({ ...st, maximize: !st.maximize }))} className="fas fa-window-maximize maximize"></i>
      }
      <i onClick={this.props.close} className="fas fa-times"></i>
    </div >
  }

  renderStocksTrader() {
    return <div className="topic_stocks_trader" style={this.state.maximize? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
      {this.renderHeader()}
      <div>
        <div>
          <label>Curriculum</label>
          <p>Game Topics</p>
          <ul>
            {
              StockTraderTopicHeadings.map((heading, i) => {
                return <Fragment>
                  {heading === "Stocks" ? <p>Basic Topic</p> : null}
                  <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                </Fragment>
              })
            }
            <label className="start_quiz_button" onClick={() => this.startQuiz()}>Start Quiz</label>
          </ul>
        </div>
        <div>
          {
            this.state.page === 7 ?
              this.renderStocks() :
              this.state.page === 8 ?
                this.renderStocksRef() :
                this.renderCurriculum(
                  this.state.page == 1
                    ? this.state.topic.basic_rules
                    : this.state.page == 2
                      ? this.state.topic.roles
                      : this.state.page == 3
                        ? this.state.topic.basic_strategy_for_traders
                        : this.state.page == 4
                          ? this.state.topic.buy_sell_point
                          : this.state.page == 5
                            ? this.state.topic.trading_breaking_news_strategy
                            : this.state.page == 6
                              ? this.state.topic.calculating_p_l_and_winner
                              : null
                )
          }
        </div>
      </div>
    </div>
  }

  renderBondsTrader() {
    return (
      <div className="topic_stocks_trader" style={this.state.maximize ? { top: 0, bottom: 0 } : { top: 48, bottom: 80 }}>
        {this.renderHeader()}
        <div>
          <div>
            <label>Curriculum</label>
            <p>Game Topics</p>
            <ul>
              {
                BondsTraderTopicHeadings.map((heading, i) => {
                  return <Fragment>
                    {heading === "Bonds" ? <p>Basic Topic</p> : null}
                    <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                  </Fragment>
                })
              }
              <li className="start_quiz_button" onClick={() => this.startQuiz()}><label>Start Quiz</label></li>
            </ul>
          </div>
          <div>
            {
              this.state.page === 6 ?
                this.renderBonds() :
                this.state.page === 7 ?
                  this.renderBondsRef() :
                  this.renderCurriculum(
                    this.state.page == 1
                      ? this.state.topic.basic_rules
                      : this.state.page == 2
                        ? this.state.topic.basic_strategy_for_traders
                        : this.state.page == 3
                          ? this.state.topic.buy_sell_point
                          : this.state.page == 4
                            ? this.state.topic.calculating_p_l_and_winner
                            : this.state.page == 5
                              ? this.state.topic.trading_breaking_news_strategy
                              : null
                  )
            }
          </div>

        </div>
      </div>
    );
  }

  renderStocksMarketMaker() {
    return (
      <div className="topic_stocks_trader" style={this.state.maximize? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
        {this.renderHeader()}
        <div>
          <div>
            <label>Curriculum</label>
            <p>Game Topics</p>
            <ul>
              {
                StockMarketMakerTopicHeadings.map((heading, i) => {
                  return <Fragment>
                    {heading === "Stocks" ? <p>Basic Topic</p> : null}
                    <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                  </Fragment>
                })
              }
              <li className="start_quiz_button" onClick={() => this.startQuiz()}>Start Quiz</li>
            </ul>
          </div>
          <div>
            {
              this.state.page === 6 ?
                this.renderStocks() :
                this.state.page === 7 ?
                  this.renderStocksRef() :
                  this.renderCurriculum(
                    this.state.page == 1
                      ? this.state.topic.basic_rules
                      : this.state.page == 2
                        ? this.state.topic.roles_of_market_maker
                        : this.state.page == 3
                          ? this.state.topic.basic_strategy_for_market_makers
                          : this.state.page == 4
                            ? this.state.topic.trading_breaking_news_strategy
                            : this.state.page == 5
                              ? this.state.topic.calculating_p_l_and_winner
                              : null
                  )
            }
          </div>

        </div>
      </div>
    );
  }

  renderBondsMarketMaker() {
    return (
      <div className="topic_stocks_trader" style={this.state.maximize? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
        {this.renderHeader()}
        <div>
          <div>
            <label>Curriculum</label>
            <p>Game Topics</p>
            <ul>
              {
                BondsMarketMakerTopicHeadings.map((heading, i) => {
                  return <Fragment>
                    {heading === "Bonds" ? <p>Basic Topic</p> : null}
                    <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                  </Fragment>
                })
              }
              <li className="start_quiz_button" onClick={() => this.startQuiz()}>Start Quiz</li>
            </ul>
          </div>
          <div>
            {
              this.state.page === 6 ?
                this.renderBonds() :
                this.state.page === 7 ?
                  this.renderBondsRef() :
                  this.renderCurriculum(
                    this.state.page == 1
                      ? this.state.topic.basic_rules
                      : this.state.page == 2
                        ? this.state.topic.basic_strategy_for_market_makers
                        : this.state.page == 3
                          ? this.state.topic.trading_breaking_news_strategy
                          : this.state.page == 4
                            ? this.state.topic.calculating_p_l_and_winner
                            : this.state.page == 5
                              ? this.state.topic.bond_fundamentals
                              : null
                  )
            }
          </div>

        </div>
      </div>
    );
  }

  renderStocksAdvancedTrader() {
    return (
      <div className="topic_stocks_trader" style={this.state.maximize? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
        {this.renderHeader()}
        <div>
          <div>
            <label>Curriculum</label>
            <p>Game Topics</p>
            <ul>
              {
                StocksAdvancedTraderHeadings.map((heading, i) => {
                  return <Fragment>
                    {heading === "Stocks" ? <p>Basic Topic</p> : null}
                    <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                  </Fragment>
                })
              }
              <li className="start_quiz_button" onClick={() => this.startQuiz()}>Start Quiz</li>
            </ul>
          </div>
          <div>
            {this.state.page === 4 ?
              this.renderStocks() :
              this.state.page === 5 ?
                this.renderStocksRef() :
                this.renderCurriculum(
                  this.state.page == 1
                    ? this.state.topic.advanced_strategy_for_traders
                    : this.state.page == 2
                      ? this.state.topic.calculating_p_l_and_winner
                      : this.state.page == 3
                        ? this.state.topic.trading_breaking_news_strategy
                        : null
                )}
          </div>

        </div>
      </div>
    );
  }

  renderBondsAdvancedTrader() {
    return (
      <div className="topic_stocks_trader" style={this.state.maximize? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
        {this.renderHeader()}
        <div>
          <div>
            <label>Curriculum</label>
            <p>Game Topics</p>

            <ul>
              {
                BondsAdvancedTraderHeadings.map((heading, i) => {
                  return <Fragment>
                    {heading === "Bonds" ? <p>Basic Topic</p> : null}
                    <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                  </Fragment>
                })
              }
              <li className="start_quiz_button" onClick={() => this.startQuiz()}>Start Quiz</li>
            </ul>
          </div>
          <div>
            {this.state.page === 6 ?
              this.renderBonds() :
              this.state.page === 7 ?
                this.renderBondsRef() :
                this.renderCurriculum(
                  this.state.page == 1
                    ? this.state.topic.advanced_strategy_for_traders
                    : this.state.page == 2
                      ? this.state.topic.calculating_p_l_and_winner
                      : this.state.page == 3
                        ? this.state.topic.trading_breaking_news_strategy
                        : this.state.page == 4
                          ? this.state.topic.bond_fundamentals
                          : this.state.page == 5
                            ? this.state.topic.economic_indicator_tutorial
                            : null
                )}
          </div>

        </div>
      </div>
    );
  }

  renderStocksAdvancedMarketMaker() {
    return (
      <div className="topic_stocks_trader" style={this.state.maximize? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
        {this.renderHeader()}
        <div>
          <div>
            <label>Curriculum</label>
            <p>Game Topics</p>
            <ul>
              {
                StocksAdvancedMarketMakerHeadings.map((heading, i) => {
                  return <Fragment>
                    {heading === "Stocks" ? <p>Basic Topic</p> : null}
                    <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                  </Fragment>
                })
              }
              <li className="start_quiz_button" onClick={() => this.startQuiz()}>Start Quiz</li>
            </ul>
          </div>
          <div>
            {this.state.page === 4 ?
              this.renderStocks() :
              this.state.page === 5 ?
                this.renderStocksRef() :
                this.renderCurriculum(
                  this.state.page == 1
                    ? this.state.topic.basic_rules
                    : this.state.page == 2
                      ? this.state.topic.basic_strategy_for_market_makers
                      : this.state.page == 3
                        ? this.state.topic.calculating_p_l_and_winner
                        : null
                )}
          </div>

        </div>
      </div>
    );
  }

  renderBondsAdvancedMarketMaker() {
    return (
      <div className="topic_stocks_trader" style={this.state.maximize? { top: 0, bottom: 0 } : { top: 45, bottom: 80 }}>
        {this.renderHeader()}
        <div>
          <div>
            <label>Curriculum</label>
            <p>Game Topics</p>
            <ul>
              {
                BondsAdvancedMarketMakerHeadings.map((heading, i) => {
                  return <Fragment>
                    {heading === "Bonds" ? <p>Basic Topic</p> : null}
                    <li style={{ opacity: this.state.page === i + 1 ? 1.0 : 0.6 }} key={i} onClick={() => this.setState(st => ({ ...st, page: i + 1 }))}>{heading}</li>
                  </Fragment>
                })
              }
              <li className="start_quiz_button" onClick={() => this.startQuiz()}>Start Quiz</li>
            </ul>
          </div>
          <div>
            {this.state.page === 6 ?
              this.renderBonds() :
              this.state.page === 7 ?
                this.renderBondsRef() :
                this.renderCurriculum(
                  this.state.page == 1
                    ? this.state.topic.basic_rules
                    : this.state.page == 2
                      ? this.state.topic.strategy_for_market_makers
                      : this.state.page == 3
                        ? this.state.topic.calculating_p_l_and_winner
                        : this.state.page == 4
                          ? this.state.topic.bond_fundamentals
                          : this.state.page == 5
                            ? this.state.topic.economic_indicator_tutorial
                            : null
                )}
          </div>

        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={this.props.style} className="topicitem_root">
        {/* <div className="jumbotron backgroundImage">
          <div
            className="container   registration-title"
            style={{ backgroundColor: "#1a73e8" }}
          >
            Learning Module - Game Type: {this.props.topic} | Game Level: {this.props.level}
          </div>
        </div> */}
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : (
            <div className="container cmt20 curriculum-content">
              {this.props.topic == "Stocks"
                ? this.props.level == "Trader"
                  ? this.renderStocksTrader()
                  : this.props.level == "Market Maker"
                    ? this.renderStocksMarketMaker()
                    : this.props.level == "Advanced Trader"
                      ? this.renderStocksAdvancedTrader()
                      : this.props.level == "Advanced Market Maker"
                        ? this.renderStocksAdvancedMarketMaker()
                        : null
                : this.props.topic == "Bonds"
                  ? this.props.level == "Trader"
                    ? this.renderBondsTrader()
                    : this.props.level == "Market Maker"
                      ? this.renderBondsMarketMaker()
                      : this.props.level == "Advanced Trader"
                        ? this.renderBondsAdvancedTrader()
                        : this.props.level == "Advanced Market Maker"
                          ? this.renderBondsAdvancedMarketMaker()
                          : null
                  : null}
            </div>
          )}
      </div>
    );
  }
}
export default withRouter(TopicItem);
