import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
class Toast {
    success(msg) {
        toast.success(msg, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
        });
    }
    warn(msg) {
        toast.warn(msg, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    error(msg) {
        toast.error(msg, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    errorWithoutAutoClose(msg) {
        toast.error(msg, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true
        });
    }
    successToastView() {
        return (
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
        )
    }
    errorToastView() {
        return (
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
        )
    }
}
const toastMessage = new Toast
export default toastMessage