import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classService from "../../services/classService";
import ProfService from "../../services/profService";
import { InviteData } from "../../interfaces";
import { ClassDetails } from "../../interfaces";
import authStore from "../../models/AuthStore";
import LoadingWheel from "../../components/LoadingWheel";
import Toast from "../../components/Toast";
import commonService from "../../services/commonService";
import Validate from "../../Helpers/Utility/Validation";
export interface InviteProps extends RouteComponentProps {}

export interface InviteState {}

class SingleInvite extends Component<InviteProps, InviteState> {
  state = {
    inviteDetails: {} as InviteData,
    errors: {} as InviteData,
    classes: Array<ClassDetails>(),
    url: "",
    showLoader: false,
    generateurl: true,
    copied: false
  };
  async componentDidMount() {
    let res = await classService.getAllClassForProfessor();
    this.setState({ classes: res.data });
  }

  async changeHandler(e: any) {
    let stateObject = {} as any;
    stateObject[e.target.name] = e.target.value;
    if (e.target.name == "classname") {
      let data = await this.state.classes.filter(item => {
        if (item.classname && item.classname.trim() == e.target.value.trim()) {
          this.state.errors.classname = "";
          this.setState({ errors: this.state.errors });
          return item;
        }
      });

      stateObject["id"] = data[0].id;
    }
    this.setState(Object.assign(this.state.inviteDetails, stateObject));
    this.setState({ generateurl: true });
  }

  async generate() {
    this.setState({ showLoader: true });
    let res = await ProfService.classInvite(this.state.inviteDetails);
    this.setState({ showLoader: false });
    if (res) {
      if (res.status == 200) {
        this.setState({ url: res.result });
      } else {
        console.log("Error", res.msg);
      }
    }
  }

  copyToClipboard() {
    var textField = document.createElement("textarea");
    textField.innerText = this.state.url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.setState({ copied: true });
  }

  async validateForm() {
    this.setState({ showLoader: true });
    let formvalid = true;
    const errors: { [index: string]: any } = {};
    let inviteError;

    inviteError = await Validate.validateSelect(
      this.state.inviteDetails.classname,
      "classname",
      "a class"
    );
    await this.setState(Object.assign(this.state.errors, inviteError.errors));
    if (inviteError.isValid == false) {
      formvalid = false;
    }

    inviteError = await Validate.validateEmail(
      this.state.inviteDetails.email,
      "email",
      "addclass"
    );
    await this.setState(Object.assign(this.state.errors, inviteError.errors));
    if (inviteError.isValid == false) {
      formvalid = false;
    }

    this.setState({ showLoader: false });
    if (formvalid == true) {
      this.generate();
      this.setState({ generateurl: false });
      this.setState({ copied: false });
    }
  }

  render() {
    return (
      <div className="section ">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 reg-card">
              <div className="registration-title">Invite Student</div>

              <div className="row mt-10 mb-10">
                <div className="col-md-4">
                  {" "}
                  Class<span className="mandtory-star">*</span>
                </div>
                <div className="col-md-8">
                  <select
                    className="form-control bfh-timezones"
                    data-country="US"
                    placeholder="date"
                    value={this.state.inviteDetails.classname}
                    name="classname"
                    onBlur={async () => {
                      let inviteError = await Validate.validateSelect(
                        this.state.inviteDetails.classname,
                        "classname",
                        "a class"
                      );
                      await this.setState(
                        Object.assign(this.state.errors, inviteError.errors)
                      );
                    }}
                    onChange={e => this.changeHandler(e)}
                  >
                    <option selected disabled>
                      Classes
                    </option>
                    {this.state.classes.map(item => {
                      return <option>{item.classname}</option>;
                    })}
                  </select>
                  <div className="validation-error">
                    {" "}
                    {this.state.errors.classname}{" "}
                  </div>
                </div>
              </div>

              <div className="row mt-10 mb-10">
                <div className="col-md-4">
                  {" "}
                  Email<span className="mandtory-star">*</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onBlur={async () => {
                      let inviteError = await Validate.validateEmail(
                        this.state.inviteDetails.email,
                        "email",
                        "addclass"
                      );
                      await this.setState(
                        Object.assign(this.state.errors, inviteError.errors)
                      );
                    }}
                    value={
                      this.state.inviteDetails.email
                        ? this.state.inviteDetails.email
                        : ""
                    }
                    onChange={e => this.changeHandler(e)}
                  />
                  <div className="validation-error">
                    {" "}
                    {this.state.errors.email}{" "}
                  </div>
                </div>
              </div>

              {this.state.url != "" ? (
                <div className="row mt-10 mb-10">
                  <div className="col-md-12 url-div">{this.state.url}</div>
                </div>
              ) : null}

              {this.state.url ? (
                <button
                  className={
                    this.state.copied
                      ? "btn btn-primary pull-right next-btn copied-clipboard"
                      : "btn btn-primary pull-right next-btn"
                  }
                  onClick={() => this.copyToClipboard()}
                >
                  Copy to clipboard
                </button>
              ) : null}

              {this.state.generateurl ? (
                <button
                  className="btn btn-primary pull-right next-btn"
                  onClick={() => this.validateForm()}
                >
                  Generate URL
                </button>
              ) : null}
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>

        {this.state.showLoader ? <LoadingWheel /> : null}

        {/* <div className="label-detail"> Allow professor to generate URL to invite students to a class </div> */}
      </div>
    );
  }
}
export default withRouter(SingleInvite);
