import React, { useEffect, useState } from 'react';
import './viewLeader.scss';

export default ({ type, onClose, data }) => {
    const [topStudents, setTopStudents] = useState(null);
    useEffect(() => {
        fetch(`https://us-central1-playftstaging.cloudfunctions.net/getTopStudents/${type.toLowerCase()}/${data.className}/5`).then((res) => res.json()).then((res) => { setTopStudents(res) }).catch((e) => onClose())
    }, [])

    const _renderContent = () => {
        return <table>
            <tr>
                <th>#</th>
                <th>Player</th>
                <th>Closing Balance</th>
                <th>Rounds Played</th>
                <th>Avg Earnings (Per Round)</th>
            </tr>
            {
                topStudents.map((tStudent, i) => {
                    return <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{Object.values(tStudent)[0][1]}</td>
                        <td>{Object.values(tStudent)[0][2].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                        <td>{Object.values(tStudent)[0][3]}</td>
                        <td>{Object.values(tStudent)[0][0].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                    </tr>
                })
            }
        </table>
    }

    return <div className="view_leaders_model">
        {
            topStudents !== null ?
                <div>
                    <div>{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} Leaderboard</div>
                    <div>
                        {_renderContent()}
                    </div>
                    <div>
                        <div onClick={onClose}>CLOSE</div>
                    </div>
                </div>
                : null
        }
    </div>
}