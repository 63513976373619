import React, { Component } from "react";

export default class LoadingWheel extends Component {
    render() {
        return (
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'rgba(0,0,0,0)' }}>
                <div className="lds-dual-ring" />
            </div>
        )
    }
}