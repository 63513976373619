import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Toast from "../../components/Toast";
import { Player } from "../../interfaces";
import PlayerService from "../../services/playerService";
import ROUTES from "../../ROUTES";
import LoadingWheel from "../../components/LoadingWheel";
import "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Constants from "../../Helpers/Constants/Constants";
import Validate from "../../Helpers/Utility/Validation";
import commonService from "../../services/commonService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import PhoneCode from "../../components/PhoneCode";
import ProfService from "../../services/profService";
// import {PhoneNumberUtil} from 'google-libphonenumber';
import { isPossibleNumber } from "libphonenumber-js";
import CommonService from "../../services/commonService";
import pSvg from "../../pSvg.svg";
import playerImg from "../../playerImg.svg";

declare var $: any;
export interface RegProps extends RouteComponentProps {}

export interface RegState {}

class playerInvitation extends Component<RegProps, RegState> {
  msg = "";

  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);
  constructor(props: any) {
    super(props);
    this.state.playerDetails.collegeCountry = "United States";
    this.state.playerDetails.country = "United States";
    this.setState(
      Object.assign(this.state.playerDetails, this.state.playerDetails)
    );
  }
  state = {
    Page: 1,
    playerDetails: {} as Player,
    error: {} as Player,
    checkAccept: false,
    checkError: "",
    emailvalid: false,
    showLoader: false,
    countrycode: "",
    countryname: "",
    phone: "",
    isDateValueNull: false,
    isSubmit: false,
    contestname: ""
  };
  async componentDidMount() {
    this.setDefaultValues();

    if (this.props.location.state) {
      await this.setState({ showLoader: true });
      let response = await PlayerService.getPlayerDetails();

      if (response) {
        await this.setState({ playerDetails: response });
        this.setState({ showLoader: false });
      }
    }
    this.isInvite();
  }
  async isInvite() {
    if (this.props.location.state && this.props.location.state.contestname) {
      this.setState({ showLoader: true });
      this.state.playerDetails.contestname = this.props.location.state.contestname;
      this.state.playerDetails.email = this.props.location.state.email;
      await this.setState({ playerDetails: this.state.playerDetails });
      this.setState({ showLoader: false });
    } else {
      // this.setState({ showLoader: true });
      // this.state.playerDetails.contestname = this.props.location.state.contestname;
      // this.state.playerDetails.email = this.props.location.state.email;
      // await this.setState({ playerDetails: this.state.playerDetails });
      // this.setState({ showLoader: false });
    }
  }
  setDefaultValues() {
    this.setState({ countrycode: "+1", countryname: "United States" });
    this.state.playerDetails.dob = moment()
      .subtract(15, "years")
      .calendar();
    this.setState(
      Object.assign(this.state.playerDetails, this.state.playerDetails)
    );
  }

  async changePhoneNumber(e: any) {
    if (e.target.name != "phone" || e.target.value.match(/^[0-9]{0,12}$/)) {
      await this.setState({ [e.target.name]: e.target.value }, () => {
        this.state.playerDetails.phonenumber =
          this.state.countrycode + this.state.phone;
      });
      Object.assign((this.state.playerDetails, this.state.playerDetails));
    }
  }

  async changePhoneNumberCode(code: any) {
    await this.setState(() => {
      this.state.playerDetails.phonenumber = code + this.state.phone;
    });
  }

  changeHandler(e: any, type?: any) {
    if (type && e.target.value.length <= 100) {
      if (type == "text") {
        if (e.target.value.match(/^[A-Za-z\s]{0,}$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value.trimLeft();
          this.setState(Object.assign(this.state.playerDetails, stateObject));
        }
      } else if (type == "number") {
        if (e.target.value.match(/^[\+]?[0-9]{0,12}$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value;
          this.setState(Object.assign(this.state.playerDetails, stateObject));
        }
      } else if (type == "password") {
        if (e.target.value.match(/^[^\s]*$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value;
          this.setState(Object.assign(this.state.playerDetails, stateObject));
        }
      }
    } else if (e.target.value.length <= 100) {
      let stateObject = {} as any;
      stateObject[e.target.name] = e.target.value.trimLeft();
      this.setState(Object.assign(this.state.playerDetails, stateObject));
    }
  }

  checkHandler(e: any) {
    let checkAccept =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ checkAccept: checkAccept });
  }

  changeDOB = (value: any) => {
    if (value !== null) {
      if (value != "Invalid Date") {
        let date = String(value.getDate());
        let month = String(value.getMonth() + 1);
        let year = String(value.getFullYear());

        this.state.error.dob = "";
        this.state.playerDetails.dob =
          year +
          (month.length == 1 ? "-0" : "-") +
          month +
          (date.length == 1 ? "-0" : "-") +
          date;

        this.setState(Object.assign(this.state.error, this.state.error));
        this.setState(
          Object.assign(this.state.playerDetails, this.state.playerDetails)
        );
        this.setState({ isDateValueNull: false });
      } else {
        this.setState({ isDateValueNull: true });
      }
    } else {
      this.state.playerDetails.dob = value;
      this.setState(
        Object.assign(this.state.playerDetails, this.state.playerDetails)
      );
    }
  };

  isAnyErrorFound(error: any) {
    this.setState(Object.assign(this.state.error, error.errors));
  }

  async validatePage() {
    this.setState({ showLoader: true });

    let formvalid = true;
    const errors: { [index: string]: any } = {};

    let formError;
    formError = await Validate.validateName(
      this.state.playerDetails.firstname,
      "firstname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateName(
      this.state.playerDetails.lastname,
      "lastname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateEmail(
        this.state.playerDetails.email,
        "email",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validatePhone(
        this.state.playerDetails.phonenumber,
        "phonenumber",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }
    if (!(this.props.location.state && this.props.location.state.id)) {
      let formError;

      formError = await Validate.validatePassword(
        this.state.playerDetails.password,
        "password"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.confirmPassword(
        this.state.playerDetails.password,
        this.state.playerDetails.confirmPassword,
        "confirmPassword"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validateCheckBox(
        this.state.checkAccept,
        "checkError"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }

    if (formvalid == true) {
      if (!(this.props.location.state && this.props.location.state.id)) {
        this.createPlayer();
      } else {
        this.updatePlayer();
      }
    } else {
      this.setState({ isSubmit: false });
      this.setState({ showLoader: false });
    }
    console.log("formvalid" + formvalid);
    return formvalid;
  }

  async validatePage1() {
    this.setState({ showLoader: true });
    let formvalid = true;
    const errors: { [index: string]: any } = {};

    let formError;
    formError = await Validate.validateName(
      this.state.playerDetails.firstname,
      "firstname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateName(
      this.state.playerDetails.lastname,
      "lastname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateDOB(
      this.state.playerDetails.dob,
      "dob",
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateCollegeName(
      this.state.playerDetails.collegeName,
      "collegeName"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateEmail(
        this.state.playerDetails.email,
        "email",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validatePhone(
        this.state.playerDetails.phonenumber,
        "phonenumber",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }
    // formError = await Validate.validateCity(this.state.playerDetails.city, "city");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateState(this.state.playerDetails.state, "state");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateZip(this.state.playerDetails.zip, "zip");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    this.setState({ showLoader: false });

    if (formvalid == true) {
      this.setState({ Page: 2 });
    }
    return formvalid;
  }

  async validatePage2() {
    let formvalid = true;
    let formError;
    this.setState({ showLoader: true });
    formError = await Validate.validateCollegeName(
      this.state.playerDetails.collegeName,
      "collegeName"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    // formError = await Validate.validateCity(this.state.playerDetails.collegeCity, "collegeCity");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateState(this.state.playerDetails.collegeState, "collegeState");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateZip(this.state.playerDetails.collegeZip, "collegeZip");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }
    this.setState({ showLoader: false });
    if (formvalid == true) {
      this.setState({ Page: 3 });
    }
    return formvalid;
  }
  async validatePage3() {
    let formvalid = true;
    const errors: { [index: string]: any } = {};
    if (!(this.props.location.state && this.props.location.state.id)) {
      let formError;

      formError = await Validate.validatePassword(
        this.state.playerDetails.password,
        "password"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.confirmPassword(
        this.state.playerDetails.password,
        this.state.playerDetails.confirmPassword,
        "confirmPassword"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validateCheckBox(
        this.state.checkAccept,
        "checkError"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }

    if (formvalid == true) {
      if (!(this.props.location.state && this.props.location.state.id)) {
        console.log(
          "this.createPlayer() this.state.playerDetails",
          this.state.playerDetails
        );
        this.createPlayer();
      } else {
        console.log(
          "this.updatePlayer() this.state.playerDetails",
          this.state.playerDetails
        );
        this.updatePlayer();
      }
    } else {
      this.setState({ isSubmit: false });
      this.setState({ showLoader: false });
    }

    return formvalid;
  }

  async createPlayer() {
    this.setState({ showLoader: true });
    this.state.playerDetails.contestid = [this.props.location.state.contestid];

    let response = await PlayerService.createPlayer(this.state.playerDetails);
    this.setState({ showLoader: false });
    if (response) {
      if (response.status) {
        if (response.status == 200) {
          this.msg = response.msg;
          this.notifySuccess();
          setTimeout(() => {
            this.props.history.push(ROUTES.HOME);
            $("#exampleModal").modal("show");
          });
        } else {
          this.msg = response.msg;
          this.notifyError();
          this.setState({ isSubmit: false });
        }
      } else {
        this.msg = response.msg;
        this.notifyError();
        this.setState({ isSubmit: false });
      }
    }
  }

  async updatePlayer() {
    this.setState({ showLoader: true });
    this.state.playerDetails.contestid = [this.props.location.state.contestid];
    let response = await PlayerService.updatePlayer(this.state.playerDetails);
    this.setState({ showLoader: false });
    if (response) {
      if (response.status == 200) {
        this.msg = response.msg;
        this.notifySuccess();
        setTimeout(() => {
          this.setState({ Page: 1, isSubmit: false });
          this.props.history.push(ROUTES.HOME);
        }, 2000);
      } else {
        this.msg = response.msg;
        this.notifyError();
      }
    }
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8", height: "6vh" }}
            className=" container registration-title"
          ></div>
        </div>
        <div className="container cmt20">
          <div className="row equal">
            <div
              className="col-md-5 reg-card btrr"
              style={{ paddingTop: "20px" }}
            >
              <div
                className="wizard"
                id="myWizard"
                style={{ paddingTop: "5vh" }}
              >
                <div className="step" style={{ textAlign: "center" }}>
                  <img src={playerImg} width="320" height="auto" alt="" />
                  <div
                    className="row mt-15 mb-15 info highlight"
                    style={{ paddingTop: "12px" }}
                  >
                    <h2 className="col-md-12 " style={{ textAlign: "center" }}>
                      Individual Registration
                    </h2>
                  </div>
                  <p className="form-left-text" style={{ paddingTop: "20px" }}>
                    Individual Players can enhance their market and trading
                    skills by using our innovative learning platform that covers
                    many investment products. In less than four minutes, you can
                    complete registration and get started with your
                    individualized course.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-7 col-sm-12 reg-card btlr"
              style={{ paddingTop: "20px" }}
            >
              <div className="wizard" id="myWizard">
                <div className="step">
                  <div className="row info-head">
                    <div className="col-md-12">
                      <div className="row mt-15 mb-15">
                        <h3 className="col-md-6 ">Personal Information</h3>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Name<span className="mandtory-star">*</span>{" "}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        placeholder="First Name"
                        disabled={
                          this.props.location.state &&
                          this.props.location.state.id
                        }
                        value={
                          this.state.playerDetails.firstname
                            ? this.state.playerDetails.firstname
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateName(
                            this.state.playerDetails.firstname,
                            "firstname"
                          );
                          this.setState(
                            Object.assign(this.state.error, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e, "text")}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.error.firstname}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        placeholder="Last Name"
                        disabled={
                          this.props.location.state &&
                          this.props.location.state.id
                        }
                        value={
                          this.state.playerDetails.lastname
                            ? this.state.playerDetails.lastname
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateName(
                            this.state.playerDetails.lastname,
                            "lastname"
                          );
                          this.setState(
                            Object.assign(this.state.error, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e, "text")}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.error.lastname}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Individual's Email<span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="email"
                        name="email"
                        disabled={this.props.location.state}
                        className="form-control"
                        placeholder="Email"
                        value={
                          this.state.playerDetails.email
                            ? this.state.playerDetails.email
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateEmail(
                            this.state.playerDetails.email,
                            "email",
                            true
                          );
                          this.setState(
                            Object.assign(this.state.error, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e)}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.error.email}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Phone Number<span className="mandtory-star">*</span>
                    </div>
                    {!this.props.location.state ? null : !this.props.location
                        .state.playerInvitation ? (
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="phonenumber"
                          className="form-control"
                          placeholder="Phone Number"
                          //   disabled={true}
                          value={this.state.playerDetails.phonenumber}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="col-md-4">
                          <PhoneCode
                            onSelect={(code: any, name: any) => {
                              this.setState({
                                countrycode: code,
                                countryname: name
                              });
                              this.changePhoneNumberCode(code);
                            }}
                            defaultValue={
                              this.state.countrycode
                                ? this.state.countryname
                                : "Select Country"
                            }
                            className="form-control"
                          />
                        </div>

                        <div className="col-md-4">
                          <input
                            type="text"
                            name="phone"
                            className="form-control phone-no-width"
                            placeholder="Phone Number"
                            value={this.state.phone ? this.state.phone : ""}
                            onBlur={async () => {
                              let error = (await Validate.validatePhone(
                                this.state.phone,
                                "phonenumber"
                              )) as any;
                              if (error.phonenumber == "") {
                                let res = await commonService.verifyPhoneNumber(
                                  this.state.playerDetails.phonenumber
                                );
                                if (res.status != 200) {
                                  error.phonenumber = res.msg;
                                }
                              }
                              this.setState(
                                Object.assign(this.state.error, error.errors)
                              );
                            }}
                            onChange={e => this.changePhoneNumber(e)}
                          />
                          <div className="validation-error">
                            {this.state.error.phonenumber &&
                            this.state.error.phonenumber != ""
                              ? this.state.error.phonenumber
                              : ""}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="row mt-15 mb-15 info-head">
                                        <h3 className="col-md-6  ">School/College Information</h3>
                                    </div>
                                    <div className="row mt-10 mb-10">
                                        <div className="col-md-4">
                                            School/College Name
                      <span className="mandtory-star">*</span>
                                        </div>
                                        <div className="col-md-8">
                                            <input
                                                type="text"
                                                name="collegeName"
                                                className="form-control"
                                                placeholder="School/College Name"
                                                onBlur={async () => {
                                                    let error = await Validate.validateCollegeName(
                                                        this.state.playerDetails.collegeName,
                                                        "collegeName"
                                                    );
                                                    this.setState(
                                                        Object.assign(this.state.error, error.errors)
                                                    );
                                                }}
                                                value={
                                                    this.state.playerDetails.collegeName
                                                        ? this.state.playerDetails.collegeName
                                                        : ""
                                                }
                                                onChange={e => this.changeHandler(e)}
                                            />
                                            <div className="validation-error">
                                                {" "}
                                                {this.state.error.collegeName}
                                            </div>
                                        </div>
                                    </div> */}

                  <div className="row mt-15 mb-15 info-head">
                    <h3 className="col-md-6 ">Login Information</h3>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">Email</div>
                    <div className="col-md-8">
                      <input
                        type="email"
                        className="form-control"
                        id="copyPreviousclass"
                        aria-describedby="previousclass"
                        disabled={true}
                        value={this.state.playerDetails.email}
                      />
                    </div>
                  </div>
                  {/* if player invitation */}
                  {/* {!this.props.location.state && this.props.location.state.playerInvitation ? ():()
                }  */}
                  {!this.props.location.state ? null : this.props.location.state
                      .playerInvitation ? (
                    <div className="row mt-10 mb-10">
                      <div className="col-md-4">
                        {" "}
                        Password<span className="mandtory-star">*</span>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="password"
                          autoComplete="new-password"
                          name="password"
                          className="form-control"
                          placeholder="Min 6 characters"
                          onBlur={async () => {
                            let error = await Validate.validatePassword(
                              this.state.playerDetails.password,
                              "password"
                            );
                            this.setState(
                              Object.assign(this.state.error, error.errors)
                            );
                          }}
                          value={
                            this.state.playerDetails.password
                              ? this.state.playerDetails.password
                              : ""
                          }
                          onChange={e => this.changeHandler(e, "password")}
                        />
                        <div className="validation-error">
                          {" "}
                          {this.state.error.password}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!this.props.location.state ? null : this.props.location.state
                      .playerInvitation ? (
                    <div className="row mt-10 mb-10">
                      <div className="col-md-4">
                        {" "}
                        Confirm Password<span className="mandtory-star">*</span>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="password"
                          autoComplete="new-password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Min 6 characters"
                          onBlur={async () => {
                            let error = await Validate.confirmPassword(
                              this.state.playerDetails.password,
                              this.state.playerDetails.confirmPassword,
                              "confirmPassword"
                            );
                            this.setState(
                              Object.assign(this.state.error, error.errors)
                            );
                          }}
                          value={
                            this.state.playerDetails.confirmPassword
                              ? this.state.playerDetails.confirmPassword
                              : ""
                          }
                          onChange={e => this.changeHandler(e, "password")}
                        />
                        <div className="validation-error">
                          {" "}
                          {this.state.error.confirmPassword}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mt-10 mb-10">
                    <div className="col-md-12 ">
                      {!this.props.location.state ? null : this.props.location
                          .state.playerInvitation ? (
                        <form className="trading-rule-chk-box mt-10">
                          <label className="checkbox-inline">
                            <input
                              type="checkbox"
                              value=""
                              name="accept"
                              onChange={e => this.checkHandler(e)}
                              checked={this.state.checkAccept}
                              className="chk-box-reg"
                            />
                            <div className="checkLabel">
                              I accept the
                              <a
                                className="terms-conditions"
                                href={"./terms.html"}
                                target="_blank"
                              >
                                {" "}
                                Terms and Conditions
                              </a>{" "}
                              and Privacy Policy of this site
                            </div>
                          </label>
                          <div className="validation-error">
                            {" "}
                            {this.state.error.checkError}
                          </div>
                        </form>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="row mt-10 mb-10"
                    style={{ textAlign: "center" }}
                  >
                    <button
                      className="btn btn-primary  next-btn "
                      disabled={this.state.isSubmit}
                      onClick={() => {
                        this.setState({ isSubmit: true });
                        this.validatePage();
                      }}
                    >
                      {this.props.location.state && this.props.location.state.id
                        ? "Update"
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="step validation-error">
                All fields marked with an asterisk are required.
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoader ? <LoadingWheel /> : null}
      </div>
    );
  }

  // showError() {}
}

export default withRouter(playerInvitation);
