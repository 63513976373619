import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";

export interface PlatformProps extends RouteComponentProps {}

export interface PlatformState {}

export default class Platform extends Component<PlatformProps, PlatformState> {
  render() {
    return (
      <div id="about" className="section wb">
        <div className="row jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8" }}
            className=" container registration-title"
          >
            Welcome to FutureTraders!
          </div>
        </div>
        <div className="container cmt0">
          <div className="row">
            <div className="col-md-7">
              <div className="message-box">
                <p className="">
                  FutureTraders is a full-fledged learning platform for anyone
                  wanting to learn to be a Trader and/or a Market Maker and
                  experience the rush of an active trading environment!
                </p>
                <p>
                  Our trading simulation is fast, fun, and engaging. We utilize
                  a unique mathematical and informational ecosystem to optimize
                  the learning experience and exponentially speed up the
                  learning curve. We use scaffold levels of increasing
                  complexity and excitement and provide exposure to many
                  investment products such as Stocks, Bonds, Commodities,
                  Options, and Crypto Currencies. Our goal is to create savvy
                  investors and help them learn how to trade with an edge and
                  master the Markets.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="post-media fadeIn col-md-12">
                <iframe
                  className="top-align-video"
                  style={{ marginTop: "0px" }}
                  src="https://www.youtube.com/embed/nqlo2PhA18s"
                  height="270"
                  width="480"
                  frameBorder="0"
                  allowFullScreen
                >
                  {" "}
                </iframe>
              </div>
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="row">
            <div className="col-md-12">
              <h3 className="heading-portfolio">What is FutureTraders?</h3>
              <p>
                {" "}
                Use our Multi-Dimensional Learning Platform to quickly learn the
                skills to trade any financial product!  Learn to react to
                important news events using the most exciting trading simulation
                ever invented to quickly learn the skills of a trader and market
                maker!{" "}
              </p>
              <p>
                <b>Professors:</b> Use our simulation to turn any class into an
                active trading floor to exponentially accelerate your students'
                learning curve and make your class more fun!{" "}
              </p>
              <p>
                <b>Students:</b> Try our demo to experience the rush of an
                active trading floor to quickly learn the skills of a trader and
                market maker while competing against your peers to make the most
                profits!{" "}
              </p>
              <p>
                FutureTraders is an interactive trading simulation in which
                students compete against peers and/or bots to generate the
                greatest trading profits. FutureTraders employs insistent design
                and scaffold gameplay to encourage a multi-perspectival
                understanding of financial markets, one that helps students
                better understand the complex and sometimes contradictory
                strategies that ultimately determine financial success. It
                combines the strategy and probability theory of card games with
                the skills of a financial trader and market maker. Designed to
                be portable, persistent, and fun, FutureTraders serves as a
                model of how complex questions of financial literacy can be
                taught through digital gaming.{" "}
              </p>
              <p>
                In this version, students can trade stocks and bonds and
                experience the role of both a trader and a market maker at
                various levels of complexity. In future versions, we will add
                many additional financial products such as Crypto currencies,
                Forex, Commodities and even teach students how to evaluate and
                trade derivatives on all the available financial products.
              </p>
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="row">
            <div className="col-md-12">
              <h3 className="heading-portfolio">
                {" "}
                Why is FutureTraders important to your course?{" "}
              </h3>
              <p>
                <i> FutureTraders</i> offers an invaluable tool for instructors
                and students in many disciplines on many levels. Finance
                instructors can use it to teach students the mechanics and
                strategy of trading stocks, bonds or any other financial
                product. Students will experience the roles of a trader and a
                market maker and quickly grasp concepts such as market
                efficiency, relative value, expected value, and many other
                concepts crucial to advanced financial literacy. Economics
                instructors can utilize FutureTraders to simulate macroeconomic
                and geo-political events and their effect on interest rates and
                bond prices. Management instructors can use FutureTraders to
                teach negotiation strategies. Psychology instructors can utilize
                FutureTraders to analyze behaviors such as fear, greed, and risk
                aversion. Statistics and Mathematics instructors can use it to
                teach basic through advanced mathematical concepts.{" "}
              </p>
              <p>
                Utilizing <i>FutureTraders</i> to ‘game-ify’ your class will
                increase student engagement and exponentially speed up the
                learning curve. Unlike other trading games where students have
                to trade real stocks in real time and therefore need a long
                time-frame to see the implications of their decisions, our
                unique mathematical and news event ecosystem bombards students
                with fast breaking news events they must immediately react to
                and make many trading decisions within a 2-minute trading round.
                They then get instantaneous feedback on the consequences of
                their evaluation and trading decisions thus leading a super-fast
                learning curve.{" "}
              </p>
              <p>
                Students will develop strong negotiation and valuation skills.
                They will learn situational awareness and practical mathematical
                concepts, trends and patterns. Order flow related supply and
                demand concepts will be utilized and reinforced.{" "}
              </p>
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="row">
            <div className="col-md-12 decrease-margin-top">
              <h3 className="heading-portfolio">
                How to utilize FutureTraders in your course{" "}
              </h3>
              <p>
                FutureTraders is objective, measurable and easy to administer.
                It can be used on its own or to supplement any section of a
                Finance or Economics class. When introducing an overview of the
                stock or bond markets, you can have students read the
                FutureTraders tutorial and take one of the lower level quizzes
                to reinforce the concepts learned in class. Once they pass the
                quiz, you can assign them to play the lower game levels at set
                number of rounds. For example, you can require every student to
                play 10 rounds as a trader and then as a market maker. In this
                fast-paced trading floor simulated environment the rounds are
                only 2 minutes long. Giving students a few minutes after each
                round to analyze their trades and learn from their mistakes, you
                can have multiple games in a very short time span.{" "}
              </p>
              <p>
                Through game play, students will master the underlying mechanics
                and mathematics of complex trading strategies. They will learn
                how markets work, how to use bids and offers, how to recognize
                trends, how to understand liquidity, how to evaluate the impact
                of news events, and how to react quickly to new information.
              </p>
              <p>
                You can quickly evaluate how well they mastered the material by
                seeing their quiz scores and the profit or loss from the rounds
                they played.{" "}
              </p>
              <p>
                You can then repeat the process for the more advanced rounds,
                where the news events will be more challenging and you can even
                set up a class competition to see how the best trader and market
                maker is and give out extra credit for the top performers.{" "}
              </p>
              <p>
                We plan to have a large competition in November where we will
                issue prize money and other incentives for the most profitable
                traders.{" "}
              </p>
            </div>

            <div className="col-md-3">
              <div className="post-media fadeIn">
                <iframe
                  src="https://www.youtube.com/embed/bk6YnvNKiPk"
                  frameBorder="0"
                  allowFullScreen
                >
                  {" "}
                </iframe>
              </div>
            </div>

            <div className="col-md-3">
              <div className="post-media fadeIn">
                <iframe
                  src="https://www.youtube.com/embed/dUS3SwmvX3E"
                  frameBorder="0"
                  allowFullScreen
                >
                  {" "}
                </iframe>
              </div>
            </div>
            <div className="col-md-3">
              <div className="post-media fadeIn">
                <iframe
                  src="https://www.youtube.com/embed/MWU7KLQjXRI"
                  frameBorder="0"
                  allowFullScreen
                >
                  {" "}
                </iframe>
              </div>
            </div>
            <div className="col-md-3">
              <div className="post-media fadeIn">
                <iframe
                  src="https://www.youtube.com/embed/9o-lrQC41jw"
                  frameBorder="0"
                  allowFullScreen
                >
                  {" "}
                </iframe>
              </div>
            </div>
          </div>

          <div className="row second-video-position">
            <div className="col-md-12">
              <p>
                Please try out the demo version of our award-winning game –
                FutureTraders to experience rush of an active trading
                environment.
              </p>
              <a
                href="https://playfuturetradergame.firebaseapp.com/"
                target="_blank"
                className="btn btn-light btn-radius btn-brd grd1"
              >
                Play Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
