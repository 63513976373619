import React from 'react';
import './idleTimeout.scss';

export default ({ onClose }) => {
    return <div className="idleTimeout">
        <div>
            <div>
                <label>Session timeout (you have been idle for more than 15 min)</label>
            </div>
            <div>
                <label onClick={onClose}>Close</label>
            </div>
        </div>
    </div>
}