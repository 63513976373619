import { Components } from "react";
import Config from "../config/Config";
import * as firebase from "../config/firebase";
import authStore from "../models/AuthStore";
import { Post, Delete, Get } from "./apiServices";

class ContestService {
  constructor() {}

  async createContest(contestDetails) {
    const res = await Post(
      Config.base_url + "/contestManager/create_contest",
      authStore.authToken,
      JSON.stringify(contestDetails)
    ).then((res) => res.json());
    return res;

    // try {
    //   let response = await Post(
    //     Config.base_url + "/contestManager/create_contest",
    //     authStore.authToken,
    //     JSON.stringify(contestDetails)
    //   );

    //   if (response.status == 200) {
    //     return { status: 200, msg: "Success", contestData:(response.json()). };
    //   } else if (response.status == 401) {
    //     return { status: 401, msg: "Unauthorized" };
    //   } else {
    //     return { msg: "Something went wrong.Please try again later" };
    //   }
    // } catch (error) {
    //   return {
    //     status: 400,
    //     msg: "Something went wrong.Please try again later"
    //   };
    // }
  }

  async createGame(contestDetails) {
    const name = `${contestDetails.contestname}/@#FT#@/`;
    const data = {...contestDetails,"contestname":name}
    const res = await Post(
      Config.base_url + "contestManager/create_game",
      authStore.authToken,
      JSON.stringify(data)
    ).then((res) => res.json()).catch((e) => {
      console.log(e)
    });
    return res;

    // try {
    //   let response = await Post(
    //     Config.base_url + "/contestManager/create_contest",
    //     authStore.authToken,
    //     JSON.stringify(contestDetails)
    //   );

    //   if (response.status == 200) {
    //     return { status: 200, msg: "Success", contestData:(response.json()). };
    //   } else if (response.status == 401) {
    //     return { status: 401, msg: "Unauthorized" };
    //   } else {
    //     return { msg: "Something went wrong.Please try again later" };
    //   }
    // } catch (error) {
    //   return {
    //     status: 400,
    //     msg: "Something went wrong.Please try again later"
    //   };
    // }
  }


  async updateContest(contestDetails) {
    try {
      let data = await Post(
        Config.base_url + "/contestManager/update_contest",
        authStore.authToken,
        JSON.stringify(contestDetails)
      );
      return { status: 200, msg: "Contest details updated successfully" };
    } catch (error) {
      console.log("Error: ", error);
      return { status: 400, msg: "Failed" };
    }
  }

  async deleteContest(contestid) {
    try {
      let response = await Delete(
        Config.base_url + "/contestManager/update_contest",
        authStore.authToken,
        JSON.stringify({ contestID: contestid })
      );

      if (response.status == 200) {
        return { msg: "Success", status: 200 };
      } else if (response.status == 404) {
        return { msg: "Document Not Found", res: response, status: 400 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized User", res: response, status: 401 };
      } else {
        return { msg: "Something went wrong.Please try again later" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong.Please try again later"
      };
    }
  }

  async getContestbyId(contestid) {
    try {
      let response = await Post(
        Config.base_url + "/contestManager/get_contest_by_id",
        authStore.authToken,
        JSON.stringify({ contestID: contestid })
      );
      if (response.status == 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status == 404) {
        return { msg: "Document Not Found", status: 400 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized User", status: 401 };
      } else {
        return { msg: "Something went wrong.Please try again later" };
      }
    } catch (error) {
      return {
        status: 404,
        msg:
          "You are trying to access an invalid contest. Please make sure the URL is correct"
      };
    }
  }

  async getContestbyName(contestname) {
    try {
      let response = await Post(
        Config.base_url + "/contestManager/get_contest_by_name",
        authStore.authToken,
        JSON.stringify({ contestname: contestname })
      );
      if (response.status == 200) {
        let data = await response.json();
        return { msg: "Success", data: data[0], status: 200 };
      } else if (response.status == 404) {
        return { msg: "Document Not Found", status: 400 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized User", status: 401 };
      } else {
        return { msg: "Something went wrong.Please try again later" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong.Please try again later"
      };
    }
  }

  async registerContest(params) {
    try {
      let response = await Post(
        Config.base_url + "contestManager/invite",
        authStore.authToken,
        JSON.stringify(params)
      );
      let data = await response.json();
      if (response.status === 200) {
        return { msg: "Success", res: data, status: 200 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized", res: data, status: 200 };
      } else if (response.status === 404) {
        return {
          msg: "Not Found",
          res: data,
          email: params.email,
          status: 404
        };
      } else if (data == "Maximum number of students reached!") {
        return { msg: data, status: 402 };
      } else {
        return {
          msg:
            "You are trying to access an invalid contest. Please make sure the URL is correct",
          res: data,
          status: 400
        };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getAllContestsForProfessor() {
    try {
      let response = await Get(
        Config.base_url + "contestManager/get_contests_for_professor",
        authStore.authToken
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 404) {
        return { msg: "Not Found", status: 404 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }
  async getAllInvitedContestsForProfessor() {
    try {
      let response = await Get(
        Config.base_url + "contestManager/get_invited_contests_for_professor",
        authStore.authToken
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 404) {
        return { msg: "Not Found", status: 404 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getAllContestsForStudent() {
    try {
      let response = await Get(
        Config.base_url + "contestManager/get_contests_for_student",
        authStore.authToken
      );
      // console.log("Response"+response.status);
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getAllContestsForPlayer() {
    try {
      let response = await Get(
        Config.base_url + "contestManager/get_contests_for_player",
        authStore.authToken
      );
      // console.log("Response"+response.status);
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }
  async getAllInvitedContestsForPlayer() {
    try {
      let response = await Get(
        Config.base_url + "contestManager/get_invited_contests_for_player",
        authStore.authToken
      );
      // console.log("Response"+response.status);
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getAllContestsForTeacher() {
    try {
      let response = await Get(
        Config.base_url + "contestManager/get_contests_for_teacher",
        authStore.authToken
      );
      // console.log("Response"+response.status);
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }
  async getAllInvitedContestsForTeacher() {
    try {
      let response = await Get(
        Config.base_url + "contestManager/get_invited_contests_for_teacher",
        authStore.authToken
      );
      // console.log("Response"+response.status);
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }
  async getGameStatusOfContestForUser(contestid) {
    try {
      let response = await Post(
        Config.base_url + "contestManager/get_game_status_of_contest_for_user",
        authStore.authToken,
        JSON.stringify({ contestid: contestid })
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }
}

const ContesttService = new ContestService();
export default ContesttService;
