import React, { useEffect } from 'react';
import './showGameReport.scss';
import ftlogo from "../logo.png";

export default ({ classRecord, data, onClose }) => {

    return <div className="show_game_report_card" >
        <div>
            {/* title */}
            <div>
                <img
                    src={ftlogo}
                    width="33px"
                    height="25px"
                    alt="image"
                />
                <label>Report Card</label>
                <label onClick={onClose}>X</label>
            </div>
            {/* student details */}
            <div>
                <p><label>Name:</label> {data.firstname + " " + data.lastname}</p>
                <p><label>{"classname" in classRecord ? "Classname" : "Contestname"}:</label> {"classname" in classRecord ? classRecord.classname : classRecord.contestname}</p>
                <div>{new Date().toLocaleString()}</div>
            </div>
            {/* body */}
            <div>
                <table>
                    <thead>
                        <tr >
                            <th style={{ textAlign: 'center' }} scope="col" > # </th>
                            {/* <th style={{ textAlign: 'center' }} scope="col" > Player </th> */}
                            <th style={{ textAlign: 'center' }} scope="col" > Game Type </th>
                            <th style={{ textAlign: 'left' }} scope="col" > Level </th>
                            <th style={{ textAlign: 'center' }} scope="col" > Opening Balance </th>
                            {
                                [...Array(parseInt(classRecord.roundAteachLevel)).keys()].map((x, i) => {
                                    return <th style={{ textAlign: 'center' }} scope="col" > Round#{i + 1} </th>
                                })
                            }
                            <th style={{ textAlign: 'center' }} scope="col" > Ending Balance </th>
                        </tr >
                    </thead>
                    <tbody>
                        {
                            data.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp).slice(0, 8).reverse().map((qz, j) => {
                                let arry = data.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp).slice(0, 8).reverse();
                                let openingBalance = arry.slice(0, j).reduce((total, x) => total + x.profitLoss.reduce((t, y) => t + y, 0), "classname" in classRecord ? 0 : parseInt(classRecord.initialCapital));
                                let closingBalance = openingBalance + qz.profitLoss.reduce((total, z) => total + z, 0);
                                return <tr key={j} >
                                    <th scope="row" >{j + 1}</th>
                                    {/* <td > </td> */}
                                    <td style={{ textAlign: 'center' }}> {qz.gameType} </td>
                                    <td > {qz.level} </td>
                                    <td style={{ textAlign: 'right' }} > {openingBalance < 0 ? `-$${Math.abs(openingBalance)}` : `$${openingBalance}`} </td>
                                    {
                                        [...Array(parseInt(classRecord.roundAteachLevel)).keys()].map((x, i) => {
                                            return <td style={{ textAlign: 'right' }} key={i}> {
                                                qz.profitLoss != undefined && qz.profitLoss[i] != undefined ?
                                                    qz.profitLoss[i] < 0 ?
                                                        String(qz.profitLoss[i]).replace("-", "-$") :
                                                        "$" + qz.profitLoss[i] : "-"
                                            }
                                            </td>
                                        })
                                    }

                                    <td style={{ textAlign: 'right' }} >{closingBalance < 0 ? `-$${Math.abs(closingBalance)}` : `$${closingBalance}`}</td>
                                </tr >
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* footer */}
            <div></div>
        </div>
    </div>
}