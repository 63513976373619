import React, { useState, useEffect, Fragment } from 'react';
import './ContestDashboard.scss';
import { HorizontalBar, Bar, Pie, Line } from 'react-chartjs-2';
import ReactTooltip from 'react-tooltip';
import ROUTES from '../../ROUTES';
import contestService from "../../services/contestService";
import authStore from "../../models/AuthStore";
import studentService from "../../services/studentService";
import playerService from "../../services/playerService";
import ProfessorService from "../../services/profService";
import teacherService from '../../services/teacherService';
import LoadingWheel from "../../components/LoadingWheel";
import ShowGameReport from '../../models/showGameReport';

export default (props) => {
    const [contestRecord, setContestRecord] = useState(null);
    const [professorRecord, setProfessorRecord] = useState(null);
    const [teacherRecord, setTeacherRecord] = useState(null);
    const [playerRecord, setPlayerRecord] = useState(null);
    const [studentRecord, setStudentRecord] = useState(null);
    const [resultArray, setResultArray] = useState(null);
    const [showContestDetails, toggleContestDetails] = useState(true);
    const [quizTotalForEachStudent, setQuizTotalForEachStudent] = useState(null);
    // states for graphs
    const [horizontalBarData, setHorizontalBarData] = useState(null);
    const [pieData, setPieData] = useState(null);
    const [gameData, setGameData] = useState(null);
    const [allStudentsPerformance, setAllStudentsPerformance] = useState(null);
    // table expands
    const [expandGameTable, toggleExpandGameTable] = useState(0);
    // hide all charts
    const [showCharts, toggleCharts] = useState(true);
    // loading status
    const [status, setStatus] = useState("Loading...");
    // dashboard widnow maximize
    const [maximize, toggleMaximize] = useState(false);
    // to toggle demo account
    const [showDemoAccounts, toggleDemoAccounts] = useState(true);
    const [showGameDemoAccounts, toggleGameDemoAccounts] = useState(true);

    // data fetch

    useEffect(() => {
        localStorage.removeItem("DASHBOARD_CONTESTNAME");
        loadinitSetup();
    }, [])

    //after getting required data map to all the graphs
    useEffect(() => {
        if (resultArray !== null) {
            if (resultArray.find(x => "gameEvents" in x) === undefined) {
                if (resultArray !== undefined) {
                    getLineChartData();
                } else {
                    setTimeout(() => {
                        setStatus("NO DATA FOUND")
                        setTimeout(() => { props.close() }, 600);
                    }, 6000);
                }
            } else {
                if (resultArray.find(x => 'gameEvents' in x) !== undefined) {
                    getTop5Students();
                    getPieChartData();
                    getLineChartData();
                    getVerticalChartData();
                } else {
                    toggleCharts(false);
                    setTimeout(() => {
                        setTimeout(() => { props.close() }, 600);
                    }, 6000);
                }
            }
        }
    }, [resultArray]);

    const loadinitSetup = async () => {
        let contestdetails = await contestService.getContestbyName(props.contestName);
        setContestRecord(contestdetails.data);
        if (authStore.user.role == "PROFESSOR") {
            await getProfessorScores(contestdetails.data.id);
            loadProfessorRecord(contestdetails.data.uid);
        } else if (authStore.user.role == "TEACHER") {
            await getTeacherScores(contestdetails.data.id);
            loadTeacherRecord(contestdetails.data.uid);
        } else if (authStore.user.role == "PLAYER") {
            loadTeacherOrProfessorRecord(contestdetails.data.uid)
            await getPlayerScores(contestdetails.data.id);
        } else {
            //student
            loadTeacherOrProfessorRecord(contestdetails.data.uid)
            await getStudentScores(contestdetails.data.id);
        }
    }

    const loadTeacherOrProfessorRecord = async (email) => {
        let result = await ProfessorService.getProfessorByEmail(email);
        let result1 = await teacherService.getTeacherByEmail(email);
        let result2 = await playerService.getPlayerByEmail(email);
        setProfessorRecord({ ...result2, ...result1, ...result });
    }

    const getTeacherScores = async (contestid) => {
        if (props.contestType === "Invited") {
            let resultList = await studentService.getGameResultOfStudentForContest(authStore.user.user_id, contestid);
            calculateUserScore(resultList.data);
        } else {
            let resultList = await ProfessorService.getGameResultbyContest(contestid);
            calculateUserScore(resultList.data);
        }
    }

    const loadTeacherRecord = async (email) => {
        let result = await teacherService.getTeacherByEmail(email);
        let result1 = await playerService.getPlayerByEmail(email)
        let result2 = await ProfessorService.getProfessorByEmail(email)
        setTeacherRecord({ ...result, ...result1, ...result2 });
    }

    const getProfessorScores = async (contestid) => {
        if (props.contestType === "Invited") {
            let resultList = await studentService.getGameResultOfStudentForContest(authStore.user.user_id, contestid);
            calculateUserScore(resultList.data);
        } else {
            let resultList = await ProfessorService.getGameResultbyContest(contestid);
            calculateUserScore(resultList.data);
        }
    }

    const loadProfessorRecord = async (email) => {
        let result = await ProfessorService.getProfessorByEmail(email);
        let result1 = await teacherService.getTeacherByEmail(email);
        let result2 = await playerService.getPlayerByEmail(email);
        setProfessorRecord({ ...result, ...result1, ...result2 });
    }

    const getPlayerScores = async (contestid) => {
        if (props.contestType === "Invited") {
            let resultList = await studentService.getGameResultOfStudentForContest(authStore.user.user_id, contestid);
            calculateUserScore(resultList.data);
        } else {
            let resultList = await ProfessorService.getGameResultbyContest(contestid);
            calculateUserScore(resultList.data);
        }
    }

    const getStudentScores = async (contestid) => {
        let resultList = await studentService.getGameResultOfStudentForContest(authStore.user.user_id, contestid);
        calculateUserScore(resultList.data);
    }

    const calculateUserScore = (resultList) => {
        if (props.contestType !== "Invited") {
            let result = [];
            resultList.forEach((value, key) => result.push(value));

            console.log('result' + JSON.stringify(result));

            let sortedResult = result.sort((a, b) => b.timeStamp - a.timeStamp).map((sr) => {
                const topicLevelList = [];
                if (sr.gameEvents !== undefined) {
                    const filterdGameList = sr.gameEvents.filter(x => {
                        if (x.gameType.trim().length !== 0 && !topicLevelList.includes(x.gameType + x.level)) {
                            topicLevelList.push(x.gameType + x.level);
                            return x;
                        }
                    }).filter(x => x !== undefined)
                    return { ...sr, gameEvents: filterdGameList }
                } else {
                    return sr
                }
            });
            sortedResult = [...sortedResult.filter(x => !x.firstname.toUpperCase().includes('_PROF') && !x.firstname.toUpperCase().includes('_TA')),...sortedResult.filter(x => x.firstname.toUpperCase().includes('_PROF') || x.firstname.toUpperCase().includes('_TA'))];
            setResultArray(sortedResult);
        } else {
            let topicLevelList = [];
            const filterdGameList = resultList.sort((a, b) => b.timeStamp - a.timeStamp).filter(x => {
                if (x.gameType.trim().length !== 0 && !topicLevelList.includes(x.gameType + x.level)) {
                    topicLevelList.push(x.gameType + x.level);
                    return x;
                }
            })

            setResultArray(filterdGameList);
        }
    }

    // getUserStatus only for level stocks and bonds
    const getUserStatus = (userRecords) => {
        if (contestRecord.tradeRules.length === 2) {
            if (userRecords.gameEvents.find(x => x.gameType === "Bonds" && x.level === "Advanced Market Maker") !== undefined) {
                return "Completed";
            } else {
                return "In Progress"
            }
        } else {
            if (userRecords.gameEvents.find(x => x.level === "Advanced Market Maker") !== undefined) {
                return "Completed";
            } else {
                return "In Progress"
            }
        }
    }

    // data fetch ends

    const prettyDate = (str) => {
        let date = new Date(str);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear();
    }

    const _renderContestDetailsCard = () => {
        return <div style={showContestDetails ? { height: 250, padding: 20, margin: 10 } : { height: 0 }}>
            <div>
                <div>
                    <label>Host: <label>{professorRecord !== null ? professorRecord.firstname + " " + professorRecord.lastname : teacherRecord.firstname + " " + teacherRecord.lastname}</label></label>
                    <label>Subject: <label>{contestRecord.subject}</label></label>
                    <label>Game Type: <label>{contestRecord.tradeRules.reduce((total, x) => total.length > 0 ? total + ", " + x : total + x, "")}</label></label>
                    <label>Initial Capital: <label>{contestRecord.initialCapital}</label></label>
                    <label>Registration Date: <label>{prettyDate(contestRecord.regStartDate) + " - " + prettyDate(contestRecord.regEndDate)}</label></label>
                    <label>Trading Date: <label>{prettyDate(contestRecord.tradeStartDate) + " - " + prettyDate(contestRecord.tradeEndDate)}</label></label>
                </div>
                <div>
                    {/* <label>No Of Attempts: <label>{contestRecord.noofattempts}</label></label> */}
                    <label>No Of Participants: <label>{contestRecord.noofparticipants}</label></label>
                    <label>Rounds At Each Level: <label>{contestRecord.roundAteachLevel}</label></label>
                </div>
            </div>
            <div>
                <label>Description</label>
                <p>{contestRecord.description}</p>
            </div>
        </div>
    }

    const getEndingBalance = (result) => {
        let balance = 0;
        result.gameEvents.forEach((gEvent) => {
            const cTotal = gEvent.profitLoss.reduce((total,x) => total + x ,0);
            balance += cTotal
        })
        const endingBalance = parseInt(balance) + parseInt(contestRecord.initialCapital)
        return endingBalance < 0 ? `-$${Math.abs(endingBalance)}` : `$${endingBalance}`;
    }

    const _renderGPTable = () => {
        return <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    {
                        props.contestType === "Invited" ? null : <th scope="col">Player</th>
                    }
                    {
                        props.contestType !== "Invited" ? null :
                            <Fragment>
                                <th style={{ textAlign: 'center' }} scope="col">Game Type</th>
                                <th style={{ textAlign: 'left' }} scope="col">Level</th>
                                <th style={{ textAlign: 'right' }} scope="col">Opening Balance</th>
                                {
                                    [...Array(parseInt(contestRecord.roundAteachLevel)).keys()].map((x, key) => {
                                        return <th style={{ textAlign: 'right' }} key={key} scope="col">Round#{key + 1}</th>
                                    })
                                }
                            </Fragment>
                    }
                    <th style={{ textAlign: 'right' }} scope="col">Ending Balance</th>
                    {/* <th style={{ textAlign: 'right' }} scope="col">Minimum Balance</th> */}
                    <th scope="col" style={{ textAlign: 'center' }}>{props.contestType === "Invited" ? "Status" : "Overall Status"}</th>
                </tr>
            </thead>
            <tbody>
                {
                    resultArray.map((result, i) => {
                        // host
                        if ('gameEvents' in result && (showDemoAccounts ? true : !result.firstname.toUpperCase().includes('_PROF') && !result.firstname.toUpperCase().includes('_TA'))) {
                            return <Fragment>
                                <tr>
                                    <th scope="row">{i + 1}</th>
                                    <td onClick={() => toggleExpandGameTable(st => st === i + 1 ? 0 : i + 1)}><a>{result.firstname + " " + result.lastname}</a></td>
                                    {
                                        props.contestType !== "Invited" ? null :
                                            <Fragment>
                                                <td style={{ textAlign: 'center' }}>Multiple</td>
                                                <td style={{ textAlign: 'center' }}>Multiple</td>
                                                <td style={{ textAlign: 'right' }}>-</td>
                                                {
                                                    [...Array(parseInt(contestRecord.roundAteachLevel)).keys()].map((x, i) => {
                                                        return <td style={{ textAlign: 'right' }}>
                                                            {
                                                                result.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp)[0].profitLoss != undefined && result.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp)[0].profitLoss[i] != undefined
                                                                    ? result.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp)[0].profitLoss[i] < 0
                                                                        ? String(result.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp)[0].profitLoss[i]).replace("-", "-$")
                                                                        : "$" + result.gameEvents.sort((a, b) => b.timeStamp - a.timeStamp)[0].profitLoss[i]
                                                                    : "-"
                                                            }
                                                        </td>
                                                    })
                                                }
                                                {/* <td style={{ textAlign: 'right' }}>-</td> */}
                                            </Fragment>
                                    }
                                    <td style={{ textAlign: 'right' }}>{getEndingBalance(result)}</td>
                                    <td style={{ textAlign: 'center' }}>{getUserStatus(result)}</td>
                                </tr>
                                <Fragment>
                                    {
                                        (i + 1) === expandGameTable ?
                                            <ShowGameReport classRecord={contestRecord} data={result} onClose={() => toggleExpandGameTable(false)} />
                                            : null
                                    }
                                </Fragment>
                            </Fragment>
                        }
                        // participant
                        if ('profitLoss' in result) {
                            let qz = result;
                            return <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                {/* <td>{authStore.user.name}</td> */}
                                <td style={{ textAlign: 'center' }}>{qz.gameType}</td>
                                <td style={{ textAlign: 'left' }}>{qz.level}</td>
                                <td style={{ textAlign: 'right' }}>{qz.openingBalance}</td>

                                {
                                    [...Array(parseInt(contestRecord.roundAteachLevel)).keys()].map((x, i) => {
                                        return <td style={{ textAlign: 'right' }}>
                                            {
                                                qz.profitLoss != undefined && qz.profitLoss[i] != undefined
                                                    ? qz.profitLoss[i] < 0
                                                        ? String(qz.profitLoss[i]).replace("-", "-$")
                                                        : "$" + qz.profitLoss[i]
                                                    : "-"
                                            }
                                        </td>
                                    })
                                }
                                {/* <td style={{ textAlign: 'right' }}>-</td> */}
                                <td style={{ textAlign: 'right' }}>
                                    {qz.profitLoss != undefined && qz.profitLoss[4] != undefined
                                        ? "$" +
                                        (qz.openingBalance +
                                            qz.profitLoss[0] +
                                            qz.profitLoss[1] +
                                            qz.profitLoss[2] +
                                            qz.profitLoss[3] +
                                            qz.profitLoss[4])
                                        : "-"}
                                </td>
                                <td style={{ textAlign: 'center' }}>Completed</td>
                                <td></td>
                            </tr>
                        }
                        //only for hosts
                        else {
                            if ((showGameDemoAccounts ? true : !result.firstname.toUpperCase().includes('_PROF') && !result.firstname.toUpperCase().includes('_TA'))) {
                                return <tr>
                                    <th scope="row">{i + 1}</th>
                                    <td>{result.firstname + " " + result.lastname}</td>
                                    {
                                        props.contestType !== "Invited" ? null :
                                            <Fragment>
                                                <td style={{ textAlign: 'center' }}>-</td>
                                                <td style={{ textAlign: 'center' }}>-</td>
                                                <td style={{ textAlign: 'right' }}>-</td>
                                                {
                                                    [...Array(parseInt(contestRecord.roundAteachLevel)).keys()].map((x, key) => {
                                                        return <td key={key} scope="col" style={{ textAlign: 'right' }}>-</td>
                                                    })
                                                }
                                                {/* <td style={{ textAlign: 'right' }}>-</td> */}
                                            </Fragment>
                                    }
                                    <td style={{ textAlign: 'right' }}>-</td>
                                    <td style={{ textAlign: 'center' }}>-</td>
                                </tr>
                            }
                        }
                    })
                }
            </tbody>
        </table >
    }

    const getCompletedPlayers = () => {
        if (resultArray.find(x => "gameEvents" in x) !== undefined) {
            if (contestRecord.tradeRules.includes("Stocks") && contestRecord.tradeRules.includes("Bonds")) {
                let count = 0;
                resultArray.forEach((gResult) => {
                    if ("gameEvents" in gResult) {
                        let result = gResult.gameEvents.filter(x => x.status === 0).length;
                        if (result === 8) {
                            count++;
                        }
                    }
                });
                return count;
            } else {
                let count = 0;
                resultArray.forEach((gResult) => {
                    if ("gameEvents" in gResult) {
                        let result = gResult.gameEvents.filter(x => x.status === 0).length;
                        if (result === 4) {
                            count++;
                        }
                    }
                });
                return count;
            }
        } else {
            return 0;
        }
    }

    const getTop5Students = () => {
        let students = [];

        resultArray.forEach((gResult) => {
            if (gResult.gameEvents !== undefined) {
                let amount = gResult.gameEvents.reduce((total, x) => total + parseInt(x.openingBalance) + parseInt(x.profitLoss.reduce((sum, y) => sum + parseInt(y), 0)), 0);
                students.push({ "firstname": gResult.firstname, "lastname": gResult.lastname, amount });
            }
        });

        students = students.sort((x, y) => y.amount - x.amount);
        const names = students.map(x => x.firstname);
        const amounts = students.map(x => x.amount);

        const hBarData = {
            labels: names.length <= 5 ? names : names.slice(0, 5),
            datasets: [
                {
                    label: '',
                    backgroundColor: ['rgba(10,182,4,1)', 'rgba(10,182,4,0.9)', 'rgba(10,182,4,0.8)', 'rgba(10,182,4,0.7)', 'rgba(10,182,4,0.6)',],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: amounts.slice(0, 5)
                }
            ]
        };
        setHorizontalBarData(hBarData);
    }

    const getPieChartData = async () => {
        let students = [];
        resultArray.forEach((gResult) => {
            let student = { firstname: gResult.firstname, lastname: gResult.lastname, amount: 0 }
            if ("gameEvents" in gResult) {
                gResult.gameEvents.forEach((gEvent) => {
                    const amount = gEvent.profitLoss.reduce((total, x) => total + x, 0);
                    student['amount'] = student['amount'] + amount;
                });
                students.push(student);
            }
        });
        let sortedList = students.sort((x, y) => y.amount - x.amount);
        let amounts = sortedList.map(x => x.amount);
        let sumofamounts = amounts.reduce((total, x) => total + x, 0);
        let numofamounts = amounts.length;
        let avgofamounts = sumofamounts / numofamounts;
        let list1 = sortedList.filter(x => x.amount <= 0);
        let list2 = sortedList.filter(x => x.amount > 0 && x.amount < avgofamounts);
        let list3 = sortedList.filter(x => x.amount >= avgofamounts);

        const data = {
            labels: ['Low Earners', `Mid Earners`, `High Earners`],
            datasets: [{
                data: [list1.length, list2.length, list3.length],
                backgroundColor: [
                    '#F15435',
                    '#FF6384',
                    '#8E41D6',
                ],
                hoverBackgroundColor: [
                    '#FFCE56',
                    '#FF6384',
                    '#8E41D6',
                ]
            }]
        }
        setPieData(data);
    }

    const getLineChartData = () => {
        const stockLevels = ['Trader(Stocks)', 'Market Maker', 'Advanced Trader', 'Advanced Market Maker'];
        const bondLevels = ['Trader(Bonds)', 'Market Maker', 'Advanced Trader', 'Advanced Market Maker']
        let levels = [];
        let students = [];
        if (contestRecord.tradeRules.includes("Stocks") && contestRecord.tradeRules.includes("Bonds")) {
            levels = [...stockLevels, ...bondLevels];
        } else if (contestRecord.tradeRules.includes("Stocks")) {
            levels = stockLevels;
        } else {
            levels = bondLevels;
        }

        resultArray.forEach((gResult) => {
            if ("gameEvents" in gResult) {
                gResult.gameEvents.forEach((eEvent) => {
                    students.push({ firstname: gResult.firstname, lastname: gResult.lastname, "type": eEvent.gameType, level: eEvent.level, amount: (eEvent.openingBalance + eEvent.profitLoss.reduce((total, x) => total + x, 0)) });
                })
            }

            if ('profitLoss' in gResult) {
                students.push({ firstname: gResult.firstname, lastname: gResult.lastname, "type": gResult.gameType, level: gResult.level, amount: (gResult.openingBalance + gResult.profitLoss.reduce((total, x) => total + x, 0)) });
            }
        });

        const data = {
            labels: levels,
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: '#7935F1',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [students.filter(x => x.type === "Stocks" && x.level === "Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Stocks" && x.level === "Market Maker").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Stocks" && x.level === "Advanced Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Stocks" && x.level === "Advanced Market Maker").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Market Maker").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Advanced Trader").reduce((total, x) => total + parseInt(x.amount), 0), students.filter(x => x.type === "Bonds" && x.level === "Advanced Market Maker").reduce((total, x) => total + parseInt(x.amount), 0)]
                }
            ]
        };
        setGameData(data);
    }

    const getVerticalChartData = () => {
        let students = [];

        resultArray.forEach(async (gResult) => {
            if ("gameEvents" in gResult) {
                let amount = gResult.gameEvents.reduce((total, x) => total + parseInt(x.openingBalance) + parseInt(x.profitLoss.reduce((sum, y) => sum + parseInt(y), 0)), 0);
                students.push({ "firstname": gResult.firstname, "lastname": gResult.lastname, amount });
            }
        });

        const names = students.map(x => x.firstname);
        const amounts = students.map(x => x.amount);

        const data = {
            labels: [...names],
            datasets: [
                {
                    label: '',
                    backgroundColor: [...new Array(names.length).keys()].map((val, i) => '#35C1F1'),
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: [...amounts]
                }
            ]
        };
        setAllStudentsPerformance(data);
    }

    return (contestRecord && (professorRecord || teacherRecord) && resultArray) &&
        showCharts ?
        <div style={maximize ? { ...props.style, top: 0, bottom: 0, zIndex:250 } : { ...props.style, top: 45, bottom: 80 }} className="contest_dashboard">
            <div className='dashboard_navbar'>
                <label>Contest Dashboard - {contestRecord.contestname}</label>
                <i onClick={() => toggleContestDetails(st => !st)} data-tip data-for='classlist' className="fas fa-clipboard-list"></i>
                <i onClick={() => window.print()} data-tip data-for='print' className="fas fa-print"></i>
                <i onClick={props.minimize} className="fas fa-window-minimize minus"></i>
                {
                    maximize ?
                        <i onClick={() => toggleMaximize(false)} className="fas fa-compress-arrows-alt maximize"></i> :
                        <i onClick={() => toggleMaximize(true)} className="fas fa-window-maximize maximize"></i>
                }
                <i onClick={props.close} data-tip data-for='close' className="fas fa-times"></i>
                <ReactTooltip multiline={false} place="bottom" id='classlist'>Click to toggle the detailed class informaion</ReactTooltip>
                <ReactTooltip multiline={false} place="bottom" id='print'>Print</ReactTooltip>
                <ReactTooltip multiline={false} place="bottom" id='close'>Close</ReactTooltip>
            </div>

            {_renderContestDetailsCard()}

            <div>
                {
                    (authStore.user.role === "PROFESSOR" || authStore.user.role === "TEACHER" || authStore.user.role === "PLAYER") && props.contestType !== "Invited" ?
                        <div>
                            <div>
                                <div>
                                    <img src={require('./res/img/Path 11.png')} />
                                    <div>
                                        <label>Students Attended</label>
                                        <p>{contestRecord.participants.length + "/" + contestRecord.noofparticipants}</p>
                                    </div>
                                </div>
                                <div>
                                    <img src={require('./res/img/Path 12.png')} />
                                    <div>
                                        <label>Students Completed</label>
                                        <p>{getCompletedPlayers() + "/" + contestRecord.noofparticipants}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label style={{ textAlign: 'center', display: 'block' }}>TOP 5 Participants</label>
                                {showCharts && <HorizontalBar height={130} data={horizontalBarData} />}
                            </div>
                        </div> : <div style={{ display: 'none' }}></div>
                }
                <div>
                    {
                        (authStore.user.role === "PROFESSOR" || authStore.user.role === "TEACHER" || authStore.user.role === "PLAYER") && props.contestType !== "Invited" ?
                            <div>
                                <label style={{ textAlign: 'center', display: 'block' }}>Game Results</label>
                                {showCharts && <Pie height={260} data={pieData} />}
                            </div> : <div style={{ display: 'none' }}></div>
                    }
                    <div>
                        <label style={{ textAlign: 'center', display: 'block' }}>Average Performance on Each Level</label>
                        {showCharts && <Line height={(authStore.user.role === "PROFESSOR" || authStore.user.role === "TEACHER" || authStore.user.role === "PLAYER") && props.contestType !== "Invited" ? 140 : 60} data={gameData} />}
                    </div>
                </div>
            </div>
            {/* row 2 */}
            {
                (authStore.user.role === "PROFESSOR" || authStore.user.role === "TEACHER" || authStore.user.role === "PLAYER") && props.contestType !== "Invited" ?
                    <div>
                        <label style={{ textAlign: 'center', display: 'block' }}>Overall Students Performance</label>
                        {showCharts && <Bar height={80} data={allStudentsPerformance} />}
                    </div> : <div style={{ display: 'none' }}></div>
            }
            {/* row 3 */}
            <div>
                <div><label>Game Performance</label><div>{authStore.user.role !== "STUDENT" ? <div><input onChange={() => toggleGameDemoAccounts(st => !st)} type="checkbox" checked={showGameDemoAccounts} />{!showGameDemoAccounts ? "  Show Support Accounts" : "  Show Support Accounts"}</div> : null}</div></div>
                {_renderGPTable()}
            </div>
        </div> :
        <div style={{ position: 'fixed', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: 0, bottom: 0, left: 0, right: 0, zIndex: 999 }}>
            {status !== "NO DATA FOUND" ? <LoadingWheel /> : <div style={{ padding: '10px 20px', backgroundColor: 'white', boxShadow: '0px 0px 3px rgba(0,0,0,0.3)', fontSize: 14 }}>{status}</div>}
        </div>
}