import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import "./DeleteConfirm.css";
import 'jquery'
import ROUTES from "../../ROUTES";
import classService from '../../services/classService'
import Toast from '../../components/Toast'
declare var $: any
class DeleteConfirm extends Component<any>
{
    msg = ''
    notifySuccess = () => Toast.success(this.msg)
    notifyError = () => Toast.error(this.msg)

    state = {
        error: null
    };

    async deleteClass(id: any) {
        let res = await classService.deleteClass(id)
        if (res.status) {
            if (res.status == 200) {
                this.msg = res.msg
                this.notifySuccess()
                this.props.getClasses()
                this.cancelModal()
            } else {
                this.msg = res.msg
                this.notifyError()
                this.cancelModal()
            }
        } else {
            this.msg = res.msg
            this.notifyError()
            this.cancelModal()
        }
    }


    cancelModal = () => {
        $("#classDeleteForm").modal("hide");
    };

    render() {
        return (
            <div className="appslist">
                <div
                    className="modal fade"
                    id="classDeleteForm"
                    role="dialog"
                    aria-labelledby="exampleModalLabel-2"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel-2">
                                    Confirm
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 clearfix">
                                        <p className="mb-0 text-color-style">You are about to delete the class. Are you sure?</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer clearfix">
                                <div className="">
                                    <button type="button" className="btn btn-primary btn-sm mr-3"
                                        onClick={e => this.deleteClass(this.props.id)}
                                    >
                                        OK
                                    </button>
                                    <button type="button" className="btn btn-sm cancel-button-margin"
                                        onClick={e => this.cancelModal()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {Toast.successToastView()}
                {Toast.errorToastView()}
            </div>

        );
    }
}

export default withRouter(DeleteConfirm)