import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import "jquery";
import ROUTES from "../../../ROUTES";
import classService from "../../../services/classService";
import Toast from "../../../components/Toast";
import authStore from "../../../models/AuthStore";

declare var $: any;
class CreateContest extends Component<any> {
  addnewContest() {
    this.props.history.push(ROUTES.CONTESTREG);
  }

  render() {
    return (
      <>
        {authStore.user.role == "PROFESSOR" ||
          authStore.user.role == "TEACHER" ||
          authStore.user.role == "PLAYER" ? (
            <div className=" row" style={{ marginTop: 40 }}>
              <div className="col-md-3 mt-20 ">
                <a href="#" onClick={() => this.addnewContest()}>
                  <div
                    style={{ minHeight: "128px" }}
                    className="card text-center "
                  >
                    <div className="card-body add-new-class" id="card">
                      <span className="add-class-button">
                        <i className="fas fa-plus  fa-2x"></i>
                      </span>
                      <span className="add-class">Create Contest</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          ) : null}
      </>
    );
  }
}

export default withRouter(CreateContest);
