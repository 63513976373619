import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { MdAdd, MdRemove } from "react-icons/md";
import Progressbar from "../../components/Progressbar";
import ROUTES from "../../ROUTES";
import Config from "../../config/Config";
import authStore from "../../models/AuthStore";
export interface TopicProps extends RouteComponentProps {
  expand: boolean;
  index: number;
  toggle: any;
  topictitle: string;
  topicname: string;
  showCurriculum?: any;
  quizStatus: any;
  gameStatus?: any;
  tab?: any;
  prevGameStatus?: any;
  attempts?: any;
  classid?: any;
  classname?: any;
}

class TopicListItem extends React.Component<TopicProps, any> {
  courseStatus() {
    if (this.props.index == 0) console.log(this.props);
    return (
      <Fragment>
        <li className="topic-progress-group " style={{ height: 43, display: 'flex', alignItems: 'center' }}>
          <a
            style={{ fontSize: 15, opacity: 0.8, height: 43, padding: '10px 20px' }}
            className={
              this.props.quizStatus == 1 ||
                this.props.quizStatus == 3 ||
                (this.props.quizStatus != 0 && this.props.prevGameStatus == 0)
                ? "btn-topic"
                : "btn-disable"
            }
            onClick={() =>
              this.props.quizStatus == 1 ||
                this.props.quizStatus == 3 ||
                (this.props.quizStatus != 0 && this.props.prevGameStatus == 0)
                ? this.props.showCurriculum(
                  this.props.topictitle,
                  this.props.topicname
                )
                : {}
            }
          >
            {this.props.topicname}
          </a>
          <Progressbar
            status={
              this.props.quizStatus == 0
                ? <p style={{width:'100%', height:'100%', backgroundColor:'#51D600', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:4, marginTop:10, color:'white',cursor:'no-drop'}}>Completed</p>
                : this.props.quizStatus == 1 || this.props.quizStatus == 3
                ? <p onClick={() =>
                  this.props.quizStatus == 1 ||
                    this.props.quizStatus == 3 ||
                    (this.props.quizStatus != 0 && this.props.prevGameStatus == 0)
                    ? this.props.showCurriculum(
                      this.props.topictitle,
                      this.props.topicname
                    )
                    : {}
                } style={{ cursor:'pointer', width:'100%', height:'100%', backgroundColor:'#FF942C', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:4, marginTop:10, color:'white'}}>Start</p>
                : this.props.quizStatus == 2
                    ? <p style={{marginTop:9, cursor:'no-drop'}}>Pending</p>
                    : <p onClick={() =>
                      this.props.quizStatus == 1 ||
                        this.props.quizStatus == 3 ||
                        (this.props.quizStatus != 0 && this.props.prevGameStatus == 0)
                        ? this.props.showCurriculum(
                          this.props.topictitle,
                          this.props.topicname
                        )
                        : {}
                    } style={{marginTop:9, cursor:'no-drop'}}>
                      {
                        (this.props.quizStatus != 0 && this.props.prevGameStatus == 0) ? "Start" : "Pending"
                    }
                    </p>
            }
          />
        </li>
        {this.props.expand ? (
          <div className="expand-item">
            <span className="expand-start">
              Number of attempts :{" "}
              {this.props.attempts ? this.props.attempts : 0}
            </span>
            <span className="expand-end">
              Status :{" "}
              {this.props.quizStatus == 0
                ?  <p style={{marginTop:9}}>Pass</p>
                : this.props.quizStatus == 3
                  ?  <p style={{marginTop:9}}>Fail</p>
                  :  <p style={{marginTop:9}}>Pending</p>}
            </span>
          </div>
        ) : null}
      </Fragment>
    );
  }

  gameStatus() {
    return (
      <Fragment>
        <li className="topic-progress-group " style={{ height: 43, display: 'flex', alignItems: 'center' }}>
          {this.props.gameStatus == 1 ? (
            <a
              style={{ fontSize: 15, opacity: 0.8, height: 43, padding: '10px 20px' }}
              href={
                Config.base_url.includes("staging")
                  ? "https://playfuturetraderstaging.firebaseapp.com?token=" +
                  authStore.authToken +
                  "&current_class_name=" +
                  this.props.classid
                  : "http://playftgameapp.firebaseapp.com?token=" +
                  authStore.authToken +
                  "&current_class_name=" +
                  this.props.classid
              }
              target="_blank"
            >
              {this.props.topicname}
            </a>
          ) : (
              <a style={{ fontSize: 15, opacity: 0.8, height: 43, padding: '10px 20px' }}>{this.props.topicname}</a>
            )}

          <Progressbar
            status={
              this.props.gameStatus == 0
                ? <p style={{width:'100%', height:'100%', backgroundColor:'#51D600', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:4, marginTop:10, color:'white',cursor:'no-drop'}}>Completed</p>
                : this.props.gameStatus == 1
                  ? <p onClick={this.gamePlayClicked} style={{ cursor:'pointer', width:'100%', height:'100%', backgroundColor:'#FF942C', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:4, marginTop:10, color:'white'}}>Start</p>
                  : this.props.gameStatus == 2
                    ? <p style={{marginTop:9, cursor:'no-drop'}}>Pending</p>
                    : <p style={{marginTop:9, cursor:'no-drop'}}>Pending</p>
            }
          />
        </li>
      </Fragment >
    );
  }

  gamePlayClicked = () => {
    if(this.props.gameStatus == 1){
      window.open(Config.base_url.includes("staging")
      ? "https://playfuturetraderstaging.firebaseapp.com?token=" +
      authStore.authToken +
      "&current_class_name=" +
      this.props.classid
      : "http://playftgameapp.firebaseapp.com?token=" +
      authStore.authToken +
      "&current_class_name=" +
      this.props.classid,"_blank");
    }
  }

  render() {
    return this.props.tab == "learnStatus"
      ? this.courseStatus()
      : this.gameStatus();
  }
}
export default withRouter(TopicListItem);
