import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import commonService from "../../services/commonService";
import validation from "../../Helpers/Utility/Validation";
import Toast from "../../components/Toast";
import LoadingWheel from "../../components/LoadingWheel";
import Validate from "../../Helpers/Utility/Validation";
import { Player } from "../../interfaces";
import PlayerService from "../../services/playerService";
import ROUTES from "../../ROUTES";
import "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Constants from "../../Helpers/Constants/Constants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import PhoneCode from "../../components/PhoneCode";
import ProfService from "../../services/profService";
// import {PhoneNumberUtil} from 'google-libphonenumber';
import { isPossibleNumber } from "libphonenumber-js";
import CommonService from "../../services/commonService";
import resultSvg from "../../result.svg";
import "./Result.css";
import authStore from "../../models/AuthStore";
import classService from "../../services/classService";
import Config from "../../config/Config";

export interface ResultProps extends RouteComponentProps {}

class Result extends Component<ResultProps, any> {
  state = {
    resultDetails: [] as any,
    showLoader: true,
    classid: "",
  };

  async getQuizResult() {
    if (this.props.location.state && this.props.location.state.result) {
      let res = await classService.getClassbyName(
        this.props.location.state.result.classname
      );
      this.props.location.state.result.passingScore = res.data.passingScore;
      this.setState((st:any) => ({...st,classid:res.data.id}));
    }
  }
  async componentDidMount() {
    this.setState({ showLoader: true });
    await this.getQuizResult();
    this.setState({
      resultDetails: this.props.location.state.result,
    });
    // this.setState({
    //   classid: this.props.location.state.id,
    // });
    this.setState({
      showLoader: false,
    });
  }

  getDates(date: any) {
    var dates = new Date(date);
    let dateTime = dates.toLocaleString();
    return dateTime;
  }

  componentDidUpdate() {
    window.onpopstate = (e: any) => {
      this.props.history.push(ROUTES.CLASSLIST);
    };
  }

  setDefaultValues() {}

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8" }}
            className=" container registration-title"
          ></div>
        </div>
        <div className="container cmt20">
          {this.state.showLoader ? (
            <LoadingWheel />
          ) : (
            <div className="row equal">
              <div
                className="col-md-5 reg-card btrr "
                style={{ paddingTop: "20px" }}
              >
                <div
                  className="wizard"
                  id="myWizard"
                  style={{ paddingTop: "5vh" }}
                >
                  <div className="step" style={{ textAlign: "center" }}>
                    <img src={resultSvg} width="320" height="auto" alt="" />
                    <div
                      className="row mt-15 mb-15 info highlight"
                      style={{ paddingTop: "12px" }}
                    >
                      <h2
                        className="col-md-12 "
                        style={{ textAlign: "center" }}
                      >
                        Quiz Result
                      </h2>
                    </div>
                    <p
                      className="form-left-text"
                      style={{ paddingTop: "20px" }}
                    >
                      {/* Professors and Teachers can augment their classes by
                    leveraging our innovative and seamless learning platform. In
                    less than five minutes you can complete Registration and
                    setup your first class. Then on, it's a breeze. */}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-7  col-sm-12 reg-card btlr"
                style={{ paddingTop: "20px" }}
              >
                <div className="wizard" id="myWizard">
                  <div className="step">
                    {/* <div className="row info-head">
                    <div className="col-md-12">
                      <div className="row mt-15 mb-15">
                        <h3 className="col-md-6 ">Class Information</h3>
                      </div>
                    </div>
                  </div> */}
                    {/* {this.state.showLoader ? <LoadingWheel /> : null}
          {!this.state.showLoader ? this.renderQuiz() : <LoadingWheel />} */}

                    <div className="row mt-10 mb-10">
                      <table className="table table-hover table-bordered">
                        <tbody>
                          <tr>
                            <td className="">Student Name</td>
                            <td className="">
                              {" "}
                              {this.state.resultDetails.firstname}{" "}
                              {this.state.resultDetails.lastname}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Class Name</td>
                            <td className="">
                              {" "}
                              {this.state.resultDetails.classname}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Topic</td>{" "}
                            <td className="">
                              {this.state.resultDetails.topic} (
                              {this.state.resultDetails.level})
                            </td>
                          </tr>
                          <tr>
                            <td className="">Start Time :</td>{" "}
                            <td className="">
                              {this.getDates(
                                this.state.resultDetails.startTime
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="">End Time :</td>{" "}
                            <td className="">
                              {this.getDates(this.state.resultDetails.endTime)}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Passing Percentage</td>{" "}
                            <td className="">
                              {this.state.resultDetails.passingScore}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Number of Questions</td>{" "}
                            <td className="">
                              {this.state.resultDetails.totalqstns}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Correct Answers</td>{" "}
                            <td className="">
                              {this.state.resultDetails.correct}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Incorrect Anwsers</td>{" "}
                            <td className="">
                              {this.state.resultDetails.incorrect}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Score</td>{" "}
                            <td className="">
                              {this.state.resultDetails.amount}
                            </td>
                          </tr>
                          <tr>
                            <td align="center" colSpan={2} className="">
                              <h1
                                className={
                                  this.state.resultDetails.status == 0
                                    ? "result-pass"
                                    : "result-fail"
                                }
                              >
                                {this.state.resultDetails.status == 0
                                  ? "Pass"
                                  : "Fail"}
                              </h1>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="row mt-10 mb-10">
                      <div
                        className={
                          this.state.resultDetails.status == 0
                            ? "report-card-pass "
                            : "report-card-fail "
                        }
                      >
                        {this.state.resultDetails.status == 0 && this.state.classid.length > 0 ? (
                          <div>
                            Congratulations, you have earned the license to play
                            FT Game {this.state.resultDetails.topic} as a{" "}
                            {this.state.resultDetails.level}. Please{" "}
                            <a
                              href={
                                Config.base_url.includes("staging")
                                  ? "https://playfuturetraderstaging.firebaseapp.com?token=" +
                                    authStore.authToken +
                                    "&current_class_name=" +
                                    this.state.classid
                                  : "http://playftgameapp.firebaseapp.com?token=" +
                                    authStore.authToken +
                                    "&current_class_name=" +
                                    this.state.classid
                              }
                              target="_blank"
                            >
                              click here
                            </a>{" "}
                            to play the game.
                          </div>
                        ) : (
                          "Sorry, please retry. Remember, every failure helps you get a bit closer to success."
                        )}
                      </div>
                    </div>
                    {/* <div
                    className="row mt-10 mb-10"
                    style={{ textAlign: "center" }}
                  >
                    <button
                      className="btn btn-primary next-btn btn-md"
                      // onClick={() => this.validatePage1()}
                    >
                      Play Game
                    </button>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(Result);
