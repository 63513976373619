import React, { Component } from "react";

export default class Loader extends Component {
    render() {
        return(
            <div id="preloader">
            <div className="loader">
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__ball"></div>
            </div>
        </div>
        )
    }
}