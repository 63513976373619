import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import commonService from "../../services/commonService";
import validation from "../../Helpers/Utility/Validation";
import Toast from "../../components/Toast";
import LoadingWheel from "../../components/LoadingWheel";
import Validate from "../../Helpers/Utility/Validation";
import newsletterSvg from "../../newsletter.svg";

export interface SignUpProps extends RouteComponentProps {}

export default class signUp extends Component<SignUpProps, any> {
  msg = "";
  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);

  state = {
    signUpDetails: {} as any,
    errors: {} as any,
    showLoader: false
  };

  updateFieldValue(e: any, type?: any) {
    let stateObject = {} as any;
    if (type == "text") {
      if (e.target.value.match(/^[A-Za-z\s]{0,30}$/)) {
        stateObject[e.target.name] = e.target.value.trimLeft();
      }
    } else if (type == "number") {
      if (e.target.value.match(/^[0-9]{0,15}$/)) {
        stateObject[e.target.name] = e.target.value.trimLeft();
      }
    } else {
      stateObject[e.target.name] = e.target.value.trimLeft();
    }
    this.setState(Object.assign(this.state.signUpDetails, stateObject));
  }

  async validateFields() {
    let errors = {} as any;
    Object.assign(
      errors,
      validation.validateName(this.state.signUpDetails.firstname, "firstname")
    );
    Object.assign(
      errors,
      validation.validateName(this.state.signUpDetails.lastname, "lastname")
    );
    Object.assign(
      errors,
      validation.validateEmail(this.state.signUpDetails.email, "email")
    );
    // Object.assign(
    //   errors,
    //   await validation.validatePhone(this.state.signUpDetails.phone, "phone")
    // );
    this.setState(Object.assign(this.state.errors, errors));
    return Object.keys(this.state.errors).length != 0;
  }
  async submitForm() {
    await this.validateFields();
    if (
      this.state.errors.firstname == "" &&
      this.state.errors.lastname == "" &&
      this.state.errors.email == "" &&
      this.state.errors.comments == ""
    ) {
      let res = await commonService.signUp(this.state.signUpDetails);
      if (res.status == 200) {
        this.msg = res.msg;
        this.notifySuccess();
      } else {
        this.msg = res.msg;
        this.notifySuccess();
      }
    }
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8" }}
            className=" container registration-title"
          ></div>
        </div>
        <div className="container cmt20">
          <div className="row equal">
            <div
              className="col-md-8 reg-card col-md-offset-2"
              style={{ paddingTop: "20px" }}
            >
              <div
                className="wizard"
                id="myWizard"
                style={{ paddingTop: "5vh" }}
              >
                <div className="step" style={{ textAlign: "center" }}>
                  <img src={newsletterSvg} width="320" height="auto" alt="" />
                  <div
                    className="row mt-15 mb-15 info highlight"
                    style={{ paddingTop: "12px" }}
                  >
                    <h2 className="col-md-12 " style={{ textAlign: "center" }}>
                      Sign Up for Newsletter Updates
                      {/* {this.state.classname} */}
                    </h2>
                  </div>
                </div>
                <div className="row mt-10 mb-10">
                  <div className="col-md-12">
                    <input
                      type="email"
                      name="email"
                      className="form-control input-lg"
                      value={
                        this.state.signUpDetails.email
                          ? this.state.signUpDetails.email
                          : ""
                      }
                      onChange={e => this.updateFieldValue(e)}
                    />
                    <div className="validation-error move-top">
                      {" "}
                      {this.state.errors.email}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-10 mb-10 text-center">
                {" "}
                <button
                  type="submit"
                  className="btn btn-light btn-radius btn-brd grd1 btn-block"
                  onClick={() => this.submitForm()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {Toast.successToastView()}
        {Toast.errorToastView()}
        {this.state.showLoader ? <LoadingWheel /> : null}
      </div>
    );
  }
}
