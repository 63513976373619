import React from "react";
import {
  withRouter,
  RouteChildrenProps,
  Redirect,
  RouteComponentProps
} from "react-router";
import ROUTES from "../../ROUTES";
import "./TopicList.css";
import Progressbar from "../../components/Progressbar";
import { MdAdd, MdRemove } from "react-icons/md";
import ContestTopicListItem from "./ContestTopicListItem";
import { ContestDetails } from "../../interfaces";
import studentService from "../../services/studentService";
import professorService from "../../services/profService";
import playerService from "../../services/playerService";
import contestService from "../../services/contestService";
import authStore from "../../models/AuthStore";
import LoadingWheel from "../../components/LoadingWheel";
export interface ContestTopicProps extends RouteComponentProps {}
export interface ContestTopicState {}

class ContestTopic extends React.Component<
  ContestTopicProps,
  ContestTopicState
> {
  state = {
    tab: "gameStatus",
    showLoader: true,
    gameStatus: [] as any,
    expand: { Stocks: [], Bonds: [] } as any,
    // quizStatus: {} as any,
    topics: [],
    attempts: { Stocks: {}, Bonds: {} } as any,
    contestid: "",
    contestname: "",
    initialCapital: ""
  };

  async componentDidMount() {
    let contestDetails = await contestService.getContestbyName(
      this.props.location.state
    );
    // TO DO: Change Logic
    this.setState({ topics: contestDetails.data.tradeRules })
    // this.setState({ topics: ["Stocks", "Bonds"] });
    this.setState({ contestid: contestDetails.data.id });
    this.setState({ contestname: contestDetails.data.contestname });
    this.setState({ initialCapital: contestDetails.data.initialCapital });

    // let quizResult = await studentService.getQuizResultStatusbyUser(
    //   classDetails.data.id
    // );

    let gameResult = await contestService.getGameStatusOfContestForUser(
      contestDetails.data.id
    );
    
    // if (authStore.user.role == "STUDENT") {
    //   let gameResult = await studentService.getResultStatusOfContestbyUser(
    //     contestDetails.data.id
    //   );
    // } else if (authStore.user.role == "PROFESSOR") {
    //   //get_game_status_of_contest_for_professor
    //   let gameResult = await professorService.getGameStatusOfContestForProfessor(
    //     contestDetails.data.id
    //   );
    // } else if (authStore.user.role == "PLAYER") {
    //   let gameResult = await playerService.getGameStatusOfContestForPlayer(
    //     contestDetails.data.id
    //   );
    // } else {
    // }
    // let quizObj = { Stocks: {}, Bonds: {} } as any;
    // let attempts = { Stocks: {}, Bonds: {} } as any;
    // await quizResult.data.forEach((result: any) => {
    //   if (attempts[result.topic][result.level]) {
    //     attempts[result.topic][result.level] += 1;
    //   } else {
    //     attempts[result.topic][result.level] = 1;
    //   }

    //   if (quizObj[result.topic]) {
    //     quizObj[result.topic][result.level] = result.status;
    //   }
    // });

    let gameObj = { Stocks: {}, Bonds: {} } as any;
    await gameResult.data.forEach((item: any) => {
      if(item.gameType.length === 2){
        if (gameObj["Stocks"]) {
          gameObj["Stocks"][item.level] = item.status;
        }
      }else{
        if (gameObj[item.gameType]) {
          gameObj[item.gameType][item.level] = item.status;
        }
      }
    });

    this.setState({
      gameStatus: gameObj,
      // attempts: attempts,
      // quizStatus: quizObj,
      showLoader: false
    });
  }

  showContestTopicItem(topic: string, level: string) {
    this.props.history.push({
      pathname: ROUTES.CONTEST_TOPICITEM,
      state: {
        topic: topic,
        level: level,
        contestname: this.props.location.state ? this.props.location.state : ""
      }
    });
  }

  // toggleExpand(index: number, topic: string) {
  //   this.state.expand[topic][index] = !this.state.expand[topic][index];
  //   this.setState({ expand: this.state.expand });
  // }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8" }}
            className=" container registration-title"
          >
            Game Console ({this.props.location.state.replace("/@#FT#@/","")})
          </div>
        </div>
        {this.state.showLoader ? (
          <LoadingWheel />
        ) : (
          <div className="container cmt20">
            <div className="row ">
              <div className="col-md-12 list-group">
                {/* <div className="reg-card-topic"> */}
                {/* <div className="list-group "> */}
                <div className="list-group-item list-group-item-action flex-column align-items-start">
                  <div className="tab">
                    {/* <button
                      className={
                        this.state.tab == "learnStatus"
                          ? "tablinks-active"
                          : "tablinks"
                      }
                      onClick={() => this.setState({ tab: "learnStatus" })}
                    >
                      <strong>Learning Status</strong>
                    </button> */}
                    {/* <button
                      className={
                        this.state.tab == "gameStatus"
                          ? "tablinks-active"
                          : "tablinks"
                      }
                      onClick={() => this.setState({ tab: "gameStatus" })}
                    >
                      <strong>Game Status</strong>
                    </button> */}
                  </div>

                  {this.state.topics.map((item, index) => {
                    let prevTopic =
                      index == 0 ? undefined : this.state.topics[index - 1];
                    return (
                      <div className="d-flex w-100 justify-content-between">
                        <div className="mb-1 topic-head" style={{ marginTop: 0, height:50, display:'flex', alignItems:'center', paddingLeft:20, fontSize:17.5 }}>{item}</div>
                        <small>
                          <ul className="link-list">
                            <ContestTopicListItem
                              index={0}
                              // expand={
                              //   this.state.expand[item]
                              //     ? this.state.expand[item][0]
                              //     : false
                              // }
                              initialCapital={this.state.initialCapital}
                              topictitle={item}
                              // quizStatus={
                              //   this.state.quizStatus[item]
                              //     ? this.state.quizStatus[item]["Trader"]
                              //     : 2 // 2-Pending Status
                              // }
                              // tab={this.state.tab}
                              // attempts={
                              //   this.state.attempts[item]["Trader"]
                              //     ? this.state.attempts[item]["Trader"]
                              //     : 0
                              // }
                              contestid={this.state.contestid}
                              contestname={this.state.contestname}
                              prevGameStatus={
                                prevTopic
                                  ? this.state.gameStatus[prevTopic]
                                    ? this.state.gameStatus[prevTopic][
                                        "Advanced Market Maker"
                                      ]
                                    : 2
                                  : 0
                              }
                              gameStatus={
                                this.state.gameStatus[item]
                                  ? this.state.gameStatus[item]["Trader"]
                                  : 2
                              }
                              showCurriculum={(title: string, level: string) =>
                                this.showContestTopicItem(title, level)
                              }
                              // toggle={(index: number, topic: string) =>
                              //   this.toggleExpand(index, topic)
                              // }
                              topicname="Trader"
                            />

                            <ContestTopicListItem
                              index={1}
                              // expand={
                              //   this.state.expand[item]
                              //     ? this.state.expand[item][1]
                              //     : false
                              // }
                              // attempts={
                              //   this.state.attempts[item]["Market Maker"]
                              //     ? this.state.attempts[item]["Market Maker"]
                              //     : 0
                              // }
                              // quizStatus={
                              //   this.state.quizStatus[item]
                              //     ? this.state.quizStatus[item]["Market Maker"]
                              //     : 2
                              // }
                              contestid={this.state.contestid}
                              contestname={this.state.contestname}
                              prevGameStatus={
                                this.state.gameStatus[item]
                                  ? this.state.gameStatus[item]["Trader"]
                                  : 2
                              }
                              gameStatus={
                                this.state.gameStatus[item]
                                  ? this.state.gameStatus[item]["Market Maker"]
                                  : 2
                              }
                              // tab={this.state.tab}
                              topictitle={item}
                              showCurriculum={(title: string, level: string) =>
                                this.showContestTopicItem(title, level)
                              }
                              // toggle={(index: number, topic: string) =>
                              //   this.toggleExpand(index, topic)
                              // }
                              topicname="Market Maker"
                            />

                            <ContestTopicListItem
                              index={2}
                              // expand={
                              //   this.state.expand[item]
                              //     ? this.state.expand[item][2]
                              //     : false
                              // }
                              // attempts={
                              //   this.state.attempts[item]["Advanced Trader"]
                              //     ? this.state.attempts[item]["Advanced Trader"]
                              //     : 0
                              // }
                              // quizStatus={
                              //   this.state.quizStatus[item]
                              //     ? this.state.quizStatus[item][
                              //         "Advanced Trader"
                              //       ]
                              //     : 2
                              // }
                              contestid={this.state.contestid}
                              contestname={this.state.contestname}
                              prevGameStatus={
                                this.state.gameStatus[item]
                                  ? this.state.gameStatus[item]["Market Maker"]
                                  : 2
                              }
                              gameStatus={
                                this.state.gameStatus[item]
                                  ? this.state.gameStatus[item][
                                      "Advanced Trader"
                                    ]
                                  : 2
                              }
                              // tab={this.state.tab}
                              topictitle={item}
                              showCurriculum={(title: string, level: string) =>
                                this.showContestTopicItem(title, level)
                              }
                              // toggle={(index: number, topic: string) =>
                              //   this.toggleExpand(index, topic)
                              // }
                              topicname="Advanced Trader"
                            />

                            <ContestTopicListItem
                              index={3}
                              // expand={
                              //   this.state.expand[item]
                              //     ? this.state.expand[item][3]
                              //     : false
                              // }
                              // attempts={
                              //   this.state.attempts[item][
                              //     "Advanced Market Maker"
                              //   ]
                              //     ? this.state.attempts[item][
                              //         "Advanced Market Maker"
                              //       ]
                              //     : 0
                              // }
                              // quizStatus={
                              //   this.state.quizStatus[item]
                              //     ? this.state.quizStatus[item][
                              //         "Advanced Market Maker"
                              //       ]
                              //     : 2
                              // }

                              contestid={this.state.contestid}
                              contestname={this.state.contestname}
                              prevGameStatus={
                                this.state.gameStatus[item]
                                  ? this.state.gameStatus[item][
                                      "Advanced Trader"
                                    ]
                                  : 2
                              }
                              gameStatus={
                                this.state.gameStatus[item]
                                  ? this.state.gameStatus[item][
                                      "Advanced Market Maker"
                                    ]
                                  : 2
                              }
                              // tab={this.state.tab}
                              topictitle={item}
                              showCurriculum={(title: string, level: string) =>
                                this.showContestTopicItem(title, level)
                              }
                              // toggle={(index: number, topic: string) =>
                              //   this.toggleExpand(index, topic)
                              // }
                              topicname="Advanced Market Maker"
                            />
                          </ul>
                        </small>
                      </div>
                    );
                  })}
                </div>

                {/* </div> */}

                {/* </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(ContestTopic);
