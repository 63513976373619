import React, { Component } from "react";
import * as Scroll from 'react-scroll';
import { RouteComponentProps } from "react-router-dom";
import Nav from "../components/Nav";
import EmailUs from "../components/EmailUs";
import Footer from "../components/Footer.js";
import Scrolltop from "../components/Scrolltop";
import Loader from "../components/Loader";
import Login from "../components/Login";
import StudentInvite from "../components/StudentInvite";
import ContestStudentInvite from "../components/ContestStudentInvite";
import Sidebar from "../components/Sidebar";
import authStore from "../models/AuthStore";
import LoadingWheel from "../components/LoadingWheel";
import ClassCreatedModel from '../models/classCreated';
import StudentJoinedModel from '../models/studentJoined';
import CustAlert from '../models/cust_alert';

export interface AppListProps { }

export interface AppListState { }

export default class AppHome extends Component<any, AppListState> {
  constructor(props:any){
    super(props);
    this.state = {
      toggleTabs: true
    }
  }
  async componentDidMount() {
    // const script = document.createElement("script");

    // script.src = "https://use.typekit.net/foobar.js";
    // script.async = true;

    // document.body.appendChild(script);
    Scroll.Events.scrollEvent.register('begin', function (to, element) {
      console.log("begin", arguments);
    });

    Scroll.Events.scrollEvent.register('end', function (to, element) {
      console.log("end", arguments);
    });

    Scroll.scrollSpy.update();
  }

  async componentWillUnmount() {
    Scroll.Events.scrollEvent.remove('begin');
    Scroll.Events.scrollEvent.remove('end');
  }

  render() {
    return (
      <div className="top-bar">
        <Loader />
        {/* <EmailUs /> */}
        <Nav minimizeAllTabs={this.props.minimizeAllTabs}/>
        {/* {authStore.user ? <Sidebar /> : null} */}
        <div
          // style={{ paddingBottom: 80 }}
          className={authStore.user ? "content-wrapper " : "content-wrapper"}
        >
          {this.props.children}
        </div>
        <Footer user={this.props.user}/>
        <Scrolltop />
        <Login />
        {authStore.user ? <StudentInvite /> : null}
        {authStore.user ? <ContestStudentInvite /> : null}
        {/* <ClassCreatedModel onClose={""} data={{ link: '' }} location={""} /> */}
        {/* <StudentJoinedModel /> */}
      </div>
    );
  }
}
