import React from 'react';
import './showReportCard.scss'
import ftlogo from "../logo.png";

export default ({ classRecord, data, onClose }) => {
    return <div className="show_report_card" >
        <div>
            {/* title */}
            <div>
                <img
                    src={ftlogo}
                    width="33px"
                    height="25px"
                    alt="image"
                />
                <label>Report Card</label>
                <label onClick={onClose}>X</label>
            </div>
            {/* student details */}
            <div>
                <p><label>Name: </label> {data.quiz.sort((a, b) => b.timeStamp - a.timeStamp)[0].firstname + " " + data.quiz.sort((a, b) => b.timeStamp - a.timeStamp)[0].lastname}</p>
                <p><label>ClassName: </label> {classRecord.classname}</p>
                <p><label>PassingScore: </label>{classRecord.passingScore}%</p>
                <div>{new Date().toLocaleString()}</div>
            </div>
            {/* body */}
            <div>
                <table>
                    <thead>
                        <tr >
                            <th scope="col" > # </th>
                            <th style={{ textAlign: 'center', width: 120 }} scope="col" > Topic </th>
                            <th style={{ textAlign: 'left' }} scope="col" > Level </th>
                            <th style={{ textAlign: 'right' }} scope="col" > Score(%) </th>
                            {/* <th style={{ textAlign: 'right', width: 180 }} scope="col" > Passing Score(%) </th> */}
                            <th style={{ textAlign: 'center', width: 120 }} scope="col" > Result </th>
                            <th style={{ textAlign: 'right' }} scope="col" > Amount Earned($) </th>
                            <th style={{ textAlign: 'center' }} scope="col" ></th>
                        </tr >
                    </thead>
                    <tbody>
                        {
                            data.quiz.sort((a, b) => b.timeStamp - a.timeStamp).filter(x => x.status === 0).reverse().slice(0, 8).map((qz, j) => {
                                return <tr key={j} >
                                    <th scope="row" >{j + 1}</th>
                                    <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'center' }} > {qz.topic} </td>
                                    <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'left' }} > {qz.level} </td>
                                    <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > {((100 / (parseInt(qz.correct) + parseInt(qz.incorrect))) * qz.correct).toString().substr(0, 4)}% </td>
                                    {/* <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > {qz.passingScore}% </td> */}
                                    <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'center' }} > {qz.status == 0 ? "Pass" : qz.status == 3 ? "Fail" : "-"} </td>
                                    <td style={{ color: 'rgba(0,0,0,0.6)', textAlign: 'right' }} > ${qz.amount} </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* footer */}
            <div></div>
        </div>
    </div>
}