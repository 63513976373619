import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Toast from "../../components/Toast";
import { Professor } from "../../interfaces";
import ProfService from "../../services/profService";
import ROUTES from "../../ROUTES";
import LoadingWheel from "../../components/LoadingWheel";
import "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Constants from "../../Helpers/Constants/Constants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import Validate from "../../Helpers/Utility/Validation";
import moment from "moment";
import commonService from "../../services/commonService";
import { red } from "@material-ui/core/colors";
import PhoneCode from "../../components/PhoneCode";
import { isPossibleNumber } from "libphonenumber-js";
import CommonService from "../../services/commonService";
import undraw from "../../undraw.png";
import pSvg from "../../pSvg.svg";
import professorImg from "../../professorImg.svg";

declare var $: any;
export interface RegProps extends RouteComponentProps { }

class ProfessorReg extends Component<RegProps, any> {
  msg = "";

  notifySuccess = () => Toast.success(this.msg);
  notifyError = () => Toast.error(this.msg);
  constructor(props: any) {
    super(props);

    this.state = {
      Page: 1,
      profDetails: {} as Professor,
      error: {} as Professor,
      checkAccept: false,
      checkError: "",
      emailvalid: false,
      showLoader: false,
      countrycode: "",
      countryName: "",
      phone: "",
      isDateValueNull: false,
      isSubmit: false
    };

    this.state.profDetails.collegeCountry = "United States";
    this.state.profDetails.country = "United States";
    this.setState(
      Object.assign(this.state.profDetails, this.state.profDetails)
    );
  }

  async componentDidMount() {
    this.setDefaultValues();

    if (this.props.location.state) {
      await this.setState({ showLoader: true });
      let response = await ProfService.getProfessordetails();

      if (response) {
        await this.setState({ profDetails: response });
        this.setState({ showLoader: false });
      }
    }
  }
  //    return JSON.parse(localStorage.getItem('ticker') || '{}');
  // async getInitialState() {
  //     console.log("TEST: ", this.props.location.state);
  // if (this.props.location.state) {
  //         await this.setState({ showLoader: true })
  //         let response = await ProfService.getProfessordetails()

  //         if (response) {
  //             await this.setState({ profDetails: response })
  //             this.setState({ showLoader: false })
  //         }
  //     }
  // }

  setDefaultValues() {
    this.setState({ countrycode: "+1", countryName: "United States" });
    this.state.profDetails.dob = moment()
      .subtract(15, "years")
      .calendar();
    this.setState(
      Object.assign(this.state.profDetails, this.state.profDetails)
    );
  }

  changeHandler(e: any, type?: any) {
    if (type && e.target.value.length <= 100) {
      if (type == "text") {
        if (e.target.value.match(/^[A-Za-z\s]{0,}$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value.trimLeft();
          this.setState(Object.assign(this.state.profDetails, stateObject));
        }
      } else if (type == "number") {
        if (e.target.value.match(/^[\+]?[0-9]{0,12}$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value;
          this.setState(Object.assign(this.state.profDetails, stateObject));
        }
      } else if (type == "password") {
        if (e.target.value.match(/^[^\s]*$/)) {
          let stateObject = {} as any;
          stateObject[e.target.name] = e.target.value;
          this.setState(Object.assign(this.state.profDetails, stateObject));
        }
      }
    } else if (e.target.value.length <= 100) {
      let stateObject = {} as any;
      stateObject[e.target.name] = e.target.value.trimLeft();
      this.setState(Object.assign(this.state.profDetails, stateObject));
    }
  }

  checkHandler(e: any) {
    let checkAccept =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ checkAccept: checkAccept });
  }

  changeDOB = (value: any) => {
    if (value !== null) {
      if (value != "Invalid Date") {
        let date = String(value.getDate());
        let month = String(value.getMonth() + 1);
        let year = String(value.getFullYear());

        this.state.error.dob = "";
        this.state.profDetails.dob =
          year +
          (month.length == 1 ? "-0" : "-") +
          month +
          (date.length == 1 ? "-0" : "-") +
          date;

        this.setState(Object.assign(this.state.error, this.state.error));
        this.setState(
          Object.assign(this.state.profDetails, this.state.profDetails)
        );
        this.setState({ isDateValueNull: false });
      } else {
        this.setState({ isDateValueNull: true });
      }
    } else {
      this.state.profDetails.dob = value;
      this.setState(
        Object.assign(this.state.profDetails, this.state.profDetails)
      );
    }
  };

  async changePhoneNumber(e: any) {
    if (e.target.name != "phone" || e.target.value.match(/^[0-9]{0,12}$/)) {
      await this.setState({ [e.target.name]: e.target.value }, () => {
        this.state.profDetails.phonenumber =
          this.state.countrycode + this.state.phone;
      });
    }
    Object.assign((this.state.profDetails, this.state.profDetails));
  }

  async changePhoneNumberCode(code: any) {
    await this.setState(() => {
      this.state.profDetails.phonenumber = code + this.state.phone;
    });
  }

  isAnyErrorFound(error: any) {
    this.setState(Object.assign(this.state.error, error.errors));
  }

  async validatePage() {
    this.setState({ showLoader: true });

    let formvalid = true;
    const errors: { [index: string]: any } = {};

    let formError;
    formError = await Validate.validateName(
      this.state.profDetails.firstname,
      "firstname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateName(
      this.state.profDetails.lastname,
      "lastname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateEmail(
        this.state.profDetails.email,
        "email",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validatePhone(
        this.state.profDetails.phonenumber,
        "phonenumber",
        true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }

    formError = await Validate.validateCollegeName(
      this.state.profDetails.collegeName,
      "collegeName"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    if (!(this.props.location.state && this.props.location.state.id)) {
      let formError;

      formError = await Validate.validatePassword(
        this.state.profDetails.password,
        "password"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.confirmPassword(
        this.state.profDetails.password,
        this.state.profDetails.confirmPassword,
        "confirmPassword"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validateCheckBox(
        this.state.checkAccept,
        "checkError"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }

    if (formvalid == true) {
      if (this.props.location.state) {
        this.uodateProfessor();
      } else {
        this.createProfessor();
      }
    } else {
      this.setState({ isSubmit: false });
      this.setState({ showLoader: false });
    }

    return formvalid;
  }

  async validatePage1() {
    let formvalid = true;
    let str = String(this.state.profDetails.phonenumber).substring(0, 1);

    if (this.state.profDetails.country && str != "+") {
      let country = await Constants.countries.filter(item => {
        if (item.name == this.state.profDetails.country) {
          return item;
        }
      });
      this.state.profDetails.phonenumber =
        country[0].dial_code + this.state.profDetails.phonenumber;
    }

    let formError;
    formError = await Validate.validateName(
      this.state.profDetails.firstname,
      "firstname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateName(
      this.state.profDetails.lastname,
      "lastname"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    formError = await Validate.validateDOB(
      this.state.profDetails.dob,
      "dob",
      this.state.isDateValueNull
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }
    if (!(this.props.location.state && this.props.location.state.id)) {
      formError = await Validate.validateEmail(
        this.state.profDetails.email,
        "email",
        this.props.location.state ? false : true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validatePhone(
        this.state.profDetails.phonenumber,
        "phonenumber",
        this.props.location.state ? false : true
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }
    // formError = await Validate.validateCity(this.state.profDetails.city, "city");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateState(this.state.profDetails.state, "state");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateZip(this.state.profDetails.zip, "zip");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    if (formvalid == true) {
      this.setState({ Page: 2 });
    }
  }
  async validatePage2() {
    this.setState({ showLoader: true });
    let formvalid = true;
    let formError;

    formError = await Validate.validateCollegeName(
      this.state.profDetails.collegeName,
      "collegeName"
    );
    if (!formError.isValid) {
      this.isAnyErrorFound(formError);
      formvalid = false;
    }

    // formError = await Validate.validateCity(this.state.profDetails.collegeCity, "collegeCity");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateState(this.state.profDetails.collegeState, "collegeState");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    // formError = await Validate.validateZip(this.state.profDetails.collegeZip, "collegeZip");
    // if (!formError.isValid) {
    //     this.isAnyErrorFound(formError);
    //     formvalid = false;
    // }

    this.setState({ showLoader: false });
    if (formvalid == true) {
      this.setState({ Page: 3 });
    }
  }
  async validatePage3() {
    let formvalid = true;
    let formError;
    if (!this.props.location.state) {
      formError = await Validate.validatePassword(
        this.state.profDetails.password,
        "password"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.confirmPassword(
        this.state.profDetails.password,
        this.state.profDetails.confirmPassword,
        "confirmPassword"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }

      formError = await Validate.validateCheckBox(
        this.state.checkAccept,
        "checkError"
      );
      if (!formError.isValid) {
        this.isAnyErrorFound(formError);
        formvalid = false;
      }
    }
    if (formvalid == true) {
      if (this.props.location.state) {
        this.uodateProfessor();
      } else {
        this.createProfessor();
      }
    } else {
      this.setState({ isSubmit: false });
    }

    return formvalid;
  }

  async createProfessor() {
    this.setState({ showLoader: true });
    let response = await ProfService.createProfessor(this.state.profDetails);
    this.setState({ showLoader: false });
    console.log("response" + response);
    if (response) {
      if (response.status) {
        if (response.status == 200) {
          this.msg = response.msg;
          this.notifySuccess();
          setTimeout(() => {
            this.props.history.push(ROUTES.HOME);
            $("#exampleModal").modal("show");
          }, 2000);
        } else {
          this.msg = response.msg;
          this.notifyError();
          this.setState({ isSubmit: false });
        }
      } else {
        this.msg = response.msg;
        this.notifyError();
        this.setState({ isSubmit: false });
      }
    }
  }

  async uodateProfessor() {
    this.setState({ showLoader: true });
    let response = await ProfService.updateProfessor(this.state.profDetails);
    this.setState({ showLoader: false });
    if (response) {
      if (response.status == 200) {
        this.msg = response.msg;
        // console.log("after notifySuccess")
        // console.log("after notifySuccess")
        await this.notifySuccess();
        setTimeout(() => {
          this.setState({ Page: 1, isSubmit: false });
          this.props.history.push(ROUTES.HOME);
        }, 2000);
      } else {
        this.msg = response.msg;
        this.notifyError();
      }
    }
  }

  render() {
    return (
      <div className="section wb">
        <div className="jumbotron backgroundImage">
          <div
            style={{ backgroundColor: "#1a73e8", height: "6vh" }}
            className=" container registration-title"
          ></div>
        </div>
        <div className="container cmt20">
          <div className="row equal">
            <div
              className="col-md-5 reg-card btrr"
              style={{ paddingTop: "20px" }}
            >
              <div
                className="wizard"
                id="myWizard"
                style={{ paddingTop: "5vh" }}
              >
                <div className="step" style={{ textAlign: "center" }}>
                  <img src={professorImg} width="320" height="auto" alt="" />
                  <div
                    className="row mt-15 mb-15 info highlight"
                    style={{ paddingTop: "12px" }}
                  >
                    <h2 className="col-md-12 " style={{ textAlign: "center" }}>
                      Professor Registration
                    </h2>
                  </div>
                  <p className="form-left-text" style={{ paddingTop: "20px" }}>
                    Professors and Teachers can augment their classes by
                    leveraging our innovative and seamless learning platform. In
                    less than five minutes, you can complete Registration and
                    set up your first class. Then on, it's a breeze.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-7 col-sm-12 reg-card btlr"
              style={{ paddingTop: "20px" }}
            >
              <div className="wizard" id="myWizard">
                <div className="step">
                  <div className="row info-head">
                    <div className="col-md-12">
                      <div className="row mt-15 mb-15">
                        <h3 className="col-md-6 ">Personal Information</h3>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Name<span className="mandtory-star">*</span>{" "}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        placeholder="First Name"
                        // disabled={
                        //   this.props.location.state &&
                        //   this.props.location.state.id
                        // }
                        value={
                          this.state.profDetails.firstname
                            ? this.state.profDetails.firstname
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateName(
                            this.state.profDetails.firstname,
                            "firstname"
                          );
                          this.setState(
                            Object.assign(this.state.error, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e, "text")}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.error.firstname}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        placeholder="Last Name"
                        // disabled={
                        //   this.props.location.state &&
                        //   this.props.location.state.id
                        // }
                        value={
                          this.state.profDetails.lastname
                            ? this.state.profDetails.lastname
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateName(
                            this.state.profDetails.lastname,
                            "lastname"
                          );
                          this.setState(
                            Object.assign(this.state.error, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e, "text")}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.error.lastname}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Email<span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="email"
                        name="email"
                        disabled={this.props.location.state}
                        className="form-control"
                        placeholder="Email"
                        value={
                          this.state.profDetails.email
                            ? this.state.profDetails.email
                            : ""
                        }
                        onBlur={async () => {
                          let error = await Validate.validateEmail(
                            this.state.profDetails.email,
                            "email",
                            true
                          );
                          this.setState(
                            Object.assign(this.state.error, error.errors)
                          );
                        }}
                        onChange={e => this.changeHandler(e)}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.error.email}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      Phone Number<span className="mandtory-star">*</span>
                    </div>
                    {this.props.location.state ? (
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="phonenumber"
                          className="form-control"
                          placeholder="Phone Number"
                          disabled={true}
                          value={this.state.profDetails.phonenumber}
                        />
                      </div>
                    ) : (
                        <div>
                          <div className="col-md-4">
                            <PhoneCode
                              onSelect={(code: any, name: any) => {
                                this.setState({
                                  countrycode: code,
                                  countryName: name
                                });
                                this.changePhoneNumberCode(code);
                              }}
                              defaultValue={
                                this.state.countrycode
                                  ? this.state.countryName
                                  : "Select Country"
                              }
                              className="form-control"
                            />
                          </div>

                          <div className="col-md-4">
                            <input
                              type="text"
                              name="phone"
                              className="form-control phone-no-width"
                              placeholder="Phone Number"
                              value={this.state.phone ? this.state.phone : ""}
                              onBlur={async () => {
                                let error = (await Validate.validatePhone(
                                  this.state.phone,
                                  "phonenumber"
                                )) as any;
                                if (error.phonenumber == "") {
                                  let res = await commonService.verifyPhoneNumber(
                                    this.state.profDetails.phonenumber
                                  );
                                  if (res.status != 200) {
                                    error.phonenumber = res.msg;
                                  }
                                }
                                this.setState(
                                  Object.assign(this.state.error, error.errors)
                                );
                              }}
                              onChange={e => this.changePhoneNumber(e)}
                            />
                            <div className="validation-error">
                              {this.state.error.phonenumber &&
                                this.state.error.phonenumber != ""
                                ? this.state.error.phonenumber
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="row mt-15 mb-15 info-head">
                    <h3 className="col-md-6  ">School/College Information</h3>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">
                      School/College Name
                      <span className="mandtory-star">*</span>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        name="collegeName"
                        className="form-control"
                        placeholder="School/College Name"
                        onBlur={async () => {
                          let error = await Validate.validateCollegeName(
                            this.state.profDetails.collegeName,
                            "collegeName"
                          );
                          this.setState(
                            Object.assign(this.state.error, error.errors)
                          );
                        }}
                        value={
                          this.state.profDetails.collegeName
                            ? this.state.profDetails.collegeName
                            : ""
                        }
                        onChange={e => this.changeHandler(e)}
                      />
                      <div className="validation-error">
                        {" "}
                        {this.state.error.collegeName}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-15 mb-15 info-head">
                    <h3 className="col-md-6 ">Login Information</h3>
                  </div>
                  <div className="row mt-10 mb-10">
                    <div className="col-md-4">Email</div>
                    <div className="col-md-8">
                      <input
                        type="email"
                        className="form-control"
                        id="copyPreviousclass"
                        aria-describedby="previousclass"
                        disabled={true}
                        value={this.state.profDetails.email}
                      />
                    </div>
                  </div>
                  {!this.props.location.state ? (
                    <div className="row mt-10 mb-10">
                      <div className="col-md-4">
                        {" "}
                        Password<span className="mandtory-star">*</span>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="password"
                          autoComplete="new-password"
                          name="password"
                          className="form-control"
                          placeholder="Min 6 characters"
                          onBlur={async () => {
                            let error = await Validate.validatePassword(
                              this.state.profDetails.password,
                              "password"
                            );
                            this.setState(
                              Object.assign(this.state.error, error.errors)
                            );
                          }}
                          value={
                            this.state.profDetails.password
                              ? this.state.profDetails.password
                              : ""
                          }
                          onChange={e => this.changeHandler(e, "password")}
                        />
                        <div className="validation-error">
                          {" "}
                          {this.state.error.password}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!this.props.location.state ? (
                    <div className="row mt-10 mb-10">
                      <div className="col-md-4">
                        {" "}
                        Confirm Password<span className="mandtory-star">*</span>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="password"
                          autoComplete="new-password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Min 6 characters"
                          onBlur={async () => {
                            let error = await Validate.confirmPassword(
                              this.state.profDetails.password,
                              this.state.profDetails.confirmPassword,
                              "confirmPassword"
                            );
                            this.setState(
                              Object.assign(this.state.error, error.errors)
                            );
                          }}
                          value={
                            this.state.profDetails.confirmPassword
                              ? this.state.profDetails.confirmPassword
                              : ""
                          }
                          onChange={e => this.changeHandler(e, "password")}
                        />
                        <div className="validation-error">
                          {" "}
                          {this.state.error.confirmPassword}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mt-10 mb-10">
                    <div className="col-md-12 ">
                      {!this.props.location.state ? (
                        <form className="trading-rule-chk-box mt-10">
                          <label className="checkbox-inline">
                            <input
                              type="checkbox"
                              value=""
                              name="accept"
                              onChange={e => this.checkHandler(e)}
                              checked={this.state.checkAccept}
                              className="chk-box-reg"
                            />
                            <div className="checkLabel">
                              I accept the
                              <a
                                className="terms-conditions"
                                href={"./terms.html"}
                                target="_blank"
                              >
                                {" "}
                                Terms and Conditions
                              </a>{" "}
                              and Privacy Policy of this site
                            </div>
                          </label>
                          <div className="validation-error">
                            {" "}
                            {this.state.error.checkError}
                          </div>
                        </form>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="row mt-10 mb-10"
                    style={{ float:'right', paddingRight:16 }}
                  >
                    <button
                      className="btn btn-primary next-btn"
                      disabled={this.state.isSubmit}
                      onClick={() => {
                        this.setState({ isSubmit: true });
                        this.validatePage();
                      }}
                    >
                      {this.props.location.state && this.props.location.state.id
                        ? "Update"
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="step validation-error">
                All fields marked with an asterisk are required.
              </div>
            </div>
          </div>
        </div>
        {/* {Toast.successToastView()}
        {Toast.errorToastView()} */}
        {this.state.showLoader ? <LoadingWheel /> : null}
      </div>
    );
  }

  showError() { }
}

export default withRouter(ProfessorReg);
