import { Components } from "react";
import Config from "../config/Config";
import * as firebase from "../config/firebase";
import authStore from "../models/AuthStore";
import { Post, Delete, Get } from "./apiServices";

class ClassService {
  constructor() { }

  async createClass(classDetails) {
    const res = await Post(
      Config.base_url + "/classManager/create_class",
      authStore.authToken,
      JSON.stringify(classDetails)
    ).then((res) => res.json());
    return res;
    // try {
    //   let response = 

    //   if (response.status == 200) {
    //     return { status: 200, msg: "Success" };
    //   } else if (response.status == 401) {
    //     return { status: 401, msg: "Unauthorized" };
    //   } else {
    //     return { msg: "Something went wrong.Please try again later" };
    //   }
    // } catch (error) {
    //   return {
    //     status: 400,
    //     msg: "Something went wrong.Please try again later"
    //   };
    // }
  }

  async updateClass(classDetails) {
    try {
      let data = await Post(
        Config.base_url + "/classManager/update_class",
        authStore.authToken,
        JSON.stringify(classDetails)
      );
      return { status: 200, msg: "Class details updated successfully" };
    } catch (error) {
      console.log("Error: ", error);
      return { status: 400, msg: "Failed" };
    }
  }

  async deleteClass(classid) {
    try {
      let response = await Delete(
        Config.base_url + "/classManager/update_class",
        authStore.authToken,
        JSON.stringify({ classID: classid })
      );

      if (response.status == 200) {
        return { msg: "Success", status: 200 };
      } else if (response.status == 404) {
        return { msg: "Document Not Found", res: response, status: 400 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized User", res: response, status: 401 };
      } else {
        return { msg: "Something went wrong.Please try again later" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong.Please try again later"
      };
    }
  }

  async getClassbyId(classid) {
    try {
      let response = await Post(
        Config.base_url + "/classManager/get_class_by_id",
        authStore.authToken,
        JSON.stringify({ classID: classid })
      );
      if (response.status == 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status == 404) {
        return { msg: "Document Not Found", status: 400 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized User", status: 401 };
      } else {
        return { msg: "Something went wrong.Please try again later" };
      }
    } catch (error) {
      return {
        status: 404,
        msg:
          "You are trying to access an invalid class. Please make sure the URL is correct"
      };
    }
  }

  async getClassbyName(classname) {
    try {
      let response = await Post(
        Config.base_url + "/classManager/get_class_by_name",
        authStore.authToken,
        JSON.stringify({ classname: classname })
      );
      if (response.status == 200) {
        let data = await response.json();
        return { msg: "Success", data: data[0], status: 200 };
      } else if (response.status == 404) {
        return { msg: "Document Not Found", status: 400 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized User", status: 401 };
      } else {
        return { msg: "Something went wrong.Please try again later" };
      }
    } catch (error) {
      return {
        status: 400,
        msg: "Something went wrong.Please try again later"
      };
    }
  }

  async registerClass(params) {
    try {
      let response = await Post(
        Config.base_url + "classManager/invite",
        authStore.authToken,
        JSON.stringify(params)
      );
      let data = await response.json();
      if (response.status === 200) {
        return { msg: "Success", res: data, status: 200 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized", res: data, status: 200 };
      } else if (response.status === 404) {
        return {
          msg: "Not Found",
          res: data,
          email: params.email,
          status: 404
        };
      } else if (data == "Maximum number of students reached!") {
        return { msg: data, status: 402 };
      } else {
        return {
          msg:
            "You are trying to access an invalid class. Please make sure the URL is correct",
          res: data,
          status: 400
        };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async registerClassWithUsername(params) {
    try {
      let response = await Post(
        Config.base_url + "classManager/invite_by_username",
        authStore.authToken,
        JSON.stringify(params)
      );
      let data = await response.json();
      if (response.status === 200) {
        return { msg: "Success", res: data, status: 200 };
      } else if (response.status == 401) {
        return { msg: "Unauthorized", res: data, status: 200 };
      } else if (response.status === 404) {
        return {
          msg: "Not Found",
          res: data,
          email: params.email,
          status: 404
        };
      } else if (data == "Maximum number of students reached!") {
        return { msg: data, status: 402 };
      } else {
        return {
          msg:
            "You are trying to access an invalid class. Please make sure the URL is correct",
          res: data,
          status: 400
        };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getAllClassForProfessor() {
    try {
      let response = await Get(
        Config.base_url + "classManager/get_classes_for_professor",
        authStore.authToken
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 404) {
        return { msg: "Not Found", status: 404 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }
  async getAllClassesForTeacher() {
    try {
      let response = await Get(
        Config.base_url + "classManager/get_classes_for_teacher",
        authStore.authToken
      );
      if (response.status === 200) {
        let data = await response.json();
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 404) {
        return { msg: "Not Found", status: 404 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }

  async getAllClassesForStudent() {
    try {
      let response = await Get(
        Config.base_url + "classManager/get_classes_for_student",
        authStore.authToken
      );
      // console.log("Response"+response.status);
      if (response.status === 200) {
        let data = await response.json();
        console.log(data);
        return { msg: "Success", data: data, status: 200 };
      } else if (response.status === 401) {
        return { msg: "Unauthorized", status: 401 };
      } else {
        return { msg: "Failed", status: 400 };
      }
    } catch (error) {
      return {
        msg: "Failed",
        res: "Something went wrong.Please try again later",
        status: 400
      };
    }
  }
}

const ClasService = new ClassService();
export default ClasService;
